import {uniq} from 'lodash';

export const NODE = 'node';
export const OUT = 'out';
export const IN = 'in_';

export const namedNodesFunctions = new Set([NODE, OUT, IN]);

export const GRAPH_QUERY_FUNCTIONS = [
  {name: NODE, description: 'Starts a path query.'},
  {name: 'match', description: 'Groups multiple path queries.'},
  {name: 'optional', description: 'Makes an optional path.'},
];

/* eslint-disable max-len */

export const GRAPH_QUERY_METHODS = [
  {name: NODE, description: 'Updates criteria for current node.'},
  {name: OUT, description: 'Navigates through out relationships with given criteria from current node and sets current node to relationship target node.'},
  {name: IN, description: 'Navigates through in relationships with given criteria to current node and sets current node to relationship source node.'},
  {name: 'having', description: 'Filters path by executing subquery with given nodes/relationships with given names being same as in current path. If number of results does not fit into given limits, rejects the path.'},
  {name: 'where', description: 'Filters path by evaluating given predicate function for path nodes/relationships with given names. If names are not given, it infers names from names of function arguments.'},
  {name: 'ensure_different', description: 'Ensures that nodes/relationships with given names are unique among each other.'},
  {name: 'distinct', description: 'Ensures that all paths contain different combinations of nodes/relationships with given names. If names are not given, all path names to this point is assumed.'},
];

const GRAPH_QUERY_SINGLE_PATH_METHODS = ['having', 'where', 'ensure_different'];
const GRAPH_QUERY_MULTI_PATH_METHODS = [...GRAPH_QUERY_SINGLE_PATH_METHODS, 'distinct'];

export const GRAPH_QUERY_ALL_METHODS = uniq([NODE, IN, OUT, ...GRAPH_QUERY_SINGLE_PATH_METHODS, ...GRAPH_QUERY_MULTI_PATH_METHODS]);

export const GRAPH_QUERY_METHODS_MAP = {
  [NODE]: [OUT, IN, ...GRAPH_QUERY_SINGLE_PATH_METHODS],
  [OUT]: [NODE, ...GRAPH_QUERY_SINGLE_PATH_METHODS],
  [IN]: [NODE, ...GRAPH_QUERY_SINGLE_PATH_METHODS],
  match: GRAPH_QUERY_MULTI_PATH_METHODS,
  having: GRAPH_QUERY_MULTI_PATH_METHODS,
  where: GRAPH_QUERY_MULTI_PATH_METHODS,
  ensure_different: GRAPH_QUERY_MULTI_PATH_METHODS,
  distinct: GRAPH_QUERY_MULTI_PATH_METHODS,
};

export const GRAPH_QUERY_KWARGS = {
  [NODE]: [
    {name: 'type', type: 'string', description: 'Node type.'},
    {name: 'name', type: 'string', description: 'Name in the query to assign to the node.', score: 2500},
    {name: 'tag', type: 'string', description: 'Node tag.', score: 2400},
    {name: 'id', type: 'string', description: 'Node ID.'},
  ],
  [OUT]: [
    {name: 'type', type: 'string', description: 'Relationship type.'},
    {name: 'name', type: 'string', description: 'Name in the query to assign to the relationship.'},
    {name: 'id', type: 'string', description: 'Relationship ID.'},
  ],
  [IN]: [
    {name: 'type', type: 'string', description: 'Relationship type.'},
    {name: 'name', type: 'string', description: 'Name in the query to assign to the relationship.'},
    {name: 'id', type: 'string', description: 'Relationship ID.'},
  ],
  where: [
    {name: 'predicate', type: 'function', description: 'Function to filter paths with.'},
    {name: 'names', type: 'array', description: 'Names of nodes/relationships to pass to predicate. If None, it defaults to predicate function argument names.'},
  ],
  having: [
    {name: 'query', description: 'Subquery to evaluate.'},
    {name: 'names', type: 'array', description: 'List of nodes/relationships that should be same between path and subquery paths. If None, it assumes all named nodes/relationships in subquery should be the same as nodes/relationships with same names in current path.'},
    {name: 'at_least', type: 'number', description: 'Minimum required number of subquery matches. If None - unbounded.'},
    {name: 'at_most', type: 'number', description: 'Maximum required number of subquery matches. If None - unbounded.'},
    {name: 'inverse', type: 'boolean', description: 'If True, inverse range (select results that are outside given range instead of those that are in range). Default is False.'},
  ],
  distinct: [
    {name: 'names', type: 'array', description: 'Names of objects which combinations should be unique. If not specified, all path names up to this point is assumed'},
  ],
};

export const GRAPH_QUERY_MATCHERS = [
  {name: 'eq', description: 'Checks if the property is equal to the argument.'},
  {name: 'aeq', description: 'Checks if the property matches the provided glob pattern.'},
  {name: 'ne', description: 'Checks if the property is not equal to the argument.'},
  {name: 'gt', description: 'Checks if the property is greater than the argument.'},
  {name: 'ge', description: 'Checks if the property is greater than or equal to the argument.'},
  {name: 'lt', description: 'Checks if the property is less than the argument.'},
  {name: 'le', description: 'Checks if the property is less than or equal to the argument.'},
  {name: 'is_none', description: 'Checks if the property is None.'},
  {name: 'not_none', description: 'Checks if the property is not None.'},
  {name: 'is_in', description: 'Checks if the property is in the provided set.'},
  {name: 'not_in', description: 'Checks if the property is not in the provided set.'},
  {name: 'has_keys', description: 'Checks if the property has all the keys from the provided set.'},
  {name: 'has_items', description: 'Checks if the property has all the items from the provided set.'},
  {name: '_and', description: 'Checks if the property passes checks from all the provided matchers.'},
  {name: '_or', description: 'Checks if the property passes checks from at least one of the provided matchers.'},
  {name: '_not', description: 'Negates the function of the provided matcher.'},
];

export const GRAPH_QUERY_TAG_MATCHERS = [
  {name: 'has_all', description: 'Checks if the node has all the tags from the provided set.'},
  {name: 'has_any', description: 'Checks if the node has at least one tag from the provided set.'},
  {name: 'has_none', description: 'Checks if the node has none of the tags from the provided set.'},
];
