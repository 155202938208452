/* eslint-disable camelcase */
import {NODE_STATUSES} from './consts';
import {ALL_NODE_ROLES, LINK_ROLES} from '../../../roles';

export interface INode {
  role: ALL_NODE_ROLES,
  id: string;
  layer_status?: typeof NODE_STATUSES[number];
  anomaly?: any;
  external?: boolean;
  access_group_id?: string | null;
  rack_id?: string | null;
  pod_id?: string | null;
  label?: string;
  superspine_plane_id?: string | null;
  part_of?: string;
  'composed-of'?: string[];
}

export interface ILink {
  id: string;
  endpoints: ILinkEndpoints;
  role: LINK_ROLES;
  label: string;
}

export type ILinkEndpoints = ILinkEndpoint[];

export interface ILinkEndpoint {
  id: string;
  if_id: string;
  if_name: string;
  label: string;
  ipv4_addr: string | null;
  ipv6_addr: string | null;
  role: ALL_NODE_ROLES
  operation_state: string | null
  tags: string[] | null;
}

export interface IPlane {
  id: string;
  label: string;
}

export interface IPod {
  id: string;
  label: string;
  global_catalog_id?: string;
  position: number;
}

export interface IRack {
  rack_id: string;
  pod_id: string;
  label: string;
}

export interface ISecurityZone {
  id: string;
  label: string;
}

export interface IVlan {
  endpoints: string[];
  id: string;
  label: string;
  security_zone_id: string;
}

export interface IAccessGroup {
  pod_id: string;
  rack_id: string;
  access_group_id: string;
  label: string;
}

export type Size = {
  width: number;
  height: number;
}

export type Layout = {
  left: number,
  top: number,
  width: number,
  height: number
}

export type Padding = {
  left: number,
  top: number,
  right: number,
  bottom: number
}

export const emptyLayout = (): Layout => ({width: 0, height: 0, left: 0, top: 0});

export type Point = {
  x: number;
  y: number;
}

export type SetLayoutFn = (layout: Layout) => void;

export enum LayoutType {
  Column = 'column',
  Node = 'node',
  Pad = 'pad',
  NodePair = 'node pair',
  Row = 'row',
  Stack = 'stack',
  VlanBus = 'vlan bus',
}

export type TopologySettingsType = {
  expandNodes?: boolean;
  toggledNodes?: string[];
  showLinks?: boolean;
  showSpines?: boolean;
  showSuperspines?: boolean;
  showLeafs?: boolean;
}

export type TopologySettingsField = keyof TopologySettingsType;

export type LinkData = {
  point1: Point;
  point2: Point;
  sourceLink: ILink;
}

export enum PairRendering {
  Left = 'left',
  Right = 'right',
}

export type TopologyData = {
  links: ILink[];
  nodes: INode[];
  externalRouters: INode[];
  superspinesByPlanes: Record<string, INode[]>;
  spinesByPods: Record<string, INode[]>;
  leafsByRacks: Record<string, INode[]>;
  leafPairsByRacks: Record<string, INode[]>;
  accessSwitchesByRacks: Record<string, INode[]>;
  genericsByRacks: Record<string, INode[]>;
  accessSwitchesByAccessGroups: Record<string, INode[]>;
  accessSwitchPairsByAccessGroups: Record<string, INode[]>;
  genericsByAccessGroups: Record<string, INode[]>;
  rackByPods: Record<string, IRack[]>;
  accessGroupsByRacks: Record<string, IAccessGroup[]>;
  indexedNodes: Record<string, INode>;
  indexedRawNodes: Record<string, INode>;
  nodeByRoles: Record<string, INode[]>;
  indexedRacks: Record<string, IRack> | null;
  indexedPods: Record<string, IPod> | null;
  indexedPlanes: Record<string, IPlane>;
  indexedAccessGroups: Record<string, IAccessGroup>;
  indexedVlans: Record<string, IVlan>;
  hasAccessGroups: boolean;
  hasVlans: boolean;
  securityZones: ISecurityZone[];
  vlansBySecurityZone: Record<string, IVlan[]>;
  vlans: IVlan[];
}
