import {useMemo} from 'react';
import {Group} from '@visx/group';
import {Arc, Pie} from '@visx/shape';
import cx from 'classnames';
import {Spring, animated, to, useTransition} from '@react-spring/web';
import {forEach, uniqueId} from 'lodash';
import {useResizeDetector} from 'react-resize-detector';
import {formatNumber} from 'apstra-ui-common';
import {scaleOrdinal} from 'd3';

import ChartLegend, {useHoveredLegendItem} from './ChartLegend';
import {TooltipPopup, TooltipProvider, useTooltip} from './GraphTooltips';

import './DonutChart.less';

export const Filter3d = ({filterId}) => {
  return (
    <filter id={filterId}>
      <feGaussianBlur in='SourceAlpha' stdDeviation='9' />
      <feSpecularLighting
        surfaceScale='3' specularConstant='.75'
        specularExponent='17' lightingColor='#fefefe'
      >
        <fePointLight x='50' y='-30000' z='20000' />
      </feSpecularLighting>
      <feComposite in2='SourceAlpha' operator='in' />
      <feComposite
        in='SourceGraphic' operator='arithmetic'
        k1='0' k2='1' k3='1' k4='0'
      />
    </filter>
  );
};

const DonutChart = ({
  pie,
  cornerRadius = 5,
  thickness = 5,
  className, onClick,
  onSectorClick,
  values, children,
  width: propsWidth,
  withLegend = false,
  showPieLabels = true
}) => {
  const {ref, width = propsWidth || 100} = useResizeDetector({handleHeight: false, handleWidth: !propsWidth});
  const radius = 0.5 * width;
  const backgroundArcThickness = 2.5 * thickness;
  const donutOuterRadius = radius - 0.5 * (backgroundArcThickness - thickness);

  const filterId = useMemo(() => uniqueId('donut-chart-shadow-filter'), []);

  const [ordinalColorScale, legendDescriptionMap] = useMemo(() => {
    const domain = [];
    const range = [];
    const legendDescriptionMap = {};
    forEach(values, ({id, label, value, units, color}) => {
      domain.push(id);
      legendDescriptionMap[id] = {
        name: label,
        value: formatNumber(value, {units, short: true}),
      };
      range.push(color);
    });
    return [
      scaleOrdinal()
        .domain(domain)
        .range(range),
      legendDescriptionMap,
    ];
  }, [values]);

  const {onMouseOver, onMouseOut, hoveredItem} = useHoveredLegendItem();

  return (
    <div className='donut-chart-wrapper'>
      <div
        ref={ref}
        className={cx('donut-chart', className, {pie})}
        style={propsWidth ? {width: propsWidth, height: propsWidth} : undefined}
      >
        <TooltipProvider>
          <svg width={width} height={width} onClick={onClick}>
            <defs>
              <Filter3d filterId={filterId} />
            </defs>
            <Group top={radius} left={radius}>
              <Arc
                className='donut-chart-background-arc'
                startAngle={0} endAngle={360}
                innerRadius={pie ? 0 : radius - backgroundArcThickness} outerRadius={radius}
                filter={`url(#${filterId})`}
              />
              <Pie
                data={values.filter(({value}) => !!value)}
                pieValue={({value}) => value}
                outerRadius={donutOuterRadius}
                innerRadius={pie ? 0 : donutOuterRadius - thickness}
                cornerRadius={pie ? 0 : cornerRadius}
                pieSortValues={() => 0}
              >
                {(pieData) => (
                  <AnimatedPie
                    {...pieData}
                    getKey={(arc) => arc.data.id}
                    onClickDatum={({data: {id}}) => onSectorClick?.(id)}
                    filter={!pie ? `url(#${filterId})` : undefined}
                    padAngle={pie ? 0 : undefined}
                    showLabel={pie && showPieLabels}
                    onMouseOut={onMouseOut}
                    onMouseOver={onMouseOver}
                    hoveredItem={hoveredItem}
                  />
                )}
              </Pie>
            </Group>
          </svg>
          <TooltipPopup />
        </TooltipProvider>
        {children && <div className='children-container'>{children}</div>}
      </div>
      {withLegend && (
        <ChartLegend
          ordinalColorScale={ordinalColorScale}
          legendDescriptionMap={legendDescriptionMap}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
          hoveredItem={hoveredItem}
        />
      )}
    </div>
  );
};

const enterUpdateTransition = ({startAngle, endAngle}) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

function AnimatedPie({
  padAngle = 0.05,
  minLabelRenderAngle = 0.3,
  arcs,
  path,
  getKey,
  onClickDatum,
  filter,
  showLabel,
  onMouseOut,
  onMouseOver,
  hoveredItem,
}) {
  const {sharedTooltip} = useTooltip();
  const transitions = useTransition(arcs, {
    from: enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: enterUpdateTransition,
    keys: getKey,
  });
  return transitions((props, arc, {key}) => {
    const {tooltip} = arc.data;
    const [centroidX, centroidY] = path.centroid(arc);
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= minLabelRenderAngle;

    return (
      <g
        key={key}
        className={cx('donut-chart-arc-area', {hovered: hoveredItem === arc.data.id})}
        onClick={() => onClickDatum(arc)}
        onTouchStart={() => onClickDatum(arc)}
        onMouseOut={() => {
          onMouseOut();
          sharedTooltip.hide();
        }}
        onMouseOver={() => {
          onMouseOver(arc.data.id);
          sharedTooltip.show(tooltip, true);
        }}
      >
        <animated.path
          className={cx('donut-chart-arc', `graph-color-${arc.data.color}`, {faded: arc.data.faded})}
          d={to([props.startAngle, props.endAngle],
            (startAngle, endAngle) => path({...arc, startAngle, endAngle, padAngle}))}
          filter={filter}
        />
        {
          <Spring
            to={{centroidX, centroidY}}
          >
            {({centroidX, centroidY}) =>
              <animated.text
                className='donut-chart-arc-label'
                data-test-arc-label-id={arc.data.id} // element is used to define position for click in tests
                x={centroidX}
                y={centroidY}
                textAnchor='middle'
              >
                {(showLabel && hasSpaceForLabel && arc.data.label) || '\u00A0'}
              </animated.text> // fallback space value to render text even if label is empty
            }
          </Spring>
        }
        <animated.path
          className='donut-chart-arc-expander'
          d={to([props.startAngle, props.endAngle],
            (startAngle, endAngle) => path({...arc, startAngle, endAngle, padAngle}))}
        />
      </g>
    );
  });
}

export default DonutChart;
