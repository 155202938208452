import {Fragment, useEffect, useMemo, useState} from 'react';
import {Form, Icon, Message, Popup} from 'semantic-ui-react';
import {toJS} from 'mobx';
import {
  map, find, filter, first, flatMap, mapValues, get, castArray, groupBy, without,
  isPlainObject, isString, isMatch, pullAllWith, assign,
} from 'lodash';
import {
  FetchDataError, FormFragment, FormValidationError, RadioGroupInput, ResourceModal, SingleFileInput,
  formSchemaToJSONSchema, generatePropertyFromSchema, interpolateRoute, notifier, request, requestProgress,
  validateFormProperties
} from 'apstra-ui-common';

import uploadStore from './uploadStore';
import {initUploadNotifier} from './UploadNotifier';

const routes = {
  deviceOSImageUpload: '/api/device-os/images',
  deviceOSImageExternal: '/api/device-os/images-external',
  deviceOSImageDetails: '/api/device-os/images/<image_id>',
};

const SHA256_CHECKSUM = 'SHA256';
const SHA512_CHECKSUM = 'SHA512';
const MD5_CHECKSUM = 'MD5';
const CHECKSUMS_DATA = {
  [SHA256_CHECKSUM]: {
    description: 'SHA256 checksum (64 characters)',
  },
  [SHA512_CHECKSUM]: {
    description: 'SHA512 checksum (128 characters)',
    // pattern: '^([a-fA-F0-9]{128})?$',
  },
  [MD5_CHECKSUM]: {
    description: 'MD5 checksum',
  },
};

const DeviceOSImageModal = ({
  mode = 'create', open, warningThreshold = 5, platforms, osImage,
  onSuccess, onClose, stats, trigger
}) => {
  const [properties, setProperties] = useState({});
  const [controller, setController] = useState(null);

  const formSchema = useMemo(() => {
    const {checksum = [SHA512_CHECKSUM]} = find(platforms, {platform: properties.platform}) || {};
    const checksumData = checksum.length > 1 ?
      CHECKSUMS_DATA[castArray(properties.checksum_type)] :
      CHECKSUMS_DATA[checksum];
    return filter([
      {
        name: 'platform',
        required: true,
        schema: {
          type: 'string',
          title: 'Platform',
          enum: map(platforms, 'platform'),
        }
      },
      {
        name: 'description',
        required: true,
        schema: {
          type: 'string',
          title: 'Description',
          minLength: 1,
        }
      },
      ...(mode === 'create' ? [
        {
          name: 'external',
          as: RadioGroupInput,
          schema: {
            type: 'boolean',
            default: false,
            oneOf: [
              {const: false, title: 'Upload Image'},
              {const: true, title: 'Provide Image URL'},
            ],
          },
        },
        properties.external ? {
          name: 'image_url',
          required: true,
          fieldProps: {description: 'Only HTTP URLs are supported', descriptionAsTooltip: true},
          schema: {
            type: 'string',
            title: 'Image URL',
            minLength: 1,
          }
        } : {
          name: 'image',
          required: true,
          as: SingleFileInput,
          cancellationAvailable: true,
          schema: {title: 'Image'}
        }
      ] : []),
      {
        name: 'checksum',
        fieldProps: {
          label: (
            <Fragment>
              {'Checksum'}
              {' '}
              <Popup
                key='popup'
                trigger={
                  <Icon name='info circle' />
                }
                content={CHECKSUM_TOOLTIP}
                position='right center'
                wide
              />
            </Fragment>
          )
        },
        schema: {
          type: 'string',
          title: 'Checksum',
          ...checksumData,
        }
      },
      properties.checksum?.length > 0 && checksum.length > 1 && {
        name: 'checksum_type',
        as: RadioGroupInput,
        required: properties.checksum?.length > 0,
        schema: {
          type: 'string',
          default: first(checksum),
          oneOf: map(checksum, (type) => ({
            const: type,
            title: type
          })),
        },
      },
    ]);
  }, [platforms, mode, properties.platform, properties.checksum, properties.checksum_type, properties.external]);

  const withDefaultValues = (properties) => {
    const result = {};
    for (const {name, schema} of formSchema) result[name] = properties[name] ?? generatePropertyFromSchema(schema);
    return result;
  };

  const resetState = () => {
    const properties = {};
    if (osImage) {
      for (const {name} of formSchema) properties[name] = osImage[name];
    } else {
      Object.assign(properties, withDefaultValues(properties));
    }
    setProperties(properties);
  };

  // set default values when schema updated
  useEffect(
    () => setProperties(withDefaultValues(properties)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formSchema]
  );

  const submit = () => {
    const errors = validateFormProperties(formSchemaToJSONSchema(formSchema), properties);
    if (errors) throw new FormValidationError(errors);
    const external = properties.external;
    const propertyNames = without(map(formSchema, 'name'), 'external');
    let body;
    if (mode === 'create' && !external) {
      body = new FormData();
      for (const name of propertyNames) body.append(name, properties[name]);
    } else {
      body = {};
      for (const name of propertyNames) body[name] = properties[name];
      body = JSON.stringify(body);
    }
    const url = mode !== 'create' ?
      interpolateRoute(routes.deviceOSImageDetails, {imageId: osImage.id})
    : external ? routes.deviceOSImageExternal : routes.deviceOSImageUpload;
    const controller = new AbortController();
    setController(controller);
    if (mode === 'create' && !external) {
      const payload = assign({}, properties);
      const uploadId = uploadStore.addUploadItem(payload);
      initUploadNotifier();
      requestProgress(url, {
        method: 'POST',
        body,
        onProgress: (uploadProgress) => {
          uploadStore.updateUploadItemProgress(uploadId, {
            uploadProgress,
            onCancelRequest: () => controller.abort()
          });
        },
        signal: controller.signal
      }).then(() => {
        if (onSuccess) onSuccess();
        notifier.notify({message: 'Device OS Image has been successfully created'});
        uploadStore.deleteUploadItem(uploadId);
      }).catch((error) => {
        uploadStore.updateUploadItemProgress(uploadId, {error});
      });
      return {
        message: 'Device OS image has been started to upload',
      };
    }

    return request(url, {method: mode === 'create' ? 'POST' : 'PATCH', body, signal: controller.signal});
  };

  const onCancelRequest = () => {
    if (controller) {
      controller.abort();
      setController(null);
    }
  };

  const processErrors = ({errors}) => {
    if (isPlainObject(errors)) {
      return flatMap(errors, (propertyErrors, propertyName) => {
        return castArray(propertyErrors).map((message) => ({type: 'property', propertyName, message}));
      });
    } else if (isString(errors)) {
      return [{type: 'generic', message: errors}];
    } else {
      return [];
    }
  };

  const submitAvailable =
    mode !== 'create' ||
    !!properties.external ||
    properties.image instanceof File;
  const freeSpace = get(stats, ['free'], 0);
  const partitionName = get(stats, ['partition_name']);
  return (
    <ResourceModal
      mode={mode}
      resourceName='Device OS Image'
      resourceLabel={get(osImage, ['image_name'])}
      titlesByMode={{
        create: 'Register',
        update: 'Edit',
      }}
      actionsByMode={{
        create: properties.external ? 'Register' : 'Upload',
        update: 'Update',
      }}
      trigger={trigger}
      size='small'
      showCreateAnother={false}
      submitAvailable={submitAvailable}
      open={open}
      onClose={onClose}
      resetState={resetState}
      submit={submit}
      processErrors={processErrors}
      onSuccess={onSuccess}
    >
      {({actionInProgress, errors, setErrors}) =>
        <>
          {freeSpace < warningThreshold &&
            <Message icon warning>
              <Icon name='warning sign' />
              <Message.Content>
                <Message.Header>
                  {'The partition '}
                  <b>{partitionName}</b>
                  {` has under ${warningThreshold}GB of free space.`}
                </Message.Header>
              </Message.Content>
            </Message>
          }
          <DeviceOSImageForm
            schema={formSchema}
            properties={properties}
            actionInProgress={actionInProgress}
            errors={toJS(errors)}
            setErrors={setErrors}
            setProperties={setProperties}
            onCancelRequest={onCancelRequest}
          />
        </>
      }
    </ResourceModal>
  );
};

export default DeviceOSImageModal;

export const DeviceOSImageForm = ({
  properties, setProperties, errors: errorsProps, setErrors, actionInProgress, onCancelRequest,
  ...props
}) => {
  const setPropertyValue = (name, value) => {
    if (actionInProgress && name === 'image') {
      onCancelRequest();
      return;
    }

    const update = {...properties};
    update[name] = value;
    setProperties(update);
    setErrors(pullAllWith(errorsProps, [{type: 'property', propertyName: name}], isMatch));
  };

  const errors = useMemo(() => {
    return {
      form: mapValues(
        groupBy(filter(errorsProps, {type: 'property'}), 'propertyName'),
        (errorGroup) => flatMap(errorGroup, 'message')
      ),
      generic: find(errorsProps, {type: 'generic'}),
      http: find(errorsProps, {type: 'http'}),
    };
  }, [errorsProps]);

  return (
    <Fragment>
      <Form>
        <FormFragment
          {...props}
          disabled={actionInProgress}
          values={properties}
          errors={errors.form}
          onChange={(name, value) => setPropertyValue(name, value)}
          allowNewTags={false}
        />
      </Form>
      {errors.http &&
        <FetchDataError error={errors.http.error} />
      }
      {errors.generic &&
        <Message error icon='warning sign' header='Error' content={errors.generic.message} />
      }
    </Fragment>
  );
};

const CHECKSUM_TOOLTIP = 'This information is typically available on the support website where this image was' +
  ' downloaded from. It is used to verify the OS image integrity after it’s uploaded to a device during an OS' +
  ' upgrade operation';
