import {Component} from 'react';
import {map, union, isEmpty, filter, values, uniq, flatten, find, includes, get} from 'lodash';
import {reaction, computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {ValueInput} from 'apstra-ui-common';

import IBAContext from '../IBAContext';

const telemetryStringData = 'aos.sdk.telemetry.schemas.iba_string_data';
const telemetryIntegerData = 'aos.sdk.telemetry.schemas.iba_integer_data';
const telemetryGenericData = 'aos.sdk.telemetry.schemas.generic';

const processorDataTypeSchemasMatch = {
  extensible_data_collector: {
    table_ts: [telemetryStringData],
    ts: [telemetryStringData],
    table_ns: [telemetryIntegerData],
    ns: [telemetryIntegerData],
    table_dss: [telemetryIntegerData],
    dss: [telemetryIntegerData],
  },
  generic_data_collector: {
    ts: [telemetryGenericData],
    ns: [telemetryGenericData],
    dss: [telemetryGenericData],
  },
};

@observer
export default class ServiceNameInput extends Component {
  static contextType = IBAContext;

  constructor(props) {
    super(props);
    makeObservable(this);
    if (this.processorDataTypes) {
      this.disposeDataTypeReaction = reaction(
        () => this.dataType,
        () => {
          const invalidSchema = !includes(this.dataTypeSchemas, this.serviceNameSchema);
          if (this.dataTypeSchemas && this.serviceNameSchema && invalidSchema) {
            this.props.onChange('');
          }
        }
      );
    }
  }

  componentWillUnmount() {
    if (this.processorDataTypes) {
      this.disposeDataTypeReaction();
    }
  }

  @computed get dataType() {
    return this.props.values.data_type;
  }
  @computed get dataTypeSchemas() {
    const {dataType, processorDataTypes} = this;
    return processorDataTypes ? processorDataTypes[dataType] : null;
  }
  @computed get processorDataTypes() {
    return processorDataTypeSchemasMatch[this.props.processor.type];
  }
  @computed get serviceNameSchema() {
    return get(find(this.props.telemetryServiceRegistryItems,
      {service_name: this.props.value}), ['storage_schema_path']);
  }

  @computed get options() {
    const {value, telemetryServiceRegistryItems} = this.props;
    const serviceNames = this.getAvailableServiceNames(telemetryServiceRegistryItems);
    return !isEmpty(serviceNames) ? union(serviceNames, (value ? [value] : [])).sort() : [];
  }

  getAvailableServiceNames = (items) => {
    const {dataType, processorDataTypes} = this;
    if (processorDataTypes) {
      let schemas;
      if (processorDataTypes[dataType]) {
        schemas = processorDataTypes[dataType];
      } else {
        schemas = uniq(flatten(values(processorDataTypes)));
      }
      items = filter(items, (item) => schemas.includes(item.storage_schema_path));
    }
    return map(items, 'service_name');
  };

  render() {
    const {name, value, schema, required, disabled, errors, onChange, loaderVisible} = this.props;

    return (
      <ValueInput
        name={name}
        value={value}
        schema={{...schema, enum: this.options}}
        required={required}
        disabled={disabled}
        loading={loaderVisible}
        errors={errors}
        onChange={onChange}
      />
    );
  }
}
