import {Component} from 'react';
import PropTypes from 'prop-types';
import {observable, action, reaction, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {isBoolean, forIn, isEmpty, isArray, isString} from 'lodash';
import {Checkbox, parseState, stringifyState, withRouter} from 'apstra-ui-common';

import './TopologySettings.less';

export const TOPOLOGY_SETTING = {
  SHOW_LINKS: 'showLinks',
  EXPAND_NODES: 'expandNodes',
  TOGGLED_NODES: 'toggledNodes',
  SHOW_NODES: 'showNodes',
};

export const TOPOLOGY_SETTING_QUERY = {
  [TOPOLOGY_SETTING.SHOW_LINKS]: 'show-links',
  [TOPOLOGY_SETTING.EXPAND_NODES]: 'expand-nodes',
  [TOPOLOGY_SETTING.TOGGLED_NODES]: 'toggled-nodes',
  [TOPOLOGY_SETTING.SHOW_NODES]: 'show-nodes',
};

@withRouter
@observer
export default class TopologySettings extends Component {
  static propTypes = {
    width: PropTypes.number,
    isCollapsed: PropTypes.bool,
    isFiveStage: PropTypes.bool,
    selectedPodId: PropTypes.string,
    selectedRackId: PropTypes.string,
    selectedAccessGroupId: PropTypes.string,
    selectedNodeId: PropTypes.string,
    showLinks: PropTypes.bool,
    expandNodes: PropTypes.bool,
    toggledNodes: PropTypes.arrayOf(PropTypes.string),
    showNodes: PropTypes.string,
    basicSettings: PropTypes.bool,
    hideSettings: PropTypes.bool,
    persistSettings: PropTypes.bool,
    onChange: PropTypes.func,
    hideExpandNodes: PropTypes.bool,
  };

  @observable.ref settings = {
    expandNodes: false,
    showLinks: false,
    toggledNodes: [],
    showNodes: null
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.setInitialSettings();
    this.disposeSelectedRackIdReaction = reaction(
      () => this.props.selectedRackId,
      (selectedRackId) => {
        if (selectedRackId) {
          this.setSetting(TOPOLOGY_SETTING.EXPAND_NODES, true);
          if (this.settings[TOPOLOGY_SETTING.SHOW_NODES] === 'superspines') {
            this.setSetting(TOPOLOGY_SETTING.SHOW_NODES, null);
          }
        }
      }
    );
    this.disposeToggledNodesReaction = reaction(
      () => this.props.toggledNodes,
      (toggledNodes) => {
        if (toggledNodes.length !== this.settings.toggledNodes.length) {
          this.setSetting(TOPOLOGY_SETTING.TOGGLED_NODES, toggledNodes);
        }
      }
    );
  }

  componentWillUnmount() {
    this.disposeSelectedRackIdReaction();
    this.disposeToggledNodesReaction();
  }

  get visibleSettings() {
    const {isFiveStage, selectedPodId, selectedRackId, selectedNodeId, basicSettings, isCollapsed,
      hideExpandNodes, accessGroups, selectedAccessGroupId} = this.props;

    const hasAccessGroups = !!accessGroups?.length;
    return {
      expandNodes: !hideExpandNodes && (!selectedRackId || (hasAccessGroups && !selectedAccessGroupId)),
      showLinks: true,
      showNodesSpines: selectedRackId && !selectedNodeId && !basicSettings && !isCollapsed && !hasAccessGroups,
      showNodesSuperspines: isFiveStage && selectedPodId && !selectedRackId && !selectedNodeId && !basicSettings &&
        !hasAccessGroups
    };
  }

  @action
  setInitialSettings = () => {
    const {selectedRackId, showLinks, expandNodes, showNodes, persistSettings, userStoreKey, userStore,
      isCollapsed} = this.props;
    const storedSettings = userStore.getStoreValue([userStoreKey]);
    let initialSettings = storedSettings ?? {};

    if (isCollapsed) {
      initialSettings.expandNodes = true;
    }
    if (selectedRackId) {
      initialSettings.showLinks = true;
    }
    if (isBoolean(showLinks) && !isBoolean(storedSettings?.showLinks)) {
      initialSettings.showLinks = showLinks;
    }
    if (isBoolean(expandNodes) && !isBoolean(storedSettings?.expandNodes)) {
      initialSettings.expandNodes = expandNodes;
    }
    if (showNodes) {
      initialSettings.showNodes = showNodes;
    }
    if (persistSettings) {
      initialSettings = {
        ...initialSettings,
        ...this.parseQueryParams()
      };
    }
    if (selectedRackId) {
      initialSettings.expandNodes = true;
    }
    this.settings = {
      ...this.settings,
      ...initialSettings
    };
    this.emitOnChange();
  };

  @action
  setSetting = (field, value) => {
    const {props: {userStoreKey, userStore}} = this;
    const settingsUpdates = {
      [field]: value
    };

    if (field === TOPOLOGY_SETTING.EXPAND_NODES) {
      settingsUpdates[TOPOLOGY_SETTING.TOGGLED_NODES] = [];
    }

    this.settings = {...this.settings, ...settingsUpdates};

    if (this.props.persistSettings) {
      this.persistSettingsInQuery(settingsUpdates);
    }
    userStore.setFor(userStoreKey, {...userStore.getStoreValue([userStoreKey]) ?? {}, ...this.settings});

    this.emitOnChange();
  };

  emitOnChange = () => {
    const {settings, props: {onChange}} = this;
    onChange?.(settings);
  };

  parseQueryParams = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const settings = {};

    forIn(TOPOLOGY_SETTING_QUERY, (query, field) => {
      if (searchParams.has(query)) {
        let value = searchParams.get(query);
        if (field === TOPOLOGY_SETTING.SHOW_LINKS || field === TOPOLOGY_SETTING.EXPAND_NODES) {
          value = value === 'true';
        } else if (field === TOPOLOGY_SETTING.TOGGLED_NODES) {
          try {
            const state = parseState(value);
            value = isArray(state) ? state : [];
          } catch {
            value = [];
          }
        }
        settings[field] = value;
      }
    });

    return settings;
  };

  persistSettingsInQuery = (settings) => {
    const searchParams = new URLSearchParams(this.props.location.search);
    forIn(settings, (value, field) => {
      const query = TOPOLOGY_SETTING_QUERY[field];
      if (query) {
        if (isString(value) || isBoolean(value)) {
          searchParams.set(query, value);
        } else if (isArray(value) && !isEmpty(value)) {
          const state = stringifyState(value);
          searchParams.set(query, state);
        } else {
          searchParams.delete(query);
        }
      }
    });
    this.props.navigate(`?${searchParams.toString()}`);
  };

  render() {
    const {settings, visibleSettings, setSetting, props: {width, hideSettings}} = this;
    const {showLinks, expandNodes, showNodes} = settings;

    if (hideSettings) {
      return null;
    }

    const style = width ? {width} : null;

    return (
      <div className='tp-settings' style={style}>
        {visibleSettings.expandNodes &&
          <Checkbox
            label='Expand Nodes?'
            indeterminate={!settings.expandNodes && !isEmpty(settings.toggledNodes)}
            checked={settings.expandNodes}
            onChange={() => setSetting(TOPOLOGY_SETTING.EXPAND_NODES, !expandNodes)}
          />
        }
        {visibleSettings.showLinks &&
          <Checkbox
            label='Show Links?'
            checked={showLinks}
            onChange={() => setSetting(TOPOLOGY_SETTING.SHOW_LINKS, !showLinks)}
          />
        }
        {visibleSettings.showNodesSpines &&
          <Checkbox
            label='Show Spines?'
            checked={showNodes === 'spines'}
            onChange={() => setSetting(TOPOLOGY_SETTING.SHOW_NODES, showNodes === 'spines' ? null : 'spines')}
          />
        }
        {visibleSettings.showNodesSuperspines &&
          <Checkbox
            label='Show Superspines?'
            checked={showNodes === 'superspines'}
            onChange={() => setSetting(TOPOLOGY_SETTING.SHOW_NODES, showNodes === 'superspines' ? null : 'superspines')}
          />
        }
      </div>
    );
  }
}
