/* eslint-disable sonarjs/no-duplicate-string */
import ace from 'ace-builds';
import {interpolateRoute, request} from 'apstra-ui-common';
import {endsWith, escape, get, isPlainObject, every, keys, map, reduce, transform} from 'lodash';

function buildDocHTML({header, description, meta = []}) {
  return [
    header && ('<p><b>' + escape(header) + '</b></p>'),
    meta.length && [
      '<div class="ui bulleted list">',
      ...map(meta, (item) => '<div class="item">' + item + '</div>'),
      '</div>',
    ].join(''),
    description && ('<p>' + escape(description) + '</p>')
  ].filter(Boolean).join('');
}

ace.define(
  'ace/mode/junos-cli',
  ['require', 'exports', 'module'],
  (require, exports) => {
    const {TextHighlightRules} = require('ace/mode/text_highlight_rules');
    const {Mode: TextMode} = require('ace/mode/text');
    const {TokenIterator} = require('ace/token_iterator');
    const {BaseCompleter} = require('ace/base_completer');

    class JunosCLICompleter extends BaseCompleter {
      telemetrySchemas = null;
      systemDetails = {};
      deviceCommandMaps = {};
      deviceCommandArguments = {};

      async getTelemetrySchemas() {
        if (this.telemetrySchemas) return this.telemetrySchemas;
        try {
          this.telemetrySchemas = request('/api/telemetry/schemas', {method: 'OPTIONS'})
            .then((result) => result.items.cli)
            .catch((e) => {throw e;});
          this.telemetrySchemas = await this.telemetrySchemas;
          return this.telemetrySchemas;
        } catch {
          this.telemetrySchemas = null;
          return null;
        }
      }

      async getSystemDetails(systemId) {
        if (Object.hasOwn(this.systemDetails, systemId)) return this.systemDetails[systemId];
        try {
          this.systemDetails[systemId] = request(interpolateRoute('/api/systems/<system_id>', {systemId}));
          this.systemDetails[systemId] = await this.systemDetails[systemId];
          return this.systemDetails[systemId];
        } catch {
          delete this.systemDetails[systemId];
          return null;
        }
      }

      async getRouteKeyForSystem(systemId) {
        const [telemetrySchemas, systemDetails] = await Promise.all([
          this.getTelemetrySchemas(),
          this.getSystemDetails(systemId),
        ]);
        if (!telemetrySchemas || !systemDetails) return null;
        const source = 'cli';
        const family = systemDetails.facts.os_variant;
        const osType = endsWith(systemDetails.facts.os_version, '-EVO') ? 'junos_evo' : 'junos';
        const versions = get(telemetrySchemas, [osType, systemDetails.facts.os_variant, 'versions']);
        const systemOsVersionOrdinal = systemDetails.facts.os_version_ordinal ?? null;
        const closestVersion = reduce(versions, (result, versionInfo, versionText) => {
          const versionOrdinal = versionInfo?.ordinal ?? null;
          if (versionOrdinal) {
            if (
              // this version is the first available one
              !result ||
              // or this version is lesser than system's version but higher than previously found
              versionOrdinal <= (systemOsVersionOrdinal || Infinity) && versionOrdinal > result.ordinal
            ) {
              return {text: versionText, ordinal: versionOrdinal};
            }
          }
          return result;
        }, null);
        if (!closestVersion) return null;
        const osVersion = closestVersion.text;
        return [source, osType, family, osVersion].join('/');
      }

      getRouteKey() {
        try {
          if (!this.params) return null;
          const {systemId, rpcSchemaParams} = this.params;
          if (systemId) return this.getRouteKeyForSystem(systemId);
          if (isPlainObject(rpcSchemaParams)) {
            const {source, osType, family, osVersion} = rpcSchemaParams;
            const routeKeyParts = [source, osType, family, osVersion];
            if (every(routeKeyParts)) return routeKeyParts.join('/');
          }
        } catch {}
        return null;
      }

      async getDeviceCommandMap() {
        const routeKey = await this.getRouteKey();
        if (!routeKey) return null;
        if (Object.hasOwn(this.deviceCommandMaps, routeKey)) return this.deviceCommandMaps[routeKey];
        try {
          const route = '/api/telemetry/schemas/' + routeKey + '/cmd_rpc_map';
          this.deviceCommandMaps[routeKey] = request(route)
            .then((result) => result.items)
            .catch((e) => {throw e;});
          this.deviceCommandMaps[routeKey] = await this.deviceCommandMaps[routeKey];
          return this.deviceCommandMaps[routeKey];
        } catch {
          delete this.deviceCommandMaps[routeKey];
          return null;
        }
      }

      async getCommandArguments(rpcName) {
        const routeKey = await this.getRouteKey();
        if (!routeKey) return null;
        const storageKey = routeKey + '/' + rpcName;
        if (Object.hasOwn(this.deviceCommandArguments, storageKey)) return this.deviceCommandArguments[storageKey];
        try {
          const route = '/api/telemetry/schemas/' + routeKey + '/rpc/' + rpcName;
          this.deviceCommandArguments[storageKey] = request(route)
            .then((result) => result.items.arguments)
            .catch((e) => {throw e;});
          this.deviceCommandArguments[storageKey] = await this.deviceCommandArguments[storageKey];
          return this.deviceCommandArguments[storageKey];
        } catch {
          delete this.deviceCommandArguments[storageKey];
          return null;
        }
      }

      getCommandParts(session, pos) {
        const iterator = new TokenIterator(session, pos.row, pos.column);
        const parts = [];
        do {
          const token = iterator.stepBackward();
          if (!token) break;
          if (token.type === 'text.command') parts.unshift(token.value);
        } while (true); // eslint-disable-line no-constant-condition
        return parts[0] === 'show' ? parts : null;
      }

      getArgumentCompletions(argumentsMap, initialMeta = []) {
        return transform(argumentsMap, (result, {description, type, nokeyword, list, idx}, argument) => {
          let typeName = type.name;
          if (typeName === 'choice') {
            result.push(...this.getArgumentCompletions(
              type.args,
              ['Choice value of <b>' + escape(argument) + '</b>']
            ));
            return;
          }
          if (typeName === 'enum' && nokeyword) {
            result.push(...map(type.values, (description, value) => ({
              caption: value,
              snippet: value,
              meta: argument,
              docHTML: buildDocHTML({
                header: 'Argument', description,
                meta: ['Enum value of <b>' + escape(argument) + '</b>']
              })
            })));
            return;
          }
          let score = 0;
          let caption = argument;
          let snippet = argument;
          const meta = [...initialMeta];
          if (typeName) {
            if (typeName === 'union') {
              typeName = keys(type.types).join(' or ');
            }
            if (typeName !== 'empty') {
              meta.push('<b>Type:</b> ' + escape(typeName));
            }
            if (typeName === 'string' && type.properties) {
              if (type.properties.length) {
                meta.push('<b>Length:</b> ' + escape(type.properties.length));
              }
              if (type.properties['pattern-message']) {
                meta.push(escape(type.properties['pattern-message']));
              }
            }
          }
          if (nokeyword) {
            score = 1000 - (idx ?? 0);
            caption = '<' + argument + '>';
            // string constructor is needed here because ace filters out completions with same snippets
            // eslint-disable-next-line no-new-wrappers
            snippet = new String('');
            meta.push('<b>Non-keyword argument</b>');
          }
          if (list) {
            meta.push('<b>List</b>');
            typeName = '[' + typeName + ']';
          }
          const completion = {
            caption, snippet, score,
            meta: typeName === 'empty' ? null : typeName,
            docHTML: buildDocHTML({header: 'Argument', meta, description})
          };
          result.push(completion);
        }, []);
      }

      async getShowCommandCompletions(parts, commandMap) {
        let currentMap = commandMap;
        let possibleKeywordArgument = null;
        for (const part of parts) {
          if (Object.hasOwn(currentMap, part)) {
            currentMap = currentMap[part];
          } else {
            possibleKeywordArgument = part;
          }
        }
        const result = [];
        const rpcName = get(currentMap, ['__rpc__', 'name']);
        if (rpcName) {
          const argumentsMap = await this.getCommandArguments(rpcName);
          const possibleKeywordArgumentType = argumentsMap?.[possibleKeywordArgument]?.type;
          if (possibleKeywordArgumentType?.name === 'container') {
            result.push(...this.getArgumentCompletions(possibleKeywordArgumentType.args));
          } else if (
            possibleKeywordArgumentType?.name === 'enum' &&
            possibleKeywordArgumentType?.nokeyword !== true
          ) {
            result.push(...this.getArgumentCompletions(
              transform(possibleKeywordArgumentType.values, (result, description, argument) => {
                result[argument] = {type: {name: 'empty'}, description};
              }, {})
            ));
          } else {
            result.push(...this.getArgumentCompletions(argumentsMap));
          }
        }
        return possibleKeywordArgument ? result : transform(currentMap, (result, subCommandMap, command) => {
          if (command === '__rpc__') return;
          const description = get(currentMap, [command, '__rpc__', 'description'], null);
          const completion = {
            caption: command,
            snippet: command,
            meta: 'command',
            docHTML: buildDocHTML({header: 'Command', description})
          };
          result.push(completion);
        }, result);
      }

      async getCustomCompletions(state, session, pos) {
        const commandMapPromise = this.getDeviceCommandMap();
        const token = session.getTokenAt(pos.row, pos.column);
        if (token.index === 0 && 'show'.substring(0, pos.column) === token.value) {
          return [{caption: 'show', snippet: 'show', meta: 'command'}];
        }
        const parts = this.getCommandParts(session, pos);
        if (!parts) return [];
        const commandMap = await commandMapPromise;
        if (!commandMap) return [];
        return this.getShowCommandCompletions(parts, commandMap);
      }

      checkForCustomCharacterCompletions(editor) {
        const pos = editor.getCursorPosition();
        const line = editor.getSession().getLine(pos.row).substr(0, pos.column);
        if (/([\w-]\s+)$/.test(line)) {
          this.showCompletionsPopup(editor);
        }
      }
    }

    class JunosCLIHighlightRules extends TextHighlightRules {
      constructor() {
        super();

        this.$rules = {
          start: [
            {
              token: 'text.command',
              regex: /[\w-]+/,
            },
            {
              token: 'text',
              regex: /\s+/
            },
          ],
        };
        this.normalizeRules();
      }
    }

    class JunosCLIMode extends TextMode {
      $id = 'ace/mode/junos-cli';
      $behaviour = this.$defaultBehaviour;
      HighlightRules = JunosCLIHighlightRules;
      completer = new JunosCLICompleter();
    }

    exports.Mode = JunosCLIMode;
    exports.HighlightRules = JunosCLIHighlightRules;
  }
);

ace.require(['ace/mode/junos-cli']);
