import {every, filter, forEach, map, reject} from 'lodash';

import {Layout, LayoutType} from '../types';
import {ColumnLayout, LayoutAlgorithm, NodeLayout, NodePairLayout, PadLayout,
  RowLayout, StackLayout, VlanBusLayout} from '../layouts';
import {TOPOLOGY_CONFIG, TopologyConfigType} from '../consts';

type TreeNodeData = {
  id: string;
  layoutData: Record<string, Layout>;
}

function isTreeNodeData(data: any): data is TreeNodeData {
  return !!data.id && !!data.layoutData;
}

export type TreeNode = {
  children: TreeNode[] | null,
  parent?: TreeNode,
  childrenLayout: LayoutAlgorithm;
  data?: TreeNodeData;
  layout: Layout;
  isEmpty?: boolean;
  config: TopologyConfigType;
};

const layoutMap: Record<LayoutType, LayoutAlgorithm> = {
  [LayoutType.Column]: new ColumnLayout(),
  [LayoutType.Node]: new NodeLayout(),
  [LayoutType.Pad]: new PadLayout(),
  [LayoutType.NodePair]: new NodePairLayout(),
  [LayoutType.Row]: new RowLayout(),
  [LayoutType.Stack]: new StackLayout(),
  [LayoutType.VlanBus]: new VlanBusLayout(),
};

export const layout = (
  layoutType: LayoutType,
  children: (TreeNode | false)[] | null,
  options: Partial<TopologyConfigType> | TreeNodeData = {}
): TreeNode => {
  const filteredChildren = children && (filter(children) as TreeNode[]);

  let data;
  let configOverrides = {};
  if (isTreeNodeData(options)) {
    data = options;
  } else {
    configOverrides = options;
  }

  const result = {
    children: filteredChildren,
    childrenLayout: layoutMap[layoutType],
    data,
    layout: {
      top: 0,
      left: 0,
      width: 0,
      height: 0,
    },
    isEmpty: filteredChildren !== null && every(filteredChildren, 'isEmpty'),
    config: {...TOPOLOGY_CONFIG, ...configOverrides}
  };

  forEach(filteredChildren, (child: TreeNode) => {child.parent = result;});

  return result;
};

function calculateChildren(root: TreeNode) {
  if (root.childrenLayout.recalculateChildren(root.layout, map(root.children, 'layout'), root.config)) {
    forEach(root.children, calculateChildren);
  }
}

export function calculateLayout(root: TreeNode, inverted = false) {
  if (!inverted) {
    forEach(root.children, (child) => calculateLayout(child, inverted));

    if (root.children) {
      (root as any).children = reject(
        root.children,
        (child) => child.isEmpty && !!child.children
      );
    }
    while (root.childrenLayout.recalculateOwn(root.layout, map(root.children, 'layout'), root.config)) {
      calculateChildren(root);
    }
  } else {
    calculateChildren(root);
  }
}

export function saveLayout(root: TreeNode, left = 0, top = 0) {
  const absoluteLeft = left + root.layout.left;
  const absoluteTop = top + root.layout.top;
  if (root.data?.layoutData && root.data?.id) {
    root.data.layoutData[root.data?.id] = {
      ...root.layout,
      left: absoluteLeft,
      top: absoluteTop,
    };
  }
  forEach(root.children, (child) => saveLayout(child, absoluteLeft, absoluteTop));
}
