import {Component, Fragment} from 'react';
import cx from 'classnames';
import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {LinePath, AreaClosed} from '@visx/shape';
import {curveLinear} from '@visx/curve';
import {RectClipPath} from '@visx/clip-path';
import {forEach, isFinite, uniqueId, some, isNumber, transform, find, isEmpty, reverse, isNull} from 'lodash';
import {
  CHART_VALUE_CIRCLE_RADIUS, CHART_ELEMENTS_STROKE_WIDTH,
  CHART_RANGE_HOVER_RECTANGLE_HEIGHT, NumericChartLayout, formatNumber
} from 'apstra-ui-common';

import ChartHoverHandler from './ChartHoverHandler';

import './LineChart.less';

@observer
export default class LineChart extends Component {
  static defaultProps = {
    mode: 'compact',
    minValue: 0,
    maxValue: 0,
    units: '',
    rangeClipPadding: 1,
    dimensions: {
      compact: {
        height: 60 + (CHART_VALUE_CIRCLE_RADIUS + CHART_ELEMENTS_STROKE_WIDTH) * 2,
        margin: {
          top: 4 + CHART_VALUE_CIRCLE_RADIUS + CHART_ELEMENTS_STROKE_WIDTH,
          right: 3,
          bottom: 5 + CHART_VALUE_CIRCLE_RADIUS + CHART_ELEMENTS_STROKE_WIDTH,
          left: 40
        },
        numTicksRows: 4,
        rangeLineOffset: 0
      },
      expanded: {
        height: 300,
        margin: {top: 10, right: 3, bottom: 30, left: 40},
        numTicksRows: 10,
        rangeLineOffset: 9
      },
    },
    fill: true,
    colors: ['green', 'red'],
    maxSamplesDisplayCircles: 150,
    yAxisLeftLabelWidth: 10,
    valueKeyName: 'value',
    inlineUnitsMaxLength: 2,
    minSpaceBetweenCircles: (CHART_VALUE_CIRCLE_RADIUS * 2 + CHART_ELEMENTS_STROKE_WIDTH * 2) * 2,
  };

  lineChartId = uniqueId('line-chart-id-');
  rangeClipId = uniqueId(`${this.lineChartId}-range-clip-id-`);
  positiveClipPathId = uniqueId(`${this.lineChartId}-positive-clip-path-id-`);
  negativeClipPathId = uniqueId(`${this.lineChartId}-negative-clip-path-id-`);

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get anomalousColor() {
    return this.props.colors[1];
  }

  @computed get nonAnomalousColor() {
    return this.props.colors[0];
  }

  @computed get anomalyRangesByColor() {
    if (isEmpty(this.props.rangesByColor)) {
      return [];
    }
    // normalize rangeByColor object
    const rangesByColorClone = this.props.rangesByColor;
    const ranges = transform(rangesByColorClone, (result, ranges, color) => {
      forEach(ranges, ({name, borders, borderColor, includeBorders}) => {
        if (isNumber(borders[0]) || isNumber(borders[1])) {
          const minBorder = isNumber(borders[0]) ? borders[0] : -Infinity;
          const maxBorder = isNumber(borders[1]) ? borders[1] : Infinity;
          result.push({
            rangeClipId: uniqueId(`${this.lineChartId}-range-clip-id-`),
            name,
            color: color ?? 'red',
            borderColor: borderColor ?? 'red',
            borders: [minBorder, maxBorder],
            userDefinedBorders: [minBorder, maxBorder],
            includeBorders: borders[0] !== borders[1]
              ? {min: includeBorders.min ?? true, max: includeBorders.max ?? false}
              : {min: true, max: true},
          });
        }
      });
    }, []);

    // sort ranges
    ranges.sort((a, b) => {
      if (a.borders[0] !== b.borders[0]) {
        return a.borders[0] - b.borders[0];
      }
      return a.borders[1] - b.borders[1];
    });

    forEach(ranges, (range, idx) => {
      // remove intersections in case the max value is greater than the next range's min value
      if (
        idx !== ranges.length - 1 &&
        ranges[idx + 1].borders[0] !== -Infinity &&
        (range.borders[1] === Infinity || range.borders[1] > ranges[idx + 1].borders[0])
      ) {
        range.borders[1] = ranges[idx + 1].borders[0];
      }

      // set min value equal to the previous range's max value
      if (idx !== 0 && range.borders[0] === -Infinity) {
        range.borders[0] = ranges[idx - 1].borders[1];
      }

      // define common lines (for the popup label)
      range.intersections = {
        min:
          range.borders[0] &&
          ranges[idx - 1] &&
          range.borders[0] === ranges[idx - 1].borders[1] &&
          range.borders[0],
        max:
          range.borders[1] &&
          ranges[idx + 1] &&
          range.borders[1] === ranges[idx + 1].borders[0] &&
          range.borders[1],
      };
    });
    return ranges;
  }

  @computed get isChartWithRanges() {
    return this.anomalyRangesByColor.length > 0 || isFinite(this.props.rangeMin) || isFinite(this.props.rangeMax);
  }

  @computed get humanReadableRanges() {
    if (this.anomalyRangesByColor.length > 0) {
      return reverse(transform(this.anomalyRangesByColor, (result, {
        borders, userDefinedBorders, rangeClipId, name, borderColor, includeBorders
      }) => {
        const rangeInfo = this.formatRangeValue(
          {value: userDefinedBorders[0], include: includeBorders.min},
          {value: userDefinedBorders[1], include: includeBorders.max},
        );
        result.push({
          rangeInfo, name, rangeClipId, borderColor,
          borders: [
            {value: borders[0], include: includeBorders.min},
            {value: borders[1], include: includeBorders.max},
          ],
        });
      }, []));
    }
    if (isFinite(this.props.rangeMin) || isFinite(this.props.rangeMax)) {
      const minVal = {value: this.props.rangeMin, include: true};
      const maxVal = {value: this.props.rangeMax, include: true};
      const rangeInfo = this.formatRangeValue(minVal, maxVal);
      return [{
        rangeInfo,
        name: '',
        rangeClipId: this.rangeClipId,
        borderColor: 'red',
        borders: [minVal, maxVal],
      }];
    }
    return null;
  }

  @computed get isValueCirclesLayerVisible() {
    return this.props.samples.length < this.props.width / this.props.minSpaceBetweenCircles &&
      this.props.samples.length < this.props.maxSamplesDisplayCircles;
  }

  @computed get baseLineColor() {
    return this.isChartWithRanges ? this.nonAnomalousColor : this.anomalousColor;
  }

  getRangesPopupInfo = (value) => {
    if (!this.humanReadableRanges || !this.props.showRangeInfoInPopup) {
      return null;
    }
    return transform(this.humanReadableRanges, (result, {rangeClipId, borderColor, name, rangeInfo, borders}) => {
      result.push(
        <div
          key={rangeClipId}
          className={cx(
            `popup-range-value-${borderColor}`,
            {'range-highlighted': this.isInRange(value, borders[0], borders[1])}
          )}
        >
          {name}{name ? ': ' : ''}{rangeInfo}
        </div>
      );
    });
  };

  formatRangeValue = (minBorder, maxBorder) => {
    const from = isFinite(minBorder.value) ? formatNumber(minBorder.value, {short: true}) : null;
    const to = isFinite(maxBorder.value) ? formatNumber(maxBorder.value, {short: true}) : null;
    if (from === to) {
      return `${from}`;
    }
    return !isNull(from) && !isNull(to)
      ? `${minBorder.include ? '[' : '('}${from}, ${to}${maxBorder.include ? ']' : ')'}`
      : !isNull(from)
        ? `>${minBorder.include ? '=' : ''} ${from}`
        : `<${maxBorder.include ? '=' : ''} ${to}`;
  };

  getRangeColorByValue = (value) => {
    if (this.anomalyRangesByColor.length > 0) {
      const range = find(this.anomalyRangesByColor, (rangeByColor) => {
        const minVal = rangeByColor.borders[0] || this.props.minValue;
        const maxVal = rangeByColor.borders[1] || this.props.maxValue;
        return this.isInRange(
          value,
          {value: minVal, include: rangeByColor.includeBorders.min},
          {value: maxVal, include: rangeByColor.includeBorders.max},
        );
      });
      if (range) {
        return range.color;
      }
    }
    return this.anomalousColor;
  };

  isInRange = (value, minBorder, maxBorder) => {
    const minVal = isFinite(minBorder.value) ? minBorder.value : -Infinity;
    const maxVal = isFinite(maxBorder.value) ? maxBorder.value : Infinity;
    if (minVal === maxVal && minVal === value) {
      return true;
    }
    const result = [];
    if (minBorder.include) {
      result.push(value >= minVal);
    } else {
      result.push(value > minVal);
    }
    if (maxBorder.include) {
      result.push(value <= maxVal);
    } else {
      result.push(value < maxVal);
    }
    return result.filter((val) => val).length === 2;
  };

  isAnomalousValue = (value) => {
    if (this.anomalyRangesByColor.length > 0) {
      return some(this.anomalyRangesByColor, ({borders: [rangeMin, rangeMax], includeBorders}) => {
        const minBorderCondition = includeBorders.min ? value < rangeMin : value <= rangeMin;
        const maxBorderCondition = includeBorders.max ? value > rangeMax : value >= rangeMax;
        return !(isFinite(rangeMin) && minBorderCondition || isFinite(rangeMax) && maxBorderCondition);
      });
    }

    return !(
      isFinite(this.props.rangeMin) && value < this.props.rangeMin ||
      isFinite(this.props.rangeMax) && value > this.props.rangeMax
    );
  };

  formatValue = (value) => {
    const {units} = this.props;
    const shortNumber = formatNumber(value, {units, short: true});
    const longNumber = formatNumber(value, {units, short: false});
    return shortNumber === longNumber ? shortNumber : `${shortNumber} (${longNumber})`;
  };

  onRangeLineHover = (event, popupLabel) => {
    const rangeLineDimentions = event.target.getBoundingClientRect();
    return this.props.showPopup({
      node: event.target,
      custom: true,
      content: popupLabel,
      popupProps: {offset: [-(rangeLineDimentions.left + rangeLineDimentions.width / 2 - event.clientX), 0]}
    });
  };

  getValueCircleColor = (value) => {
    return !this.isChartWithRanges || (this.isChartWithRanges && this.isAnomalousValue(value))
      ? this.getRangeColorByValue(value)
      : this.nonAnomalousColor;
  };

  getRangePopupLabel = (rangeType, rangeValue, rangeBordersMatch, intersections) => {
    return rangeBordersMatch || intersections.min && rangeType === 'min' || intersections.max && rangeType === 'max'
      ? [`min/max: ${rangeValue}`]
      : [`${rangeType}: ${rangeValue}`];
  };

  render() {
    const {
      rangeClipId, anomalousColor, nonAnomalousColor, anomalyRangesByColor,
      positiveClipPathId, negativeClipPathId, isChartWithRanges, baseLineColor, isValueCirclesLayerVisible,
      formatValue, getRangesPopupInfo, onRangeLineHover, getValueCircleColor, getRangePopupLabel,
      props: {
        mode, dimensions,
        rangeClipPadding,
        samples, sampleTimes,
        minValue, maxValue,
        rangeMin, rangeMax,
        width: chartWidth,
        showPopup, hidePopup,
        timelineStartTime, timelineEndTime,
        timeIndicators,
        valueKeyName, units, yAxisLeftLabelWidth,
        inlineUnitsMaxLength, numTicksColumns, fill,
      }
    } = this;
    const {rangeLineOffset} = dimensions[mode];
    return (
      <NumericChartLayout
        className='line-chart'
        mode={mode}
        dimensions={dimensions}
        minValue={minValue}
        maxValue={maxValue}
        width={chartWidth}
        showPopup={showPopup}
        hidePopup={hidePopup}
        timelineStartTime={timelineStartTime}
        timelineEndTime={timelineEndTime}
        timeIndicators={timeIndicators}
        units={units}
        inlineUnitsMaxLength={inlineUnitsMaxLength}
        yAxisLeftLabelWidth={yAxisLeftLabelWidth}
        numTicksColumns={numTicksColumns}
      >
        {({xScale, yScale, yScaleMin, yMax, xMax}) => {
          const valueCircles = [];
          const rangesLines = [];
          const linePathData = [];
          const lines = [];
          if (chartWidth > 0) {
            // add value circles for samples + add extra props for samples + fill in the line path data
            forEach(samples, (sample, sampleIndex) => {
              const x = xScale(sampleTimes[sampleIndex]);
              const y = yScale(sample[valueKeyName]);
              const color = getValueCircleColor(sample[valueKeyName]);
              sample.color = color;
              sample.popupRangeInfo = getRangesPopupInfo(sample[valueKeyName]);
              linePathData.push([x, y]);

              // show all but last value circle or if only one sample exists
              if (isValueCirclesLayerVisible && (samples.length === 1 || sampleIndex !== samples.length - 1)) {
                valueCircles.push(
                  <circle
                    key={`value-circle-${sampleIndex}`}
                    className={`line-chart-value-circle graph-color-${color}`}
                    cx={x}
                    cy={y}
                    r={CHART_VALUE_CIRCLE_RADIUS}
                  />
                );
              }
            });

            // add base chart line and its filling (if needed)
            if (fill && !isChartWithRanges) {
              lines.push(
                <Fragment key='line-normal-filled'>
                  <LinePath
                    data={linePathData}
                    curve={curveLinear}
                    className={`line-chart-line graph-color-${baseLineColor}`}
                  />
                  <AreaClosed
                    data={linePathData}
                    x={0}
                    y={0}
                    yScale={yScale}
                    clipPath={`url(#${positiveClipPathId})`}
                    className={`line-chart-filling graph-fill-color-${baseLineColor}`}
                    curve={curveLinear}
                  />
                  <RectClipPath
                    id={positiveClipPathId}
                    x={0}
                    y={-1}
                    width={chartWidth}
                    height={yScale(0) + 1}
                  />
                  <AreaClosed
                    data={linePathData}
                    x={0}
                    y={0}
                    y0={0}
                    yScale={yScale}
                    clipPath={`url(#${negativeClipPathId})`}
                    className={`line-chart-filling graph-fill-color-${baseLineColor}`}
                    curve={curveLinear}
                  />
                  <RectClipPath
                    id={negativeClipPathId}
                    x={0}
                    y={yScale(0)}
                    width={chartWidth}
                    height={yMax}
                  />
                </Fragment>
              );
            } else {
              lines.push(
                <LinePath
                  key='line-normal'
                  className={`line-chart-line graph-color-${baseLineColor}`}
                  data={linePathData}
                  curve={curveLinear}
                />
              );
            }

            // on top of the base line, add anomalous lines cropped by each anomalous range
            if (isChartWithRanges) {
              const ranges = anomalyRangesByColor.length === 0
                ? [{
                  rangeClipId,
                  borders: [rangeMin, rangeMax],
                  color: anomalousColor,
                  borderColor: 'red',
                  intersections: {min: null, max: null}
                }]
                : anomalyRangesByColor;
              forEach(ranges, ({color, borders, rangeClipId, borderColor, intersections}, idx) => {
                const clipTop = (isFinite(borders[1]) ? yScale(borders[1]) : 0) - rangeClipPadding;
                const clipBottom = (isFinite(borders[0]) ? yScale(borders[0]) : yMax) + rangeClipPadding;
                lines.push(
                  <Fragment key={`anomalous-line-${rangeClipId}`}>
                    <RectClipPath
                      id={rangeClipId}
                      x={-rangeClipPadding - rangeLineOffset}
                      y={clipTop}
                      width={xMax + rangeClipPadding + rangeLineOffset}
                      height={clipBottom - clipTop > 0 ? clipBottom - clipTop : 0}
                    />
                    <LinePath
                      className={`line-chart-line graph-color-${color}`}
                      data={linePathData}
                      curve={curveLinear}
                      clipPath={isChartWithRanges ? `url(#${rangeClipId})` : undefined}
                    />
                  </Fragment>
                );

                // add horizontal dashed lines and background to indicate anomalous ranges
                const rangeBordersMatch = borders[0] === borders[1];
                for (const {range, name} of [{name: 'min', range: borders[0]}, {name: 'max', range: borders[1]}]) {
                  if (isFinite(range) && range >= minValue && range <= maxValue) {
                    const y = yScale(range);
                    const popupLabel = getRangePopupLabel(name, range, rangeBordersMatch, intersections);
                    if (name === 'min' || !rangeBordersMatch) {
                      // corner-case with ugly borders overlapping between 1st & 2nd range
                      let minBorderColor = null;
                      if (idx === 1 && name === 'min' && intersections.min) {
                        minBorderColor = ranges[idx - 1].borderColor;
                      }
                      // anomalous ranges' lines
                      rangesLines.push(
                        <Fragment key={`range ${rangeBordersMatch ? 'min/max' : name} ${range}`}>
                          <rect
                            className='line-chart-range-hover-rect'
                            x={-rangeLineOffset}
                            width={xMax}
                            y={y - CHART_RANGE_HOVER_RECTANGLE_HEIGHT / 2}
                            height={CHART_RANGE_HOVER_RECTANGLE_HEIGHT}
                            onMouseMove={(e) => onRangeLineHover(e, popupLabel)}
                            onMouseLeave={hidePopup}
                          />
                          <line
                            className={`line-chart-range-${minBorderColor ?? borderColor ?? 'red'}`}
                            x1={-rangeLineOffset}
                            y1={y}
                            x2={xMax}
                            y2={y}
                            clipPath={`url(#${rangeClipId})`}
                          />
                        </Fragment>
                      );
                    }
                  }
                }
                // anomalous ranges' backgrounds
                const fillingYMin = isFinite(borders[0]) && yScale(borders[0]) < yScaleMin
                  ? yScale(borders[0])
                  : yScaleMin;
                const fillingYMax = isFinite(borders[1]) && yScale(borders[1]) > 0
                  ? yScale(borders[1])
                  : 0;
                if (fillingYMin - fillingYMax > 0) {
                  rangesLines.push(
                    <rect
                      key={`range-filling-${rangeClipId}`}
                      x={0}
                      y={fillingYMax}
                      width={xMax}
                      height={fillingYMin - fillingYMax}
                      className={`line-chart-range-filling graph-fill-color-${borderColor}`}
                    />
                  );
                }
              });
            }
          }
          return (
            <Fragment>
              {lines}
              {valueCircles}
              <ChartHoverHandler
                samples={samples}
                sampleTimes={sampleTimes}
                width={Math.abs(xMax)}
                height={yMax}
                yScale={yScale}
                yScaleMin={yScaleMin}
                xScale={xScale}
                valueKeyName={valueKeyName}
                rangesDefined={isChartWithRanges}
                rangeMin={rangeMin}
                rangeMax={rangeMax}
                anomalousColor={anomalousColor}
                nonAnomalousColor={nonAnomalousColor}
                formatValue={formatValue}
                showPopup={showPopup}
                hidePopup={hidePopup}
              />
              {rangesLines}
            </Fragment>
          );
        }}
      </NumericChartLayout>
    );
  }
}
