import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {replace} from 'lodash';

export function useScrollToAnchor() {
  const scrolledRef = useRef(false);
  const {hash} = useLocation();
  const hashRef = useRef(hash);

  useEffect(() => {
    if (hash) {
      if (hashRef.current !== hash) {
        hashRef.current = hash;
        scrolledRef.current = false;
      }
      if (!scrolledRef.current) {
        const id = replace(hash, '#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({behavior: 'smooth'});
          scrolledRef.current = true;
        }
      }
    }
  });

  const resetScrolledRef = () => {
    scrolledRef.current = false;
  };
  return {resetScrolledRef};
}
