import {observable, makeObservable} from 'mobx';

class Link {
  get id() {
    return `${this.from}-${this.to}`;
  }
  @observable from = null;
  @observable to = null;

  constructor(data = {}) {
    makeObservable(this);
    Object.assign(this, data);
  }
}

export default Link;
