import {Component, Fragment} from 'react';
import {Form, Icon, Input} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {computed, makeObservable} from 'mobx';
import PropTypes from 'prop-types';
import {isEmpty, map, keys, get, filter, castArray, isString} from 'lodash';
import {DropdownControl, Field, MapInput, createValueRenderer} from 'apstra-ui-common';

import './GraphAnnotationPropertiesInput.less';

@observer
export default class GraphAnnotationPropertiesInput extends Component {
  static propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    schema: PropTypes.object,
    errors: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get columns() {
    const {values} = this.props;
    return map(keys(values?.values), (key) => ({
      key, value: key, text: key,
    }));
  }

  render() {
    const {props: {name, value, schema, required, disabled, errors, onChange}, columns} = this;
    return (
      <Field
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors.generic}
      >
        <Field className='input-header'>
          {!isEmpty(value) && (
            <Form.Group className='input-subheader'>
              <Form.Field required width={4} label='Name' />
              <Form.Field width={12} className='multi-items-input'>
                <Form.Group>
                  <Form.Field required width={8} label='Key with Node Id' />
                  <Form.Field required width={8} label='Column' />
                </Form.Group>
              </Form.Field>
              <Form.Field className='no-input-field hidden-icon'>
                <Icon name='remove' />
              </Form.Field>
            </Form.Group>
          )}
        </Field>
        <MapInput
          key='control'
          value={value}
          disabled={disabled}
          generateNewEntry={() => ['', {node_id: '', column: ''}]}
          onChange={onChange}
          buttonText='Add Annotation Property'
          noItemsMessage='There are no annotation properties defined.'
        >
          {({key, value, index, setEntryKey, setEntryValue}) => {
            const keyError = get(errors, [index, key]);
            const indexErrors = {
              name: isString(keyError) ? castArray(keyError) : [],
              nodeId: filter(castArray(get(keyError, ['node_id']))),
              column: filter(castArray(get(keyError, ['column'])))
            };
            return (
              <Fragment>
                <Field required width={4} errors={indexErrors.name}>
                  <Input
                    disabled={disabled}
                    value={key}
                    onChange={(e) => setEntryKey(index, e.target.value)}
                  />
                </Field>
                <Field width={12} className='multi-items-input'>
                  <Form.Group>
                    <Field required width={8} errors={indexErrors.nodeId}>
                      <Input
                        disabled={disabled}
                        value={value.node_id}
                        onChange={(e) => setEntryValue(index, {...value, node_id: e.target.value})}
                      />
                    </Field>
                    <Field required width={8} errors={indexErrors.column}>
                      <DropdownControl
                        search
                        clearable
                        aria-label='Column Name'
                        disabled={disabled}
                        value={value.column}
                        options={columns}
                        onChange={(column) => setEntryValue(index, {...value, column})}
                      />
                    </Field>
                  </Form.Group>
                </Field>
              </Fragment>
            );
          }}
        </MapInput>
      </Field>
    );
  }
}

export const graphAnnotationPropertiesRenderer = createValueRenderer({
  condition: ({name}) => name === 'graph_annotation_properties',
  renderValueInput({name, value, values, schema, required, disabled, errors, onChange}) {
    return (
      <GraphAnnotationPropertiesInput
        name={name}
        value={value || {}}
        values={values}
        schema={schema}
        required={required}
        disabled={disabled}
        errors={errors}
        onChange={onChange}
      />
    );
  }
});
