import {forEach, isEmpty, uniqueId, assign} from 'lodash';

const beforeUnloadFn = (e) => {
  const message = 'Some requests are still pending. Are you sure you want to leave this page?';
  e.returnValue = message;
  return message;
};

class UploadStore {
  subscribers = {};
  items = {};

  addUploadItem(payload) {
    if (isEmpty(this.items)) window.addEventListener('beforeunload', beforeUnloadFn);
    const id = uniqueId();
    this.items[id] = {
      payload,
      uploadProgress: null,
    };
    this.runSubscribers();
    return id;
  }

  updateUploadItemProgress(id, update = {}) {
    assign(this.items[id], update);
    this.runSubscribers();
  }

  deleteUploadItem(id) {
    delete this.items[id];
    if (isEmpty(this.items)) window.removeEventListener('beforeunload', beforeUnloadFn);
    this.runSubscribers();
  }

  runSubscribers() {
    forEach(this.subscribers, (fn) => fn(this.items));
  }

  subscribe(fn) {
    const id = uniqueId();
    this.subscribers[id] = fn;
    return id;
  }

  unsubscribe(id) {
    delete this.subscribers[id];
  }
}

const uploadStore = new UploadStore();

export default uploadStore;
