import {useEffect, useState, useMemo} from 'react';
import {observer} from 'mobx-react';
import {Modal, Button, Dropdown, Table} from 'semantic-ui-react';
import {map, reduce, groupBy, forEach, filter, uniqueId} from 'lodash';

import {useCablingMapStore} from '../store/useCablingMapStore';
import {useTooltip} from '../../components/graphs/GraphTooltips';
import Step from '../store/Step';
import {useDevicesLabels} from '../utils';
import './SystemAssigner.less';

// Makes DDL options from subset of available systems
// Taken devices are highlighted
const makeDdlItems = (nodeId, systems, systemsNodes, devicesLabels) => {
  return map(
    filter(
      systems,
      ({id}) => (!systemsNodes?.[id] || systemsNodes[id] === nodeId),
    ),
    ({id}) => ({
      value: id,
      text: devicesLabels[id]
    })
  );
};

// Commits made chages, tracks them as a history step
const commitChanges = (nodes, nodesSystems, changes) => {
  const change = [];
  forEach(nodes, (node) => {
    const newSystemId = nodesSystems[node.id];
    if (node.systemId === newSystemId) return;
    const step = Step.modification(null, node);
    node.setProperty('systemId', newSystemId);
    step.setResult(node);
    change.push(step);
  });
  if (change.length) {
    changes.register(change);
  }
};

const SystemAssigner = ({close, nodes, availableDevices}) => {
  const [nodesSystems, setNodesSystems] = useState({});
  const [systemsNodes, setSystemsNodes] = useState({});
  const {cablingMap: {changes}} = useCablingMapStore();
  const {sharedTooltip} = useTooltip();

  const devicesLabels = useDevicesLabels(availableDevices);
  const systemLabelId = uniqueId('system-assigner-label-');

  const devicesByProfile = useMemo(
    () => (groupBy(
      filter(availableDevices, ({blueprint_id: bpId}) => (!bpId)),
      'device_profile_id'
    )),
    [availableDevices]
  );

  // Calculates which of available systems are assigned already
  useEffect(() => {
    const systemsTaken = {};
    setNodesSystems(reduce(
      nodes,
      (result, node) => {
        const systemId = node?.systemId;
        if (systemId) {
          systemsTaken[systemId] = node.id;
          result[node.id] = systemId;
        }
        return result;
      },
      {}
    ));
    setSystemsNodes(systemsTaken);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const assignSystem = (systemId, nodeId) => {
    const previousSystem = nodesSystems?.[nodeId];
    const previousNode = systemsNodes?.[systemId];

    if (previousNode) {
      nodesSystems[previousNode] = null;
    }
    nodesSystems[nodeId] = systemId;
    setNodesSystems({...nodesSystems});

    if (previousSystem) {
      systemsNodes[previousSystem] = null;
    }
    if (systemId) {
      systemsNodes[systemId] = nodeId;
    }
    setSystemsNodes({...systemsNodes});
  };

  return (
    <Modal
      open
      closeOnDimmerClick={false}
      onClose={close}
      size='large'
      className='cme-system-assigner'
      mountNode={sharedTooltip.mountRef}
    >
      <Modal.Header>{'Systems Batch Assignment'}</Modal.Header>
      <Modal.Content scrolling>
        <Table className='system-assigner' celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{'Node'}</Table.HeaderCell>
              <Table.HeaderCell>{'Device Profile'}</Table.HeaderCell>
              <Table.HeaderCell id={systemLabelId}>{'System'}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              map(
                nodes,
                (node) => {
                  const deviceProfile = node?.deviceProfile;
                  return (
                    <Table.Row
                      key={node.id}
                      active={node.isExternal}
                      error={!node.deviceProfileId && !node.isExternal}
                    >
                      <Table.Cell>{node.label}</Table.Cell>
                      <Table.Cell>{deviceProfile?.label ?? 'N/A'}</Table.Cell>
                      <Table.Cell>
                        {
                          node.deviceProfileId ?
                            <Dropdown
                              fluid
                              search
                              clearable
                              selection
                              value={nodesSystems[node.id]}
                              options={
                                makeDdlItems(
                                  node.id,
                                  devicesByProfile?.[deviceProfile?.globalCatalogId] ?? {},
                                  systemsNodes,
                                  devicesLabels
                                )
                              }
                              onChange={(event, {value}) => assignSystem(value, node.id)}
                              searchInput={{'aria-labelledby': systemLabelId}}
                            /> :
                            (
                              node.isExternal ?
                                'External node' :
                                'Device profile is not defined!'
                            )
                        }
                      </Table.Cell>
                    </Table.Row>
                  );
                }
              )
            }
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          content='Apply Changes'
          onClick={() => {
            commitChanges(nodes, nodesSystems, changes);
            close();
          }}
        />
        <Button
          content='Discard'
          onClick={close}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default observer(SystemAssigner);
