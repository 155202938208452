import {Component, Fragment} from 'react';
import {Icon, Label} from 'semantic-ui-react';
import {map} from 'lodash';
import {Field, createValueRenderer} from 'apstra-ui-common';

import TagsControl from './TagsControl';

export default class TagsInput extends Component {
  static defaultProps = {
    allowNewTags: true,
  };

  renderLabel = ({text: tag}) => (
    <Label>
      <Icon name='tag' />
      {tag}
      <Icon link name='delete' onClick={() => this.removeTag(tag)} aria-label='Remove' />
    </Label>
  );

  render() {
    const {
      name, value, schema, required, disabled, errors, onChange, placeholder, knownTags, allowNewTags, fieldProps,
    } = this.props;
    return (
      <Field
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors}
        {...fieldProps}
      >
        <TagsControl
          value={value}
          placeholder={placeholder}
          knownTags={knownTags}
          allowNewTags={allowNewTags}
          onChange={onChange}
        />
      </Field>
    );
  }
}

export const tagsRenderer = createValueRenderer({
  condition: ({name}) => name === 'tags',
  renderValue({value, onClick}) {
    return map(value, (tag) =>
      <Fragment key={tag}>
        <Label
          style={onClick && {cursor: 'pointer'}}
          onClick={onClick ? () => onClick(tag) : null}
        >
          <Icon name='tag' />{tag}
        </Label>&nbsp;
      </Fragment>
    );
  },
  renderValueInput({name, value, schema, required, disabled, errors, onChange, allowNewTags, knownTags, fieldProps}) {
    return (
      <TagsInput
        name={name}
        value={value}
        schema={schema}
        required={required}
        disabled={disabled}
        errors={errors}
        onChange={onChange}
        knownTags={knownTags}
        allowNewTags={allowNewTags}
        fieldProps={fieldProps}
      />
    );
  }
});
