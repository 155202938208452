import {Component} from 'react';
import {scaleLinear} from 'd3';
import cx from 'classnames';
import {FormattedNumber} from 'apstra-ui-common';

import './SingleHorizontalBar.less';

export default class SingleHorizontalBar extends Component {
  static defaultProps = {
    xMin: 0,
    xMax: 100,
    value: 0,
    minValue: 0,
    maxValue: 0,
    units: '',
  };

  barScale = scaleLinear().range([this.props.xMin, this.props.xMax]);

  render() {
    const {barScale, props: {value, minValue, maxValue, units}} = this;
    barScale.domain([minValue, maxValue]);
    return (
      <div className='graph-container'>
        <div
          className={cx('iba-single-horizontal-bar', {negative: value <= 0})}
          style={{width: barScale(value) + '%'}}
        >
          <FormattedNumber value={value} units={units} />
        </div>
      </div>
    );
  }
}
