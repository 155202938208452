import {get, has, head} from 'lodash';
import {request} from 'apstra-ui-common';

const urlRoot = '/api/telemetry-service-registry';

export function getServiceName(file) {
  const fileName = get(file, ['name'], '');
  return head(fileName.split('.json'));
}

export function addServiceNameIfNeeded({serviceName, schema}) {
  if (has(schema, 'service_name')) return schema;
  return {...schema, service_name: serviceName};
}

export function transformRawDataToRequest(serviceName, schema) {
  const transformed = {service_name: serviceName};
  const applicationSchema = {...schema};
  if (has(applicationSchema, 'storage_schema_path')) {
    transformed.storage_schema_path = applicationSchema.storage_schema_path;
    delete applicationSchema.storage_schema_path;
  }
  transformed.application_schema = applicationSchema;
  return transformed;
}

export function requestServiceRegistry({schema, signal}) {
  return request(urlRoot, {method: 'POST', body: JSON.stringify(schema), signal});
}

export function deleteServiceRegistry(serviceRegistry) {
  const url = `${urlRoot}/${serviceRegistry.service_name}`;
  return request(url, {method: 'DELETE'});
}

export const formSchema = [
  {
    name: 'service_name',
    required: true,
    schema: {
      type: 'string',
      title: 'Service Name',
    },
    disabled: true,
  },
  {
    name: 'storage_schema_path',
    required: true,
    schema: {
      type: 'string',
      title: 'Storage Schema Path',
      enum: [
        'aos.sdk.telemetry.schemas.generic',
        'aos.sdk.telemetry.schemas.graph',
        'aos.sdk.telemetry.schemas.iba_string_data',
        'aos.sdk.telemetry.schemas.iba_integer_data'
      ]
    }
  },
  {
    name: 'description',
    schema: {
      type: 'string',
      title: 'Description',
    },
  },
  {
    name: 'version',
    schema: {
      type: 'string',
      title: 'Version',
    }
  },
  {
    name: 'application_schema',
    required: true,
    schema: {
      type: 'string',
      title: 'Application Schema',
    }
  }
];
