import {Component} from 'react';
import {observer} from 'mobx-react';
import {computed, reaction, makeObservable} from 'mobx';
import {get, map, find, some, includes, filter, transform, head, has, union} from 'lodash';
import {Button, Form, Icon, Popup} from 'semantic-ui-react';
import {CodeEditorInput, DataTable, DropdownControl, Field} from 'apstra-ui-common';

import buildPropertyDocHTMLFromSchema from '../../pythonExpression/buildPropertyDocHTMLFromSchema';

const infoPopup = (key, schema) => (
  <Popup
    trigger={
      <Icon name='info circle' color='grey' aria-label={schema?.description || key} />
    }
    position='right center'
    wide
  >
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{__html: buildPropertyDocHTMLFromSchema(schema)}}
    />
  </Popup>
);

const tableSchema = [
  {
    name: 'key',
    label: 'Key Name',
    formatter: ({item: {required, key, schema}, params: {onChange, values, extraItems, disabled}}) => required ?
      <Form.Field className='no-input-field'>
        {key}
        {' '}
        {infoPopup(key, schema)}
      </Form.Field> :
      <Form.Group>
        <Form.Field width='15'>
          <DropdownControl
            fluid
            value={key}
            options={map([key, ...extraItems], (item) => ({key: item, text: item, value: item}))}
            onChange={(value) => {
              const result = {...values, [value]: ''};
              delete result[key];
              onChange(result);
            }}
            disabled={disabled}
          />
        </Form.Field>
        <Form.Field className='no-input-field'>
          {infoPopup(key, schema)}
        </Form.Field>
      </Form.Group>
  },
  {
    name: 'value',
    label: 'Value',
    formatter: ({
      item: {value, key, schema}, params: {onChange, values, errors, disabled}
    }) => (
      <CodeEditorInput
        mode='python-expression'
        schema={schema}
        value={value}
        onChange={(value) => onChange({...values, [key]: value})}
        errors={errors[key]}
        disabled={disabled}
      />
    ),
  },
  {
    name: 'action',
    formatter: ({item: {required, key}, params: {disabled, deleteProperty}}) => (
      <Form.Field className='no-input-field'>
        <Icon
          link
          color={required ? 'grey' : 'red'}
          name='remove'
          disabled={required || disabled}
          onClick={() => deleteProperty(key)}
          aria-label='Delete'
        />
      </Form.Field>
    )
  }
];

@observer
export default class KeysInput extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
    this.disposeServiceNameReaction = reaction(
      () => this.serviceName,
      () => {
        const {value} = this.props;
        const {required} = this.getProperties();
        if (some(required, (item) => !includes(value, item))) {
          const newValue = transform(required, (result, key) => {
            result[key] = '';
          }, {});
          this.props.onChange(newValue);
        }
      },
      {
        fireImmediately: true,
      }
    );
  }

  componentWillUnmount() {
    this.disposeServiceNameReaction();
  }

  getProperties = () => {
    return {
      required: get(this.service, ['application_schema', 'properties', 'key', 'required']),
      properties: get(this.service, ['application_schema', 'properties', 'key', 'properties']),
    };
  };

  @computed get errors() {
    const {props: {errors}, value} = this;
    const getErrors = (key) => map(filter(errors, (error) => has(error, [key])), (error) => error[key]);
    return transform(value, (result, value, key) => {
      result[key] = getErrors(key);
    }, {keysError: getErrors('keys')});
  }

  @computed get serviceName() {
    return this.props.values.service_name;
  }

  @computed get service() {
    const {serviceName, props: {telemetryServiceRegistryItems}} = this;
    return find(telemetryServiceRegistryItems, {service_name: serviceName});
  }

  @computed get extraItems() {
    const {properties} = this.getProperties();
    return filter(map(properties, (schema, key) => key), (key) => !has(this.value, [key]));
  }

  @computed get items() {
    const {required, properties} = this.getProperties();
    return map(this.value, (value, key) => ({key, schema: properties[key], value, required: includes(required, key)}));
  }

  @computed get value() {
    const {value, values} = this.props;
    const {required} = this.getProperties();
    return transform(union(value, required), (result, key) => {
      result[key] = values[key] ?? '';
    }, {});
  }

  addProperty = () => {
    const key = head(this.extraItems);
    this.props.onChange({...this.value, [key]: ''});
  };

  deleteProperty = (key) => {
    const result = {...this.value};
    delete result[key];
    this.props.onChange(result);
  };

  render() {
    const {props: {name, schema, required, disabled, onChange}, value, extraItems, errors, deleteProperty} = this;
    return (
      <Field
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors.keysError}
      >
        <DataTable
          size='small'
          celled
          schema={tableSchema}
          items={this.items}
          params={{onChange, values: value, errors, extraItems, disabled, deleteProperty}}
          getCellProps={({name}) => {
            if (name === 'key') return {width: 4};
            if (name === 'value') return {};
            return {width: 1};
          }}
          getRowProps={() => ({verticalAlign: 'top'})}
        />
        {this.extraItems.length > 0 && (
          <Button
            type='button'
            color='teal'
            size='tiny'
            icon='add'
            labelPosition='left'
            content='Add key'
            onClick={this.addProperty}
          />
        )}
      </Field>
    );
  }
}
