import {useEffect} from 'react';
import {FetchDataError, Loader, stringifyState} from 'apstra-ui-common';
import {Button, Form, Grid, Modal} from 'semantic-ui-react';
import {isString} from 'lodash';
import {useNavigate} from 'react-router-dom';

import ReportData from './ReportData';
import ReportFilters from './ReportFilters';
import MultiParagraphText from '../../../components/MultiParagraphText';
import {getDefaultValues, useReportForm} from './useReportForm';

import './ReportModal.less';

const ReportModal = ({reportName, ...props}) => reportName ? (
  <ReportData reportName={reportName} customLoader>
    <ReportForm {...props} />
  </ReportData>
) : null;

const ReportForm = ({
  open, trigger, description, onClose, reportUrl, leftColumnWidth, rightColumnWidth,
  blueprintId, reportSchema, probeList, loaderVisible, fetchDataError,
}) => {
  const {
    values, errors, useCombineTime, parametersFormSchema, probeFormSchema,
    onChange, onValidate, onReset, onUpdate,
  } = useReportForm(reportSchema);
  const navigate = useNavigate();

  useEffect(() => {
    onUpdate(getDefaultValues(reportSchema));
  }, [reportSchema, onUpdate]);

  const onGenerate = () => {
    const isFormValid = onValidate();
    if (isFormValid) {
      navigate(reportUrl + `?report-filter=${stringifyState({filters: values})}`);
    }
  };

  return (
    <Modal
      open={open}
      trigger={trigger}
      onClose={onClose}
      onUnmount={onReset}
      closeOnDimmerClick={false}
      className='report-modal'
      closeIcon
    >
      <Modal.Header>
        {'Generate Predefined Report'}
      </Modal.Header>
      <Modal.Content>
        {loaderVisible ?
          <Loader /> :
          fetchDataError ?
            <FetchDataError error={fetchDataError} /> :
            <Grid divided>
              <Grid.Column width={leftColumnWidth}>
                <Form>
                  <ReportFilters
                    predefinedProbeIds={reportSchema?.predefined_probes}
                    probeList={probeList}
                    blueprintId={blueprintId}
                    onChange={onChange}
                    parametersFormSchema={parametersFormSchema}
                    probeFormSchema={probeFormSchema}
                    useCombineTime={useCombineTime}
                    errors={errors}
                    values={values}
                  />
                </Form>
              </Grid.Column>
              <Grid.Column width={rightColumnWidth}>
                {description && (
                  isString(description) ?
                    <MultiParagraphText text={description} />
                    :
                    description
                )}
              </Grid.Column>
            </Grid>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          size='large'
          content='Generate'
          onClick={onGenerate}
        />
      </Modal.Actions>
    </Modal>
  );
};

ReportForm.defaultProps = {
  leftColumnWidth: 8,
  rightColumnWidth: 8,
};

export default ReportModal;
