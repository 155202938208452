import {Lexer, CstParser, createToken} from 'chevrotain';

export const LParen = createToken({name: 'LParen', pattern: '('});
export const RParen = createToken({name: 'RParen', pattern: ')'});
export const LCurly = createToken({name: 'LCurly', pattern: '{'});
export const RCurly = createToken({name: 'RCurly', pattern: '}'});
export const LSquare = createToken({name: 'LSquare', pattern: '['});
export const RSquare = createToken({name: 'RSquare', pattern: ']'});
export const Dot = createToken({name: 'Dot', pattern: '.'});
export const Comma = createToken({name: 'Comma', pattern: ','});
export const Colon = createToken({name: 'Colon', pattern: ':'});
export const SemiColon = createToken({name: 'SemiColon', pattern: ';'});
export const Equals = createToken({name: 'Equals', pattern: '='});
export const Asterisk = createToken({name: 'Asterisk', pattern: '*'});
export const DoubleAsterisk = createToken({name: 'DoubleAsterisk', pattern: '**'});
export const Plus = createToken({name: 'Plus', pattern: '+'});
export const Minus = createToken({name: 'Minus', pattern: '-'});
export const Tilde = createToken({name: 'Tilde', pattern: '~'});
export const Identifier = createToken({
  name: 'Identifier',
  pattern: /[a-zA-Z_]\w*/,
});
export const NumberLiteral = createToken({
  name: 'NumberLiteral',
  pattern: /-?(0|[1-9]\d*)(\.\d+)?([eE][+-]?\d+)?/,
});
export const InfixOperator = createToken({
  name: 'InfixOperator',
  pattern: /(if|else|for|or|and|not\s+in|is\s+not|is|in|==|!=|>=|<=|<>|>>|<<|<|>|\/\/?|%|\||&|\^)/,
  longer_alt: Identifier,
});
export const Lambda = createToken({
  name: 'Lambda',
  pattern: 'lambda',
  longer_alt: Identifier,
});
export const Not = createToken({
  name: 'Not',
  pattern: 'not',
  longer_alt: Identifier
});
export const WhiteSpace = createToken({
  name: 'WhiteSpace',
  pattern: /\s+/,
  group: Lexer.SKIPPED
});
export const LineTerminator = createToken({
  name: 'LineTerminator',
  pattern: /\\?(\n\r|\r|\n)/,
  group: Lexer.SKIPPED
});

const stringPrefix = '(?:r|u|ur|R|U|UR|Ur|uR|b|B|br|Br|bR|BR)?';
export const makeShortStringPattern = (encloser) => new RegExp(
  stringPrefix +
  encloser +
  `(:?[^\\\\\\n\\r${encloser}]|\\\\(:?x[0-9A-Fa-f]{2}|[0-7]{3}|[\\\\abfnrtv'"]|U[0-9A-Fa-f]{8}|u[0-9A-Fa-f]{4}))*` +
  encloser
);
export const makeLongStringPattern = (encloser) => new RegExp(
  stringPrefix +
  encloser + encloser + encloser +
  `(?:\\\\${encloser}${encloser}${encloser}|[^${encloser}]|${encloser}(?!${encloser}${encloser}))*` +
  encloser + encloser + encloser
);
export const ShortSingleQuoteStringLiteral = createToken({
  name: 'ShortSingleQuoteStringLiteral',
  pattern: makeShortStringPattern("'"),
  longer_alt: Identifier,
});
export const ShortDoubleQuoteStringLiteral = createToken({
  name: 'ShortDoubleQuoteStringLiteral',
  pattern: makeShortStringPattern('"'),
  longer_alt: Identifier,
});
export const LongSingleQuoteStringLiteral = createToken({
  name: 'LongSingleQuoteStringLiteral',
  pattern: makeLongStringPattern("'"),
  longer_alt: Identifier,
});
export const LongDoubleQuoteStringLiteral = createToken({
  name: 'LongDoubleQuoteStringLiteral',
  pattern: makeLongStringPattern('"'),
  longer_alt: Identifier,
});

export const allTokens = [
  WhiteSpace,
  LineTerminator,
  LParen,
  RParen,
  LCurly,
  RCurly,
  LSquare,
  RSquare,
  Dot,
  Comma,
  Colon,
  SemiColon,
  NumberLiteral,
  LongSingleQuoteStringLiteral,
  LongDoubleQuoteStringLiteral,
  ShortSingleQuoteStringLiteral,
  ShortDoubleQuoteStringLiteral,
  DoubleAsterisk,
  Asterisk,
  Plus,
  Minus,
  Tilde,
  InfixOperator,
  Not,
  Equals,
  Lambda,
  Identifier,
];

export const PythonExpressionLexer = new Lexer(allTokens);

let parserInstance = null;

export default class PythonExpressionParser extends CstParser {
  static get instance() {
    if (!parserInstance) parserInstance = new this();
    return parserInstance;
  }

  static parse(text) {
    const lexResult = PythonExpressionLexer.tokenize(text);
    const parser = this.instance;
    parser.input = lexResult.tokens;
    const cst = parser.expression();
    return {
      cst,
      lexErrors: lexResult.errors,
      parseErrors: parser.errors
    };
  }

  constructor() {
    super(allTokens);
    const $ = this;
    $.RULE('expression', () => {
      $.OR([
        {ALT: () => $.SUBRULE($.prefixOperatorExpression)},
        {ALT: () => $.SUBRULE($.functionCall)},
        {ALT: () => $.SUBRULE($.lambda)},
        {ALT: () => $.SUBRULE($.list)},
        {ALT: () => $.SUBRULE($.dict)},
        {ALT: () => $.SUBRULE($.parenWrappedExpressionOrTuple)},
        {ALT: () => $.CONSUME(NumberLiteral)},
        {ALT: () => $.SUBRULE($.string)},
        {ALT: () => $.CONSUME(Identifier)},
      ]);
      $.OPTION(() => $.SUBRULE($.chainedExpression));
      $.OPTION2(() => $.SUBRULE($.indexingOrSlicingChain));
      $.OPTION3(() => $.SUBRULE($.infixOperatorExpression));
    });
    $.RULE('chainedExpression', () => {
      $.CONSUME(Dot);
      $.SUBRULE($.expression);
    });
    $.RULE('indexingOrSlicingChain', () => {
      $.AT_LEAST_ONE(() => {
        $.CONSUME(LSquare);
        $.SUBRULE($.expression);
        $.CONSUME(RSquare);
      });
      $.OPTION(() => $.SUBRULE($.chainedExpression));
    });
    $.RULE('infixOperatorExpression', () => {
      $.SUBRULE($.infixOperator);
      $.SUBRULE($.expression);
    });
    $.RULE('infixOperator', () => {
      $.OR([
        {ALT: () => $.CONSUME(InfixOperator)},
        {ALT: () => $.CONSUME(Plus)},
        {ALT: () => $.CONSUME(Minus)},
        {ALT: () => $.CONSUME(DoubleAsterisk)},
        {ALT: () => $.CONSUME(Asterisk)},
      ]);
    });
    $.RULE('prefixOperatorExpression', () => {
      $.SUBRULE($.prefixOperator);
      $.SUBRULE($.expression);
    });
    $.RULE('prefixOperator', () => {
      $.OR([
        {ALT: () => $.CONSUME(Not)},
        {ALT: () => $.CONSUME(Plus)},
        {ALT: () => $.CONSUME(Minus)},
        {ALT: () => $.CONSUME(Tilde)},
      ]);
    });
    $.RULE('string', () => {
      $.OR([
        {ALT: () => $.CONSUME(LongSingleQuoteStringLiteral)},
        {ALT: () => $.CONSUME(LongDoubleQuoteStringLiteral)},
        {ALT: () => $.CONSUME(ShortSingleQuoteStringLiteral)},
        {ALT: () => $.CONSUME(ShortDoubleQuoteStringLiteral)},
      ]);
    });
    $.RULE('parenWrappedExpressionOrTuple', () => {
      $.CONSUME(LParen);
      $.OPTION(() => {
        $.SUBRULE($.expression);
        $.OPTION2(() => {
          $.CONSUME(Comma, {LABEL: 'tupleDistinguishingComma'});
          $.MANY(() => {
            $.SUBRULE2($.expression, {LABEL: 'restTupleExpressions'});
            $.OPTION3(() => $.CONSUME2(Comma));
          });
        });
      });
      $.CONSUME(RParen);
    });
    $.RULE('list', () => {
      $.CONSUME(LSquare);
      $.OPTION(() => {
        $.SUBRULE($.expression);
        $.MANY(() => {
          $.CONSUME(Comma);
          $.SUBRULE2($.expression);
        });
        $.OPTION2(() => {
          $.CONSUME2(Comma);
        });
      });
      $.CONSUME(RSquare);
    });
    $.RULE('keyValuePair', () => {
      $.SUBRULE($.expression, {LABEL: 'key'});
      $.CONSUME(Colon);
      $.SUBRULE2($.expression, {LABEL: 'value'});
    });
    $.RULE('dict', () => {
      $.CONSUME(LCurly);
      $.OPTION(() => {
        $.SUBRULE($.keyValuePair);
        $.MANY(() => {
          $.CONSUME(Comma);
          $.SUBRULE2($.keyValuePair);
        });
        $.OPTION2(() => $.CONSUME2(Comma));
      });
      $.CONSUME(RCurly);
    });
    $.RULE('functionCall', () => {
      $.CONSUME(Identifier, {LABEL: 'functionName'});
      $.CONSUME(LParen);
      $.SUBRULE($.functionArguments);
      $.CONSUME(RParen);
    });
    $.RULE('lambda', () => {
      $.CONSUME(Lambda);
      $.SUBRULE($.functionArguments);
      $.CONSUME(Colon);
      $.SUBRULE($.expression);
    });
    $.RULE('functionArguments', () => {
      $.OPTION(() => {
        $.SUBRULE($.functionArgument);
        $.MANY(() => {
          $.CONSUME(Comma);
          $.SUBRULE2($.functionArgument);
        });
        $.OPTION2(() => $.CONSUME2(Comma));
      });
    });
    $.RULE('functionArgument', () => {
      $.OR([
        {ALT: () => $.SUBRULE($.keywordArgument)},
        {ALT: () => $.SUBRULE($.positionalArgument)},
        {ALT: () => $.SUBRULE($.variadicKeywordArguments)},
        {ALT: () => $.SUBRULE($.variadicPositionalArguments)},
      ]);
    });
    $.RULE('positionalArgument', () => {
      $.SUBRULE($.expression);
    });
    $.RULE('keywordArgument', () => {
      $.CONSUME(Identifier, {LABEL: 'key'});
      $.CONSUME(Equals);
      $.SUBRULE($.expression, {LABEL: 'value'});
    });
    $.RULE('variadicPositionalArguments', () => {
      $.CONSUME(Asterisk);
      $.SUBRULE($.expression);
    });
    $.RULE('variadicKeywordArguments', () => {
      $.CONSUME(DoubleAsterisk);
      $.SUBRULE($.expression);
    });

    $.performSelfAnalysis();
  }
}
