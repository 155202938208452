import {Component} from 'react';
import {Link} from 'react-router-dom';
import {Table} from 'semantic-ui-react';
import {map, find, uniq} from 'lodash';
import {interpolateRoute, request, stringifyState} from 'apstra-ui-common';

import getCellColor from '../getCellColor';
import IBAContext from '../IBAContext';

import './AnomalyHeatmapWidget.less';

export default class AnomalyHeatmapWidget extends Component {
  static contextType = IBAContext;

  static pollingInterval = 10000;

  static async fetchData({widget, blueprintId, routes, signal}) {
    const {items: anomalyHeatmapData} = await request(
      interpolateRoute(routes.anomalyHeatmapWidgetData, {blueprintId, widgetId: widget.id}), {signal}
    );
    return {anomalyHeatmapData};
  }

  buildLinkToAnomalies(tags = []) {
    const {blueprintId} = this.context;
    const stringifiedFilters = stringifyState({
      filters: {
        tags: {tags, strict: true},
        showStagesWithoutAnomalies: true,
      }
    });
    return `/blueprints/${blueprintId}/analytics/anomalies?grouped=true&probe-anomalies-filter=${stringifiedFilters}`;
  }

  render() {
    const {
      widget, widgetData: {anomalyHeatmapData},
    } = this.props;
    return (
      <div className='iba-anomaly-heatmap-widget'>
        <Table
          definition
          celled
          size='small'
          textAlign='center'
        >
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell collapsing />
              {map(widget.columns, (column, columnIndex) =>
                <Table.HeaderCell key={`${column} ${columnIndex}`} >
                  <Link reloadDocument to={this.buildLinkToAnomalies([column])}>{column}</Link>
                </Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {map(widget.rows, (row, rowIndex) =>
              <Table.Row key={`${row} ${rowIndex}`}>
                <Table.Cell>
                  <Link reloadDocument to={this.buildLinkToAnomalies([row])}>{row}</Link>
                </Table.Cell>
                {map(widget.columns, (column, columnIndex) => {
                  const key = `${column} ${columnIndex}`;
                  const cell = find(anomalyHeatmapData, {row, column});
                  const {background, foreground} = getCellColor(cell);
                  if (!cell) return <Table.Cell key={key}>{'–'}</Table.Cell>;
                  return (
                    <Table.Cell
                      key={key}
                      style={{backgroundColor: background}}
                    >
                      <Link
                        reloadDocument
                        to={this.buildLinkToAnomalies(uniq([row, column]))}
                        style={{color: foreground}}
                      >
                        {`${cell.anomalies} / ${cell.items}`}
                      </Link>
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
