import {useRef, useEffect} from 'react';
import {observer} from 'mobx-react';
import {Accordion, Grid} from 'semantic-ui-react';
import {chain, keys, union} from 'lodash';

import EptParametersDiff from './EptParametersDiff';
import DiffLine from './DiffLine';
import './ParametersDiff.less';

const ParametersDiff = ({eptA, eptB, onlyShowDifferent = false}) => {
  const activeEptRef = useRef();
  const activeId = eptA.activePrimitiveId || eptB.activePrimitiveId;

  useEffect(() => {
    if (activeId && activeEptRef.current) {
      activeEptRef.current.scrollIntoView({behavior: 'smooth'});
    }
  });

  const setActivePrimitive = (id) => {
    eptA.setActivePrimitive(id);
    eptB.setActivePrimitive(id);
  };

  const eptsParameters = chain(union(keys(eptA.epts), keys(eptB.epts)))
    .filter((eptId) => !!eptId)
    .map((eptId) => (
      <EptParametersDiff
        ref={activeId === eptId ? activeEptRef : null}
        key={eptId}
        eptA={eptA.epts[eptId]}
        eptB={eptB.epts[eptId]}
        activePrimitiveId={eptA.activePrimitiveId}
        setActivePrimitive={setActivePrimitive}
        onlyShowDifferent={onlyShowDifferent}
      />)
    );

  return (
    <>
      <Grid>
        <DiffLine
          title={<h5>{'Title'}</h5>}
          value1={eptA.title}
          value2={eptB.title}
        />
        <DiffLine
          title='Description'
          value1={eptA.description}
          value2={eptB.description}
        />
        <DiffLine
          title='Tags'
          value1={eptA.tags}
          value2={eptB.tags}
        />
      </Grid>
      <h3>{'Parameters'}</h3>
      <Accordion className='ct-parameters-diff' styled>
        {eptsParameters.value()}
      </Accordion>
    </>
  );
};

export default observer(ParametersDiff);
