import {compact, countBy, every, filter, first, flatten, forEach, isArray, keyBy, map, max, min, size, some, sortBy,
  transform, uniq} from 'lodash';
import {action, computed, makeObservable, observable} from 'mobx';

import Node from './Node';
import {pickBatchChangeFor, registerNodeChange, sameValue, tryPlacing} from '../utils';
import {NODE_ROLES, PEER_LINKS_TYPES, PORT_ROLES, REDUNDANCY} from '../const';
import Step from '../../cablingMapEditor/store/Step';

class NodePropertiesStore {
  @observable.ref rackStore;
  @observable.ref selection;
  @observable selectedNodes = [];
  @observable result;

  @computed
  get firstNode() {
    return first(this.selectedNodes);
  }

  @computed
  get selectionSize() {
    return size(this.selectedNodes);
  }

  @computed
  get isPair() {
    return this.selectionSize === 2 && this.firstNode.isPairedWith(this.selectedNodes[1].id);
  }

  @computed
  get isBatch() {
    return this.selectionSize > 1 && !this.isPair;
  }

  @computed
  get isSwitch() {
    return this.result.role && !this.result.isGeneric;
  }

  @computed
  get noLogicalDevice() {
    return !this.result.logicalDevice;
  }

  @computed
  get portChannels() {
    return compact(map(this.selectedNodes, 'portChannelId'));
  }

  // True if all selected generics have portChannelId set
  @computed
  get allPortChannelsSet() {
    return this.isBatch ? (size(this.portChannels) === this.selectionSize) : true;
  }

  @computed
  get batchSpineLinksSpeed() {
    return sameValue(this.selectedNodes, 'spineLinksSpeed', null);
  }

  @computed
  get usedPortsFilter() {
    return this.batchSpineLinksSpeed ?
      'usedPorts' :
      nonSpinePorts;
  }

  // Related links groups excluding peers
  @computed
  get linksRelated() {
    return keyBy(
      filter(this.rackStore.linksGroups, (linksGroup) => linksGroup.contains(this.selectedNodes)),
      'peeringType'
    );
  }

  // Discover which redundant pairs given node is connected with
  @computed
  get linksByRedundancy() {
    return this.linksRelated.null ?
      transform(
        flatten([this.linksRelated.null]),
        (acc, {fromName, toName, isPeer}) => {
          if (!isPeer) {
            acc[
              first(this.rackStore.nodesByName[this.result.name === fromName ? toName : fromName])?.redundancyProtocol
            ] = true;
          }
        },
        {}
      ) :
      {};
  }

  @computed
  get hasLinksToESI() {
    return this.linksByRedundancy[REDUNDANCY.ESI];
  }

  @computed
  get hasLinksToMLAG() {
    return this.linksByRedundancy[REDUNDANCY.MLAG];
  }

  // Make MLAG redundancy available only if:
  @computed
  get canSwitchToMlag() {
    // Role is leaf
    return this.result.isLeaf &&
    // Logical device is defined
    !this.noLogicalDevice &&
    // Node has no links to ESI access switch pairs
    !this.hasLinksToESI && (
      // There are no ESI leaf pairs in rack type
      !this.rackStore.esiLeafPairsCount || (
        // or the only ESI leaf pair is the current node
        this.rackStore.esiLeafPairsCount === 1 && this.result.isEsiLeafPair
      )
    );
  }

  // Make ESI redundancy available only if:
  @computed
  get canSwitchToEsi() {
    // Logical device is defined
    return !this.noLogicalDevice &&
    // Node has no links to MLAG leaf pairs
    !this.hasLinksToMLAG && (
      // Role is access switch
      !this.result.isLeaf || (
        // There are no MLAG leaf pairs in rack type
        !this.rackStore.mlagLeafPairsCount || (
          // or the only MLAG leaf pair is the current node
          this.rackStore.mlagLeafPairsCount === 1 && this.result.isMlagLeafPair
        )
      )
    );
  }

  // Affected nodes include selected nodes + their pairs
  @computed
  get affectedNodes() {
    return this.selectionSize ? (
      this.isBatch ?
        sortBy(
          compact(
            map(
              uniq(
                transform(
                  this.selectedNodes,
                  (acc, {idsAffected}) => acc.push(...idsAffected),
                  []
                )
              ),
              (id) => this.rackStore.nodes[id]
            )
          ),
          'id'
        ) :
        [this.firstNode]
    ) : [];
  }

  // Ports used by each of affected nodes
  @computed
  get hostedPorts() {
    return map(this.affectedNodes, this.usedPortsFilter);
  }

  @computed
  get spinePorts() {
    if (!this.result.isLeaf) return {};

    // For batch management all selected nodes must have available SPINE ports of the same speed
    const allSpinePorts = transform(
      this.affectedNodes,
      (acc, {availablePorts}) => {
        const nodeSpinePorts = countBy(filter(availablePorts, {hasSpineRole: true}), 'speedString');
        forEach(nodeSpinePorts, (count, speedString) => {
          (acc[speedString] ??= []).push(count);
        });
      },
      {}
    );

    const affectedCount = size(this.affectedNodes);
    return transform(
      allSpinePorts,
      (acc, speedCounts, speedString) => {
        if (size(speedCounts) !== affectedCount) return;
        acc[speedString] = min(speedCounts);
      },
      {}
    );
  }

  @computed
  get maxSpineLinks() {
    return (this.spinePorts[this.result.spineLinksSpeed] || 0) + (this.result.spineLinksCount || 0);
  }

  @computed
  get spinePortsExist() {
    return size(this.spinePorts) > 0 || this.result.spineLinksCount > 0;
  }

  @computed
  get deviceOptions() {
    const deviceFilter = devicesFilters[this.result.role];
    return sortBy(
      map(
        filter(this.rackStore.devices, ({id, ports: ldPorts}) => {
          // First try to filted LDs out by the role (e.g. Access Switch should always have a
          // connection to Leaf, thus it needs ports with 'leaf' role).
          // If nodes of different roles selected - role filter is empty
          const roleFilter =
          // Filter in already selected device or
            id === this.result.logicalDevice?.id ||
            // that correspond to the node's role
            some(ldPorts, (port) => port.matchesAnyOfRoles(deviceFilter(this.rackStore.isL3Clos)));
          if (!roleFilter) return false;

          // Check if this LD is capable of hosting ALL links for each of selected nodes
          // including their pairs
          return every(this.hostedPorts, (nodePorts) => !!tryPlacing(nodePorts, ldPorts));
        }
        ),
        ({id: value, display_name: text}) => ({text, value})
      ), 'value'
    );
  }

  @computed
  get portChannelError() {
    return this.isBatch ?
      some(this.selectedNodes, 'hasPortChannelErrors') :
      !!this.result.portChannelIdsErrors?.portChannelId;
  }

  // TRUE if all selected nodes are paired with MLAG redundancy
  @computed
  get allMlagPairs() {
    return sameValue(this.selectedNodes, 'isMlagLeafPair', false);
  }

  constructor(rackStore, selection, nodes) {
    makeObservable(this);

    this.rackStore = rackStore;
    this.selection = selection;
    this.init(nodes);
  }

  @action
  init(nodes = []) {
    this.selectedNodes = isArray(nodes) ? [...nodes] : [nodes];

    this.result = this.selectionSize ? (
      this.isBatch ?
      new Node({
        label: '',
        logicalDevice: sameValue(nodes, 'logicalDevice', null, 'logicalDevice.id'),
        tags: sameValue(nodes, 'tags', []),
        portChannelId: min(this.portChannels),
        portChannelIdExt: max(this.portChannels),
        mlagVlanId: sameValue(nodes, 'mlagVlanId', 0),
        spineLinksSpeed: this.batchSpineLinksSpeed,
        spineLinksCount: this.batchSpineLinksSpeed ? sameValue(nodes, 'spineLinksCount', 0) : 0,
        role: sameValue(nodes, 'role')
      }, this.rackStore) :
      this.firstNode
    ) : {};
  }

  @action
  trackChange = (property, value) => {
    if (this.isBatch) {
      this.result.setProperty(property, value);
    }
    const change = pickBatchChangeFor(this.affectedNodes, this.rackStore);
    registerNodeChange(change, this.rackStore, property, value);
  };

  @action
  setPortChannel = (property, value) => {
    if (this.isBatch) {
      // Find/generate batch change
      const change = pickBatchChangeFor(this.affectedNodes, this.rackStore);

      // For batch mode only port channel id range for generic systems
      // can be managed
      this.result.setProperty(property, value);
      let portChannelId = this.result.portChannelId;
      forEach(this.selectedNodes, (node) => {
        do {
          const pcid = portChannelId <= this.result.portChannelIdExt ? portChannelId : 0;
          node.setProperty('portChannelId', pcid);
          node.setProperty('portChannelIdExt', pcid);
          portChannelId++;
        } while (node.hasPortChannelErrors);
      });
      // Register changes made to affected nodes
      registerNodeChange(change, this.rackStore);
    } else {
      // Single generic has just one PortChannelID
      this.trackChange('portChannelId', value);
      this.trackChange('portChannelIdExt', value);
    }
  };

  @action
  changeRedundancy = ({value}) => {
    if (this.isBatch) return;

    if (this.result.redundancyProtocol !== value) {
      const change = [];

      if (!value) {
        // If removing redundancy - just delete the pair node with their links

        // Delete pair node
        const pairId = this.result.pairedWith?.id;
        this.selection.remove(pairId);
        // Adding node+links deletion to the change
        this.rackStore.deleteNode(pairId, change);
      } else {
        const step = Step.modification(this.result).inverted;
        if (this.result.redundancyProtocol) {
          // Redundancy protocol change ESI<->MLAG

          // Every redundancy change must remove peer links
          forEach(
            this.result.isLeaf ?
              // ... and L3 peers for ESI leaf pairs
              compact([this.linksRelated?.[PEER_LINKS_TYPES.L2_PEER], this.linksRelated?.[PEER_LINKS_TYPES.L3_PEER]]) :
              this.linksRelated[PEER_LINKS_TYPES.L2_PEER],
            (linksGroup) => {
              // Register deletion in history
              change.push(Step.deletion(linksGroup));
              // Delete links group
              this.rackStore.deleteLinksGroup(linksGroup.id);
            }
          );
        } else {
          // Create peer node

          const [cloneId] = this.rackStore.cloneSelected([this.result.id], true, false);
          if (cloneId) {
            const clone = this.rackStore.nodes[cloneId];
            this.rackStore.createPeerNodeFor(this.result, value, clone);

            // Adding clone node creation to the change
            change.push(Step.creation(clone));
          }
        }

        // Set new redundancy protocol (will be propagated to the pair node)
        this.result.redundancyProtocol = value;

        step.setResult(this.result);
        change.push(step);
      }

      // Registering change in the history
      this.rackStore.changes.register(change);
    }
  };
}

export default NodePropertiesStore;

const nonSpinePorts = ({usedPorts}) => filter(usedPorts, {hasSpineRole: false});

const devicesFilters = {
  [NODE_ROLES.LEAF]: (isL3Clos) => (
    // If design is L3Clos, device is supposed to have port with spine roles available
    isL3Clos ? [PORT_ROLES.SPINE] : []
  ),
  [NODE_ROLES.ACCESS_SWITCH]: () => (
    // Access switches should always be connected with leafs so having ports with leaf role is
    // mandatory
    [PORT_ROLES.LEAF]
  ),
  [NODE_ROLES.GENERIC]: () => (
    // Any generic has to be either connected with a leaf or access switch (or both)
    [PORT_ROLES.LEAF, PORT_ROLES.ACCESS_SWITCH]
  ),
  undefined: () => []
};
