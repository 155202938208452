import {Component} from 'react';
import {toJS} from 'mobx';
import {find, flatMap, size, isEmpty, isObject} from 'lodash';

import {getStageFormSchema, getProcessorByStageName} from '../stageUtils';
import ExportModal from '../../components/ExportModal';
import IBAContext from '../IBAContext';

export default class ProbeExportModal extends Component {
  static contextType = IBAContext;

  export = () => {
    const {processorDefinitions} = this.context;
    const probe = toJS(this.props.probe);
    if (!probe) return '';
    const exportedProbe = {label: probe.label};
    if (!isEmpty(probe.description)) exportedProbe.description = probe.description;
    if (!isEmpty(probe.tags)) exportedProbe.tags = probe.tags;
    exportedProbe.processors = probe.processors;
    const stages = flatMap(probe.stages, (stage) => {
      const exportedStage = {name: stage.name};
      const processor = getProcessorByStageName({probe, stageName: stage.name});
      const processorDefinition = find(processorDefinitions, {name: processor.type});
      for (const {name} of getStageFormSchema(processorDefinition)) {
        const value = stage[name];
        if (value && (!isObject(value) || !isEmpty(value))) exportedStage[name] = value;
      }
      return size(exportedStage) > 1 ? [exportedStage] : [];
    });
    if (stages.length) exportedProbe.stages = stages;
    return JSON.stringify(exportedProbe, null, 2);
  };

  render() {
    const {probe, ...props} = this.props;
    if (!probe) return null;
    return (
      <ExportModal
        header={`Export ${probe.label ?? 'Probe'}`}
        fileName={`${this.props.probe.label ?? 'Untitled Probe'}.json`}
        export={this.export}
        type='application/json'
        {...props}
      />
    );
  }
}
