export const NODE_STATUSES = ['dark-red', 'red', 'orange', 'yellow', 'purple', 'teal', 'green'];

export const TOPOLOGY_CONFIG = {
  gap: 20,
  columns: 4,
  vlanGap: 10,
  padding: [25, 10, 10],
  interfaceHorizontalPadding: 20,
  interfaceVerticalPadding: 3,
  interfaceSize: 6,
  columnRightPadding: 0,
  nodePairSpace: 6,
  minGraphWidth: 600,
};

export const VLAN_COLOR_NAMES = ['teal', 'purple', 'orange', 'blue', 'violet',
  'green', 'pink', 'brown', 'olive', 'black', 'red'];

export type TopologyConfigType = typeof TOPOLOGY_CONFIG;
