import PropTypes from 'prop-types';
import {useMemo, useState, Fragment} from 'react';
import {slice, map, sortBy, isUndefined, forEach} from 'lodash';
import {Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import './AnomalousMetrics.less';

const StatsItems = ({items}) =>
  map(items, ({link, label}, index) =>
    <div key={index} className='item'>
      <Link to={link}>{label}</Link>
    </div>
  );

const AnomalousMetrics = ({value = [], showMinItems = 3}) => {
  const [showMore, setShowMore] = useState(value?.length > showMinItems);
  const items = useMemo(() => {
    const sortedValue = sortBy(value, ({lane}) => isUndefined(lane) ? -1 : lane);
    return showMore ?
      slice(sortedValue, 0, showMinItems) :
      sortedValue;
  }, [value, showMore, showMinItems]);
  const [interfaceStats, laneStats] = useMemo(() => {
    const interfaceStats = [];
    const laneStats = {};
    forEach(items, ({lane, ...item}) => {
      if (isUndefined(lane)) {
        interfaceStats.push(item);
      } else {
        laneStats[lane] = laneStats[lane] || [];
        laneStats[lane].push(item);
      }
    });
    return [interfaceStats, laneStats];
  }, [items]);

  return (
    <div className='anomalous-metrics'>
      <div className='ui bulleted list'>
        <StatsItems items={interfaceStats} />
      </div>
      {map(laneStats, (items, lane) =>
        <Fragment key={lane}>
          <div>{`Lane ${lane}`}</div>
          <div className='ui bulleted list'>
            <StatsItems items={items} />
          </div>
        </Fragment>
      )}
      {showMore && <Button content='More...' size='tiny' compact onClick={() => setShowMore(false)} />}
    </div>
  );
};

AnomalousMetrics.propTypes = {
  value: PropTypes.array,
};

export default AnomalousMetrics;
