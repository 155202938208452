import {Component, Fragment, createRef} from 'react';
import {
  observable,
  observe,
  computed,
  action,
  autorun,
  reaction,
  toJS,
  makeObservable,
  runInAction,
} from 'mobx';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {Grid, Breadcrumb, Label, Form, Button, Icon, Popup, Message} from 'semantic-ui-react';
import {
  map, mapValues, find, filter, some, forEach, values, pick, union, transform, includes,
  startCase, upperFirst, groupBy, isPlainObject, isMatch, isEqual, castArray, pullAllWith,
  assignWith, chunk, isEmpty, get, zipObject, findIndex, cloneDeep,
} from 'lodash';
import pluralize from 'pluralize';
import {Base64} from 'js-base64';
import {ActionsMenu, FetchDataError, Field, FormFragment, GenericErrors, Value,
  formatBytes, interpolateRoute, request, withRouter} from 'apstra-ui-common';

import generateProbeURI from '../generateProbeURI';
import ProbeDeletionModal from './ProbeDeletionModal';
import {descriptionRenderer} from '../descriptionRenderer';
import {tagsRenderer} from '../../components/TagsInput';
import {getStageFormSchema, getProcessorByStageName, getInputStageNames} from '../stageUtils';
import PythonExpressionPlainTextFormatter from '../../pythonExpression/PythonExpressionPlainTextFormatter';
import ProbeActions from './ProbeActions';
import ProbeStage from './ProbeStage';
import ProbeProcessor from './ProbeProcessor';
import ProbeGraph from './ProbeGraph';
import ProbeExportModal from './ProbeExportModal';
import ProbeImportModal from './ProbeImportModal';
import PredefinedProbeModal from './PredefinedProbeModal';
import UpdatedByLabel from './UpdatedByLabel';
import PredefinedEntityIcon from './PredefinedEntityIcon';
import ProbeToggle from './ProbeToggle';
import IBAContext from '../IBAContext';
import PredefinedDashboardsLinks from './PredefinedDashboardsLinks';
import {ReactComponent as StagePersisted} from '../../../styles/icons/iba/stage-persisted.svg';

import './ProbeDetails.less';

@withRouter
@observer
export default class ProbeDetails extends Component {
  static contextType = IBAContext;

  static pollingInterval = 10000;

  static defaultProps = {
    probePropertiesSchema: [
      {
        name: 'label',
        required: true,
        schema: {title: 'Name', type: 'string'}
      },
      {
        name: 'tags',
        schema: {
          title: 'Tags',
          type: 'array',
          items: {type: 'string'},
        }
      },
      {
        name: 'description',
        schema: {title: 'Description', type: 'string'}
      },
      {
        name: 'enabled',
        appearance: 'toggle',
        schema: {
          type: 'boolean',
          title: 'Enabled',
          description: "Disabled probes don't produce data and don't raise anomalies.",
        }
      },
    ]
  };

  static async fetchTelemetryServiceRegistryItems({routes, signal}) {
    const {items: telemetryServiceRegistryItems} = await request(routes.telemetryServiceRegistry, {signal});
    return {telemetryServiceRegistryItems};
  }

  static async fetchData({blueprintId, probeId, routes, previousData, signal}) {
    const {telemetryServiceRegistryItems} = previousData ?
      previousData :
      await ProbeDetails.fetchTelemetryServiceRegistryItems({routes, signal});

    const route = routes.probeDetails;
    const probe = await request(interpolateRoute(route, {
      blueprintId,
      probeId
    }), {signal});

    // Disk usage can be empty for a new probe. In this case response returns 404 status code.
    let diskUsage = null;
    try {
      diskUsage = some(probe?.stages, {enable_metric_logging: true}) ?
        await request(interpolateRoute(routes.diskSpaceUsageForProbe, {
          blueprintId,
          probeId
        }), {signal}) :
        null;
    } catch {
    }
    return {probe, diskUsage, telemetryServiceRegistryItems};
  }

  @observable currentProbe = !this.props.probeId ? {
    label: '',
    description: '',
    disabled: false,
    tags: [],
    processors: [],
    stages: []
  } : this.props.location.state?.probe ?? {
    label: this.props.probe.label,
    description: this.props.probe.description,
    disabled: this.props.probe.disabled,
    tags: this.props.probe.tags,
    processors: this.props.probe.processors,
    stages: map(
      this.props.probe.stages,
      (stage) => pick(
        stage,
        'name',
        'type',
        'keys',
        'warnings',
        'description',
        'tags',
        'units',
        'enable_metric_logging',
        'retention_duration',
        'values',
        'graph_annotation_properties',
        'dynamic',
      )
    ),
  };

  @observable currentProcessorName = null;
  @observable currentStageName = null;
  @observable currentActionInProgress = null;
  @observable.shallow errors = [];
  @observable.ref highlightConnectionsFor = null;
  @observable highlightedInputStageName = null;
  @observable isProbeDirty = false;
  @observable probeNavigationExpanded = this.props.location.state?.probeNavigationExpanded ?? false;

  @action
  toggleProbeNavigationExpanded = () => {
    this.probeNavigationExpanded = !this.probeNavigationExpanded;
  };

  @action
  highlightStage = (stageName, highlight = true) => {
    if (highlight) {
      this.highlightedInputStageName = stageName;
    } else {
      this.highlightedInputStageName = null;
    }
  };

  @action
  highlightProcessorAndRelatedStages = (processorName) => {
    this.highlightConnectionsFor = ['processor', processorName];
  };

  @action
  highlightStageAndRelatedProcessors = (stageName) => {
    this.highlightConnectionsFor = ['stage', stageName];
  };

  @action
  highlightCurrentEntity = () => {
    this.highlightConnectionsFor = null;
  };

  getRelatedHighlightsForProcessor(processorName) {
    return {
      processors: {[processorName]: {highlightConnections: true}},
      stages: transform(
        find(this.probe.processors, {name: processorName}).inputs, (result, {stage: stageName}) => {
          result[stageName] = {};
        }, {}
      )
    };
  }

  getRelatedHighlightsForStage(stageName) {
    return {
      processors: transform(
        this.probe.processors.filter((processor) => includes(getInputStageNames(processor), stageName)),
        (result, processor) => {
          result[processor.name] = {};
        },
        {}
      ),
      stages: {[stageName]: {highlightConnections: true}}
    };
  }

  @computed get highlights() {
    const highlights = {processors: {}, stages: {}};
    if (this.highlightConnectionsFor) {
      const [entity, entityName] = this.highlightConnectionsFor;
      if (entity === 'processor') {
        Object.assign(highlights, this.getRelatedHighlightsForProcessor(entityName));
      } else if (entity === 'stage') {
        Object.assign(highlights, this.getRelatedHighlightsForStage(entityName));
      }
    } else {
      const {currentProcessorName, currentStageName} = this;
      if (currentProcessorName) {
        Object.assign(highlights, this.getRelatedHighlightsForProcessor(currentProcessorName));
      } else if (currentStageName) {
        Object.assign(highlights, this.getRelatedHighlightsForStage(currentStageName));
      }
    }
    if (this.highlightedInputStageName) {
      highlights.stages[this.highlightedInputStageName] = {veryHighlighted: true};
    }
    return highlights;
  }

  processErrors({errors}) {
    const result = [];
    function addError(messages, generateError) {
      result.push(...castArray(messages).map((message) => generateError(message)));
    }
    if (isPlainObject(errors)) {
      if ('processors' in errors) {
        if (isPlainObject(errors.processors)) {
          forEach(errors.processors, (processorErrors, processorIndex) => {
            const processor = this.probe.processors[Number(processorIndex)];
            if (processor) {
              if (isPlainObject(processorErrors)) {
                forEach({inputs: 'input', properties: 'property'}, (errorType, errorGroup) => {
                  if (errorGroup in processorErrors) {
                    if (isPlainObject(processorErrors[errorGroup])) {
                      forEach(processorErrors[errorGroup], (groupErrors, propertyName) => {
                        addError(groupErrors, (message) => ({
                          type: 'processor' + upperFirst(errorType),
                          [errorType + 'Name']: propertyName,
                          processorName: processor.name,
                          message
                        }));
                      });
                    } else {
                      addError(processorErrors[errorGroup], (message) => (
                        {type: 'processor', processorName: processor.name, message}
                      ));
                    }
                  }
                });
              } else {
                addError(processorErrors, (message) => (
                  {type: 'processor', processorName: processor.name, message}
                ));
              }
            }
          });
        } else {
          addError(errors.processors, (message) => ({type: 'processors', message}));
        }
      }
      if ('stages' in errors) {
        forEach(errors.stages, (stageErrors, stageIndex) => {
          const stage = this.probe.stages[Number(stageIndex)];
          if (stage) {
            const processor = find(
              this.probe.processors,
              (processor) => find(processor.outputs, (outputName) => outputName === stage.name)
            );
            if (processor) {
              forEach(stageErrors, (propertyErrors, propertyName) => {
                addError(propertyErrors, (message) => (
                  {type: 'stageProperty', stageName: stage.name, processorName: processor.name, propertyName, message}
                ));
              });
            }
          }
        });
      }
      for (const propertyName of ['label', 'description', 'tags']) {
        if (propertyName in errors) {
          addError(errors[propertyName], (message) => ({type: 'probeProperty', propertyName, message}));
        }
      }
    }
    return result;
  }

  formatGraphQueries(probe) {
    return {...probe, processors: map(probe.processors, (processor) => {
      if ('graph_query' in processor.properties) {
        const originalProcessor = this.props.action === 'update' ?
          find(this.props.probe.processors, {name: processor.name}) :
          null;
        if (
          this.props.action !== 'update' || !originalProcessor ||
          originalProcessor && 'graph_query' in originalProcessor.properties &&
          !isEqual(processor.properties.graph_query, originalProcessor.properties.graph_query)
        ) {
          const graphQuery = map(castArray(processor.properties.graph_query), (graphQuery) =>
            PythonExpressionPlainTextFormatter.parseAndFormat(graphQuery)
          );
          processor = toJS(processor);
          processor.properties.graph_query = graphQuery;
        }
      }
      return processor;
    })};
  }

  getNormalizedProbeGraph = (probe) => ({
    processors: probe.processors,
    stages: map(probe.stages, (stage) => {
      const processor = getProcessorByStageName({probe, stageName: stage.name});
      const processorDefinition = find(this.context.processorDefinitions, {name: processor.type});
      const schema = getStageFormSchema(processorDefinition);
      const fields = ['name', ...map(schema, 'name')];
      return pick(stage, fields);
    })
  });

  probeToRequestBody() {
    return {...toJS(this.probe), ...this.formatGraphQueries(this.getNormalizedProbeGraph(this.probe))};
  }

  probeToPatchRequestBody() {
    return pick(toJS(this.probe), 'label', 'description', 'disabled', 'tags');
  }

  @action
  submit = async ({action = 'create', redirect = true} = {}) => {
    const {blueprintId, refetchKnownTags, routes} = this.context;
    const {navigate, probeId} = this.props;
    const {isProbeDirty} = this;
    this.currentActionInProgress = action;
    this.errors.length = 0;
    const route = action !== 'update' ?
      interpolateRoute(routes.probeList, {blueprintId})
    :
      interpolateRoute(routes.probeDetails, {blueprintId, probeId});
    const method = action !== 'update' ? 'POST' : isProbeDirty ? 'PUT' : 'PATCH';
    const body = JSON.stringify(method === 'PATCH' ? this.probeToPatchRequestBody() : this.probeToRequestBody());
    try {
      const {id: newProbeId} = await request(route, {method, body});
      refetchKnownTags();
      if (redirect) {
        const {currentProcessorName, currentStageName} = this;
        navigate(generateProbeURI({
          blueprintId,
          probeId: newProbeId ?? probeId,
          processorName: currentProcessorName,
          stageName: currentStageName,
        }));
      }
    } catch (error) {
      runInAction(() => {
        this.currentActionInProgress = null;
        const {response, responseBody} = error;
        if (response && response.status === 422 && responseBody) {
          this.errors = this.processErrors(responseBody);
          this.propertiesFormRef?.current?.scrollIntoView({block: 'start', behavior: 'smooth'});
        } else {
          this.errors.push({type: 'http', error});
        }
      });
    }
  };

  @computed get probe() {
    return this.props.action ? this.currentProbe : observable(this.props.probe);
  }

  @computed get isProbeValid() {
    return this.probe.processors.length > 0;
  }

  @computed get currentKnownStageTags() {
    return union(...map(this.probe.stages, 'tags'));
  }

  get allKnownStageTags() {
    return union(this.currentKnownStageTags, this.context.knownTags);
  }

  @computed get processorsErrors() {
    return filter(this.errors, {type: 'processors'});
  }

  @computed get propertyErrorMessages() {
    return mapValues(
      groupBy(filter(this.errors, {type: 'probeProperty'}), 'propertyName'),
      (errors) => map(errors, 'message')
    );
  }

  @computed get httpError() {
    return find(this.errors, {type: 'http'});
  }

  getMatchedRouteParameters = (routePattern, path) => {
    const paramNames = [];
    const matcher = new RegExp(
      routePattern.replace(/:[^/]+/gi, (name) => {
        paramNames.push(name.substr(1));
        return '([^/]+)';
      })
    );
    const matches = path.match(matcher);
    if (matches) {
      return zipObject(paramNames, matches.slice(1));
    }
  };

  @computed get matchedPathParams() {
    const {location: {pathname}} = this.props;
    return ['/processors/:processorName', '/stages/:stageName'].reduce(
      (result, pathSuffix) => {
        const match = this.getMatchedRouteParameters(pathSuffix, pathname);
        if (match) {
          assignWith(result, match, (srcValue, targetValue) => {
            try {
              return Base64.decode(targetValue);
            } catch {
              return null;
            }
          });
        }
        return result;
      }, {}
    );
  }

  @computed get currentEntities() {
    const {currentProcessorName, currentStageName, probe} = this;
    let processor = null;
    let stage = null;
    if (currentProcessorName) {
      processor = find(probe.processors, {name: currentProcessorName});
    } else if (currentStageName) {
      stage = find(probe.stages, {name: currentStageName});
      processor = find(
        probe.processors,
        (processor) => find(processor.outputs, (outputName) => outputName === currentStageName)
      );
    }
    return {processor, stage};
  }

  @computed get defaultProcessorName() {
    return this.probe.processors.length ? this.probe.processors[0].name : null;
  }

  @computed get defaultStageName() {
    return this.probe.processors.length ? values(this.probe.processors[0].outputs)[0] : null;
  }

  @computed get probeHasUnsupportedProcessorTypes() {
    const {processorDefinitions} = this.context;
    const processors = new Set(map(processorDefinitions, 'name'));
    return !!find(this.probe.processors, ({type}) => !processors.has(type));
  }

  @action
  setCurrentProcessorName = (processorName) => {
    this.onCurrentEntitiesChange({processorName});
  };

  @action
  setCurrentStageName = (stageName) => {
    this.onCurrentEntitiesChange({stageName});
  };

  @action
  onCurrentEntitiesChange = ({stageName, processorName, replaceHistory}) => {
    this.setCurrentEntitiesNames({stageName, processorName});
    if (this.props.probeId) {
      this.redirectToProbe({stageName, processorName, replaceHistory});
    }
  };

  @action
  setCurrentEntitiesNames({stageName, processorName}) {
    this.currentStageName = stageName;
    this.currentProcessorName = processorName;
  }

  @action
  setCurrentEntitiesFromPathParams = () => {
    const {
      defaultProcessorName, defaultStageName,
      matchedPathParams: {processorName, stageName},
      props: {action}
    } = this;

    if (
      processorName && some(this.probe.processors, {name: processorName}) ||
      stageName && some(this.probe.stages, {name: stageName})
    ) {
      // if one of provided names is valid - select the corresponding entity
      this.setCurrentEntitiesNames({stageName, processorName});
    } else if (action === 'create') {
      // this is a new probe and it's empty - do nothing
    } else if (action && defaultProcessorName) {
      // for any other action we select the default (first) processor if it's available
      this.onCurrentEntitiesChange({processorName: defaultProcessorName, replaceHistory: true});
    } else if (!action && defaultStageName) {
      // for readonly view we select the default stage if it's available
      this.onCurrentEntitiesChange({stageName: defaultStageName, replaceHistory: true});
    }
  };

  @computed get probeProperties() {
    return {...this.probe, enabled: !this.probe.disabled};
  }

  @computed get predefinedDashboards() {
    return filter(get(this.props, ['probe', 'referencing_dashboards']), 'predefined_dashboard');
  }

  @action
  setProbePropertyValue = (propertyName, value) => {
    if (propertyName === 'enabled') {
      this.probe.disabled = !value;
    } else {
      this.probe[propertyName] = value;
    }
    pullAllWith(this.errors, [{type: 'probeProperty', propertyName}], isMatch);
  };

  @action
  clearGenericErrors = () => {
    pullAllWith(this.errors, [{type: 'processors'}, {type: 'http'}], isMatch);
  };

  @action
  markProbeAsDirty = (isDirty) => {
    this.isProbeDirty = isDirty;
  };

  @action
  revertProbeGraph = () => {
    Object.assign(this.probe, this.getNormalizedProbeGraph(this.props.probe));
    this.markProbeAsDirty(false);
    this.redirectToProbe({processorName: this.defaultProcessorName, replaceHistory: true});
  };

  checkProbeGraphModification = () => {
    if (
      this.props.action === 'update' &&
      !this.isProbeDirty &&
      !isEqual(this.getNormalizedProbeGraph(this.probe), this.getNormalizedProbeGraph(this.props.probe))
    ) {
      this.markProbeAsDirty(true);
    }
  };

  @action
  setProbeDeletionModalProps = (props) => {
    this.probeDeletionModalProps = props;
  };

  @action
  setProbeExportModalProps = (props) => {
    this.probeExportModalProps = props;
  };

  @action
  setPredefinedProbeModalProps = (props) => {
    this.predefinedProbeModalProps = props;
  };

  @observable.ref probeDeletionModalProps = null;
  @observable.ref probeExportModalProps = null;
  @observable.ref predefinedProbeModalProps = null;

  redirectToProbe = ({
    blueprintId = this.context.blueprintId,
    probeId = this.props.probeId,
    processorName,
    stageName,
    action = this.props.action,
    replaceHistory = false
  }) => {
    const url = generateProbeURI({
      blueprintId,
      probeId,
      processorName,
      stageName,
      action
    });
    this.props.navigate(
      url,
      {
        state: {
          probe: cloneDeep(this.probe),
          probeNavigationExpanded: this.probeNavigationExpanded,
        },
        replace: replaceHistory
      }
    );
  };

  renderBreadcrumbSections() {
    const {blueprintId, predefinedProbes} = this.context;
    const {probeId, action, refetchData, diskUsage} = this.props;
    const {probe} = this;
    const result = [{key: 'home', content: 'Probes', as: Link, to: generateProbeURI({blueprintId})}];
    if (!probeId) {
      result.push({key: 'label', active: true, content: 'New Probe'});
    } else if (action) {
      const currentLabel = this.props.probe.label;
      result.push(
        {key: 'label', content: currentLabel, as: Link, to: generateProbeURI({blueprintId, probeId})},
        {key: 'action', active: true, content: action === 'update' ? 'Edit' : 'Clone'}
      );
    } else {
      result.push({
        key: 'label',
        active: true,
        content: (
          <Fragment>
            {probe.label}
            &nbsp;
            {probe.predefined_probe &&
              <PredefinedEntityIcon
                predefinedEntities={predefinedProbes}
                predefinedEntityName={probe.predefined_probe}
                predefinedEntityType='Predefined probe'
                trigger={<Label icon='box' />}
              />
            }
            &nbsp;
            <ProbeStateLabel probe={probe} />
            &nbsp;
            {probe.state === 'operational' && !probe.disabled && (
              <Fragment>
                <ProbeAnomalyCountLabel anomalyCount={probe.anomaly_count} />
                &nbsp;
              </Fragment>
            )}
            <UpdatedByLabel updatedBy={probe.updated_by} timestamp={probe.updated_at} />
            &nbsp;
            {diskUsage && <DiskUsageLabel diskUsage={diskUsage} />}
            &nbsp;
            <Form className='probe-toggle-form'>
              <Field inline label='Enabled'>
                <ProbeToggle
                  probe={probe}
                  value={!probe.disabled}
                  refetchData={refetchData}
                />
              </Field>
            </Form>
          </Fragment>
        )
      });
    }
    return result;
  }

  checkDetailedError() {
    this.errors.length = 0;
    if (this.probe.last_error) {
      const {last_error: {detailed_error: detailedErrors, processor}} = this.probe;
      const processorIndex = findIndex(this.probe.processors, ({name}) => name === processor);
      if (processorIndex >= 0) {
        this.errors = this.processErrors({
          errors: {
            processors: {[processorIndex]: detailedErrors}
          }
        });
      } else {
        this.errors = this.processErrors({errors: detailedErrors});
      }
    }
  }

  constructor(props) {
    super(props);
    makeObservable(this);
    this.disposeClearGenericErrorsObserver = observe(this.probe.processors, this.clearGenericErrors);
    this.disposeLocationReaction = reaction(
      () => this.props.location,
      () => this.setCurrentEntitiesFromPathParams()
    );
    this.disposeErrorReaction = reaction(
      () => this.probe.last_error,
      () => this.checkDetailedError()
    );
  }

  componentDidMount() {
    this.setCurrentEntitiesFromPathParams();
    this.checkDetailedError();
    this.disposeDirtyChecker = autorun(this.checkProbeGraphModification);
  }

  componentWillUnmount() {
    this.disposeClearGenericErrorsObserver();
    this.disposeLocationReaction();
    this.disposeDirtyChecker();
    this.disposeErrorReaction();
  }

  propertiesFormRef = createRef();

  render() {
    const {blueprintId} = this.context;
    const {
      probeId, action,
      refetchData, probePropertiesSchema,
      navigate, telemetryServiceRegistryItems,
    } = this.props;
    const {
      probe, allKnownStageTags,
      currentProcessorName, currentStageName, currentEntities: {processor, stage},
      probeDeletionModalProps, probeExportModalProps, predefinedProbeModalProps,
      setProbeDeletionModalProps, setProbeExportModalProps, setPredefinedProbeModalProps,
      probeHasUnsupportedProcessorTypes, probeNavigationExpanded, toggleProbeNavigationExpanded,
    } = this;

    if (!processor && !stage && !action) {
      return null;
    }
    return (
      <Grid className='probe-details'>
        <Grid.Row>
          <Grid.Column width={12} verticalAlign='middle'>
            <Breadcrumb
              size='big'
              icon='right caret'
              sections={this.renderBreadcrumbSections()}
            />
            {!isEmpty(probe.tags) &&
              <div className='probe-tags'>
                {tagsRenderer.renderValueWithoutCondition({value: probe.tags})}
              </div>
            }
          </Grid.Column>
          <Grid.Column width={4} textAlign='right'>
            {!action ?
              <ProbeActions
                showExportButton
                probe={probe}
                processorName={currentProcessorName}
                stageName={currentStageName}
                setProbeDeletionModalProps={setProbeDeletionModalProps}
                setProbeExportModalProps={setProbeExportModalProps}
                setPredefinedProbeModalProps={setPredefinedProbeModalProps}
                refetchData={refetchData}
              />
            :
              <ActionsMenu
                items={[{
                  icon: 'share square',
                  title: 'Export',
                  disabled: !!this.currentActionInProgress || !this.isProbeValid,
                  onClick: () => setProbeExportModalProps({open: true, probe}),
                }]}
              />
            }
          </Grid.Column>
        </Grid.Row>
        {!action && probe.description &&
          <Grid.Row>
            <Grid.Column>
              <Value name='description' value={probe.description} renderers={[descriptionRenderer.renderValue]} />
            </Grid.Column>
          </Grid.Row>
        }
        {!action && probe.state === 'error' && (probe.last_error || probe.task_error) &&
          <Grid.Row>
            <Grid.Column>
              {probe.last_error &&
                <GenericErrors errors={probe.last_error.message}>
                  <ProbeTelemetryWarningServiceStageLink {...{probe, blueprintId}} />
                </GenericErrors>
              }
              {probe.task_error &&
                <GenericErrors errors={probe.task_error} />
              }
            </Grid.Column>
          </Grid.Row>
        }
        {action &&
          <Grid.Row>
            <Grid.Column>
              <a ref={this.propertiesFormRef} />
              <Form className='probe-properties'>
                {chunk(probePropertiesSchema, 2).map((schemaChunk, index) =>
                  <Form.Group key={index} widths='equal'>
                    <FormFragment
                      schema={schemaChunk}
                      values={this.probeProperties}
                      renderers={[
                        descriptionRenderer.renderValueInput,
                        tagsRenderer.renderValueInput,
                      ]}
                      errors={this.propertyErrorMessages}
                      disabled={!!this.currentActionInProgress}
                      onChange={this.setProbePropertyValue}
                      knownTags={allKnownStageTags}
                    />
                  </Form.Group>
                )}
              </Form>
            </Grid.Column>
          </Grid.Row>
        }
        {probeHasUnsupportedProcessorTypes ? (
          <Grid.Row>
            <Grid.Column>
              <Message icon warning>
                <Icon name='warning sign' />
                <Message.Content>
                  <Message.Header>
                    {'This probe contains processors with unsupported types and cannot be rendered'}
                  </Message.Header>
                </Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <Grid.Row>
            <Grid.Column
              className='probe-nav probe-sticky'
              width={probeNavigationExpanded ? 12 : 4}
            >
              <ProbeGraph
                probe={probe}
                currentProcessor={processor}
                currentStageName={currentStageName}
                setCurrentProcessorName={this.setCurrentProcessorName}
                setCurrentStageName={this.setCurrentStageName}
                highlights={this.highlights}
                highlightProcessorAndRelatedStages={this.highlightProcessorAndRelatedStages}
                highlightStageAndRelatedProcessors={this.highlightStageAndRelatedProcessors}
                highlightCurrentEntity={this.highlightCurrentEntity}
                actionInProgress={!!this.currentActionInProgress}
                errors={this.errors}
                editable={!!action}
                toggleProbeNavigationExpanded={toggleProbeNavigationExpanded}
                probeNavigationExpanded={probeNavigationExpanded}
              />
            </Grid.Column>
            <Grid.Column className='probe-contents' width={probeNavigationExpanded ? 4 : 12}>
              {currentProcessorName ?
                <ProbeProcessor
                  key={currentProcessorName}
                  compact={probeNavigationExpanded}
                  editable={!!action}
                  probe={probe}
                  processor={processor}
                  telemetryServiceRegistryItems={telemetryServiceRegistryItems}
                  setCurrentProcessorName={this.setCurrentProcessorName}
                  highlightStage={this.highlightStage}
                  actionInProgress={!!this.currentActionInProgress}
                  errors={this.errors}
                />
              : currentStageName ?
                <ProbeStage
                  key={currentStageName}
                  compact={probeNavigationExpanded}
                  editable={!!action}
                  probe={probe}
                  stage={stage}
                  processor={processor}
                  knownTags={allKnownStageTags}
                  actionInProgress={!!this.currentActionInProgress}
                  errors={this.errors}
                />
              :
                <EmptyProbeScreen
                  isNew={!probeId}
                  probe={probe}
                  setCurrentProcessorName={this.setCurrentProcessorName}
                />
              }
            </Grid.Column>
          </Grid.Row>
        )}
        {!!this.processorsErrors.length &&
          <Grid.Row>
            <Grid.Column>
              <GenericErrors errors={map(this.processorsErrors, 'message')} />
            </Grid.Column>
          </Grid.Row>
        }
        {this.httpError &&
          <Grid.Row>
            <Grid.Column>
              <FetchDataError error={this.httpError.error} />
            </Grid.Column>
          </Grid.Row>
        }
        {action === 'update' && this.props.probe.predefined_probe && this.isProbeDirty &&
          <Grid.Row>
            <Grid.Column>
              <Message icon warning>
                <Icon name='warning sign' />
                <Message.Content>
                  <Message.Header>{'Modification of Predefined Probe'}</Message.Header>
                  <p>{'After saving the changes, it won\'t be possible to edit this probe as a predefined probe.'}</p>
                  {this.predefinedDashboards.length > 0 && (
                    <p>
                      {'Also, the following predefined '}
                      {pluralize('dashboard', this.predefinedDashboards.length)}
                      {' will no longer be predefined:'}
                      <PredefinedDashboardsLinks dashboards={this.predefinedDashboards} />
                    </p>
                  )}
                  <Button
                    icon='undo'
                    content='Revert Probe Changes'
                    onClick={this.revertProbeGraph}
                  />
                </Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
        }
        {action &&
          <Grid.Row>
            <Grid.Column textAlign='center'>
              {probeId ?
                <Fragment>
                  <Button
                    primary size='large'
                    disabled={!!this.currentActionInProgress || !this.isProbeValid}
                    loading={this.currentActionInProgress === 'update'}
                    onClick={() => this.submit({action})}
                    content={action === 'update' ? 'Update Probe' : 'Create Probe'}
                  />
                  <Button
                    className='ghost'
                    size='large'
                    disabled={!!this.currentActionInProgress}
                    as={Link}
                    to={generateProbeURI(
                      {blueprintId, probeId, processorName: currentProcessorName, stageName: currentStageName}
                    )}
                    content='Cancel'
                  />
                </Fragment>
              :
                <Button
                  primary size='large'
                  disabled={!!this.currentActionInProgress || !this.isProbeValid}
                  loading={this.currentActionInProgress === 'create'}
                  onClick={() => this.submit({action: 'create'})}
                  content='Create Probe'
                />
              }
            </Grid.Column>
          </Grid.Row>
        }
        <ProbeDeletionModal
          open={false}
          onClose={() => setProbeDeletionModalProps({open: false})}
          onSuccess={() => navigate(generateProbeURI({blueprintId}))}
          {...probeDeletionModalProps}
        />
        <ProbeExportModal
          open={false}
          onClose={() => setProbeExportModalProps({open: false})}
          {...probeExportModalProps}
        />
        <PredefinedProbeModal
          open={false}
          onClose={() => setPredefinedProbeModalProps({open: false})}
          {...predefinedProbeModalProps}
        />
      </Grid>
    );
  }
}

export const ProbeStateLabel = ({probe: {state, disabled}}) => {
  return (
    <Label
      color={
        disabled ? null :
        state === 'error' ? 'red' :
        state === 'operational' ? 'green' :
        state === 'maintenance' ? 'orange' :
        null
      }
    >
      {disabled ?
        <Fragment>
          <Icon name='pause' />
          {'Disabled'}
        </Fragment>
      :
        <Fragment>
          {state === 'error' && <Icon name='warning sign' />}
          {state === 'operational' && <Icon name='check circle' />}
          {state === 'configuring' && <Icon name='hourglass half' />}
          {state === 'maintenance' && <Icon name='wrench' />}
          {startCase(state)}
        </Fragment>
      }
    </Label>
  );
};

export const ProbeTelemetryWarningServiceStageLink = ({probe, blueprintId}) => {
  const telemetryServiceWarningState = get(probe, ['probe_state', 'telemetry_service', 'state']);
  if (telemetryServiceWarningState !== 'error') return null;
  const findStageWithWarnings = () => {
    let result = null;
    forEach(probe.processors, (processor) => {
      forEach(processor.outputs, (stageName) => {
        const stage = find(probe.stages, {name: stageName});
        if (stage?.warnings > 0) {
          result = stageName;
          return false;
        }
      });
      if (result) return false;
    });
    return result;
  };
  const stageName = findStageWithWarnings();
  return stageName ?
    <b>
      <Link to={generateProbeURI({blueprintId, probeId: probe.id, stageName})}>
        {'Navigate to stage'}
      </Link>
    </b> : null;
};

export const ProbeAnomalyCountLabel = ({anomalyCount}) => {
  return (
    <Label color={anomalyCount ? 'red' : 'green'}>
      <Icon name={anomalyCount ? 'warning sign' : 'check circle'} />
      {anomalyCount ? pluralize('anomaly', anomalyCount, true) : 'No anomalies'}
    </Label>
  );
};

export const DiskUsageLabel = ({diskUsage}) => {
  return (
    <Popup
      content={
        <Fragment>
          <strong>{'Consumed disk size: '}</strong>
          {formatBytes(diskUsage.size || 0)}
        </Fragment>
      }
      trigger={
        <Label icon={<StagePersisted className='stage-icon' />} content={formatBytes(diskUsage.size)} />
      }
    />
  );
};

export const EmptyProbeScreen = ({probe, isNew, setCurrentProcessorName}) => {
  return (
    isNew ?
      <div className='empty-probe-screen'>
        <div>
          {
            'Start creation of a new probe by adding a processor. ' +
            'Alternatively, you can import a probe from JSON.'
          }
        </div>
        <ProbeImportModal
          trigger={
            <Button
              size='large'
              secondary
              icon='upload'
              content='Import Probe'
            />
          }
          probe={probe}
          onSuccess={() => {
            setCurrentProcessorName(probe.processors[0].name);
          }}
        />
      </div>
    :
      'Add at least one processor to a probe.'
  );
};
