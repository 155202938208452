import {Component, createRef} from 'react';
import {HashRouter as Router} from 'react-router-dom';
import {observable, action, computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Message} from 'semantic-ui-react';
import {debounce, filter, find, map} from 'lodash';
import cx from 'classnames';
import {FetchData} from 'apstra-ui-common';

import wrapWithComponent from '../../../wrapWithComponent';

import TopologyTooltip from './TopologyTooltip';
import buildTopologyData from './buildTopologyData';
import {InterfaceTooltipContent, NodeTooltipContent} from '../GraphTooltips';
import TopologySettings from './components/TopologySettings';
import UserStoreContext from '../../../UserStoreContext';

import './Topology3d.less';

const importTopologyRendererFiber = () =>
  import(/* webpackChunkName: 'topology-renderer-fiber' */'./TopologyRendererFiber');

@observer
export default class Topology3d extends Component {
  static contextType = UserStoreContext;

  static defaultProps = {
    popupShowDelay: 300,
    userStoreKey: 'topology',
  };

  containerRef = createRef();

  @observable showLinks = true;
  @observable showSpines = false;
  @observable showSuperspines = false;
  @observable hoveredPortData = null;
  @observable hoveredNodeData = null;
  @observable hoveredPlaneData = null;
  @observable portPopupData = null;
  @observable hoveredLinkData = null;
  @observable nodePopupData = null;
  @observable planePopupData = null;
  @observable activePlaneId = null;
  @observable isMovingCamera = false;
  @observable highlightIdSet = null;
  @observable.shallow controlPanelElements = {
    top: [],
    right: [],
    bottom: [],
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  setHighlightIdSet = (highlightIdSet) => {
    this.highlightIdSet = highlightIdSet;
  };

  @action
  clearHighlightIdSet = () => {
    this.highlightIdSet = null;
  };

  @action
  onSettingsChange = ({showLinks, showNodes}) => {
    this.showLinks = showLinks;
    this.showSpines = showNodes === 'spines';
    this.showSuperspines = showNodes === 'superspines';
  };

  @action
  onNodePointerOver = (node, position) => {
    this.hoveredNodeData = {node, position};
    this.setNodePopupData(node, position);
    this.clearHighlightIdSet();
  };

  @action
  onNodePointerOut = () => {
    this.clearNodePopupData();
    this.hoveredNodeData = null;
  };

  @action
  onPortPointerOver = (port, position, parentPlaneId) => {
    this.hoveredPortData = {port, position};
    this.hoveredPlaneData = null;
    this.setPortPopupData(port, position);
    this.activePlaneId = parentPlaneId;
    this.clearHighlightIdSet();
  };

  @action
  onPortPointerOut = () => {
    this.clearPortPopupData();
    this.activePlaneId = null;
    this.hoveredPortData = null;
  };

  @action
  onPlanePointerOver = (plane, position) => {
    this.hoveredPlaneData = {plane, position};
    this.setPlanePopupData(plane, position);
    this.activePlaneId = plane.id;
    this.clearHighlightIdSet();
  };

  @action
  onPlanePointerOut = () => {
    this.clearPlanePopupData();
    this.activePlaneId = null;
    this.hoveredPlaneData = null;
  };

  @action
  onVlanPointerOver = (plane, position) => {
    this.onPlanePointerOver(plane, position);
    this.setHighlightIdSet(plane.endpoints);
  };

  @action
  onVlanPointerOut = (plane) => {
    this.clearHighlightIdSet();
    this.onPlanePointerOut(plane);
  };

  setPortPopupData = debounce(action((port, position) => {
    this.portPopupData = {port, position};
  }),
  this.props.popupShowDelay);

  @action
  clearPortPopupData = () => {
    this.setPortPopupData.cancel();
    this.portPopupData = null;
  };

  @action
  onLinkPointerOver = (link) => {
    this.hoveredLinkData = link;
  };

  @action
  onLinkPointerOut = () => {
    this.hoveredLinkData = null;
  };

  setNodePopupData = debounce(action((node, position) => {
    this.nodePopupData = {node, position};
  }),
  this.props.popupShowDelay);

  @action
  clearNodePopupData = () => {
    this.setNodePopupData.cancel();
    this.nodePopupData = null;
  };

  setPlanePopupData = debounce(action((plane, position) => {
    this.planePopupData = {plane, position};
  }),
  this.props.popupShowDelay);

  @action
  clearPlanePopupData = () => {
    this.setPlanePopupData.cancel();
    this.planePopupData = null;
  };

  @action
  onCameraControlsEnd = () => {
    this.isMovingCamera = false;
  };

  @action
  registerControlPanelElement = (position, element) => {
    this.controlPanelElements[position] = [...this.controlPanelElements[position], element];
    return element;
  };

  @action
  unregisterControlPanelElement = (position, element) => {
    this.controlPanelElements[position] = this.controlPanelElements[position].filter((e) => e !== element);
  };

  onCameraControlsChange = () => {
    this.isMovingCamera = true;
  };

  prioritizeNodeIntersections = (intersects) => {
    if (this.isMovingCamera) return [];
    const activeIntersects = filter(intersects, ({object}) => object.userData.isActive || object.userData.isLink);
    const result = this.getIntersection(activeIntersects.length ? activeIntersects : intersects);
    return result;
  };

  getIntersection = (intersects) => {
    const port = find(intersects, ({object}) => object.userData?.isPort);
    const node = find(intersects, ({object}) => object.userData?.isNode);
    const link = find(intersects, ({object}) => object.userData?.isLink);
    const plane = find(intersects, ({object}) => object.userData?.plane);
    return filter([port || node || link, plane]);
  };

  @observable error = null;

  @action
  setError(error) {
    this.error = error;
  }

  componentDidCatch(error) {
    this.setError(error);
  }

  @computed
  get topologyData() {
    return buildTopologyData(this.props);
  }

  render() {
    if (this.error) {
      return (
        <Message
          negative
          icon='warning sign'
          header={`Error rendering 3d topology. ${this.error}`}
        />
      );
    }

    const {context, props, showLinks, showSpines, showSuperspines, topologyData,
      onNodePointerOver, onNodePointerOut,
      onPlanePointerOver, onPlanePointerOut, containerRef, hoveredNodeData, hoveredPlaneData, hoveredLinkData,
      hoveredPortData, onPortPointerOver, onPortPointerOut, portPopupData,
      nodePopupData, planePopupData, onCameraControlsEnd, onCameraControlsChange,
      highlightIdSet, onVlanPointerOver, onVlanPointerOut, onLinkPointerOver, onLinkPointerOut,
      controlPanelElements, registerControlPanelElement, unregisterControlPanelElement, onSettingsChange} = this;
    const {isFiveStage, layer, onClickPlane, onClickPod, onClickRack, onClickNode, onClickLink, onClickSecurityZone,
      onClickVlan, onClickPort, onClickAccessGroup, selectedPodId, selectedRackId, userStoreKey} = props;
    const {userStore} = context;
    const canSelectShowSpines = !!selectedRackId;
    const canSelectShowSuperspines = !!selectedPodId && !selectedRackId && !!isFiveStage;
    return (
      <div className={cx('topology-3d', {
        hovered: hoveredNodeData || hoveredPlaneData || hoveredPortData || hoveredLinkData
      })}
      >
        <TopologySettings
          {...props}
          showLinks
          hideExpandNodes
          onChange={onSettingsChange}
          userStore={userStore}
          userStoreKey={`${userStoreKey}-3D`}
        />
        <div className='top-control-panel'>
          {map(controlPanelElements.top, (Element, i) => <Element key={i} />)}
        </div>
        <div className='right-control-panel'>
          {map(controlPanelElements.right, (Element, i) => <Element key={i} />)}
        </div>
        <div className='bottom-control-panel'>
          {map(controlPanelElements.bottom, (Element, i) => <Element key={i} />)}
        </div>
        <div
          ref={containerRef}
          className='topology-3d-container'
        >
          <FetchData
            pollingInterval={null}
            fetchData={importTopologyRendererFiber}
          >
            {({TopologyRendererFiber}) =>
              <TopologyRendererFiber
                styleBridgeElement={containerRef.current}
                showLinks={showLinks}
                topologyData={topologyData}
                onClickPlane={onClickPlane}
                onClickPod={onClickPod}
                onClickRack={onClickRack}
                onClickNode={onClickNode}
                onClickLink={onClickLink}
                onClickSecurityZone={onClickSecurityZone}
                onClickVlan={onClickVlan}
                onClickPort={onClickPort}
                onClickAccessGroup={onClickAccessGroup}
                onNodePointerOver={onNodePointerOver}
                onNodePointerOut={onNodePointerOut}
                onPlanePointerOver={onPlanePointerOver}
                onPlanePointerOut={onPlanePointerOut}
                onVlanPointerOver={onVlanPointerOver}
                onVlanPointerOut={onVlanPointerOut}
                onPortPointerOver={onPortPointerOver}
                onPortPointerOut={onPortPointerOut}
                onLinkPointerOver={onLinkPointerOver}
                onLinkPointerOut={onLinkPointerOut}
                onCameraControlsEnd={onCameraControlsEnd}
                onCameraControlsChange={onCameraControlsChange}
                showSpines={!canSelectShowSpines || showSpines}
                showSuperspines={(!canSelectShowSuperspines && !canSelectShowSpines) || showSuperspines}
                activePlaneId={this.activePlaneId}
                prioritizeNodeIntersections={this.prioritizeNodeIntersections}
                highlightIdSet={highlightIdSet}
                registerControlPanelElement={registerControlPanelElement}
                unregisterControlPanelElement={unregisterControlPanelElement}
                showPorts={!!selectedRackId}
              />
            }
          </FetchData>
          {portPopupData &&
            <TopologyTooltip
              key={portPopupData.port.id}
              boundaryContainer={containerRef.current}
              content={(
                <InterfaceTooltipContent
                  {...portPopupData.port}
                  label={portPopupData.port.interfaceName}
                />
              )}
              {...portPopupData.position}
            />
          }
          {nodePopupData && !portPopupData &&
            <TopologyTooltip
              key={nodePopupData.node.id}
              boundaryContainer={containerRef.current}
              content={<NodeTooltipContent node={nodePopupData.node} layer={layer} />}
              {...nodePopupData.position}
            />
          }
          {planePopupData && !nodePopupData && !portPopupData &&
            <TopologyTooltip
              key={planePopupData.plane.id}
              boundaryContainer={containerRef.current}
              content={planePopupData.plane.label || planePopupData.plane.id}
              {...planePopupData.position}
            />
          }
        </div>
      </div>
    );
  }
}

const Topology3dWithRouter = wrapWithComponent(Router)(Topology3d);

export {Topology3dWithRouter};
