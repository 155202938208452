import {Component} from 'react';
import {Input} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {computed, makeObservable} from 'mobx';
import PropTypes from 'prop-types';
import {map, sortBy} from 'lodash';
import {DataTable, Field, createValueRenderer} from 'apstra-ui-common';

import humanizeString from '../../humanizeString';

const tableSchema = [
  {
    name: 'value',
    label: 'Value',
    value: ({item: {title}}) => title,
  },
  {
    name: 'units',
    label: 'Units',
    value: ({item: {units}}) => units,
    formatter: ({value: units, item: {value}, params: {onChange, values, ...inputProps}}) => (
      <Input
        {...inputProps}
        value={units}
        onChange={(e) => onChange({
          ...values,
          [value]: e.target.value
        })}
      />
    ),
  }
];

@observer
export default class UnitsInput extends Component {
  static propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    schema: PropTypes.object,
    errors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
  };

  @computed
  get items() {
    const {schema, value: values} = this.props;
    return sortBy(
      map(schema.properties, ({title}, value) => ({
        units: values[value],
        title: title || humanizeString(value),
        value
      })),
      'value'
    );
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {props: {name, value, schema, required, disabled, errors, onChange}, items} = this;
    return (
      <Field
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors.generic}
      >
        {(inputProps) =>
          <DataTable
            schema={tableSchema}
            items={items}
            params={{onChange, values: value, ...inputProps}}
            getCellProps={({name}) => name === 'value' ? {collapsing: true} : {}}
          />
        }
      </Field>
    );
  }
}

export const unitsRenderer = createValueRenderer({
  condition: ({name}) => name === 'units',
  renderValueInput({name, value, schema, required, disabled, errors, onChange}) {
    return (
      <UnitsInput
        name={name}
        value={value || {}}
        schema={schema}
        required={required}
        disabled={disabled}
        errors={errors}
        onChange={onChange}
      />
    );
  }
});
