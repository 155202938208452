import {Component, Fragment} from 'react';
import {Form} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {computed, reaction, makeObservable} from 'mobx';
import {isNil, get, values, isString, isArray, map,
  isEmpty, find, keys, concat, difference, without} from 'lodash';
import {Checkbox, CodeEditorInput, ListInput, MapInput, ValueInput} from 'apstra-ui-common';

import RangeControl from '../../components/RangeControl';
import {FILTER_TYPE} from './IngestionFilter';

const ANALYZABLE_SERVICE_KEY_TYPES = ['string', 'integer', 'number'];
const FILTER_TYPE_CONDITIONS = {
  [FILTER_TYPE.any]: () => true,
  [FILTER_TYPE.equals]: ({type}) => type === 'string',
  [FILTER_TYPE.pattern]: ({type}) => type === 'string',
  [FILTER_TYPE.prefix]: ({type}) => type === 'string',
  [FILTER_TYPE.range]: ({type}) => type === 'integer' || type === 'number'
};

@observer
export default class IngestionFilterInput extends Component {
  filterTypeValues = values(FILTER_TYPE);

  constructor(props) {
    super(props);
    makeObservable(this);
    this.disposeServiceNameReaction = reaction(
      () => this.serviceName,
      () => this.props.onChange(null)
    );
  }

  componentWillUnmount() {
    this.disposeServiceNameReaction();
  }

  @computed get serviceName() {
    return this.props.values.service_name;
  }

  getErrorsByPath = (errors, path) => {
    const value = get(errors, path);
    return isString(value) ? [value] : [];
  };

  getRangeErrors = (errors) => {
    const value = errors ? errors.value : null;
    return value ? [value] : [];
  };

  getKeyErrors = ({errors}) => {
    if (isArray(errors)) {
      return errors;
    } else if (isString(errors)) {
      return [errors];
    }
    return [];
  };

  getRangeValue = (range) => {
    for (const key of ['min', 'max']) {
      if (isNil(range[key])) {
        delete range[key];
      } else {
        range[key] = this.numberOrValue(range[key]);
      }
    }
    return range;
  };

  getServiceProperties = () => {
    const {telemetryServiceRegistryItems} = this.props;
    const service = find(telemetryServiceRegistryItems, {service_name: this.serviceName});
    return get(service, ['application_schema', 'properties', 'key', 'properties']);
  };

  getServiceKeySchema = ({key}) => {
    const properties = this.getServiceProperties();
    return properties ? properties[key] : null;
  };

  getInputTypeDefaults = (type) => {
    if (type === FILTER_TYPE.prefix) {
      return {value: {prefix: ''}};
    } else if (type === FILTER_TYPE.equals || type === FILTER_TYPE.pattern) {
      return {value: ['']};
    }
    return {};
  };

  getKeyOptions = () => {
    return keys(this.getServiceProperties());
  };

  getAvailableFilterTypes = ({key}) => {
    const schema = this.getServiceKeySchema({key});
    return isEmpty(schema) || !ANALYZABLE_SERVICE_KEY_TYPES.includes(schema.type) ?
      this.filterTypeValues :
      this.filterTypeValues.filter((filter) => FILTER_TYPE_CONDITIONS[filter](schema));
  };

  renderItemTypeInputs = ({item, key, index, setEntryValue, disabled, errors}) => {
    if (!item) {
      return;
    }

    const {getServiceKeySchema, getRangeErrors, getErrorsByPath, onMaskValueChange} = this;
    const type = item.type;

    if (type === FILTER_TYPE.equals || type === FILTER_TYPE.pattern) {
      const schema = type === FILTER_TYPE.equals ?
        getServiceKeySchema({key}) : null;

      const isExpressionValue = this.isExpressionValue(item.value);

      const props = {
        schema: {},
        value: item.value,
        disabled,
        errors: getErrorsByPath(errors, 'value'),
        onChange: (value) => setEntryValue(index, {...item, value}),
      };

      return (
        <Fragment>
          {isExpressionValue ?
            <CodeEditorInput
              {...props}
              mode='python-expression'
              fieldProps={{width: 16}}
            />
          :
            <ListInput {...props} buttonText='Add Item' fieldWidth={16}>
              {({value, onChange}) => (
                <Form.Field width={8}>
                  <ValueInput
                    value={value}
                    schema={schema ?? {}}
                    disabled={disabled}
                    onChange={onChange}
                  />
                </Form.Field>
              )}
            </ListInput>
          }
        </Fragment>
      );
    } else if (type === FILTER_TYPE.prefix) {
      return (
        <Fragment>
          <CodeEditorInput
            mode='python-expression'
            value={get(item, ['value', 'prefix'])}
            placeholder='Prefix'
            disabled={disabled}
            fieldProps={{width: 8}}
            errors={concat(
              getErrorsByPath(errors, ['value', 'prefix']),
              getErrorsByPath(errors, ['value'])
            )}
            onChange={(prefix) => setEntryValue(index, {...item, value: {...item.value, prefix}})}
          />
          <CodeEditorInput
            mode='python-expression'
            value={`${get(item, ['value', 'ge_mask'], '')}`}
            placeholder='ge'
            fieldProps={{width: 4}}
            disabled={disabled}
            errors={getErrorsByPath(errors, ['value', 'ge_mask'])}
            onChange={(value) => onMaskValueChange({setEntryValue, index, item, value, maskType: 'ge_mask'})}
          />
          <Form.Field key='dash' className='no-input-field'>{'—'}</Form.Field>
          <CodeEditorInput
            mode='python-expression'
            value={`${get(item, ['value', 'le_mask'], '')}`}
            placeholder='le'
            fieldProps={{width: 4}}
            disabled={disabled}
            errors={getErrorsByPath(errors, ['value', 'le_mask'])}
            onChange={(value) => onMaskValueChange({setEntryValue, index, item, value, maskType: 'le_mask'})}
          />
        </Fragment>
      );
    } else if (type === FILTER_TYPE.range) {
      return (
        <RangeControl
          inputType='expression'
          value={item.value || {}}
          disabled={disabled}
          errors={getRangeErrors(errors)}
          onChange={(range) => setEntryValue(index, {...item, value: this.getRangeValue(range)})}
        />
      );
    }
  };

  renderAsExpressionToggler = ({item, index, setEntryValue}) => {
    const isExpressionValue = this.isExpressionValue(item.value);

    const onAsExpressionChange = () => {
      const newValue = isExpressionValue ? [] : '';
      setEntryValue(index, {...item, value: newValue});
    };

    return (
      <Form.Field width={16}>
        <Checkbox
          label='As Expression'
          checked={isExpressionValue}
          onChange={onAsExpressionChange}
        />
      </Form.Field>
    );
  };

  onMaskValueChange = ({setEntryValue, index, item, value, maskType}) => {
    const result = {...item};
    if (value === '') {
      delete result.value[maskType];
    } else {
      result.value[maskType] = this.numberOrValue(value);
    }
    setEntryValue(index, result);
  };

  isExpressionValue = (value) => !Array.isArray(value);

  numberOrValue = (value) => {
    if (isString(value) && isFinite(+value) && !value.endsWith('.')) {
      return +value;
    }
    return value;
  };

  hasAsExpressionToggler = (item) => {
    return item.type === FILTER_TYPE.equals || item.type === FILTER_TYPE.pattern;
  };

  render() {
    const {name, schema, value, required, disabled, errors, onChange} = this.props;
    const {getAvailableFilterTypes, renderItemTypeInputs, renderAsExpressionToggler, hasAsExpressionToggler,
      getErrorsByPath, getKeyErrors, getInputTypeDefaults, getKeyOptions} = this;

    const keyOptions = getKeyOptions();
    const keyAsDropdown = !isEmpty(keyOptions);

    return (
      <MapInput
        value={value}
        name={name}
        schema={schema}
        required={required}
        disabled={disabled}
        errors={errors}
        buttonText='Add Key'
        flattenErrors={false}
        onChange={onChange}
      >
        {({key, value: item, index, setEntryKey, setEntryValue, errors}) => {
          const availableFilterTypes = getAvailableFilterTypes({key});
          const availableKeyOptions = difference(keyOptions, without(keys(value), key));
          const keySchema = {type: 'string', placeholder: 'Key'};
          if (keyAsDropdown) {
            keySchema.oneOf = isEmpty(availableKeyOptions) ?
              [{const: '', title: 'No available options'}] :
              map(availableKeyOptions, (option) => ({const: option, title: option}));
          }

          return (
            <Form.Field width={12}>
              <Form.Group style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>
                <Form.Field>
                  <label style={{fontSize: '12px'}}>{`Key #${index + 1}`}</label>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <ValueInput
                  value={key}
                  placeholder='Key'
                  schema={keySchema}
                  fieldProps={{width: 10}}
                  errors={getKeyErrors({errors})}
                  onChange={(key) => {
                    setEntryKey(index, key);
                    if (keyAsDropdown) {
                      setEntryValue(index, {type: null});
                    }
                  }}
                />
                <ValueInput
                  value={item.type}
                  placeholder='Type'
                  schema={{type: 'dropdown', enum: availableFilterTypes, placeholder: 'Type'}}
                  fieldProps={{width: 6}}
                  disabled={disabled || !key}
                  errors={getErrorsByPath(errors, 'type')}
                  onChange={(type) => setEntryValue(index, {type, ...getInputTypeDefaults(type)})}
                />
              </Form.Group>
              {hasAsExpressionToggler(item) &&
                <Form.Group>
                  {renderAsExpressionToggler({
                    item,
                    index,
                    setEntryValue,
                  })}
                </Form.Group>
              }
              <Form.Group>
                {renderItemTypeInputs({
                  item,
                  key,
                  index,
                  setEntryValue,
                  disabled: disabled || !key,
                  errors
                })}
              </Form.Group>
            </Form.Field>
          );
        }}
      </MapInput>
    );
  }
}
