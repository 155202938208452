import {useState} from 'react';
import {map, transform, head} from 'lodash';
import {Form, Grid} from 'semantic-ui-react';
import {CodeEditorControl, DropdownInput, FetchData, FetchDataError, Loader, ResourceModal} from 'apstra-ui-common';

import {fetchPredefinedQuery} from './utils';

const GraphQueriesModal = ({onChange, resourceName, mode = 'update', trigger}) => {
  const [queryId, setQueryId] = useState('');
  return (
    <FetchData
      fetchData={fetchPredefinedQuery}
      pollingInterval={null}
      customLoader
    >
      {({items: queries, loaderVisible, fetchDataError}) => {
        const options = map(queries, ({id, label: text}) => ({key: id, value: id, text}));
        const queryById = transform(queries, (result, {id, query}) => {
          result[id] = query;
        }, {});
        const resetState = () => {
          setQueryId(head(queries)?.id);
        };
        return (
          <ResourceModal
            mode={mode}
            resourceName={resourceName}
            titlesByMode={{
              update: 'Update',
            }}
            trigger={trigger}
            resetState={resetState}
            submit={() => onChange(queryById[queryId])}
            submitAvailable={!!queryId}
            notifyOnSuccess={false}
          >
            {loaderVisible ?
              <Loader /> :
              fetchDataError ?
                <FetchDataError error={fetchDataError} /> :
                <Grid divided>
                  <Grid.Column width={8}>
                    <p>
                      {'Once selected, a pre-defined query is not kept synchronized, any change ' +
                        'to the query is not automatically reflected here'}
                    </p>
                    <Form>
                      <DropdownInput
                        search
                        schema={{title: 'Predefined Query'}}
                        required
                        value={queryId}
                        options={options}
                        onChange={setQueryId}
                        placeholder='Select Query'
                      />
                    </Form>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <CodeEditorControl
                      mode='graph-query'
                      value={queryById[queryId] || ''}
                      disabled
                      multiLine
                      aria-label='Graph Explorer query'
                    />
                  </Grid.Column>
                </Grid>
            }
          </ResourceModal>
        );
      }}
    </FetchData>
  );
};

export default GraphQueriesModal;
