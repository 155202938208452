import {Base64} from 'js-base64';

import {generateStageQueryParams} from './stageUtils';

export default function generateProbeURI({blueprintId, probeId, processorName, stageName, action, queryParams}) {
  let result = `/blueprints/${blueprintId}/analytics/probes`;
  if (probeId) {
    result += '/' + probeId;
    if (processorName) {
      result += '/processors/' + Base64.encodeURI(processorName);
    } else if (stageName) {
      result += '/stages/' + Base64.encodeURI(stageName);
    }
    if (action) {
      result += '/' + action;
    }
    if (queryParams) {
      result += generateStageQueryParams(queryParams);
    }
  }
  return result;
}
