import {get, values} from 'lodash';

import PythonExpressionParser from './PythonExpressionParser';
import parseStringLiteralToken from './parseStringLiteralToken';
import {namedNodesFunctions} from './consts';

let visitorInstance = null;

const PythonExpressionParserCSTVisitor = PythonExpressionParser.instance.getBaseCstVisitorConstructorWithDefaults();

export default class NamedNodeFinder extends PythonExpressionParserCSTVisitor {
  namedNodesFunctions = namedNodesFunctions;

  static get instance() {
    if (!visitorInstance) visitorInstance = new this();
    return visitorInstance;
  }

  static run(cst, options) {
    const instance = this.instance;
    instance.result = [];
    instance.functionNames = [];
    instance.namedNodesFunctions = (options && 'namedNodesFunctions' in options) ?
      options.namedNodesFunctions : namedNodesFunctions;
    instance.visit(cst);
    return instance.result;
  }

  constructor() {
    super();
    this.validateVisitor();
  }

  functionCall(ctx) {
    this.functionNames.push(ctx.functionName[0].image);
    this.visit(ctx.functionArguments);
    this.functionNames.pop();
  }

  keywordArgument(ctx) {
    const currentFunctionName = this.functionNames[this.functionNames.length - 1];
    const key = ctx.key[0].image;
    if (key === 'name' && this.namedNodesFunctions.has(currentFunctionName)) {
      const stringChildren = get(ctx, ['value', 0, 'children', 'string', 0, 'children']);
      if (stringChildren) {
        const stringToken = get(values(stringChildren), [0, 0]);
        const string = parseStringLiteralToken(stringToken);
        this.result.push(string);
      }
    }
    this.visit(ctx.value);
  }
}
