/* eslint-disable sonarjs/no-duplicate-string */
import ace from 'ace-builds';
import {convertChevrotainErrors} from 'apstra-ui-common';

import PythonExpressionParser from './PythonExpressionParser';
import PythonExpressionPlainTextFormatter from './PythonExpressionPlainTextFormatter';

const stringPrefix = '[uUbB]?';
const stringRawPrefix = '[rR]';
const stringEscape = '\\\\(x[0-9A-Fa-f]{2}|[0-7]{3}|[\\\\abfnrtv\'"]|U[0-9A-Fa-f]{8}|u[0-9A-Fa-f]{4})';
const identifier = '[a-zA-Z_]\\w*\\b';

export function makeStringState(stateName, encloser, multiLine = false, raw = false, token = 'string') {
  const rules = [];
  if (!raw) {
    rules.push({
      token: 'constant.language.escape',
      regex: stringEscape
    });
  }
  if (multiLine) {
    rules.push({
      token,
      regex: encloser + '{3}',
      next: 'start'
    });
  } else {
    rules.push({
      token,
      regex: '\\\\$',
      next: stateName
    }, {
      token,
      regex: encloser + '|$',
      next: 'start'
    });
  }
  rules.push({
    defaultToken: token
  });
  return {[stateName]: rules};
}

ace.define(
  'ace/mode/python-expression',
  ['require', 'exports', 'module'],
  (require, exports) => {
    const {TextHighlightRules} = require('ace/mode/text_highlight_rules');
    const {Mode: TextMode} = require('ace/mode/text');

    class PythonHighlightRules extends TextHighlightRules {
      constructor() {
        super();

        const keywordMapper = this.createKeywordMapper({
          'entity.lambda': 'lambda',
        }, 'identifier');

        this.$rules = {
          start: [
            {
              token: 'comment',
              regex: /#.*$/
            },
            {
              token: 'string',
              regex: stringPrefix + '"{3}',
              next: 'long-double-quote-string'
            },
            {
              token: 'string',
              regex: stringPrefix + '"(?=.)',
              next: 'short-double-quote-string'
            },
            {
              token: 'string',
              regex: stringPrefix + "'{3}",
              next: 'long-single-quote-string'
            },
            {
              token: 'string',
              regex: stringPrefix + "'(?=.)",
              next: 'short-single-quote-string'
            },
            {
              token: 'string',
              regex: stringRawPrefix + '"{3}',
              next: 'raw-long-double-quote-string'
            },
            {
              token: 'string',
              regex: stringRawPrefix + '"(?=.)',
              next: 'raw-short-double-quote-string'
            },
            {
              token: 'string',
              regex: stringRawPrefix + "'{3}",
              next: 'raw-long-single-quote-string'
            },
            {
              token: 'string',
              regex: stringRawPrefix + "'(?=.)",
              next: 'raw-short-single-quote-string'
            },
            {
              token: 'keyword.operator',
              regex: /(?:if|else|for|or|and|not|is|in|==|!=|>=|<=|<>|>>|<<|<|>|\/\/?|%|\||&|\^|\*|\+|-)\b/,
            },
            {
              token: ['keyword-argument', 'punctuation'],
              regex: '(' + identifier + ')(=)'
            },
            {
              token: ['entity.function.name', 'paren.lparen'],
              regex: '(' + identifier + ')(\\()'
            },
            {
              token: keywordMapper,
              regex: identifier
            },
            {
              token: 'punctuation',
              regex: ',|:|;|\\.'
            },
            {
              token: 'paren.lparen',
              regex: /[[({]/
            },
            {
              token: 'paren.rparen',
              regex: /[\])}]/
            },
            {
              token: 'text',
              regex: /\s+/
            },
            {
              include: 'constants'
            }
          ],
          ...makeStringState('long-double-quote-string', '"', true, false),
          ...makeStringState('long-single-quote-string', "'", true, false),
          ...makeStringState('short-double-quote-string', '"', false, false),
          ...makeStringState('short-single-quote-string', "'", false, false),
          ...makeStringState('raw-long-double-quote-string', '"', true, true),
          ...makeStringState('raw-long-single-quote-string', "'", true, true),
          ...makeStringState('raw-short-double-quote-string', '"', false, true),
          ...makeStringState('raw-short-single-quote-string', "'", false, true),
          constants: [
            {
              token: 'constant.numeric',
              regex: /-?(0|[1-9]\d*)(\.\d+)?([eE][+-]?\d+)?/
            },
            {
              token: ['punctuation', 'function.support', 'paren.lparen'],
              regex: '(\\.)(' + identifier + ')(\\()'
            }
          ]
        };
        this.normalizeRules();
      }
    }

    class PythonExpressionMode extends TextMode {
      $id = 'ace/mode/python-expression';
      $behaviour = this.$defaultBehaviour;
      HighlightRules = PythonHighlightRules;

      lineCommentStart = '#';

      getNextLineIndent(state, line, tab) {
        let indent = this.$getIndent(line);
        if (state === 'start') {
          if (line.match(/^.*[{([:]\s*$/)) indent += tab;
        }
        return indent;
      }

      format(text, multiLine) {
        try {
          const {cst, lexErrors, parseErrors} = PythonExpressionParser.parse(text);
          if (!lexErrors.length && !parseErrors.length) text = PythonExpressionPlainTextFormatter.run(cst, {multiLine});
        } catch {}
        return text;
      }

      validate(text) {
        try {
          const {lexErrors, parseErrors} = PythonExpressionParser.parse(text);
          return convertChevrotainErrors(lexErrors, parseErrors);
        } catch {}
        return [];
      }
    }

    exports.Mode = PythonExpressionMode;
    exports.HighlightRules = PythonHighlightRules;
  }
);

ace.require(['ace/mode/python-expression']);
