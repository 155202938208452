import {Component} from 'react';
import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {HashRouter as Router, Link} from 'react-router-dom';
import {isString, join} from 'lodash';
import {DataFilteringContainerWithRouter as DataFilteringContainer,
  DataFilteringLayout, DataTable, FetchData, StringListDropdownInput, natsort, request} from 'apstra-ui-common';

import wrapWithComponent from '../../wrapWithComponent';
import PlainInputSearchBox from '../../components/PlainInputSearchBox';
import IntegerListDropdownInput from '../../components/IntegerListDropdownInput';
import userStore from '../../userStore';
import nodeLabelRenderer from '../nodeLabelRenderer';

const tableSchema = [
  {
    name: 'node',
    label: 'Node Name',
    value: ({item, params: {systemIdMap}}) => systemIdMap[item.system_id].label,
    formatter: ({value, item, params: {blueprintId, systemIdMap}}) =>
      <Link
        to={`/blueprints/${blueprintId}/nodes/${systemIdMap[item.system_id].id}/active/telemetry/mac`}
        children={value}
      />
  },
  {
    name: 'system_id',
    label: 'S/N',
    value: ['system_id'],
  },
  {
    name: 'hostname',
    label: 'Hostname',
    value: ({item, params: {systemIdMap}}) => systemIdMap[item.system_id].hostname,
  },
  {
    name: 'type',
    label: 'Type',
    value: ['type'],
    formatter: ({value}) => ({staticMac: 'Static', dynamicMac: 'Dynamic'}[value] || value)
  },
  {
    name: 'vlan',
    label: 'VLAN',
    value: ['vlan'],
  },
  {
    name: 'vxlan',
    label: 'VXLAN',
    value: ['vxlan'],
  },
  {
    name: 'mac_address',
    label: 'MAC',
    value: ['mac_address'],
  },
  {
    name: 'interface_name',
    label: 'Interface',
    value: ['interface_name'],
  },
];

@wrapWithComponent(Router)
@observer
export default class BlueprintQueryMAC extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static async fetchData({blueprintId, activePage, pageSize, filters, signal}) {
    let body;
    if (isString(filters.queryFilter)) {
      body = {query_filter: filters.queryFilter};
    } else {
      const {
        system_id: systemId, mac_address: macAddress = '*', vlan, vxlan, exclude_interface: excludeInterface
      } = filters;
      body = {
        system_id: systemId,
        mac_address: macAddress,
        vlan: join(vlan, ',') || undefined,
        vxlan: join(vxlan, ',') || undefined,
        exclude_interface: join(excludeInterface, ',') || undefined,
      };
    }
    const {items, total_count: totalCount} = await request(
      `/api/blueprints/${blueprintId}/query/mac`,
      {
        method: 'POST',
        queryParams: {page: activePage, per_page: pageSize},
        body: JSON.stringify(body),
        signal
      }
    );
    return {items, totalCount};
  }
  static pollingInterval = 10000;
  static userStoreKey = 'blueprintQueryMAC';

  @computed get searchBoxSchema() {
    const nodes = Object.values(this.props.systemIdMap).sort((node1, node2) => natsort(node1.label, node2.label));
    return [
      {
        name: 'system_id',
        schema: {
          type: 'string',
          title: 'Node',
          oneOf: nodes.map(({system_id: systemId, label}) => ({const: systemId, title: label})),
        }
      },
      {
        name: 'mac_address',
        schema: {
          type: 'string',
          title: 'MAC Address',
          description: (
            <span>
              {'Use '}<strong>{'*'}</strong>{' as a placeholder, e.g. '}<strong>{'11:22:*'}</strong>
            </span>
          )
        }
      },
      {
        name: 'vlan',
        schema: {
          type: 'array',
          items: {
            type: 'number'
          },
          title: 'VLAN',
          description: 'List of VLANs'
        },
        as: IntegerListDropdownInput
      },
      {
        name: 'vxlan',
        schema: {
          type: 'array',
          items: {
            type: 'number'
          },
          title: 'VXLAN',
          description: 'List of VXLANs'
        },
        as: IntegerListDropdownInput
      },
      {
        name: 'exclude_interface',
        schema: {
          type: 'array',
          items: {
            type: 'string'
          },
          title: 'Exclude Interface',
          description: 'A list of interfaces to exclude'
        },
        as: StringListDropdownInput
      }
    ];
  }

  render() {
    const {searchBoxSchema, props: {systemIdMap, blueprintId}} = this;
    const defaultPageSize = userStore.getStoreValue([BlueprintQueryMAC.userStoreKey, 'pageSize']);
    return (
      <DataFilteringContainer
        stateQueryParam='blueprint-query-mac'
        defaultPageSize={defaultPageSize}
        setUserStoreProps={userStore.setStoreValueFn(BlueprintQueryMAC.userStoreKey)}
      >
        {({activePage, pageSize, filters, updatePagination, updateFilters}) =>
          <FetchData
            fetchData={BlueprintQueryMAC.fetchData}
            pollingInterval={BlueprintQueryMAC.pollingInterval}
            fetchParams={{blueprintId, activePage, pageSize, filters}}
            customLoader
          >
            {({items, totalCount, loaderVisible, fetchDataError}) =>
              <DataFilteringLayout
                loaderVisible={loaderVisible}
                fetchDataError={fetchDataError}
                showCopyButton
                paginationProps={{
                  activePage,
                  pageSize,
                  totalCount,
                  onChange: updatePagination
                }}
                SearchComponent={PlainInputSearchBox}
                searchProps={{
                  filters,
                  schema: searchBoxSchema,
                  onChange: updateFilters,
                  renderers: [nodeLabelRenderer],
                  plainInputProp: 'queryFilter',
                  'aria-label': 'MAC blueprint query',
                  valueProps: {
                    nodes: this.props.systemIdMap
                  }
                }}
              >
                <DataTable
                  items={items}
                  schema={tableSchema}
                  params={{systemIdMap, blueprintId}}
                />
              </DataFilteringLayout>
            }
          </FetchData>
        }
      </DataFilteringContainer>
    );
  }
}
