import {useState} from 'react';
import {LegendItem, LegendLabel, LegendOrdinal} from '@visx/legend';
import {isPlainObject, isUndefined, noop, map} from 'lodash';
import cx from 'classnames';

import './ChartLegend.less';

const ChartLegend = ({
  ordinalColorScale, legendDescriptionMap, legendGlyphSize, onMouseOver, onMouseOut, hoveredItem,
  horizontal, className,
}) => {
  const getValue = (id) => {
    if (isPlainObject(legendDescriptionMap[id])) return legendDescriptionMap[id]?.value || id;
    return legendDescriptionMap[id];
  };
  return (
    <LegendOrdinal
      scale={ordinalColorScale}
    >
      {(labels) => (
        <div className={cx('chart-legend', className, {horizontal})}>
          {map(labels, (label, i) => (
            <div
              key={`legend-ordinal-${i}`}
              className={cx('legend-row', {
                hovered: hoveredItem === label.text,
                pointer: !isUndefined(hoveredItem)
              })}
              onMouseOver={() => onMouseOver(label.text)}
              onMouseOut={onMouseOut}
              onFocus={() => onMouseOver(label.text)}
              onBlur={onMouseOut}
            >
              <div className='legend-cell'>
                <Item
                  label={legendDescriptionMap[label.text]?.name || label.text}
                  legendGlyphSize={legendGlyphSize}
                  itemClassName={legendDescriptionMap[label.text]?.itemClassName}
                  glyphClassName={legendDescriptionMap[label.text]?.glyphClassName}
                  color={label.value}
                />
              </div>
              <div className='legend-cell'>
                {getValue(label.text)}
              </div>
            </div>
          ))}
        </div>
      )}
    </LegendOrdinal>
  );
};

ChartLegend.defaultProps = {
  legendGlyphSize: 15,
  onMouseOver: noop,
  onMouseOut: noop,
  legendDescriptionMap: {},
};

const Item = ({legendGlyphSize, itemClassName, glyphClassName, label, color}) => (
  <LegendItem className={cx(itemClassName)}>
    <svg width={legendGlyphSize} height={legendGlyphSize}>
      <rect
        className={cx(
          'legend-glyph',
          color,
          glyphClassName,
        )}
        width={legendGlyphSize}
        height={legendGlyphSize}
      />
    </svg>
    <LegendLabel>
      {label}
    </LegendLabel>
  </LegendItem>
);

export function useHoveredLegendItem() {
  const [hoveredItem, setHoveredItem] = useState(null);
  const onMouseOver = (id) => {
    setHoveredItem(id);
  };
  const onMouseOut = () => setHoveredItem(null);
  return {hoveredItem, onMouseOut, onMouseOver};
}

export default ChartLegend;
