import {withResizeDetector} from 'react-resize-detector';
import cx from 'classnames';
import {scaleBand, scaleLinear, scaleTime} from 'd3';
import {transform, map, constant, max as _max, min as _min, omit, isEmpty, head, last, compact, join} from 'lodash';
import {BoxPlot} from '@visx/stats';
import {Group} from '@visx/group';
import {useMemo, useState} from 'react';
import {GridRows} from '@visx/grid';
import {Axis} from '@visx/axis';
import {formatNumber, SvgTextLengthMeasurer, formatDateAsLocalDateTime, formatChartAxisTime} from 'apstra-ui-common';

import {getFittedTickLabels} from './utils';

import ChartPopup from './ChartPopup';

import './BoxplotChart.less';

const DATA_FORMAT = {
  none: 'none',
  timestamp: 'timestamp',
};

const BoxplotChart = ({
  mode, width: chartWidth, targetRef, dimensions, samples, className,
  padding, yScaleFn, maxBoxSize, x, min, max, median, firstQuartile,
  thirdQuartile, outliers, xLabel, yLabel, units, points, pointRadius, plotsGap,
  dataXFormat, labelMarginLeft: labelMarginLeftProp, fontSize,
}) => {
  const [popupDescription, setPopupDescription] = useState(null);
  const hidePopup = () => setPopupDescription(null);

  const [textLength, setTextLength] = useState(0);
  const [unitsLength, setUnitsLength] = useState(0);
  const labelMarginLeft = labelMarginLeftProp + unitsLength;

  const {height: chartHeight, margin} = dimensions[mode];

  const xMax = (chartWidth - margin.left - margin.right) || 0;
  const yMax = (chartHeight - margin.top - margin.bottom) || 0;

  const [xScale, groups] = useMemo(() => {
    const groups = map(samples, x);
    const domain = dataXFormat === DATA_FORMAT.timestamp ?
      [head(groups), last(groups)] : groups;
    const xScaleFn = dataXFormat === DATA_FORMAT.none ?
      scaleBand :
      dataXFormat === DATA_FORMAT.timestamp ?
        scaleTime :
        scaleLinear;
    const xScale = xScaleFn()
      .domain(domain)
      .rangeRound([0, xMax]);
    if (dataXFormat === DATA_FORMAT.none) xScale.padding(padding);
    return [
      xScale,
      groups
    ];
  }, [samples, xMax, padding, x, dataXFormat]);

  const [yScale, minYValue] = useMemo(() => {
    const values = transform(samples, (result, d) => {
      result.push(min(d), max(d));
      result.push(_min(outliers(d)));
      result.push(_max(outliers(d)));
      if (points(d)) {
        result.push(_min(points(d)));
        result.push(_max(points(d)));
      }
    }, []);
    const minYValue = _min(values);
    const maxYValue = _max(values);

    return [
      yScaleFn()
        .domain([minYValue, maxYValue])
        .rangeRound([yMax, 0]),
      minYValue
    ];
  }, [samples, yMax, yScaleFn, max, min, outliers, points]);

  const formatTicksMap = useMemo(() => {
    if (dataXFormat !== DATA_FORMAT.none) return [];
    return getFittedTickLabels({
      textLength, scaleFn: xScale, labels: groups, widthMax: xMax,
    });
  }, [xScale, textLength, groups, xMax, dataXFormat]);
  const formatXTick = (value) => {
    if (dataXFormat === DATA_FORMAT.timestamp) return formatChartAxisTime(value);
    if (dataXFormat === DATA_FORMAT.none) return formatTicksMap[value];
    return value;
  };

  let boxWidth = maxBoxSize;
  if (xScale.bandwidth) boxWidth = xScale.bandwidth();
  const constrainedWidth = _min([maxBoxSize * 2 + plotsGap, boxWidth]);
  const showTicks = chartWidth > 0;

  const calculateBoxLeft = (d) => {
    if (dataXFormat === DATA_FORMAT.timestamp) {
      return xScale(x(d)) - calculateBoxWidth(d) / 2;
    }
    return xScale(x(d)) + (boxWidth + (isEmpty(points(d)) ? -constrainedWidth : plotsGap)) / 2;
  };
  const calculateBoxWidth = (d) => {
    if (dataXFormat === DATA_FORMAT.timestamp) {
      const domain = xScale.domain();
      const startTime = head(domain);
      const endTime = last(domain);
      const boxWidth = (xScale(endTime) - xScale(startTime)) / samples.length;
      return _min([maxBoxSize, boxWidth * (1 - padding)]);
    }
    return isEmpty(points(d)) ? constrainedWidth : (constrainedWidth - plotsGap) / 2;
  };

  const allPoints = useMemo(() => {
    const jitterWidth = constrainedWidth / 2 - plotsGap;
    return transform(samples, (result, d) => {
      result.push(
        map(points(d), (value) => ({
          cx: xScale(x(d)) + (boxWidth / 2) - jitterWidth - plotsGap / 2 + Math.random() * jitterWidth,
          cy: yScale(value),
        }))
      );
    }, []);
  }, [samples, xScale, yScale, plotsGap, constrainedWidth, boxWidth, points, x]);

  const label = useMemo(() => {
    return join(compact([yLabel, units]), ', ');
  }, [yLabel, units]);

  return (
    <div
      ref={targetRef}
      className={cx('boxplot-chart-graph-container', {expandable: mode !== 'expanded'})}
    >
      {
        showTicks &&
          <svg className={cx('boxplot-chart-layout', className)} width={chartWidth} height={chartHeight}>
            <Group top={margin.top} left={margin.left + labelMarginLeft}>
              <Group>
                {samples.map((d, i) => (
                  <g key={i}>
                    {!isEmpty(allPoints[i]) && (
                      <g>
                        {map(allPoints[i], ({cx, cy}, i) => (
                          <circle
                            key={i}
                            className='boxplot-point'
                            r={pointRadius}
                            cx={cx}
                            cy={cy}
                          />
                        ))}
                      </g>
                    )}
                    <BoxPlot
                      className='boxplot-chart'
                      min={min(d)}
                      max={max(d)}
                      left={calculateBoxLeft(d)}
                      firstQuartile={firstQuartile(d)}
                      thirdQuartile={thirdQuartile(d)}
                      median={median(d)}
                      boxWidth={calculateBoxWidth(d)}
                      valueScale={yScale}
                      outliers={outliers(d)}
                      minProps={{
                        onMouseOver: (e) => setPopupDescription({
                          node: e.target,
                          header: dataXFormat === DATA_FORMAT.timestamp ? formatDateAsLocalDateTime(x(d)) : x(d),
                          content: () => <BoxplotPopupContent items={{lower_fence: min(d)}} units={units} />,
                        }),
                        onMouseLeave: hidePopup,
                      }}
                      maxProps={{
                        onMouseOver: (e) => setPopupDescription({
                          node: e.target,
                          header: dataXFormat === DATA_FORMAT.timestamp ? formatDateAsLocalDateTime(x(d)) : x(d),
                          content: () => <BoxplotPopupContent items={{upper_fence: max(d)}} units={units} />,
                        }),
                        onMouseLeave: hidePopup,
                      }}
                      boxProps={{
                        onMouseOver: (e) => setPopupDescription({
                          node: e.target,
                          header: dataXFormat === DATA_FORMAT.timestamp ? formatDateAsLocalDateTime(x(d)) : x(d),
                          content: () =>
                            <BoxplotPopupContent
                              items={d}
                              outliers={outliers(d)}
                              excludeKeys={['x', 'outliers', 'points']}
                              units={units}
                            />,
                        }),
                        onMouseLeave: hidePopup,
                      }}
                    />
                  </g>
                ))}
              </Group>
              <Axis
                axisClassName='timeline-axis axis-bottom'
                orientation='bottom'
                top={yScale(_max([0, minYValue]))}
                scale={xScale}
                tickLabelProps={constant({})}
                tickFormat={() => null}
                hideTicks
              />
              <Axis
                axisClassName='timeline-axis axis-bottom'
                orientation='bottom'
                top={yScale(minYValue)}
                scale={xScale}
                tickLabelProps={constant({})}
                label={xLabel}
                labelClassName='axis-label'
                tickFormat={formatXTick}
                hideAxisLine
              />
              <Axis
                axisClassName='timeline-axis axis-left'
                orientation='left'
                scale={yScale}
                tickLabelProps={constant({dx: '-0.25em', dy: '0.25em'})}
                label={label}
                labelProps={{textAnchor: 'middle', y: -margin.left - labelMarginLeft / 2}}
                labelClassName='axis-label'
                tickFormat={(value) =>
                  formatNumber(value, {units, short: (value <= -1 || value >= 1), withIndent: true})}
              />
            </Group>
            <ChartPopup popupDescription={popupDescription} />
            {showTicks && (
              <GridRows
                className='timeline-grid'
                top={margin.top}
                left={margin.left}
                width={xMax}
                height={yMax}
                scale={yScale}
                stroke={null}
              />
            )}
            {dataXFormat === DATA_FORMAT.none && (
              <SvgTextLengthMeasurer
                value={groups}
                style={{fontSize}}
                onMeasure={(value) => setTextLength(value)}
              />
            )}
            {units && (
              <SvgTextLengthMeasurer
                value={units}
                style={{fontSize}}
                onMeasure={(value) => setUnitsLength(value)}
              />
            )}
          </svg>
      }
    </div>
  );
};

BoxplotChart.defaultProps = {
  mode: 'expanded',
  dimensions: {
    compact: {
      height: 100,
      margin: {top: 4, right: 10, bottom: 10, left: 40},
    },
    expanded: {
      height: 400,
      margin: {top: 30, right: 50, bottom: 60, left: 50},
    },
  },
  yScaleFn: scaleLinear,
  padding: 0.4,
  maxBoxSize: 40,
  plotsGap: 30,
  pointRadius: 2,
  x: (d) => d.x,
  min: (d) => d.min,
  max: (d) => d.max,
  median: (d) => d.median,
  firstQuartile: (d) => d.firstQuartile,
  thirdQuartile: (d) => d.thirdQuartile,
  outliers: (d) => d.outliers,
  points: (d) => d.points,
  dataXFormat: DATA_FORMAT.none,
  labelMarginLeft: 15,
  fontSize: 10,
};

const BoxplotPopupContent = ({items, outliers, excludeKeys = [], units}) => {
  const data = useMemo(() => {
    let filteredItems = omit(items, excludeKeys);
    const values = map(filteredItems, (value) => value);
    const minValue = _min(values);
    const maxValue = _max(values);
    const minOutlier = _min(outliers);
    const maxOutlier = _max(outliers);
    if (maxOutlier > maxValue) filteredItems = {max: maxOutlier, ...filteredItems};
    if (minOutlier < minValue) filteredItems.min = minOutlier;
    return filteredItems;
  }, [items, excludeKeys, outliers]);
  return (
    <div className='ui bulleted list'>
      {map(data, (value, key) => (
        <div key={key} className='item'>
          <b>{key}</b>
          {': '}
          {formatNumber(value, {units, short: true, withIndent: true})}
        </div>
      ))}
    </div>
  );
};

export default withResizeDetector(BoxplotChart, {handleWidth: true});
