import {PureComponent} from 'react';
import {map} from 'lodash';
import {StringListDropdownControl} from 'apstra-ui-common';

export default class IntegerListDropdownControl extends PureComponent {
  onChange = (items) => {
    const integerItems = map(items, (item) => +item);
    this.props.onChange(integerItems);
  };

  render() {
    const {props, onChange} = this;
    return (
      <StringListDropdownControl
        {...props}
        searchInput={{type: 'number'}}
        onChange={onChange}
      />
    );
  }
}
