import {Component, Fragment} from 'react';
import {Input} from 'semantic-ui-react';
import {has} from 'lodash';
import {Field, MapInput} from 'apstra-ui-common';

export default class ValueMapInput extends Component {
  generateNewEntryKey = (from = 0) => {
    const result = String(from);
    if (!has(this.props.value, [result])) return result;
    return this.generateNewEntryKey(from + 1);
  };

  generateNewEntry = () => [this.generateNewEntryKey(), ''];

  render() {
    const {name, value, schema, required, disabled, errors, onChange} = this.props;
    return (
      <MapInput
        name={name}
        value={value}
        schema={schema}
        required={required}
        disabled={disabled}
        errors={errors}
        onChange={onChange}
        generateNewEntry={this.generateNewEntry}
        buttonText='Add Entry'
        noItemsMessage='Value map is empty.'
      >
        {({key, value, index, errors, setEntryKey, setEntryValue}) =>
          <Fragment>
            <Field key='key' width={2}>
              <Input
                type='number'
                step='1'
                disabled={disabled}
                value={key}
                onChange={(e) => setEntryKey(index, e.target.value)}
              />
            </Field>
            <Field errors={errors} width={5}>
              <Input
                disabled={disabled}
                value={value}
                onChange={(e) => setEntryValue(index, e.target.value)}
              />
            </Field>
          </Fragment>
        }
      </MapInput>
    );
  }
}
