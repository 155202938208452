import {useEffect} from 'react';
import {
  HashRouter as Router, Route, Routes, Navigate,
  useParams, useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import {Grid} from 'semantic-ui-react';
import {map, values} from 'lodash';
import {FetchData, FetchDataError, Loader, interpolateRoute, request} from 'apstra-ui-common';

import IBAContext from '../IBAContext';
import ProbeDetails from './ProbeDetails';
import ProbeList from './ProbeList';
import AnomalyList from './AnomalyList';
import WidgetDetails from './WidgetDetails';
import ReportList from './report/ReportList';
import ReportContainer from './report/ReportContainer';
import WidgetList from './WidgetList';
import DashboardList from './DashboardList';
import DashboardDetails from './DashboardDetails';
import AutoEnabledDashboards from './AutoEnabledDashboards';
import generateProbeURI from '../generateProbeURI';

import './IBA.less';

export const routes = {
  probeList: '/api/blueprints/<blueprint_id>/probes',
  probeDetails: '/api/blueprints/<blueprint_id>/probes/<probe_id>',
  probeStage: '/api/blueprints/<blueprint_id>/probes/<probe_id>/query',
  probeTagList: '/api/blueprints/<blueprint_id>/probe-tags',
  probesBatchDelete: '/api/blueprints/<blueprint_id>/probes-batch-delete',
  cablingMap: '/api/blueprints/<blueprint_id>/experience/web/cabling-map',
  dashboardList: '/api/blueprints/<blueprint_id>/iba/dashboards',
  dashboardDetails: '/api/blueprints/<blueprint_id>/iba/dashboards/<dashboard_id>',
  dashboardsBatchDelete: '/api/blueprints/<blueprint_id>/iba/dashboards-batch-delete',
  widgetList: '/api/blueprints/<blueprint_id>/iba/widgets',
  widgetDetails: '/api/blueprints/<blueprint_id>/iba/widgets/<widget_id>',
  widgetsBatchDelete: '/api/blueprints/<blueprint_id>/iba/widgets-batch-delete',
  predefinedProbeList: '/api/blueprints/<blueprint_id>/iba/predefined-probes',
  predefinedProbeDetails: '/api/blueprints/<blueprint_id>/iba/predefined-probes/<predefined_probe>',
  predefinedProbeParameters: '/api/blueprints/<blueprint_id>/iba/predefined-probes/<predefined_probe>/<probe_id>',
  predefinedDashboard: '/api/blueprints/<blueprint_id>/iba/predefined-dashboards/<predefined_dashboard>',
  predefinedDashboardList: '/api/blueprints/<blueprint_id>/iba/predefined-dashboards',
  anomalyHeatmapWidgetData: '/api/blueprints/<blueprint_id>/iba/widgets/<widget_id>/anomaly-heatmap',
  anomalyList: '/api/blueprints/<blueprint_id>/anomalies',
  anomalousStageList: '/api/blueprints/<blueprint_id>/iba/anomalous-stages',
  vShpereAnomalyResolver: (
    '/api/blueprints/<blueprint_id>/virtual_infra/predefined_probes/virtual_infra_vlan_match/anomaly_resolver'
  ),
  telemetryServiceWarnings: '/api/blueprints/<blueprint_id>/iba/probes/<probe_id>/ts-warnings/<stage_name>',
  telemetryServiceRegistry: '/api/telemetry-service-registry',
  diskSpaceUsageForProbe: '/api/metricdb/metric/iba/<blueprint_id>/<probe_id>/stats',
  diskSpaceUsageForStage: '/api/metricdb/metric/iba/<blueprint_id>/<probe_id>/<stage_name>/stats',
  predefinedProbeReportList: '/api/blueprints/<blueprint_id>/predefined-reports',
  predefinedProbeReportRegistry: '/api/blueprints/<blueprint_id>/predefined-reports/<report_name>',
  predefinedProbeReportQuery: '/api/blueprints/<blueprint_id>/predefined-report/<report_name>',
};

export async function fetchKnownTags({blueprintId, routes, signal}) {
  const {items: knownTags} = await request(interpolateRoute(routes.probeTagList, {blueprintId}), {signal});
  return {knownTags: map(knownTags, 'name').sort()};
}

export async function fetchPredefinedProbes({blueprintId, routes, signal}) {
  const route = routes.predefinedProbeList;
  const {items: predefinedProbes} = await request(
    interpolateRoute(route, {blueprintId}),
    {signal, queryParams: {include_experimental: true}}
  );
  return {predefinedProbes};
}

const IBA = (props) => {
  return (
    <Router>
      <Routes>
        <Route
          path='/blueprints/:blueprintId/analytics/*'
          element={<IBAElement {...props} />}
        />
      </Routes>
    </Router>
  );
};
export default IBA;

const IBAElement = (props) => {
  const [searchParams] = useSearchParams();
  const {blueprintId} = useParams();
  const isGrouped = (searchParams.get('grouped') === 'true');

  const {
    blueprintDesign, allActiveNodes, systemIdMap, cablingMap, processorDefinitions,
    blueprintPermissions, anomalyRemediationUrl, generateVnLink, blueprintTags, isFreeform,
    systemsHrefs, deviceProfiles,
  } = props;

  const dashboardElement = <Dashboard />;
  const probeElement = <Probe />;

  return (
    <FetchData
      fetchData={IBAElement.fetchData}
      fetchParams={{blueprintId, routes}}
      pollingInterval={IBAElement.pollingInterval}
      customLoader
    >
      {({knownTags, predefinedProbes, loaderVisible, fetchDataError, refetchData}) =>
        <IBAContext.Provider
          value={{
            blueprintId, blueprintDesign, allActiveNodes, systemIdMap, cablingMap, processorDefinitions,
            knownTags, predefinedProbes,
            routes, blueprintPermissions, anomalyRemediationUrl, generateVnLink, blueprintTags, isFreeform,
            systemsHrefs, deviceProfiles,
            refetchKnownTags: () => refetchData(),
          }}
        >
          <Grid className='iba-root'>
            <Grid.Row>
              <Grid.Column>
                {loaderVisible ?
                  <Loader />
                : fetchDataError ?
                  <FetchDataError error={fetchDataError} />
                :
                  <Routes>
                    <Route path='dashboards'>
                      <Route path=':dashboardId'>
                        <Route path=':action' element={dashboardElement} />
                        <Route index element={dashboardElement} />
                      </Route>
                      <Route index element={<DashboardList />} />
                    </Route>
                    <Route
                      path='auto-enabled-dashboards'
                      element={<AutoEnabledDashboards />}
                    />
                    <Route
                      path='create-dashboard'
                      element={<CreateDashboard {...{blueprintPermissions}} />}
                    />

                    <Route
                      path='anomalies/*'
                      element={<AnomalyList grouped={isGrouped} />}
                    />

                    <Route path='widgets'>
                      <Route path=':widgetId' element={<WidgetDetails />} />
                      <Route index element={<WidgetList />} />
                    </Route>

                    <Route path='probes'>
                      <Route path='create-probe' element={<CreateProbe />} />
                      <Route path=':probeId'>
                        <Route path='stages/:stage' element={probeElement}>
                          <Route path=':action' element={probeElement} />
                        </Route>
                        <Route path='processors/:processor' element={probeElement}>
                          <Route path=':action' element={probeElement} />
                        </Route>
                        <Route path=':action' element={probeElement} />
                        <Route index element={probeElement} />
                      </Route>
                      <Route index element={<ProbeList />} />
                    </Route>

                    <Route path='reports'>
                      <Route path=':reportName' element={<ReportContainer />} />
                      <Route index element={<ReportList />} />
                    </Route>

                    <Route index element={<Navigate to='dashboards' replace />} />
                  </Routes>
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </IBAContext.Provider>
      }
    </FetchData>
  );
};

IBAElement.defaultProps = {routes};
IBAElement.pollingInterval = 10000;
IBAElement.fetchData = async ({
  blueprintId, routes,
  previousData, signal
}) => {
  const fetchParams = {blueprintId, routes, signal};
  const [{knownTags}, {predefinedProbes}] = await Promise.all([
    fetchKnownTags(fetchParams),
    previousData ? previousData : fetchPredefinedProbes(fetchParams),
  ]);
  return {knownTags, predefinedProbes};
};

const Dashboard = () => {
  const {blueprintId, dashboardId, action} = useParams();
  return (
    <FetchData
      pollingInterval={DashboardDetails.pollingInterval}
      fetchParams={{blueprintId, dashboardId, action, routes}}
    >
      <DashboardDetails dashboardId={dashboardId} action={action} />
    </FetchData>
  );
};

const CreateDashboard = ({blueprintPermissions}) => {
  const {blueprintId} = useParams();
  return (
    <FetchData
      pollingInterval={DashboardDetails.pollingInterval}
      fetchParams={{blueprintId, routes, blueprintPermissions}}
    >
      <DashboardDetails action='create' />
    </FetchData>
  );
};

const Probe = () => {
  const {blueprintId, probeId, action} = useParams();
  return (
    <FetchData
      pollingInterval={action ? null : ProbeDetails.pollingInterval}
      fetchParams={{blueprintId, probeId, action, routes}}
      fetchData={ProbeDetails.fetchData}
    >
      <ProbeDetailsWrapper
        probeId={probeId}
        action={action}
      />
    </FetchData>
  );
};

const ProbeDetailsWrapper = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const probe = location.state?.probe ?? props.probe;
    const defaultProcessorName = probe.processors.length ? probe.processors[0].name : null;
    const defaultStageName = probe.processors.length ? values(probe.processors[0].outputs)[0] : null;
    const generateUrl = ({processorName, stageName}) => generateProbeURI({
      blueprintId: params.blueprintId,
      probeId: props.probeId,
      stageName,
      processorName,
      action: props.action,
    });
    if (!params.processor && !params.stage) {
      if (props.action && defaultProcessorName) {
        // for any other action we select the default (first) processor if it's available
        navigate(generateUrl({processorName: defaultProcessorName}), {replace: true});
      } else if (!props.action && defaultStageName) {
        // for readonly view we select the default stage if it's available
        navigate(generateUrl({stageName: defaultStageName}), {replace: true});
      }
    }
  }, [
    props.probeId, props.action, props.probe, params.blueprintId,
    params.processor, params.stage, location.state, navigate,
  ]);
  return (
    <ProbeDetails
      {...props}
    />
  );
};

const CreateProbe = () => {
  return (
    <FetchData
      pollingInterval={null}
      fetchData={ProbeDetails.fetchTelemetryServiceRegistryItems}
      fetchParams={{routes}}
    >
      <ProbeDetails action='create' />
    </FetchData>
  );
};
