/* eslint-disable camelcase */
import {filter, find, flatten, forEach, groupBy, map, uniqBy} from 'lodash';
import {natsort} from 'apstra-ui-common';

import {ALL_NODE_ROLES, NODE_ROLES} from '../../../roles';

interface ITopologyData {
  isFiveStage?: boolean;
  accessGroups?: Array<IAccessGroupRaw>;
  racks: Array<IRackRaw>;
  pods: Array<IPodRaw>;
  planes: Array<ISuperspinePlaneRaw>;
  nodes: Array<INode>;
  vlans?: IVlan[];
  securityZones?: ISecurityZone[];
  links: ILink[];
}

export interface ILink {
  id: string;
  role: string;
  endpoints: [IEndpoint, IEndpoint];
}

interface IEndpoint {
  id: string;
  if_id: string;
  if_name: string;
  if_type: string;
  label: string;
  role: string;
}

interface IAccessGroupRaw {
  label: string;
  access_group_id: string,
  pod_id: string,
  rack_id: string;
}

interface IRackRaw {
  label: string;
  rack_id: string;
}

interface IPodRaw {
  id: string;
  label?: string;
}

interface ISuperspinePlaneRaw {
  id: string;
  label: string;
}

export interface IPlane {
  id: string;
}

export interface INode {
  id: string;
  access_group_id?: string,
  rack_id?: string;
  pod_id?: string;
  superspine_plane_id?: string;
  role: ALL_NODE_ROLES,
  external?: boolean;
  pair?: INode[];
  layer_status?: string;
  anomaly?: boolean;
  part_of?: string;
}

export interface IVlan {
  endpoints: string[];
  id: string;
  label: string;
  security_zone_id: string;
}

export interface IVlanResult extends Omit<IVlan, 'endpoints'> {
  endpoints: Set<string>;
}

export interface ISecurityZone {
  id: string;
  label: string;
}

export interface IBuildTopologyDataResult {
  withAccessGroups: boolean;
  accessGroups: Record<string, IAccessGroup>;
  racks: Record<string, IRack>;
  pods: Record<string, IPod>;
  superspinePlanes: Record<string, ISuperspinePlane>;
  externalGenerics: INode[],
  vlans?: IVlanResult[];
  securityZones?: ISecurityZone[];
  links: ILink[];
  portsByNodeId: Record<string, IPort[]>;
}

interface IAccessGroup extends IAccessGroupRaw, IPlane {
  accessSwitches: INode[],
  generics: INode[],
}

interface IRack extends IRackRaw, IPlane {
  pod_id?: string;
  accessGroups: IAccessGroup[],
  leafs: INode[],
  accessSwitches: INode[],
  generics: INode[],
}

interface IPod extends IPodRaw, IPlane {
  racks: IRack[],
  spines: INode[],
}

interface ISuperspinePlane extends ISuperspinePlaneRaw, IPlane {
  superspines: INode[],
}

export interface IPort {
  if_name: string;
}

const buildTopologyData = (topologyData: ITopologyData): IBuildTopologyDataResult => {
  const accessGroups = buildAccessGroups(topologyData);
  const racks = buildRacks(topologyData, accessGroups);
  const pods = buildPods(topologyData, racks);
  const superspinePlanes = buildSuperspinePlanes(topologyData);
  const externalGenerics = buildExternalGenerics(topologyData);
  return {
    withAccessGroups: !!topologyData.accessGroups?.length,
    accessGroups,
    racks,
    pods,
    superspinePlanes,
    externalGenerics,
    vlans: buildVlans(topologyData),
    securityZones: topologyData.securityZones,
    links: topologyData.links,
    portsByNodeId: buildNodePorts(topologyData),
  };
};

const buildAccessGroups = (topologyData: ITopologyData): Record<string, IAccessGroup> => {
  if (!topologyData.accessGroups?.length) {
    return {};
  }
  const result: Record<string, IAccessGroup> = {};

  forEach(topologyData.nodes, (node) => {
    const {access_group_id: accessGroupId, role, part_of: pairId} = node;
    if (!accessGroupId) {
      return;
    }
    if (!result[accessGroupId]) {
      result[accessGroupId] = {
        id: accessGroupId,
        ...find(topologyData.accessGroups, {access_group_id: accessGroupId})!,
        accessSwitches: [],
        generics: [],
      };
    }

    if (role === NODE_ROLES.ACCESS) {
      if (pairId) {
        const leafPair = find(result[accessGroupId].accessSwitches, {id: pairId});
        if (leafPair) {
          leafPair.pair?.push(node);
        } else {
          result[accessGroupId].accessSwitches.push({id: pairId, pair: [node], role: NODE_ROLES.ACCESS_PAIR});
        }
      } else {
        result[accessGroupId].accessSwitches.push(node);
      }
    } else if (role === NODE_ROLES.GENERIC) {
      result[accessGroupId].generics.push(node);
    }
  });

  return result;
};

const buildRacks = (topologyData: ITopologyData, accessGroups: Record<string, IAccessGroup>)
  : Record<string, IRack> => {
  const result: Record<string, IRack> = {};
  const withAccessGroups = !!topologyData.accessGroups?.length;

  forEach(topologyData.nodes, (node) => {
    const {rack_id: rackId, role, part_of: leafPairId, pod_id: podId} = node;
    if (!rackId) {
      return;
    }
    if (!result[rackId]) {
      result[rackId] = {
        id: rackId,
        pod_id: podId,
        ...find(topologyData.racks, {rack_id: rackId})!,
        leafs: [],
        accessSwitches: [],
        generics: [],
        accessGroups: [],
      };
    }

    if (role === NODE_ROLES.ACCESS) {
      result[rackId].accessSwitches.push(node);
    } else if (role === NODE_ROLES.GENERIC) {
      if (!withAccessGroups || !node.access_group_id) {
        result[rackId].generics.push(node);
      }
    } else if (role === NODE_ROLES.LEAF) {
      if (leafPairId) {
        const leafPair = find(result[rackId].leafs, {id: leafPairId});
        if (leafPair) {
          leafPair.pair?.push(node);
        } else {
          result[rackId].leafs.push({id: leafPairId, pair: [node], role: NODE_ROLES.LEAF_PAIR});
        }
      } else {
        result[rackId].leafs.push(node);
      }
    }
  });

  forEach(accessGroups, (accessGroup) => {
    result[accessGroup.rack_id].accessGroups.push(accessGroup);
  });

  return result;
};

function buildPods(topologyData: ITopologyData, racks: Record<string, IRack>): Record<string, IPod> {
  const result: Record<string, IPod> = {};

  forEach(topologyData.nodes, (node) => {
    const {pod_id: podId, role} = node;
    if (!podId) {
      return;
    }
    if (!result[podId]) {
      result[podId] = {
        label: 'pod',
        ...find(topologyData.pods, {id: podId})!,
        spines: [],
        racks: [],
      };
    }

    if (role === NODE_ROLES.SPINE) {
      result[podId].spines.push(node);
      result[podId].id ??= podId;
    }
  });

  forEach(racks, (rack) => {
    if (rack.pod_id) {
      result[rack.pod_id].racks.push(rack);
    }
  });

  return result;
}

function buildSuperspinePlanes(topologyData: ITopologyData)
  : Record<string, ISuperspinePlane> {
  const result: Record<string, ISuperspinePlane> = {};

  forEach(topologyData.nodes, (node) => {
    const {superspine_plane_id: superspinePlaneId, role} = node;
    if (!superspinePlaneId) {
      return;
    }
    if (!result[superspinePlaneId]) {
      result[superspinePlaneId] = {
        ...find(topologyData.planes, {id: superspinePlaneId})!,
        superspines: [],
      };
    }

    if (role === NODE_ROLES.SUPERSPINE) {
      result[superspinePlaneId].superspines.push(node);
    }
  });

  return result;
}

function buildExternalGenerics(topologyData: ITopologyData): INode[] {
  return filter(topologyData.nodes, {external: true, role: NODE_ROLES.GENERIC});
}

function buildVlans(topologyData: ITopologyData): IVlanResult[] {
  return map(topologyData.vlans, (vlan) => ({
    ...vlan,
    endpoints: new Set(vlan.endpoints)
  }));
}

function buildNodePorts(topologyData: ITopologyData) {
  const endpointsByNode = groupBy(flatten(map(topologyData.links, 'endpoints')), 'id');
  forEach(endpointsByNode,
    (endpoints) => uniqBy(endpoints.sort((a, b) => natsort(a.if_name, b.if_name)), 'if_id')
  );
  return endpointsByNode;
}

export default buildTopologyData;
