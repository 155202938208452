import {Component, Fragment, cloneElement} from 'react';
import {observable, action, toJS, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Grid, Divider, Header, Popup, Button, Placeholder, Ref} from 'semantic-ui-react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {find, flatten, without, times} from 'lodash';
import cx from 'classnames';
import {ActionsMenu} from 'apstra-ui-common';

import Widget from './Widget';
import WidgetModal from './WidgetModal';
import WidgetAdditionModal from './WidgetAdditionModal';
import IBAContext from '../IBAContext';

import './Dashboard.less';

export function generateDashboardURI({blueprintId, dashboardId, action}) {
  let result = `/blueprints/${blueprintId}/analytics/dashboards`;
  if (dashboardId) {
    result += '/' + dashboardId;
    if (action) {
      result += '/' + action;
    }
  }
  return result;
}

@observer
export default class Dashboard extends Component {
  static contextType = IBAContext;

  static defaultProps = {
    editable: false
  };

  @observable.ref widgetModalProps = null;
  @observable.ref widgetAdditionModalProps = null;
  @observable isDragging = false;

  @action
  setWidgetModalProps = (props) => {
    this.widgetModalProps = props;
  };

  @action
  setWidgetAdditionModalProps = (props) => {
    this.widgetAdditionModalProps = props;
  };

  @action
  addWidgetToColumn = (widgetId, columnIndex) => {
    const {grid} = this.props.dashboard;
    grid[columnIndex] = [...grid[columnIndex], widgetId];
  };

  @action
  removeWidgetFromColumn = (widgetId, columnIndex) => {
    const {grid} = this.props.dashboard;
    grid[columnIndex] = without(grid[columnIndex], widgetId);
  };

  @action
  onWidgetDragStart = () => {
    this.isDragging = true;
  };

  @action
  onWidgetDragEnd = ({draggableId: widgetId, source, destination}) => {
    this.isDragging = false;
    if (
      !destination ||
      source.droppableId === destination.droppableId && source.index === destination.index
    ) return;
    const {grid} = this.props.dashboard;
    if (source.droppableId === destination.droppableId) {
      const column = [...grid[source.droppableId]];
      column.splice(source.index, 1);
      column.splice(destination.index, 0, widgetId);
      grid[source.droppableId] = column;
    } else {
      const sourceColumn = [...grid[source.droppableId]];
      const destinationColumn = [...grid[destination.droppableId]];
      sourceColumn.splice(source.index, 1);
      destinationColumn.splice(destination.index, 0, widgetId);
      grid[source.droppableId] = sourceColumn;
      grid[destination.droppableId] = destinationColumn;
    }
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {editable, dashboard, widgets, probes, refetchData} = this.props;
    const {
      widgetModalProps, setWidgetModalProps,
      widgetAdditionModalProps, setWidgetAdditionModalProps,
      addWidgetToColumn, removeWidgetFromColumn,
      onWidgetDragStart, onWidgetDragEnd, isDragging,
    } = this;
    return (
      <Fragment>
        <DragDropContext onDragStart={onWidgetDragStart} onDragEnd={onWidgetDragEnd}>
          <Grid className='iba-dashboard' columns='equal'>
            {dashboard.grid.map((column, columnIndex) =>
              <Droppable
                key={columnIndex}
                droppableId={String(columnIndex)}
                isDropDisabled={!editable}
              >
                {({innerRef, placeholder, droppableProps}, {isDraggingOver}) =>
                  <Ref innerRef={innerRef}>
                    <Grid.Column
                      className={cx('iba-dashboard-column', {'dragging-over': isDraggingOver})}
                      {...droppableProps}
                    >
                      {column.map((widgetId, widgetIndex) => {
                        const widget = find(widgets, {id: widgetId});
                        if (!widget) return null;
                        return (
                          <Draggable
                            key={widget.id}
                            draggableId={widgetId}
                            index={widgetIndex}
                            isDragDisabled={!editable}
                          >
                            {({innerRef, draggableProps, dragHandleProps}) =>
                              <Ref innerRef={innerRef}>
                                <Grid className='iba-widget-container' {...draggableProps}>
                                  <Grid.Column textAlign='center' width={16}>
                                    {editable &&
                                      <DashboardWidgetActions
                                        widget={widget}
                                        columnIndex={columnIndex}
                                        setWidgetModalProps={setWidgetModalProps}
                                        removeWidgetFromColumn={removeWidgetFromColumn}
                                        refetchData={refetchData}
                                      />
                                    }
                                    <Header size='small' {...dragHandleProps}>
                                      {widget.label}
                                    </Header>
                                  </Grid.Column>
                                  <Grid.Column width={16}>
                                    <Widget
                                      widget={widget}
                                      probes={probes}
                                    />
                                  </Grid.Column>
                                </Grid>
                              </Ref>
                            }
                          </Draggable>
                        );
                      })}
                      {placeholder}
                      {editable &&
                        <AddWidgetPlaceholder
                          hidden={isDragging}
                          widgets={widgets}
                          grid={dashboard.grid}
                          columnIndex={columnIndex}
                          setWidgetModalProps={setWidgetModalProps}
                          setWidgetAdditionModalProps={setWidgetAdditionModalProps}
                          addWidgetToColumn={addWidgetToColumn}
                          refetchData={refetchData}
                        />
                      }
                    </Grid.Column>
                  </Ref>
                }
              </Droppable>
            )}
          </Grid>
        </DragDropContext>
        <WidgetModal
          open={false}
          onClose={() => setWidgetModalProps({open: false})}
          probes={probes}
          showCreateAnother={false}
          {...widgetModalProps}
        />
        <WidgetAdditionModal
          open={false}
          onClose={() => setWidgetAdditionModalProps({open: false})}
          widgets={widgets}
          {...widgetAdditionModalProps}
        />
      </Fragment>
    );
  }
}

@observer
export class DashboardWidgetActions extends Component {
  render() {
    const {widget, columnIndex, setWidgetModalProps, removeWidgetFromColumn, refetchData} = this.props;
    return <ActionsMenu
      size='tiny'
      floated='right'
      items={[
        {
          icon: 'edit',
          title: 'Edit',
          onClick: () => setWidgetModalProps({
            open: true,
            mode: 'update',
            widget,
            onSuccess: () => refetchData()
          }),
        },
        {
          icon: 'trash',
          title: 'Remove',
          onClick: () => removeWidgetFromColumn(widget.id, columnIndex),
        },
      ]}
    />;
  }
}

export class AddWidgetPlaceholder extends Component {
  render() {
    const {
      hidden,
      widgets, columnIndex, grid,
      setWidgetModalProps, setWidgetAdditionModalProps,
      addWidgetToColumn,
      refetchData,
      ...props
    } = this.props;
    const addExistingWidgetButton = (
      <Button
        fluid
        color='teal'
        icon='add'
        labelPosition='left'
        content='Add Existing Widget'
        onClick={() => setWidgetAdditionModalProps({
          open: true,
          excludeWidgetIds: flatten(toJS(grid)),
          onSuccess: (widgetId) => addWidgetToColumn(widgetId, columnIndex)
        })}
      />
    );
    const createNewWidgetButton = (
      <Button
        fluid
        color='purple'
        icon='add circle'
        labelPosition='left'
        content='Create New Widget'
        onClick={() => setWidgetModalProps({
          open: true,
          mode: 'create',
          onSuccess: ({result: {widgetId}}) => {
            addWidgetToColumn(widgetId, columnIndex);
            refetchData();
          }
        })}
      />
    );
    return (
      <div className={cx('add-widget-placeholder', {hidden})} {...props}>
        <Placeholder className='add-widget-placeholder-background' fluid>
          {times(10, (index) => <Placeholder.Line key={index} />)}
        </Placeholder>
        <div className='add-widget-placeholder-actions'>
          {widgets.length ?
            addExistingWidgetButton
          :
            <Popup
              trigger={<div>{cloneElement(addExistingWidgetButton, {disabled: true})}</div>}
              position='top center'
              content='No existing widgets available. Create a new widget.'
            />
          }
          <Divider horizontal content='Or' />
          {createNewWidgetButton}
        </div>
      </div>
    );
  }
}
