import {useContext} from 'react';

import UserStoreContext from '../../UserStoreContext';
import StepByStepGuide from '../../components/StepByStepGuide';
import {GETTING_STARTED_GUIDE_KEY} from '../consts';

const GettingStartedGuide = ({firstDashboardId}) => {
  const {userStore} = useContext(UserStoreContext);
  const userPreferences = userStore.getStoreValue([GETTING_STARTED_GUIDE_KEY]) || {'steps-completeness': {}};
  const {
    'steps-completeness': {
      'add-remote-controller': isRemoteControllerAdded,
      'add-central-dashboard': isCentralDashboardAdded,
      'edit-central-dashboard': isCentralDashboardModified,
    },
  } = userPreferences;

  const steps = [
    {
      label: 'Add at least one remote Apstra server',
      navigateToUrl: '/#/instances',
      disabled: false,
      completed: isRemoteControllerAdded,
    },
    {
      label: 'Create your first Apstra Central dashboard',
      navigateToUrl: '/#/dashboards',
      disabled: false,
      completed: isCentralDashboardAdded,
    },
    {
      label: 'Edit the dashboard to select remote blueprints and set their locations',
      navigateToUrl: `/#/dashboards/${firstDashboardId}/edit`,
      disabled: !firstDashboardId && !isCentralDashboardModified,
      completed: isCentralDashboardModified,
    },
  ];

  return (
    <StepByStepGuide
      title={{expanded: 'Get started with Apstra Central'}}
      steps={steps}
      collapsed={userPreferences.collapsed}
      hideButtonText={{expanded: 'Don`t want to see checklist anymore? Change preferences.'}}
      onExpand={() => userStore.setStoreValueFn(GETTING_STARTED_GUIDE_KEY)({...userPreferences, collapsed: false})}
      onCollapse={() => userStore.setStoreValueFn(GETTING_STARTED_GUIDE_KEY)({...userPreferences, collapsed: true})}
      onHide={() => userStore.setStoreValueFn(GETTING_STARTED_GUIDE_KEY)({...userPreferences, hidden: true})}
    />
  );
};

export default GettingStartedGuide;
