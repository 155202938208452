import {useState} from 'react';
import {Form, Loader, Message} from 'semantic-ui-react';
import {FormattedJSON, request} from 'apstra-ui-common';

import './Restraw.less';

const Restraw = () => {
  const [text, setText] = useState('');
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRequestClick = async () => {
    if (!text) return;
    setLoading(true);
    try {
      const data = await request(text);
      setResponse(data);
      setError(null);
    } catch (e) {
      setResponse(null);
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='restraw'>
      <Form onSubmit={handleRequestClick}>
        <Form.Field>
          <label>{'Quick GET request:'}</label>
          <Form.Input
            fluid
            value={text}
            onChange={(e) => setText(e.target.value)}
            action={<Form.Button color='teal' attached='right' onClick={handleRequestClick}>{'Request'}</Form.Button>}
            actionPosition='right'
            placeholder='/api/version'
          />
        </Form.Field>
      </Form>
      {loading && <Loader active inline='centered' />}
      {!loading && error && <Message negative><Message.Header>{error.name}</Message.Header>{error.message}</Message>}
      {!loading && !!response && (
        <div className='response-json'>
          <FormattedJSON
            json={response}
          />
        </div>
      )}
    </div>
  );
};

export default Restraw;
