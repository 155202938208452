import {useCallback, useState} from 'react';
import {HashRouter as Router} from 'react-router-dom';
import {observer} from 'mobx-react';
import {Grid, Button, Label} from 'semantic-ui-react';
import {includes, without} from 'lodash';
import {ActionsMenu, Checkbox, DataFilter, DataFilteringContainerWithRouter as DataFilteringContainer,
  DataFilteringLayout, DataTable, FetchData, PermissionChecker,
  checkPermissions, hasPermissions, interpolateRoute, notifier, request} from 'apstra-ui-common';

import wrapWithComponent from '../../wrapWithComponent';
import SyslogConfigModal from './SyslogConfigModal';
import SyslogConfigDeletionModal from './SyslogConfigDeletionModal';
import useUserStore from '../../hooks/useUserStore';

const tableSchema = [
  {
    name: 'ipAddress',
    label: 'IP Address and Port',
    value: ({item}) => `${item.address}:${item.port}`,
    sortable: true,
  },
  {
    name: 'protocol',
    label: 'Protocol',
    value: ['protocol'],
    formatter: ({value}) => <Label>{value.toUpperCase()}</Label>,
    sortable: true,
  },
  {
    name: 'facility',
    label: 'Facility',
    value: ['facility'],
    sortable: true,
  },
  {
    name: 'timezone',
    label: 'Time Zone',
    value: ['timezone'],
    sortable: true,
  },
  {
    name: 'useForAudit',
    label: 'Use for Audit',
    value: ({item: syslogConfig, params: {useForAuditSyslogConfigIds}}) =>
      includes(useForAuditSyslogConfigIds, syslogConfig.id),
    formatter: ({item: syslogConfig, value, params: {
      routes, permissions,
      syslogConfigIdToggleInProgress, toggleSyslogConfig, toggleSyslogUseForAudit,
      ...params
    }}) => (
      <PermissionChecker
        hasPermissions={hasPermissions({permissions, route: routes.auditConfig, method: 'PUT'})}
      >
        <Checkbox
          toggle
          checked={value}
          disabled={syslogConfigIdToggleInProgress !== null}
          onChange={() => toggleSyslogConfig({
            toggle: toggleSyslogUseForAudit,
            syslogConfig,
            skipSyslogConfigs: true,
            ...params
          })}
        />
      </PermissionChecker>
    ),
    sortable: true,
  },
  {
    name: 'anomalyForwarding',
    label: 'Forward Anomalies',
    value: ['anomaly_forwarding'],
    formatter: ({item: syslogConfig, value, params: {
      routes, permissions,
      syslogConfigIdToggleInProgress, toggleSyslogConfig, toggleSyslogAnomalyForwarding,
      ...params
    }}) => (
      <PermissionChecker
        hasPermissions={hasPermissions({permissions, route: routes.syslogConfigDetails, method: 'PUT'})}
      >
        <Checkbox
          toggle
          checked={value}
          disabled={syslogConfigIdToggleInProgress !== null}
          onChange={() => toggleSyslogConfig({toggle: toggleSyslogAnomalyForwarding, syslogConfig, ...params})}
        />
      </PermissionChecker>
    ),
    sortable: true,
  },
  {
    name: 'actions',
    label: 'Actions',
    formatter: ({item: syslogConfig, params: {
      routes, permissions, setSyslogConfigModalProps, setSyslogConfigDeletionModalProps
    }}) => <ActionsMenu
      items={[
        {
          icon: 'edit',
          title: 'Edit',
          hasPermissions: hasPermissions({permissions, route: routes.syslogConfigDetails, method: 'PUT'}),
          notPermittedMessage: 'You do not have permission to edit',
          onClick: () => setSyslogConfigModalProps({open: true, mode: 'update', syslogConfig}),
        },
        {
          icon: 'clone',
          title: 'Clone',
          hasPermissions: hasPermissions({permissions, route: routes.syslogConfigList, method: 'POST'}),
          notPermittedMessage: 'You do not have permission to clone',
          onClick: () => setSyslogConfigModalProps({open: true, mode: 'clone', syslogConfig}),
        },
        {
          icon: 'trash',
          title: 'Delete',
          hasPermissions: hasPermissions({permissions, route: routes.syslogConfigDetails, method: 'DELETE'}),
          notPermittedMessage: 'You do not have permission to delete',
          onClick: () => setSyslogConfigDeletionModalProps({open: true, syslogConfig}),
        },
      ]}
    />,
  },
];

async function fetchData({signal, routes, permissions, previousData, skipSyslogConfigs = false}) {
  checkPermissions({permissions, route: routes.syslogConfigList});
  checkPermissions({permissions, route: routes.auditConfig});
  const requests = [
    skipSyslogConfigs ? {items: previousData.syslogConfigs} : request(routes.syslogConfigList, {signal}),
    request(routes.auditConfig, {signal}),
  ];
  const [{items: syslogConfigs}, {syslogs: useForAuditSyslogConfigIds}] = await Promise.all(requests);
  return {syslogConfigs, useForAuditSyslogConfigIds};
}

const SyslogConfigList = ({
  pollingInterval = 10000,
  userStoreKey = 'syslogConfigList',
  routes,
  permissions,
}) => {
  const [preferences, setPreferences] = useUserStore(userStoreKey);
  const [syslogConfigModalProps, setSyslogConfigModalProps] = useState(null);
  const [syslogConfigDeletionModalProps, setSyslogConfigDeletionModalProps] = useState(null);
  const [syslogConfigIdToggleInProgress, setSyslogConfigIdToggleInProgress] = useState(null);

  const toggleSyslogConfig = useCallback(async ({
    toggle, syslogConfig, refetchData, skipSyslogConfigs, ...toggleParams
  }) => {
    setSyslogConfigIdToggleInProgress(syslogConfig.id);
    let updateError = null;
    let message = null;
    try {
      message = await toggle({syslogConfig, ...toggleParams});
    } catch (error) {
      updateError = error;
    }
    if (message) {
      notifier.notify({resourceLabel: `${syslogConfig.address}:${syslogConfig.port}`, message});
    } else if (updateError) {
      notifier.showError(updateError);
    }
    try {
      await refetchData({fetchParams: {skipSyslogConfigs}});
    } finally {
      setSyslogConfigIdToggleInProgress(null);
    }
  }, []);

  const toggleSyslogUseForAudit = useCallback(async ({syslogConfig, useForAuditSyslogConfigIds}) => {
    let enabling = null;
    if (includes(useForAuditSyslogConfigIds, syslogConfig.id)) {
      useForAuditSyslogConfigIds = without(useForAuditSyslogConfigIds, syslogConfig.id);
      enabling = false;
    } else {
      useForAuditSyslogConfigIds = [...useForAuditSyslogConfigIds, syslogConfig.id];
      enabling = true;
    }
    await request(routes.auditConfig, {
      method: 'PUT',
      body: JSON.stringify({syslogs: useForAuditSyslogConfigIds})
    });
    return enabling ? 'Syslog is now used for audit' : 'Syslog is not used for audit anymore';
  }, [routes?.auditConfig]);

  const toggleSyslogAnomalyForwarding = useCallback(async ({syslogConfig}) => {
    const enabling = !syslogConfig.anomaly_forwarding;
    await request(
      interpolateRoute(
        routes.syslogConfigDetails,
        {syslogConfigId: syslogConfig.id}
      ), {
        method: 'PUT',
        body: JSON.stringify({anomaly_forwarding: enabling})
      }
    );
    return enabling ? 'Syslog is now used for anomaly forwarding' : 'Syslog is not used for anomaly forwarding anymore';
  }, [routes?.syslogConfigDetails]);

  const defaultPageSize = preferences?.pageSize;

  return (
    <DataFilteringContainer
      stateQueryParam='syslogs-filter'
      defaultPageSize={defaultPageSize}
      defaultSorting={{ipAddress: 'asc'}}
      setUserStoreProps={setPreferences}
    >
      {({activePage, pageSize, sorting, updatePagination, updateSorting}) =>
        <FetchData
          fetchData={fetchData}
          pollingInterval={pollingInterval}
          fetchParams={{routes, permissions}}
          customLoader
        >
          {({syslogConfigs, useForAuditSyslogConfigIds, loaderVisible, fetchDataError, refetchData}) =>
            <Grid stackable>
              <Grid.Column width={16} textAlign='right'>
                <PermissionChecker
                  hasPermissions={
                    hasPermissions({permissions, route: routes.syslogConfigList, method: 'POST'})
                  }
                  notPermittedMessage='You do not have permission to create'
                  popupProps={{
                    position: 'top center',
                    offset: ({reference}) => [0, reference.height],
                  }}
                >
                  <Button
                    primary
                    size='big'
                    icon='add circle'
                    content='Create Syslog Config'
                    onClick={() => setSyslogConfigModalProps({open: true, mode: 'create'})}
                  />
                </PermissionChecker>
              </Grid.Column>
              <Grid.Column width={16}>
                <DataFilter
                  items={syslogConfigs}
                  params={{useForAuditSyslogConfigIds}}
                  schema={tableSchema}
                  activePage={activePage}
                  pageSize={pageSize}
                  sorting={sorting}
                >
                  {({items: syslogConfigs, totalCount}) =>
                    <DataFilteringLayout
                      loaderVisible={loaderVisible}
                      fetchDataError={fetchDataError}
                      paginationProps={{
                        activePage,
                        pageSize,
                        totalCount,
                        onChange: updatePagination
                      }}
                    >
                      <DataTable
                        verticalAlign='middle'
                        items={syslogConfigs}
                        params={{
                          useForAuditSyslogConfigIds,
                          toggleSyslogConfig,
                          toggleSyslogUseForAudit,
                          toggleSyslogAnomalyForwarding,
                          syslogConfigIdToggleInProgress,
                          setSyslogConfigModalProps,
                          setSyslogConfigDeletionModalProps,
                          refetchData,
                          routes,
                          permissions,
                        }}
                        schema={tableSchema}
                        sorting={sorting}
                        updateSorting={updateSorting}
                        getHeaderCellProps={({name}) => ({collapsing: name === 'editable' || name === 'actions'})}
                        getItemKey={({id}) => id}
                      />
                    </DataFilteringLayout>
                  }
                </DataFilter>
              </Grid.Column>
              <SyslogConfigModal
                open={false}
                onClose={() => setSyslogConfigModalProps({open: false})}
                onSuccess={() => refetchData()}
                routes={routes}
                {...syslogConfigModalProps}
              />
              <SyslogConfigDeletionModal
                open={false}
                onClose={() => setSyslogConfigDeletionModalProps({open: false})}
                onSuccess={() => refetchData()}
                routes={routes}
                {...syslogConfigDeletionModalProps}
              />
            </Grid>
          }
        </FetchData>
      }
    </DataFilteringContainer>
  );
};

SyslogConfigList.defaultProps = {
  routes: {
    syslogConfigList: '/api/config/syslogs',
    syslogConfigTimeZones: '/api/config/timezones',
    syslogConfigDetails: '/api/config/syslogs/<syslog_config_id>',
    auditConfig: '/api/config/audit',
  }
};

export default wrapWithComponent(Router)(
  observer(SyslogConfigList));
