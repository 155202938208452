import {Component} from 'react';
import {ResourceDeletionModal, interpolateRoute, request} from 'apstra-ui-common';

export default class SyslogConfigDeletionModal extends Component {
  deleteSyslogConfig = () => request(
    interpolateRoute(
      this.props.routes.syslogConfigDetails,
      {syslogConfigId: this.props.syslogConfig.id}
    ),
    {method: 'DELETE'}
  );

  render() {
    const {syslogConfig, ...props} = this.props;
    return (
      <ResourceDeletionModal
        resourceName='Syslog Config'
        resourceLabel={syslogConfig ? `${syslogConfig.address}:${syslogConfig.port}` : null}
        submit={this.deleteSyslogConfig}
        {...props}
      />
    );
  }
}
