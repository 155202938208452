import {useEffect, useState} from 'react';
import {map} from 'lodash';

import uploadStore from './uploadStore';

export const useUploads = () => {
  const [uploads, setUploads] = useState([]);
  useEffect(() => {
    const subscribeId = uploadStore.subscribe((items) => {
      const uploads = map(items, ({payload, ...props}, id) => ({id, ...payload, ...props}));
      setUploads(uploads);
    });
    return () => {
      uploadStore.unsubscribe(subscribeId);
    };
  });
  return uploads;
};
