import {constant, isNil} from 'lodash';
import {Label} from 'semantic-ui-react';
import {createValueRenderer} from 'apstra-ui-common';

const nodeLabelRenderer = createValueRenderer({
  condition: ({name, value, nodes}) => (name === 'system_id' && !isNil(nodes?.[value]?.label)),
  renderValue: constant(null),
  renderValueInput: constant(null),
  getQueryPartData: ({value, nodes}) => ({
    value: <Label key={value}>{nodes[value].label}</Label>,
    matcher: '='
  })
});

export default nodeLabelRenderer;
