import {useMemo, useCallback, useState, useRef} from 'react';
import {Grid, Modal} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {compact, flatten, forEach, keyBy, map, transform} from 'lodash';

import RackEditorStore from '../store/RackEditorStore';
import {RackEditorStoreProvider} from '../hooks/useRackEditorStore';
import TooltipDataProvider from '../../cablingMapEditor/components/TooltipDataProvider';
import {TooltipPopup, TooltipProvider} from '../../components/graphs/GraphTooltips';
import Canvas from './Canvas';
import {DESIGN, NODE_ROLES, rolesOrder} from '../const';

import './RackEditor.less';
import Summary from './Summary';
import Actions from './Actions';

const RackEditor = (props) => {
  const {
    id, name, description, design, devices = [], usedDevices = [], leafs, accessSwitches, genericSystems,
    preferences, tags, open, onClose, readonly
  } = props;

  const [snapped, setSnapping] = useState(true);

  const containerRef = useRef();
  const prevStoreRef = useRef();

  // Will be used for topology initialization from api payload
  // and upon changes cancellation by user
  const restoreFromProps = useCallback((store) => {
    const {rackStore} = store;
    rackStore.reset(
      id,
      name,
      description,
      design || DESIGN.L3CLOS,
      [
        ...(devices || []),
        // some logical devices might have already been deleted, thus
        // can be taken from the rack type itself but without the ability to be reused.
        // That's why they must be marked as custom and filtered out upon LD selection
        ...map(usedDevices, (device) => ({...device, custom: true}))
      ],
      map(tags, (tag) => tag?.label ?? tag)
    );

    setSnapping(true);

    const propMap = {
      [NODE_ROLES.LEAF]: leafs,
      [NODE_ROLES.ACCESS_SWITCH]: accessSwitches,
      [NODE_ROLES.GENERIC]: genericSystems
    };
    const nodesPreferences = keyBy(preferences?.positions, 'label');
    const switches = transform(
      compact(flatten([leafs, accessSwitches, genericSystems])),
      (acc, {label}) => {acc[label] = [];}
    );
    forEach(
      rolesOrder,
      (role) => rackStore.parseNodes(propMap[role], role, switches, nodesPreferences)
    );
  }, [leafs, accessSwitches, genericSystems]); // eslint-disable-line react-hooks/exhaustive-deps

  // Memoizing store
  const store = useMemo(() => {
    if (!prevStoreRef.current) {
      prevStoreRef.current = new RackEditorStore();
    }

    restoreFromProps(prevStoreRef.current);

    return prevStoreRef.current;
  }, [leafs, accessSwitches, genericSystems]); // eslint-disable-line react-hooks/exhaustive-deps

  const canvas = (
    <>
      <TooltipPopup />
      <Canvas {...{devices, snapped, readonly}} />
    </>
  );

  const content = readonly ?
    canvas :
    <Modal
      open={open}
      closeIcon
      onClose={onClose}
      size='fullscreen'
      closeOnDimmerClick={false}
    >
      <Modal.Header>{`${id ? 'Edit' : 'Create'} Rack Type`}</Modal.Header>
      <Modal.Content scrolling>
        <Grid>
          <Summary {...props} />
          <Grid.Row>
            <Grid.Column>
              {canvas}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Actions devices={devices} onClose={onClose} />
    </Modal>;

  return (
    <RackEditorStoreProvider value={store}>
      <TooltipProvider containerRef={containerRef}>
        <TooltipDataProvider
          nodes={[]}
          links={[]}
          aggregateLinks={[]}
        >
          {content}
        </TooltipDataProvider>
      </TooltipProvider>
    </RackEditorStoreProvider>
  );
};

export default observer(RackEditor);
