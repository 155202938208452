import {Component, createRef} from 'react';
import {observer} from 'mobx-react';
import {action, observable, makeObservable} from 'mobx';
import {createPopper} from '@popperjs/core';
import cx from 'classnames';

@observer
export default class TopologyTooltip extends Component {
  static defaultProps = {
    placement: 'top',
    arrowOffset: [0, 8]
  };

  popupRef = createRef();
  popper = null;
  @observable placement = '';

  @action
  setPlacement = (placement) => {
    this.placement = placement;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidUpdate() {
    this.popper.update();
  }

  componentDidMount() {
    const {props: {boundaryContainer, arrowOffset: [xArrowOffset, yArrowOffset], placement},
      popupRef, setPlacement} = this;
    const parentRect = boundaryContainer.getBoundingClientRect();
    this.popper = createPopper(
      {
        getBoundingClientRect: () => ({
          top: parentRect.y + this.props.y,
          left: parentRect.x + this.props.x,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1
        })
      },
      popupRef.current,
      {
        placement,
        strategy: 'absolute',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: () => [xArrowOffset, yArrowOffset]
            }
          },
          {
            name: 'flip',
            options: {
              boundary: boundaryContainer,
              altBoundary: true,
              flipVariations: false,
              fallbackPlacements: ['top', 'bottom', 'left', 'right']
            }
          },
          {
            name: 'applyStyle',
            enabled: true,
            fn: ({state: {placement}}) => setPlacement(placement),
            phase: 'write'
          },
        ]
      }
    );
  }

  componentWillUnmount() {
    if (this.popper) {
      this.popper.destroy();
    }
  }

  render() {
    const {props: {content}, placement, popupRef} = this;
    return (
      <div
        ref={popupRef}
        className={cx('tp-tooltip ui popup visible center', placement)}
      >
        <div className='popup-content'>
          {content}
        </div>
      </div>
    );
  }
}
