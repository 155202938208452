import {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {map, get, keys, find, head, size, intersection, isEmpty, filter} from 'lodash';
import HTTPStatus from 'http-status';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {computed, observable, action, makeObservable} from 'mobx';
import {Form} from 'semantic-ui-react';
import {Checkbox, FetchDataError, GenericErrors, ResourceDeletionModal,
  interpolateRoute, request} from 'apstra-ui-common';

import IBAContext from '../IBAContext';

@observer
export default class DashboardDeletionModal extends Component {
  static contextType = IBAContext;

  static propTypes = {
    open: PropTypes.bool,
    dashboards: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })),
    onSuccess: PropTypes.func,
    onClose: PropTypes.func,
  };

  @observable deleteResources = false;

  @action
  toggleDeleteResources = () => {
    this.deleteResources = !this.deleteResources;
  };

  @action
  resetState = () => {
    this.deleteResources = false;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get firstDashboardId() {
    return get(head(this.props.dashboards), ['id']);
  }

  @computed
  get batchDeletion() {
    return size(this.props.dashboards) > 1;
  }

  @computed
  get dashboardIds() {
    return map(this.props.dashboards, 'id');
  }

  getDashboardLabel = (dashboardId) => {
    return get(find(this.props.dashboards, {id: dashboardId}), ['label'], dashboardId);
  };

  deleteDashboard = () => request(
    interpolateRoute(this.context.routes.dashboardDetails,
      {blueprintId: this.context.blueprintId, dashboardId: this.firstDashboardId}
    ),
    {method: 'DELETE', queryParams: this.deleteResources ? {delete_resources: true} : {}}
  );

  deleteDashboards = () => request(
    interpolateRoute(this.context.routes.dashboardsBatchDelete, {blueprintId: this.context.blueprintId}),
    {
      method: 'POST',
      queryParams: this.deleteResources ? {delete_resources: true} : {},
      body: JSON.stringify(this.dashboardIds),
      throwOnHttpStatus: [HTTPStatus.MULTI_STATUS]
    }
  );

  submit = () => {
    return this.batchDeletion ? this.deleteDashboards() : this.deleteDashboard();
  };

  renderDeleteError = ({dashboardId, label, errors}) => {
    const {blueprintId} = this.context;

    const title = (
      <Fragment>
        {'Dashboard '}
        <b>
          <Link to={`/blueprints/${blueprintId}/analytics/dashboards/${dashboardId}`} style={{color: 'inherit'}}>
            {label}
          </Link>
        </b>
        {' was not deleted'}
      </Fragment>
    );

    return (
      <GenericErrors header={title} errors={errors} />
    );
  };

  renderBatchDeleteError = (response) => {
    const {renderBatchDeleteDashboardError, getDashboardLabel} = this;

    const dashboardIds = filter(keys(response), (key) => response[key].errors);

    if (dashboardIds.length === 1) {
      const firstDashboardId = dashboardIds[0];
      return this.renderDeleteError({
        dashboardId: firstDashboardId,
        label: getDashboardLabel(firstDashboardId),
        errors: map(response[firstDashboardId].errors, 'message')
      });
    }

    return (
      <GenericErrors
        header='Some dashboards were not deleted'
        errors={dashboardIds.map((dashboardId) =>
          renderBatchDeleteDashboardError({
            dashboardId,
            label: getDashboardLabel(dashboardId),
            errors: map(response[dashboardId].errors, 'message')
          }))}
      />
    );
  };

  renderBatchDeleteDashboardError = ({dashboardId, label, errors}) => {
    const {context: {blueprintId}} = this;
    return (
      <div>
        <b><Link to={`/blueprints/${blueprintId}/analytics/dashboards/${dashboardId}`}>{label}</Link></b>
        {map(errors, (error) => <div>{error}</div>)}
      </div>
    );
  };

  renderError = (error, props) => {
    const errorCanBeRendered = error.responseBody && (
      this.batchDeletion ?
        !isEmpty(intersection(keys(error.responseBody), this.dashboardIds)) :
        error.responseBody.errors
    );

    if (!errorCanBeRendered) {
      return <FetchDataError error={error} {...props} />;
    }

    if (!this.batchDeletion) {
      return this.renderDeleteError({
        dashboardId: this.firstDashboardId,
        label: this.getDashboardLabel(this.firstDashboardId),
        errors: error.responseBody.errors
      });
    }

    return this.renderBatchDeleteError(error.responseBody);
  };

  render() {
    const {batchDeletion, props: {dashboards}} = this;
    const dashboardLabels = map(dashboards, 'label');

    return (
      <ResourceDeletionModal
        resourceLabel={dashboardLabels[0]}
        resourceLabels={dashboardLabels}
        resourceName='Dashboard'
        resourceNamePlural='Dashboards'
        submit={this.submit}
        renderError={this.renderError}
        resetState={this.resetState}
        batchDeletion={batchDeletion}
        disableResubmission
        {...this.props}
      >
        {() =>
          <Form>
            <Checkbox
              label='Delete probes and widgets used in the dashboard'
              checked={this.deleteResources}
              onChange={this.toggleDeleteResources}
            />
          </Form>
        }
      </ResourceDeletionModal>
    );
  }
}
