import {useState, useRef, useEffect} from 'react';
import {observer} from 'mobx-react';
import {Form, Input, TextArea, Icon, Accordion, Tab, Label} from 'semantic-ui-react';
import {chain, castArray, trim} from 'lodash';
import {action} from 'mobx';
import {Field} from 'apstra-ui-common';

import EptParameters from './EptParameters';
import TagsControl from '../../components/TagsControl';
import {isNotVnType} from '../utils';

import './Parameters.less';

const Parameters = ({ept, allTags, readonly, vnOnly}) => {
  const [isExpanded, setExpanded] = useState(true);
  const activeEptRef = useRef();

  const activeId = ept.activePrimitiveId;
  useEffect(() => {
    if (activeId && activeEptRef.current) {
      activeEptRef.current.scrollIntoView({behavior: 'smooth'});
    }
  });

  const handleTagsChange = action((tags) => {
    ept.tags = tags;
  });

  const readonlyView = [
    <h5 key='title'>{ept.title}</h5>,
    <p key='description'>{ept.description}</p>,
    <div key='tags' className='tags'>
      {
        castArray(ept.tags).map((tag) => <Label
          key={tag}
          content={tag}
          size='small'
          icon='tag'
        />)
      }
    </div>
  ];

  const editableView = [
    <Accordion.Title
      key='title'
      as='button'
      active={isExpanded}
      onClick={() => setExpanded(!isExpanded)}
      aria-label='Summary'
    >
      <Icon name='dropdown' />
      {'Summary'}
    </Accordion.Title>,
    <Accordion.Content key='content' active={isExpanded}>
      <Field
        required
        label='Title'
        errors={!trim(ept.title) ? ['Title is required'] : []}
      >
        <Input
          value={ept.title}
          onChange={action((event) => {ept.title = event.target.value;})}
        />
      </Field>
      <Field label='Description'>
        <TextArea
          value={ept.description}
          onChange={action((event) => {ept.description = event.target.value;})}
        />
      </Field>
      <Field label='Tags'>
        <TagsControl
          knownTags={allTags}
          onChange={handleTagsChange}
          value={ept.tags}
        />
      </Field>
    </Accordion.Content>
  ];

  return (
    <Tab.Pane>
      <Form>
        <Accordion className='cts-parameters'>
          {readonly ? readonlyView : editableView}
          {chain(ept.epts)
            .filter((singleEpt) => singleEpt.id && singleEpt.parameters && Object.keys(singleEpt.parameters).length > 0)
            .sortBy('creationOrder')
            .map((singleEpt) => <EptParameters
              key={singleEpt.id}
              {...{
                ref: activeId === singleEpt.id ? activeEptRef : null,
                activeEpt: ept,
                ept: singleEpt,
                readonly: readonly || (vnOnly && isNotVnType({type: singleEpt.dbType}))
              }}
            />)
            .value()
          }
        </Accordion>
      </Form>
    </Tab.Pane>
  );
};

export default observer(Parameters);
