import {keys, map} from 'lodash';
import {useContext} from 'react';
import {Header, Icon, List, Popup} from 'semantic-ui-react';

import HighlightText from '../components/HighlightText';
import Graph from './Graph';
import GraphExplorerContext from './GraphExplorerContext';

import buildPropertyDocHTMLFromSchema from '../pythonExpression/buildPropertyDocHTMLFromSchema';

const GraphReferenceDesignSchema = () => {
  const {referenceDesignSchema, schemaGraphStore} = useContext(GraphExplorerContext);

  return (
    <Graph
      nodes={referenceDesignSchema.nodes}
      links={referenceDesignSchema.links}
      TooltipComponent={ReferenceDesignSchemaTooltip}
      store={schemaGraphStore}
    />
  );
};

const ReferenceDesignSchemaTooltip = ({
  highlightText,
  entity
}) => {
  const {id, nodeType, linkType, sourceEntity} = entity;
  const {properties, source_type: source, target_type: target} = sourceEntity;

  const propertyDescriptions = nodeType ? properties : properties.properties;
  const propertyNames = keys(propertyDescriptions)
    .filter((propName) => propertyDescriptions[propName].type !== undefined).sort();
  return (
    <List size='mini'>
      <List.Item>
        <Header as='h5'>
          <HighlightText
            text={linkType || id}
            highlight={highlightText}
          />
        </Header>
      </List.Item>
      {linkType &&
        <span>
          <HighlightText
            text={source}
            highlight={highlightText}
          />
          {' '}
          <Icon fitted name='long arrow alternate right' size='small' />
          {' '}
          <HighlightText
            text={target}
            highlight={highlightText}
          />
        </span>
      }
      {map(propertyNames,
        (propName) => (
          <Popup
            key={propName}
            trigger={
              <List.Item>
                <span className='property-name'>
                  {propName}
                </span>
              </List.Item>
            }
            position='right center'
            size='mini'
          >
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{__html: buildPropertyDocHTMLFromSchema(propertyDescriptions[propName])}}
            />
          </Popup>
        )
      )}
    </List>
  );
};

export default GraphReferenceDesignSchema;
