import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Grid, Table, Icon, Loader} from 'semantic-ui-react';
import {observable, action, reaction, makeObservable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import Dropzone from 'react-dropzone';
import {noop, isUndefined} from 'lodash';
import cx from 'classnames';
import {FetchDataError, formatNumber} from 'apstra-ui-common';

import './MultiUploadModal.less';

const UPLOAD_NOT_STARTED = 'UPLOAD_NOT_STARTED';
const UPLOAD_IN_PROGRESS = 'UPLOAD_IN_PROGRESS';
const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
const UPLOAD_ERROR = 'UPLOAD_ERROR';

@observer
export default class MultiUploadModal extends Component {
  static propTypes = {
    ...Modal.propTypes,
    uploadFile: PropTypes.func.isRequired,
    description: PropTypes.node,
    actionIcon: PropTypes.string,
    action: PropTypes.string,
    onUploadSuccess: PropTypes.func,
    renderError: PropTypes.func,
  };

  static defaultProps = {
    header: 'Upload Files',
    description: 'Drag and drop files here or click the button below.',
    actionIcon: 'upload',
    action: 'Upload',
    closeOnDimmerClick: false,
    renderError: (error) => <FetchDataError error={error} />,
  };

  @observable isModalOpen = false;
  @observable uploadState = UPLOAD_NOT_STARTED;
  files = observable.map({}, {deep: false});

  constructor(props) {
    super(props);

    makeObservable(this);

    this.disposeModalOpenReaction = reaction(
      () => this.props.open,
      (value) => {
        if (value) {
          this.openModal();
        } else {
          this.closeModal();
        }
      }
    );
  }

  componentWillUnmount() {
    this.disposeModalOpenReaction();
  }

  @action
  openModal = () => {
    this.isModalOpen = true;
    this.setInitialValues();
  };

  @action
  closeModal = () => {
    this.isModalOpen = false;
    if (this.props.onClose) this.props.onClose();
    this.abortController.abort();
  };

  @action
  setInitialValues() {
    this.uploadState = UPLOAD_NOT_STARTED;
    this.files.clear();
    this.abortController = new AbortController();
  }

  @action
  startUpload = () => {
    const {uploadFile, onUploadSuccess} = this.props;
    const {signal} = this.abortController;
    this.uploadState = UPLOAD_IN_PROGRESS;
    for (const file of this.files.keys()) {
      this.files.set(file, [UPLOAD_IN_PROGRESS, uploadFile({file, signal})]);
    }
    this.files.forEach(async ([, promise], file) => {
      try {
        await promise;
        runInAction(() => {
          this.files.set(file, [UPLOAD_SUCCESS]);
        });
      } catch (error) {
        if (!signal.aborted) {
          runInAction(() => {
            this.files.set(file, [UPLOAD_ERROR, error]);
          });
        }
      }
      if (onUploadSuccess && this.files.get(file)[0] === UPLOAD_SUCCESS) {
        onUploadSuccess();
      }
    });
  };

  @action
  addFiles = (files) => {
    for (const file of files) {
      this.files.set(file, [UPLOAD_NOT_STARTED]);
    }
  };

  @action
  removeFile = (file) => {
    this.files.delete(file);
  };

  render() {
    const {open, trigger, header, description, accept, action, actionIcon,
      closeOnDimmerClick, renderError} = this.props;
    const {uploadState, files} = this;
    const isControlled = !isUndefined(open);
    return (
      <Modal
        className='upload-modal'
        size='small'
        closeIcon
        open={this.isModalOpen}
        onClose={this.closeModal}
        closeOnDimmerClick={closeOnDimmerClick}
        trigger={isControlled ? trigger : React.cloneElement(trigger, {onClick: this.openModal})}
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          <Grid>
            {uploadState === UPLOAD_NOT_STARTED &&
              <Grid.Row key='upload-controls'>
                <Grid.Column textAlign='center'>
                  <Dropzone
                    multiple
                    noClick
                    accept={accept}
                    onDrop={this.addFiles}
                  >
                    {({getRootProps, getInputProps, open, isDragAccept, isDragReject}) => (
                      <div
                        className={cx('dropzone', {'drag-accept': isDragAccept, 'drag-reject': isDragReject})}
                        {...getRootProps({onClick: noop})}
                      >
                        <input {...getInputProps()} />
                        <div className='upload-description'>
                          {description}
                        </div>
                        <Button
                          positive
                          icon='file'
                          content='Choose Files'
                          onClick={open}
                        />
                      </div>
                    )}
                  </Dropzone>
                </Grid.Column>
              </Grid.Row>
            }
            {files.size !== 0 &&
              <Grid.Row key='file-list'>
                <Grid.Column>
                  <Table basic='very'>
                    <Table.Body>
                      {[...files.entries()].map(([file, [state, error]], index) => [
                        <Table.Row key={`${file.name} ${index} file`}>
                          <Table.Cell>
                            <Icon name='file' />
                            {file.name}
                          </Table.Cell>
                          <Table.Cell>
                            {formatNumber(file.size, {units: 'B', short: true})}
                          </Table.Cell>
                          <Table.Cell textAlign='right'>
                            {state === UPLOAD_NOT_STARTED ?
                              <Icon
                                link
                                name='remove'
                                size='large'
                                color='red'
                                onClick={() => this.removeFile(file)}
                                aria-label='Remove'
                              />
                            : state === UPLOAD_IN_PROGRESS ?
                              <Loader active inline size='small' />
                            : state === UPLOAD_ERROR ?
                              <Icon name='exclamation circle' size='large' color='red' />
                            : state === UPLOAD_SUCCESS ?
                              <Icon name='check circle' size='large' color='green' />
                            :
                              null
                            }
                          </Table.Cell>
                        </Table.Row>,
                        state === UPLOAD_ERROR &&
                          <Table.Row key={`${file.name} ${index} error`} className='upload-error'>
                            <Table.Cell colSpan={3}>
                              {renderError(error)}
                            </Table.Cell>
                          </Table.Row>
                      ])}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            }
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            size='large'
            icon={actionIcon}
            content={action}
            disabled={!(uploadState === UPLOAD_NOT_STARTED && files.size !== 0)}
            onClick={this.startUpload}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
