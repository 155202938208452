import {Component} from 'react';
import {observer} from 'mobx-react';
import {ActionsMenu, hasBlueprintPermissions} from 'apstra-ui-common';

import IBAContext from '../IBAContext';

@observer
export default class WidgetActions extends Component {
  static contextType = IBAContext;

  render() {
    const {blueprintPermissions} = this.context;
    const {widget, setWidgetModalProps, setWidgetDeletionModalProps, ...props} = this.props;
    const hasPermissions = hasBlueprintPermissions({blueprintPermissions, action: 'edit'});
    return <ActionsMenu
      {...props}
      items={[
        {
          icon: 'edit',
          title: 'Edit',
          hasPermissions,
          notPermittedMessage: 'You do not have permission to edit',
          onClick: () => setWidgetModalProps({open: true, mode: 'update', widget}),
        },
        {
          icon: 'clone',
          title: 'Clone',
          hasPermissions,
          notPermittedMessage: 'You do not have permission to clone',
          onClick: () => setWidgetModalProps({open: true, mode: 'clone', widget}),
        },
        {
          icon: 'trash',
          title: 'Delete',
          hasPermissions,
          notPermittedMessage: 'You do not have permission to delete',
          onClick: () => setWidgetDeletionModalProps({open: true, widgets: [widget]}),
        },
      ]}
    />;
  }
}
