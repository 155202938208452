import {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {observable, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Grid, Breadcrumb} from 'semantic-ui-react';
import {FetchData, interpolateRoute, request, withRouter} from 'apstra-ui-common';

import {descriptionRenderer} from '../descriptionRenderer';
import WidgetDeletionModal from './WidgetDeletionModal';
import Widget, {widgetComponentsByType} from './Widget';
import WidgetModal from './WidgetModal';
import WidgetActions from './WidgetActions';
import UpdatedByLabel from './UpdatedByLabel';
import IBAContext from '../IBAContext';

@withRouter
@observer
export default class WidgetDetails extends Component {
  static contextType = IBAContext;

  static pollingInterval = 10000;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static async fetchData({blueprintId, widgetId, routes, signal}) {
    const [widget, {items: probes}] = await Promise.all([
      request(interpolateRoute(routes.widgetDetails, {blueprintId, widgetId})),
      request(interpolateRoute(routes.probeList, {blueprintId}), {signal}),
    ]);
    const fetchWidgetData = widgetComponentsByType[widget.type].fetchData;
    const widgetData = fetchWidgetData ?
      await fetchWidgetData({widget, probes, blueprintId, routes, signal})
    :
      null;
    return {widget, widgetData, probes};
  }

  @observable.ref widgetModalProps = null;
  @observable.ref widgetDeletionModalProps = null;

  @action
  setWidgetModalProps = (props) => {
    this.widgetModalProps = props;
  };

  @action
  setWidgetDeletionModalProps = (props) => {
    this.widgetDeletionModalProps = props;
  };

  render() {
    const {blueprintId, routes} = this.context;
    const {params: {widgetId}} = this.props;
    const {widgetModalProps, setWidgetModalProps, widgetDeletionModalProps, setWidgetDeletionModalProps} = this;
    return (
      <FetchData
        fetchData={WidgetDetails.fetchData}
        fetchParams={{blueprintId, widgetId, routes}}
        pollingInterval={WidgetDetails.pollingInterval}
      >
        {({widget, widgetData, probes, refetchData}) =>
          <Grid stackable>
            <Grid.Column width={14} verticalAlign='middle'>
              <Breadcrumb
                size='big'
                icon='right caret'
                sections={[
                  {key: 'home', content: 'Widgets', as: Link, to: `/blueprints/${blueprintId}/analytics/widgets`},
                  {key: 'label', active: true, content: (
                    <Fragment>
                      {widget.label}
                      &nbsp;
                      <UpdatedByLabel updatedBy={widget.updated_by} timestamp={widget.updated_at} />
                    </Fragment>
                  )}
                ]}
              />
            </Grid.Column>
            <Grid.Column width={2} textAlign='right'>
              <WidgetActions
                widget={widget}
                setWidgetModalProps={setWidgetModalProps}
                setWidgetDeletionModalProps={setWidgetDeletionModalProps}
              />
              <WidgetModal
                open={false}
                onClose={() => setWidgetModalProps({open: false})}
                onSuccess={() => refetchData()}
                probes={probes}
                {...widgetModalProps}
              />
              <WidgetDeletionModal
                open={false}
                onClose={() => setWidgetDeletionModalProps({open: false})}
                onSuccess={() => this.props.navigate(`/blueprints/${blueprintId}/analytics/widgets`)}
                {...widgetDeletionModalProps}
              />
            </Grid.Column>
            {widget.description &&
              <Grid.Column width={16}>
                {descriptionRenderer.renderValueWithoutCondition({value: widget.description})}
              </Grid.Column>
            }
            <Grid.Column width={16}>
              <Widget
                widget={widget}
                widgetData={widgetData}
              />
            </Grid.Column>
          </Grid>
        }
      </FetchData>
    );
  }
}
