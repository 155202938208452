import {Component} from 'react';
import {Link} from 'react-router-dom';
import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {find} from 'lodash';
import {DEFAULT_PAGE_SIZE, GenericErrors, interpolateRoute, request} from 'apstra-ui-common';

import {queryParamToSorting} from '../../queryParamUtils';
import generateProbeURI from '../generateProbeURI';
import {
  getStageQueryParamsFromWidget, getStageRenderingStrategy,
  castFilterConverter, getStageComponentPropsFromWidget
} from '../stageUtils';
import ProbeStage from './ProbeStage';
import IBAContext from '../IBAContext';

@observer
export default class StageWidget extends Component {
  static contextType = IBAContext;

  static pollingInterval = ProbeStage.pollingInterval;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static async fetchData({widget, probes, blueprintId, routes, blueprintPermissions, signal}) {
    if (!probes) {
      probes = [
        await request(interpolateRoute(routes.probeDetails, {blueprintId, probeId: widget.probe_id}), {signal})
      ];
    }
    const {probe, processor, stage} = getStageComponentPropsFromWidget({widget, probes});
    const renderingStrategy = getStageRenderingStrategy({
      probe, processor, stage,
      dataSource: widget.data_source,
      usePattern: widget.show_context
    });
    if (stage) {
      const activePage = 1;
      const pageSize = widget.max_items ?? DEFAULT_PAGE_SIZE;
      const filters = {
        filter: (renderingStrategy?.filterDeserializer || castFilterConverter)(widget.filter),
        anomalousOnly: widget.anomalous_only ?? false,
        spotlightMode: widget.spotlight_mode ?? false,
        showContextInfo: widget.show_context ?? false,
        dataSource: widget.data_source ?? null,
        timeSeriesDuration: widget.time_series_duration,
        timeSeriesAggregation: widget.aggregation_period,
        aggregationType: widget.aggregation_type,
      };
      const sorting = queryParamToSorting(widget.orderby);
      return {
        probes,
        stageData: widget.spotlight_mode ? null : await ProbeStage.fetchData({
          blueprintId, probe, processor, stage,
          activePage, pageSize, filters, sorting,
          routes, blueprintPermissions, signal
        })
      };
    } else {
      return {};
    }
  }

  @computed
  get valueColumnName() {
    const {widget, widgetData: {probes}} = this.props;
    const {stage} = getStageComponentPropsFromWidget({widget, probes});
    return stage ? find(widget.visible_columns, (name) => name in stage.values) : null;
  }

  @computed
  get renderingStrategy() {
    const {widget, widgetData: {probes}} = this.props;
    const {probe, processor, stage} = getStageComponentPropsFromWidget({widget, probes});
    return getStageRenderingStrategy({
      probe, processor, stage,
      dataSource: widget.data_source,
      usePattern: widget.show_context
    });
  }

  render() {
    const {blueprintId} = this.context;
    const {widget, widgetData: {stageData, probes}} = this.props;
    const {valueColumnName} = this;
    const {probe, processor, stage} = getStageComponentPropsFromWidget({widget, probes});
    if (!stage) {
      return (
        <GenericErrors
          header='Invalid stage'
          errors='The stage does not exist'
        />
      );
    }
    return (
      <ProbeStage
        probe={probe}
        processor={processor}
        stage={stage}
        stageData={widget.spotlight_mode ? null : stageData}
        visibleColumns={widget.visible_columns}
        filter={(this.renderingStrategy?.filterDeserializer || castFilterConverter)(widget.filter)}
        anomalousOnly={widget.anomalous_only}
        spotlightMode={widget.spotlight_mode}
        showContextInfo={widget.show_context}
        dataSource={widget.data_source}
        timeSeriesDuration={widget.time_series_duration}
        aggregationType={widget.aggregation_type}
        timeSeriesAggregation={widget.aggregation_period}
        combineGraphs={widget.combine_graphs}
        valueColumnName={valueColumnName}
        compact
        stageDataTableFooterContent={
          <Link
            to={{
              pathname: generateProbeURI({blueprintId, probeId: probe.id, stageName: stage.name}),
              search: getStageQueryParamsFromWidget({
                widget,
                stage,
                alwaysUseContext: this.renderingStrategy?.alwaysUseContext,
                filterDeserializer: this.renderingStrategy?.filterDeserializer
              }),
            }}
          >
            {'View stage'}
          </Link>
        }
      />
    );
  }
}
