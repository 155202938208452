import {Component, Fragment, createRef} from 'react';
import {observable, computed, action, toJS, makeObservable, runInAction, autorun} from 'mobx';
import {observer, Observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {Grid, Form, Button, Message, Icon, Label} from 'semantic-ui-react';
import {
  map, mapValues, groupBy, find, filter, last, isMatch, isPlainObject,
  castArray, pullAllWith, chunk, times, isEqual, isEmpty
} from 'lodash';
import {FetchDataError, FormFragment, RadioGroupInput,
  createValueRenderer, interpolateRoute, request, withRouter} from 'apstra-ui-common';

import {descriptionRenderer} from '../descriptionRenderer';
import Dashboard, {generateDashboardURI} from './Dashboard';
import DashboardDeletionModal from './DashboardDeletionModal';
import UpdatedByLabel from './UpdatedByLabel';
import IBAContext from '../IBAContext';
import DashboardCaption from './DashboardCaption';
import PredefinedEntityIcon from './PredefinedEntityIcon';

import './DashboardDetails.less';

function calculateNewGrid(value, newColumnNumber) {
  const oldColumnNumber = value.length;
  if (oldColumnNumber > newColumnNumber) {
    times(oldColumnNumber - newColumnNumber, () => {
      const widgets = value.pop();
      last(value).push(...widgets);
    });
  } else if (newColumnNumber > oldColumnNumber) {
    times(newColumnNumber - oldColumnNumber, () => {
      value.push([]);
    });
  }
  return value;
}
const layoutPickerRenderer = createValueRenderer({
  condition: ({name}) => name === 'grid',
  renderValueInput({name, value, schema, required, disabled, onChange}) {
    return (
      <Observer>
        {() =>
          <RadioGroupInput
            name={name}
            value={value.length}
            schema={schema}
            required={required}
            disabled={disabled}
            onChange={(newColumnNumber) => onChange(calculateNewGrid([...value], newColumnNumber))}
          />
        }
      </Observer>
    );
  }
});

@withRouter
@observer
export default class DashboardDetails extends Component {
  static contextType = IBAContext;

  static pollingInterval = 10000;

  static defaultProps = {
    dashboardPropertiesSchema: [
      {
        name: 'label',
        required: true,
        schema: {title: 'Name', type: 'string'}
      },
      {
        name: 'grid',
        schema: {
          title: 'Layout',
          description: 'Dashboards may have up to three columns for widgets.',
          type: 'number',
          oneOf: [
            {const: 1, title: 'One-column'},
            {const: 2, title: 'Two-column'},
            {const: 3, title: 'Three-column'},
          ]
        }
      },
      {
        name: 'description',
        schema: {title: 'Description', type: 'string'}
      },
      {
        name: 'default',
        appearance: 'toggle',
        schema: {
          type: 'boolean',
          title: 'Default',
          description: "Default analytics dashboard will be shown on the blueprint's dashboard.",
        }
      },
    ]
  };

  @observable isDashboardDirty = false;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.disposeDirtyChecker = autorun(this.checkDashboardModification);
  }

  componentWillUnmount() {
    this.disposeDirtyChecker();
  }

  static async fetchData({blueprintId, dashboardId, routes, signal}) {
    const [dashboard, {items: probes}, {items: widgets}, {items: predefinedDashboards}] = await Promise.all([
      dashboardId ? request(interpolateRoute(routes.dashboardDetails, {blueprintId, dashboardId}, {signal})) : null,
      request(interpolateRoute(routes.probeList, {blueprintId}), {signal}),
      request(interpolateRoute(routes.widgetList, {blueprintId}), {signal}),
      request(interpolateRoute(routes.predefinedDashboardList, {blueprintId}), {signal}),
    ]);
    return {dashboard, widgets, probes, predefinedDashboards};
  }

  @action
  submit = async ({action = 'create', redirect = true} = {}) => {
    const {blueprintId, routes} = this.context;
    const {navigate, dashboardId} = this.props;
    this.currentActionInProgress = action;
    this.errors.length = 0;
    try {
      const {id: newDashboardId} = await request(
        action !== 'update' ?
          interpolateRoute(routes.dashboardList, {blueprintId})
        :
          interpolateRoute(routes.dashboardDetails, {blueprintId, dashboardId}),
        {method: action !== 'update' ? 'POST' : 'PUT', body: JSON.stringify(toJS(this.dashboard))}
      );
      if (redirect) {
        navigate(generateDashboardURI({blueprintId, dashboardId: newDashboardId}));
      }
    } catch (error) {
      runInAction(() => {
        this.currentActionInProgress = null;
        const {response, responseBody} = error;
        if (response && response.status === 422 && responseBody) {
          this.errors = this.processErrors(responseBody);
          this.propertiesFormRef?.current?.scrollIntoView({block: 'start', behavior: 'smooth'});
        } else {
          this.errors.push({type: 'http', error});
        }
      });
    }
  };

  @computed get dashboard() {
    return this.props.action ? this.currentDashboard : this.props.dashboard;
  }

  @computed get isDashboardValid() {
    return true;
  }

  @computed get isDashboardPredefined() {
    return !isEmpty(this.props.dashboard.predefined_dashboard);
  }

  @computed get httpError() {
    return find(this.errors, {type: 'http'});
  }

  @computed get propertyErrorMessages() {
    return mapValues(
      groupBy(filter(this.errors, {type: 'property'}), 'propertyName'),
      (errors) => map(errors, 'message')
    );
  }

  processErrors({errors}) {
    const result = [];
    function addError(messages, generateError) {
      result.push(...castArray(messages).map((message) => generateError(message)));
    }
    if (isPlainObject(errors)) {
      for (const propertyName of ['label', 'description']) {
        if (propertyName in errors) {
          addError(errors[propertyName], (message) => ({type: 'property', propertyName, message}));
        }
      }
    }
    return result;
  }

  @action
  setDashboardAsDirty = (isDirty) => {
    this.isDashboardDirty = isDirty;
  };

  @action
  revertDashboard = () => {
    this.currentDashboard = this.getNormalizedDashboard();
    this.setDashboardAsDirty(false);
  };

  @action
  setDashboardPropertyValue = (propertyName, value) => {
    this.dashboard[propertyName] = value;
    pullAllWith(this.errors, [{type: 'property', propertyName}], isMatch);
  };

  @action
  setDashboardDeletionModalProps = (props) => {
    this.dashboardDeletionModalProps = props;
  };

  checkDashboardModification = () => {
    if (
      this.props.action === 'update' &&
      !this.isDashboardDirty &&
      !isEqual(this.dashboard.grid, this.props.dashboard.grid)
    ) {
      this.setDashboardAsDirty(true);
    }
  };

  getNormalizedDashboard = () => {
    return !this.props.dashboardId ? {
      label: '',
      description: '',
      default: false,
      grid: [[]],
    } : {
      label: this.props.dashboard.label,
      description: this.props.dashboard.description,
      default: this.props.dashboard.default,
      grid: toJS(this.props.dashboard.grid),
    };
  };

  @observable currentDashboard = this.getNormalizedDashboard();
  @observable.ref dashboardDeletionModalProps = null;
  @observable currentActionInProgress = null;
  @observable.shallow errors = [];

  propertiesFormRef = createRef();

  renderBreadcrumbSections() {
    const {blueprintId} = this.context;
    const {dashboardId, action, predefinedDashboards} = this.props;
    const {dashboard} = this;
    const result = [{key: 'home', content: 'Dashboards', as: Link, to: generateDashboardURI({blueprintId})}];
    if (!dashboardId) {
      result.push({key: 'label', active: true, content: 'New Dashboard'});
    } else if (action) {
      const currentLabel = this.props.dashboard.label;
      result.push(
        {key: 'label', content: currentLabel, as: Link, to: generateDashboardURI({blueprintId, dashboardId})},
        {key: 'action', active: true, content: action === 'update' ? 'Edit' : 'Clone'}
      );
    } else {
      result.push({
        key: 'label',
        active: true,
        content: (
          <Fragment>
            {dashboard.label}
            &nbsp;
            {dashboard.predefined_dashboard &&
              <PredefinedEntityIcon
                predefinedEntities={predefinedDashboards}
                predefinedEntityName={dashboard.predefined_dashboard}
                predefinedEntityType='Predefined dashboard'
                trigger={<Label icon='box' />}
              />
            }
            &nbsp;
            <UpdatedByLabel updatedBy={dashboard.updated_by} timestamp={dashboard.updated_at} />
          </Fragment>
        )
      });
    }
    return result;
  }

  render() {
    const {
      action, dashboardId,
      dashboardPropertiesSchema,
      widgets, probes,
      refetchData,
      navigate,
    } = this.props;
    const {blueprintId} = this.context;
    const {
      dashboard,
      dashboardDeletionModalProps,
      setDashboardDeletionModalProps,
    } = this;
    return (
      <Grid className='dashboard-details'>
        <DashboardCaption
          action={action}
          breadcrumbSections={this.renderBreadcrumbSections()}
          dashboard={dashboard}
          setDashboardDeletionModalProps={setDashboardDeletionModalProps}
        />
        {action &&
          <Grid.Row>
            <Grid.Column>
              <a ref={this.propertiesFormRef} />
              <Form className='dashboard-properties'>
                {chunk(dashboardPropertiesSchema, 2).map((schemaChunk, index) =>
                  <Form.Group key={index} widths='equal'>
                    <FormFragment
                      schema={schemaChunk}
                      values={dashboard}
                      renderers={[
                        descriptionRenderer.renderValueInput,
                        layoutPickerRenderer.renderValueInput,
                      ]}
                      errors={this.propertyErrorMessages}
                      disabled={!!this.currentActionInProgress}
                      onChange={this.setDashboardPropertyValue}
                    />
                  </Form.Group>
                )}
              </Form>
            </Grid.Column>
          </Grid.Row>
        }
        <Grid.Row>
          <Grid.Column>
            <Dashboard
              editable={!!action}
              dashboard={dashboard}
              widgets={widgets}
              probes={probes}
              refetchData={refetchData}
            />
          </Grid.Column>
        </Grid.Row>
        {this.httpError &&
          <Grid.Row>
            <Grid.Column>
              <FetchDataError error={this.httpError.error} />
            </Grid.Column>
          </Grid.Row>
        }
        {action === 'update' && this.isDashboardPredefined && this.isDashboardDirty &&
          <Grid.Row>
            <Grid.Column>
              <Message icon warning>
                <Icon name='warning sign' />
                <Message.Content>
                  <Message.Header>{'Modification of Predefined Dashboard'}</Message.Header>
                  <p>
                    {'After saving the changes, it won\'t be possible to edit '}
                    {'this dashboard as a predefined dashboard.'}
                  </p>
                  <Button
                    icon='undo'
                    content='Revert Dashboard Changes'
                    onClick={this.revertDashboard}
                  />
                </Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
        }
        {action &&
          <Grid.Row>
            <Grid.Column textAlign='center'>
              {dashboardId ?
                <Fragment>
                  <Button
                    primary size='large'
                    disabled={!!this.currentActionInProgress || !this.isDashboardValid}
                    loading={this.currentActionInProgress === 'update'}
                    onClick={() => this.submit({action})}
                    content={action === 'update' ? 'Update Dashboard' : 'Create Dashboard'}
                  />
                  <Button
                    className='ghost'
                    size='large'
                    disabled={!!this.currentActionInProgress}
                    as={Link}
                    to={generateDashboardURI({blueprintId, dashboardId})}
                    content='Cancel'
                  />
                </Fragment>
              :
                <Button
                  primary size='large'
                  disabled={!!this.currentActionInProgress || !this.isDashboardValid}
                  loading={this.currentActionInProgress === 'create'}
                  onClick={() => this.submit({action: 'create'})}
                  content='Create Dashboard'
                />
              }
            </Grid.Column>
          </Grid.Row>
        }
        <DashboardDeletionModal
          open={false}
          onClose={() => setDashboardDeletionModalProps({open: false})}
          onSuccess={() => navigate(generateDashboardURI({blueprintId}))}
          {...dashboardDeletionModalProps}
        />
      </Grid>
    );
  }
}
