import {Tab, Menu} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import cx from 'classnames';
import {map} from 'lodash';

import Parameters from './Parameters';
import Catalog from './Catalog';
import {useStore} from '../store/useStore';
import './SidePanel.less';

const makeAccessible = (title) => {
  return (
    <Menu.Item
      key={title}
      as='button'
    >
      {title}
    </Menu.Item>
  );
};

const SidePanel = ({
  epts, eptSchemas, globalCatalog, sausager, allTags, readonly, vnOnly
}) => {
  const {activeEpt} = useStore();
  const parameters = <Parameters {...{ept: activeEpt, allTags, readonly, vnOnly}} />;
  const classes = cx(
    'ct-side-panel',
    {
      'as-tabs': !readonly
    }
  );

  const panes = map(
    {
      Parameters: () => parameters,
      Primitives: () => <Catalog items={eptSchemas} isPrimitive sausager={sausager} vnOnly={vnOnly} />,
      'User-defined': () => <Catalog items={epts} sausager={sausager} vnOnly={vnOnly} />,
      'Pre-defined': () => <Catalog items={globalCatalog} isPredefined sausager={sausager} vnOnly={vnOnly} />
    },
    (render, title) => ({menuItem: makeAccessible(title), render})
  );

  return readonly ?
    <div className={classes}>{parameters}</div> :
    <Tab
      className={classes}
      panes={panes}
      activeIndex={activeEpt.sidePanelTab}
      onTabChange={(event, {activeIndex}) => activeEpt.switchSidePanelTab(activeIndex)}
    />;
};

export default observer(SidePanel);
