import {Component} from 'react';
import {observer} from 'mobx-react';
import {Popup} from 'semantic-ui-react';
import {map} from 'lodash';

import {WIDGET_TYPES} from '../consts';
import './DashboardPreview.less';

@observer
export default class DashboardPreview extends Component {
  render() {
    const {grid, widgets} = this.props;
    return (
      <div className='iba-dashboard-preview'>
        {map(grid, (column, columnIndex) =>
          <div key={columnIndex} className='iba-dashboard-preview-column'>
            {map(column, (widgetId) => {
              const widget = widgets[widgetId];
              if (!widget) return null;
              return (
                <Popup
                  key={widgetId}
                  trigger={
                    <div className='iba-widget-preview'>
                      <div>
                        <div className='widget-label'>
                          {widget.label}
                        </div>
                        <div className='widget-type'>
                          {WIDGET_TYPES[widget.type] ?? widget.type}
                        </div>
                      </div>
                    </div>
                  }
                  size='small'
                  content={widget.label}
                  position='top center'
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}
