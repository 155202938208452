export const SHOW_DROPDOWN_LABELS = {
  BLUEPRINTS: 'Blueprints',
  ALL_BLUEPRINTS: 'All Blueprints',
};

export const APSTRA_CENTRAL_CATEGORIES = {
  INFRASTRUCTURE: 'INFRASTRUCTURE',
  NETWORK_SERVICES: 'NETWORK_SERVICES',
  MANAGEMENT_SERVICES: 'MANAGEMENT_SERVICES',
  UNCATEGORIZED: 'UNCATEGORIZED',
};

export const APSTRA_CENTRAL_CATEGORIES_LABELS = {
  [APSTRA_CENTRAL_CATEGORIES.INFRASTRUCTURE]: 'Infrastructure Health',
  [APSTRA_CENTRAL_CATEGORIES.NETWORK_SERVICES]: 'Network Services Health',
  [APSTRA_CENTRAL_CATEGORIES.MANAGEMENT_SERVICES]: 'Management Services Health',
  [APSTRA_CENTRAL_CATEGORIES.UNCATEGORIZED]: 'Uncategorized Anomalies',
};

export const PREDEFINED_BLUEPRINT_ANOMALY_CATEGORIES = [
  APSTRA_CENTRAL_CATEGORIES.INFRASTRUCTURE,
  APSTRA_CENTRAL_CATEGORIES.NETWORK_SERVICES,
  APSTRA_CENTRAL_CATEGORIES.UNCATEGORIZED,
];

export const PREDEFINED_REMOTE_CONTROLLER_ANOMALY_CATEGORIES = [
  APSTRA_CENTRAL_CATEGORIES.MANAGEMENT_SERVICES,
];

export const CATEGORY_URL = {
  INFRASTRUCTURE: 'infrastructure-health',
  NETWORK_SERVICES: 'network-services-health',
  MANAGEMENT_SERVICES: 'management-services-health',
  UNCATEGORIZED: 'uncategorized-anomalies',
};

export const URL_TO_CATEGORY_TYPE = {
  [CATEGORY_URL.INFRASTRUCTURE]: APSTRA_CENTRAL_CATEGORIES.INFRASTRUCTURE,
  [CATEGORY_URL.NETWORK_SERVICES]: APSTRA_CENTRAL_CATEGORIES.NETWORK_SERVICES,
  [CATEGORY_URL.MANAGEMENT_SERVICES]: APSTRA_CENTRAL_CATEGORIES.MANAGEMENT_SERVICES,
  [CATEGORY_URL.UNCATEGORIZED]: APSTRA_CENTRAL_CATEGORIES.UNCATEGORIZED,
};

export const CATEGORY_DESCRIPTION = {
  /* eslint-disable max-len */
  [APSTRA_CENTRAL_CATEGORIES.INFRASTRUCTURE]: 'Summarizes the health of the physical infrastructure (report hardware issues such as optics degradation, sustained high temp, interface down, etc).',
  [APSTRA_CENTRAL_CATEGORIES.NETWORK_SERVICES]: 'Summarizes the health of network services (anomalies impacting Routing Zone, Virtual Networks, VN endpoints services).',
  [APSTRA_CENTRAL_CATEGORIES.MANAGEMENT_SERVICES]: 'Summarizes the health of Apstra platform related issues.',
  [APSTRA_CENTRAL_CATEGORIES.UNCATEGORIZED]: 'Report all other IBA anomalies that are not part of any predefined category.',
  /* eslint-enable max-len */
};

export const ISSUE_LEVEL = {
  CRITICAL: 'critical',
  MAJOR: 'major',
  WARNINGS: 'warnings',
};

export const ISSUE_COLOR = {
  [ISSUE_LEVEL.CRITICAL]: 'red',
  [ISSUE_LEVEL.MAJOR]: 'orange', // TODO (vkniazeva): change to 'yellow' when brandPallete.less is extended
  [ISSUE_LEVEL.WARNINGS]: 'blue',
};

export const ISSUE_ICON = {
  [ISSUE_LEVEL.CRITICAL]: 'warning circle',
  [ISSUE_LEVEL.MAJOR]: 'exclamation triangle',
  [ISSUE_LEVEL.WARNINGS]: 'info circle',
};

const MINUTE_IN_SECONDS = 60;
export const HOUR_IN_SECONDS = MINUTE_IN_SECONDS * 60;

export const TIME_PERIODS_DROPDOWN_OPTIONS = [
  {text: '1 Minutes', value: MINUTE_IN_SECONDS},
  {text: '2 Minutes', value: MINUTE_IN_SECONDS * 2},
  {text: '5 Minutes', value: MINUTE_IN_SECONDS * 5},
  {text: '10 Minutes', value: MINUTE_IN_SECONDS * 10},
  {text: '30 Minutes', value: MINUTE_IN_SECONDS * 30},
  {text: '1 Hour', value: HOUR_IN_SECONDS},
  {text: '2 Hours', value: HOUR_IN_SECONDS * 2},
  {text: '6 Hours', value: HOUR_IN_SECONDS * 6},
  // {text: '12 Hours', value: HOUR_IN_SECONDS * 12},
  // {text: '1 Day', value: HOUR_IN_SECONDS * 24},
];

export const BLUEPRINT_DESIGN_TYPE = {
  COLLAPSED: 'Collapsed',
  THREE_STAGE: '3-stage',
  FIVE_STAGE: '5-stage',
  FREEFORM: 'Freeform',
};

export const DEVICES_HEALTH_ISSUE_LEVEL = {
  ...ISSUE_LEVEL,
  HEALTHY: 'healthy',
};

export const DEVICES_HEALTH_ISSUE_COLOR = {
  ...ISSUE_COLOR,
  [DEVICES_HEALTH_ISSUE_LEVEL.HEALTHY]: 'green',
};

export const DEVICE_ISSUE_ICON = {
  ...ISSUE_ICON,
  [DEVICES_HEALTH_ISSUE_LEVEL.HEALTHY]: 'check',
};

export const GETTING_STARTED_GUIDE_KEY = 'central-getting-started-guide';
