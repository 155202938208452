import {Component} from 'react';
import {observer} from 'mobx-react';
import {observable, action, makeObservable} from 'mobx';
import {uniq, transform, castArray} from 'lodash';
import {Field, StringListDropdownControl} from 'apstra-ui-common';

import PythonExpressionParser from '../../pythonExpression/PythonExpressionParser';
import NamedNodeFinder from '../../pythonExpression/NamedNodeFinder';

@observer
export default class QueryGroupByInput extends Component {
  @observable.shallow knownItems = [];

  @action
  updateKnownItems = () => {
    this.knownItems = uniq(transform(castArray(this.props.values.graph_query), (result, text) => {
      if (text) {
        const {cst, lexErrors, parseErrors} = PythonExpressionParser.parse(text);
        if (!lexErrors.length && !parseErrors.length) {
          result.push(...NamedNodeFinder.run(cst));
        }
      }
    }, []));
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {name, value, required, schema, disabled, errors, onChange} = this.props;
    const {knownItems, updateKnownItems} = this;

    return (
      <Field
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors}
      >
        <StringListDropdownControl
          allowAdditions
          noResultsMessage='Start typing to add a new name'
          placeholder='No names specified'
          value={value}
          knownItems={knownItems}
          onChange={onChange}
          onOpen={updateKnownItems}
        />
      </Field>
    );
  }
}
