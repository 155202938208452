import {Component} from 'react';
import {action, computed, observable, set, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Popup, Header, Message, Label} from 'semantic-ui-react';
import {keys, find, findIndex, filter, forEach, pullAllWith, isMatch, values} from 'lodash';
import {ActionsMenu} from 'apstra-ui-common';

import ProcessorInputEditor from './ProcessorInputEditor';
import ProcessorProperties from './ProcessorProperties';
import ProcessorModal from './ProcessorModal';
import ProcessorInputs from './ProcessorInputs';
import IBAContext from '../IBAContext';
import ProcessorIcon from './ProcessorIcon';

import './ProbeProcessor.less';

export function deleteProcessor({probe, processor, errors, setCurrentProcessorName}) {
  let previousProcessorName = null;
  forEach(probe.processors, ({name}, index) => {
    if (name === processor.name) {
      probe.processors.splice(index, 1);
      return false;
    }
    previousProcessorName = name;
  });
  pullAllWith(errors, [{processorName: processor.name}], isMatch);
  forEach(processor.outputs, (name) => {
    pullAllWith(probe.stages, [{name}], isMatch);
    pullAllWith(errors, [{stageName: name}], isMatch);
  });
  const removedStages = new Set(values(processor.outputs));
  for (const {inputs} of probe.processors) {
    for (const input of keys(inputs)) {
      if (removedStages.has(inputs[input]?.stage)) {
        set(inputs, input, {});
      }
    }
  }
  const nextProcessorName = previousProcessorName === null ?
    probe.processors.length ? probe.processors[0].name : null :
    previousProcessorName;
  setCurrentProcessorName(nextProcessorName);
}

@observer
export default class ProbeProcessor extends Component {
  static contextType = IBAContext;

  static defaultProps = {
    errors: [],
  };

  @observable processorModalMode = null;

  @action
  openProcessorModalWithMode = (mode) => {
    this.processorModalMode = mode;
  };

  @action
  closeProcessorModal = () => {
    this.processorModalMode = null;
  };

  @action
  deleteProcessor = () => deleteProcessor(this.props);

  @action
  moveProcessorUp = () => {
    const {processorIndex, props: {probe, processor}} = this;
    const otherProcessor = probe.processors[processorIndex - 1];
    probe.processors[processorIndex - 1] = processor;
    probe.processors[processorIndex] = otherProcessor;
  };

  @action
  moveProcessorDown = () => {
    const {processorIndex, props: {probe, processor}} = this;
    const otherProcessor = probe.processors[processorIndex + 1];
    probe.processors[processorIndex + 1] = processor;
    probe.processors[processorIndex] = otherProcessor;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get processorIndex() {
    return findIndex(this.props.probe.processors, {name: this.props.processor.name});
  }

  @computed get processorErrors() {
    return filter(this.props.errors, {type: 'processor', processorName: this.props.processor.name});
  }

  render() {
    const {processorDefinitions} = this.context;
    const {
      compact, probe, processor, setCurrentProcessorName, highlightStage,
      editable, actionInProgress, errors, telemetryServiceRegistryItems,
    } = this.props;
    const processorDefinition = find(processorDefinitions, {name: processor.type});
    const processorTypeLabel = (
      <Label>
        <ProcessorIcon processorType={processor.type} />
        {processorDefinition.label ?? processor.type}
      </Label>
    );
    return (
      <div className='probe-processor'>
        {editable && [
          <ActionsMenu
            key='edit'
            size='tiny'
            floated='right'
            disabled={actionInProgress}
            items={[
              {
                icon: 'edit',
                title: 'Edit',
                onClick: () => this.openProcessorModalWithMode('update'),
              },
              {
                icon: 'clone',
                title: 'Clone',
                onClick: () => this.openProcessorModalWithMode('clone'),
              },
              {
                icon: 'trash',
                title: 'Delete',
                onClick: this.deleteProcessor,
              },
            ]}
          />,
          <ActionsMenu
            key='reorder'
            size='tiny'
            floated='right'
            disabled={actionInProgress}
            items={[
              {
                icon: 'angle down',
                title: 'Move down',
                disabled: this.processorIndex === this.props.probe.processors.length - 1,
                onClick: this.moveProcessorDown,
              },
              {
                icon: 'angle up',
                title: 'Move up',
                disabled: this.processorIndex === 0,
                onClick: this.moveProcessorUp,
              },
            ]}
          />,
        ]}
        {!compact &&
          <Header className='probe-contents-header' floated='left'>
            {'Processor:'}
            &nbsp;
            {processor.name}
            &nbsp;
            {processorDefinition.description ? (
              <Popup
                trigger={processorTypeLabel}
                content={processorDefinition.description}
                position='top center'
                wide
              />
            ) : processorTypeLabel}
          </Header>
        }
        {editable && !!this.processorErrors.length &&
          <Message
            error
            icon='warning sign'
            header='Error'
            content={
              this.processorErrors.map(({message}, index) =>
                <div key={index}>{message}</div>
              )
            }
          />
        }
        {editable &&
          <ProcessorInputEditor
            probe={probe}
            processor={processor}
            highlightStage={highlightStage}
            actionInProgress={actionInProgress}
            errors={errors}
          />
        }
        {!editable &&
          <ProcessorInputs
            probe={probe}
            processor={processor}
            highlightStage={highlightStage}
          />
        }
        <ProcessorProperties
          probe={probe}
          processor={processor}
          processorDefinition={processorDefinition}
          editable={editable}
          actionInProgress={actionInProgress}
          highlightStage={highlightStage}
          errors={errors}
          telemetryServiceRegistryItems={telemetryServiceRegistryItems}
        />
        <ProcessorModal
          open={this.processorModalMode !== null}
          onClose={this.closeProcessorModal}
          mode={this.processorModalMode}
          probe={probe}
          processor={processor}
          errors={errors}
          onSuccess={({result: processorName}) => setCurrentProcessorName(processorName)}
        />
      </div>
    );
  }
}
