import {Component, Fragment} from 'react';
import {Form, Grid, Message} from 'semantic-ui-react';
import {observable, action, computed, set, toJS, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {
  map, flatMap, filter, transform, uniq, find, intersection, pullAllWith, isMatch,
  keys, values, sortBy, groupBy, cloneDeep
} from 'lodash';
import cx from 'classnames';
import {ResourceModal, ValueInput, generatePropertyFromSchema} from 'apstra-ui-common';

import MultiParagraphText from '../../components/MultiParagraphText';
import ProcessorIcon from './ProcessorIcon';
import humanizeString from '../../humanizeString';
import {getStageFormSchema} from '../stageUtils';
import IBAContext from '../IBAContext';

import './ProcessorModal.less';

@observer
export default class ProcessorModal extends Component {
  static contextType = IBAContext;

  static defaultProps = {
    mode: 'create',
    errors: [],
  };

  @observable processorName = '';
  @observable processorType = null;
  @observable processorOutputs = {};
  @observable.shallow errors = [];

  @action
  resetState = () => {
    this.errors.length = 0;
    const {mode, processor} = this.props;
    if (mode === 'create') {
      this.setProcessorType(this.sortedProcessorDefinitions[0].name);
    } else if (mode === 'update') {
      this.setProcessorType(processor.type);
      this.setProcessorName(processor.name);
      for (const outputName of keys(this.processorDefinition.outputs)) {
        this.setProcessorOutputName(outputName, processor.outputs[outputName]);
      }
    } else if (mode === 'clone') {
      this.setProcessorType(processor.type);
    }
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  setProcessorName(processorName) {
    this.processorName = processorName;
    pullAllWith(this.errors, [{type: 'processor'}], isMatch);
  }

  @action
  setProcessorType(processorType) {
    this.processorType = processorType;
    this.generateNames();
  }

  @action
  setProcessorOutputName(outputName, value) {
    pullAllWith(this.errors, [{type: 'output', outputName}], isMatch);
    this.processorOutputs[outputName] = value;
  }

  @action
  submit = () => {
    const {mode} = this.props;
    this.validate();
    if (!this.isValid) throw new TypeError();
    if (mode === 'create') {
      this.createProcessor();
    } else if (mode === 'update') {
      this.updateProcessor();
    } else if (mode === 'clone') {
      this.createProcessor(true);
    }
    return this.processorName;
  };

  @action
  createProcessor = (clone = false) => {
    const {probe} = this.props;
    const {processor, stages} = this.generateProcessor(this.processorName, this.processorType, clone);
    probe.processors.push(processor);
    probe.stages.push(...stages);
  };

  @action
  updateProcessor = () => {
    const {probe, processor, errors} = this.props;
    pullAllWith(errors, [{processorName: processor.name}], isMatch);
    processor.name = this.processorName;
    for (const outputName of keys(this.processorDefinition.outputs)) {
      const oldOutputName = processor.outputs[outputName];
      const newOutputName = this.processorOutputs[outputName];
      if (oldOutputName !== newOutputName) {
        const stage = find(probe.stages, {name: oldOutputName});
        if (stage) {
          stage.name = newOutputName;
        }
        processor.outputs[outputName] = newOutputName;
        for (const {inputs} of probe.processors) {
          for (const inputName of keys(inputs)) {
            if (inputs[inputName] === oldOutputName) {
              set(inputs, inputName, newOutputName);
            }
          }
        }
      }
    }
  };

  generateProcessor(name, type, clone = false) {
    const {probe, processor} = this.props;
    const inputs = transform(this.processorDefinition.inputs, (result, metadata, inputName) => {
      if (inputName !== '*') result[inputName] = {};
    });
    const {outputs, stages} = transform(this.processorDefinition.outputs, ({outputs, stages}, metadata, outputName) => {
      const stageName = outputs[outputName] = this.processorOutputs[outputName];
      if (clone) {
        const sourceStage = find(probe.stages, {name: processor.outputs[outputName]});
        if (sourceStage) {
          stages.push({...cloneDeep(toJS(sourceStage)), name: stageName});
          return;
        }
      }
      stages.push({name: stageName, ...this.generateStageProperties()});
    }, {outputs: {}, stages: []});
    const properties = clone ?
      cloneDeep(toJS(processor.properties))
    :
      transform(this.processorDefinition.schema.properties, (result, propertySchema, propertyName) => {
        result[propertyName] = generatePropertyFromSchema(propertySchema);
      });
    return {processor: {type, name, inputs, outputs, properties}, stages};
  }

  generateStageProperties() {
    return transform(getStageFormSchema(this.processorDefinition), (result, {name, schema}) => {
      result[name] = generatePropertyFromSchema(schema);
    }, {});
  }

  validateProcessorName = (name) => {
    const errors = [];
    if (!name.length) errors.push('Empty name');
    if (this.props.probe.processors.some((processor) =>
      !(this.props.mode === 'update' && processor === this.props.processor) &&
      processor.name === name
    )) errors.push('Duplicate processor name');
    return errors;
  };

  validateStageName = (name) => {
    const errors = [];
    if (!name.length) errors.push('Empty name');
    if (this.props.probe.processors.some((processor) =>
      !(this.props.mode === 'update' && processor === this.props.processor) &&
      values(processor.outputs).some((outputName) => outputName === name)
    )) errors.push('Duplicate stage name');
    return errors;
  };

  @action
  validate = () => {
    this.errors.length = 0;
    const processorErrors = this.validateProcessorName(this.processorName);
    if (processorErrors.length) {
      this.errors.push({type: 'processor', message: processorErrors});
    }
    if (this.processorDefinition) {
      for (const outputName of keys(this.processorDefinition.outputs)) {
        const outputErrors = this.validateStageName(this.processorOutputs[outputName]);
        if (outputErrors.length) {
          this.errors.push({type: 'output', outputName, message: outputErrors});
        }
      }
    }
  };

  generateName(namePrefix, validate = () => []) {
    let nameSuffix = 0;
    let name;
    do {
      name = namePrefix + (nameSuffix ? ' ' + nameSuffix : '');
      nameSuffix++;
    } while (validate(name).length);
    return name;
  }

  @action
  generateNames = () => {
    if (this.processorType && this.processorDefinition) {
      this.setProcessorName(this.generateName(this.processorDefinition.label, this.validateProcessorName));
      this.processorOutputs = {};
      keys(this.processorDefinition.outputs).forEach((outputName, index, outputs) => {
        const namePrefix = outputs.length === 1 ? this.processorName : humanizeString(outputName);
        this.setProcessorOutputName(outputName, this.generateName(namePrefix, this.validateStageName));
      });
    }
  };

  @computed get availableProcessorTypes() {
    const {processorDefinitions} = this.context;
    const {probe} = this.props;
    function getPossibleProcessorDataTypes(processorDefinition) {
      return transform({inputs: 'inputTypes', outputs: 'outputTypes'}, (result, resultKey, stagesKey) => {
        result[resultKey] = uniq(flatMap(
          processorDefinition[stagesKey],
          (stageDefinition) => map(stageDefinition.types, 'type')
        ));
      }, {});
    }
    return transform(processorDefinitions, (result, processorDefinition) => {
      const {inputTypes: inputTypes1, outputTypes: outputTypes1} = getPossibleProcessorDataTypes(processorDefinition);
      if (!inputTypes1.length) {
        // processor has no inputs - always available for addition
        result[processorDefinition.name] = true;
      } else {
        // try to match with existing processors
        for (const processor of probe.processors) {
          const {inputTypes: inputTypes2, outputTypes: outputTypes2} =
            getPossibleProcessorDataTypes(find(processorDefinitions, {name: processor.type}));
          if (
            intersection(inputTypes1, outputTypes2).length ||
            intersection(inputTypes2, outputTypes1).length
          ) {
            result[processorDefinition.name] = true;
            return;
          }
        }
      }
    }, {});
  }

  @computed get sortedProcessorDefinitions() {
    const {processorDefinitions} = this.context;
    const sortedProcessorDefinitions = sortBy(processorDefinitions, 'label');
    const {available = [], unavailable = []} = groupBy(sortedProcessorDefinitions, (processorDefinition) =>
      this.availableProcessorTypes[processorDefinition.name] ? 'available' : 'unavailable'
    );
    return available.concat(unavailable);
  }

  @computed get processorDefinition() {
    const {processorDefinitions} = this.context;
    return this.processorType && find(processorDefinitions, {name: this.processorType}) || null;
  }

  @computed get isValid() {
    return this.processorType !== null && !this.errors.length;
  }

  render() {
    const {mode, open, onClose, onSuccess} = this.props;
    return (
      <ResourceModal
        mode={mode}
        className='processor-modal'
        resourceName='Processor'
        titlesByMode={{create: 'Add', update: 'Edit', clone: 'Clone'}}
        actionsByMode={{create: 'Add', update: 'Update', clone: 'Clone'}}
        open={open}
        onClose={onClose}
        resetState={this.resetState}
        submit={this.submit}
        submitAvailable={this.isValid}
        onSuccess={onSuccess}
        notifyOnSuccess={false}
        showCreateAnother={false}
      >
        {() =>
          <Grid>
            <Grid.Column width={8}>
              <Form>
                <Form.Dropdown
                  label='Processor Type'
                  selection
                  search
                  required
                  disabled={mode !== 'create'}
                  value={this.processorType}
                  options={this.sortedProcessorDefinitions.map((processorDefinition) => ({
                    key: processorDefinition.name,
                    value: processorDefinition.name,
                    text: processorDefinition.label,
                    content: (
                      <Fragment>
                        <ProcessorIcon processorType={processorDefinition.name} />
                        {processorDefinition.label}
                      </Fragment>
                    ),
                    className: cx({unavailable: !this.availableProcessorTypes[processorDefinition.name]})
                  }))}
                  onChange={(e, {value}) => this.setProcessorType(value)}
                />
                <ValueInput
                  key='processor-name'
                  name='processor-name'
                  value={this.processorName}
                  schema={{title: 'Processor Name'}}
                  required
                  errors={map(filter(this.errors, {type: 'processor'}), 'message')}
                  onChange={(value) => this.setProcessorName(value)}
                />
                {this.processorDefinition &&
                  keys(this.processorDefinition.outputs).map((outputName) =>
                    <ValueInput
                      key={`output ${outputName}`}
                      name={outputName}
                      value={this.processorOutputs[outputName] ?? ''}
                      schema={{
                        title: `Output Stage Name: ${outputName}`,
                        description: this.processorDefinition.outputs[outputName].description
                      }}
                      required
                      errors={map(filter(this.errors, {type: 'output', outputName}), 'message')}
                      onChange={(value) => this.setProcessorOutputName(outputName, value)}
                    />
                  )
                }
              </Form>
            </Grid.Column>
            <Grid.Column width={8} className='processor-description'>
              {this.processorDefinition && [
                !this.availableProcessorTypes[this.processorDefinition.name] &&
                  <Message
                    key='availability-warning'
                    warning
                    content='There are no existing processors that can be connected to a processor of this type.'
                  />,
                <MultiParagraphText key='description' text={this.processorDefinition.description} />
              ]}
            </Grid.Column>
          </Grid>
        }
      </ResourceModal>
    );
  }
}
