import {Input, Dropdown, List, Button} from 'semantic-ui-react';
import {useState, useEffect} from 'react';
import {zipWith, map, upperFirst} from 'lodash';
import cx from 'classnames';
import {stopEnterPropagation} from 'apstra-ui-common';

import './Condition.less';

const humanReadable = (text) => {
  return upperFirst(text.replace(/_/g, ' '));
};

const getConditions = (type) => {
  switch (type) {
  case 'number':
  case 'integer':
    return [
      {text: 'Equals', value: 'eq'},
      {text: 'Not equal to', value: 'ne'},
      {text: 'Greater than', value: 'gt'},
      {text: 'Less than', value: 'lt'}
    ];
  case 'string':
    return [
      {text: 'Equals', value: 'eq'},
      {text: 'Not equal to', value: 'ne'},
      {text: 'Matches', value: 'regex'}
    ];
  case 'boolean':
    return [
      {text: 'Is true', value: 'eq:true'},
      {text: 'Is false', value: 'eq:false'}
    ];
  case 'list':
    return [
      {text: 'Contains', value: 'contains'},
      {text: 'Does not contain', value: 'not_contains'}
    ];
  case 'enum':
  case 'radiobutton-enum':
  case 'node':
  default:
    return [
      {text: 'Equals', value: 'eq'},
      {text: 'Not equal to', value: 'ne'}
    ];
  }
};

const Condition = ({criterion: {condition, value}, logic, argument, update, nodeGetByType}) => {
  const [options, setOptions] = useState([]);
  const {
    type, subtype, enum_values: values, enum_display_values: displayValues, node_type: nodeType,
    placeholder = 'Value'
  } = argument;

  useEffect(() => {
    switch (subtype || type) {
    case 'enum-radiobutton':
        // Generate DDL options in state
      setOptions(zipWith(values, displayValues || values, (value, text) => ({text, value})));
      break;
    case 'node_id':
        // Fetch nodes list
      nodeGetByType(nodeType)
        .then((values) => setOptions(
          map(values, ({title: text, const: value}) => ({text, value}))
        ));
    }
  }, [type, subtype, nodeType, nodeGetByType, values, displayValues]);

  const classNames = cx('condition', `${subtype || type}-type`);

  const inputProps = {
    className: 'condition-input',
    error: !value,
    placeholder,
    value,
    onChange: (event, {value}) => update({value}),
    onKeyDown: stopEnterPropagation
  };

  let input;

  switch (subtype || type) {
  case 'number':
  case 'integer':
    input = (
      <Input
        type='number'
        {...inputProps}
        error={value === ''}
        onChange={(event, {value}) => update({value: +value})}
      />
    );
    break;
  case 'string':
    input = (
      <Input
        type='text'
        {...inputProps}
      />
    );
    break;
  case 'boolean':
    break;
  case 'enum':
  case 'enum-radiobutton':
    input = (
      <Dropdown
        selection
        search
        {...inputProps}
        options={options}
        noResultsMessage='No results.'
      />
    );
    break;
  case 'node_id':
    input = (
      <Dropdown
        selection
        search
        {...inputProps}
        options={options}
        placeholder={humanReadable(nodeType)}
        noResultsMessage='No results.'
      />
    );
    break;
  default:
    break;
  }

  return (
    <List.Item className={classNames}>
      <List.Content>
        {logic &&
          <Button
            className='ct-condition-logic-button'
            content={logic}
            onClick={() => update({logic: logic === 'and' ? 'or' : 'and'})}
          />
        }
        <Dropdown
          selection
          className='condition-type'
          error={!condition}
          options={getConditions(argument.type)}
          placeholder='Condition'
          value={condition}
          onChange={(event, {value}) => update({condition: value})}
        />
        <div className='nowrap'>
          {input}
          <Button
            icon='trash'
            onClick={() => update(null)}
          />
        </div>
      </List.Content>
    </List.Item>
  );
};

export default Condition;
