import {useState, useRef} from 'react';
import {Message, Button, Popup} from 'semantic-ui-react';
import cx from 'classnames';

import Visualizer from './Visualizer';
import './Canvas.less';
import {ReactComponent as Definitions} from '../../../styles/icons/ct/definitions.svg';

const Canvas = ({
  ept, showIntro, readonly, comparisonEpt, isComparisonDirect, scale = 1,
  onlyShowDifferent = false
}) => {
  const canvasRef = useRef();
  const [eptTooltip, showEptTooltip] = useState();
  const [left, top, width, height] = ept.canvasSize;
  let popupPos = null;
  if (eptTooltip && canvasRef.current) {
    const pt = canvasRef.current.createSVGPoint();
    pt.x = eptTooltip.position.x; pt.y = eptTooltip.position.y;
    popupPos = pt.matrixTransform(canvasRef.current.getCTM());
  }

  const classNames = cx('ct-canvas', {
    'different-only': onlyShowDifferent,
    diff: !!comparisonEpt
  });

  return (
    <div className={classNames}>
      <svg
        ref={canvasRef}
        width={width * scale}
        height={height * scale}
        viewBox={`${left} ${top} ${width} ${height}`}
        xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'
        version='1.2'
        baseProfile='full'
        id={`ct-canvas${readonly ? '-readonly' : ''}`}
        onMouseDown={() => ept.setActivePrimitive(null)}
      >
        <Definitions />
        <Visualizer {...{ept, readonly, showTooltip: showEptTooltip, comparisonEpt, isComparisonDirect}} />
      </svg>
      {showIntro && !readonly && ept?.switchSidePanelTab &&
        <Message info compact>
          <Message.Header>{'You have started blank Connectivity Template creation'}</Message.Header>
          <p>{'Please select one of the possible options to proceed with CT building:'}</p>
          <table>
            <tbody>
              <tr>
                <td>
                  <Button
                    primary
                    onClick={() => ept.switchSidePanelTab(1)}
                    content='Primitives'
                  />
                </td>
                <td>{'Select primitive to use'}</td>
              </tr>
              <tr>
                <td>
                  <Button
                    primary
                    onClick={() => ept.switchSidePanelTab(2)}
                    content='User-defined'
                  />
                </td>
                <td>
                  {
                    'Re-use user-defined Connectivity Template (all primitives it consists of will be ' +
                    'added to the current one)'
                  }
                </td>
              </tr>
              <tr>
                <td>
                  <Button
                    primary
                    onClick={() => ept.switchSidePanelTab(3)}
                    content='Pre-defined'
                  />
                </td>
                <td>
                  {
                    'Re-create Connectivity Template based on a pre-defined template'
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </Message>
      }
      {popupPos &&
        <Popup
          context={canvasRef.current}
          offset={[popupPos.x, -popupPos.y + 5]}
          size='tiny'
          position='top left'
          pinned
          open
        >
          <Popup.Header>{`Type: ${eptTooltip.schemaLabel}`}</Popup.Header>
          <Popup.Content>{eptTooltip.title}</Popup.Content>
        </Popup>
      }
    </div>
  );
};

export default Canvas;
