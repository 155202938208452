import {Component} from 'react';
import {Input} from 'semantic-ui-react';
import isNumeric from 'is-number';
import {CodeEditorControl} from 'apstra-ui-common';

export default class IntegerExpressionControl extends Component {
  onChange = (value) => {
    const newValue = isNumeric(value) ? Number(value) : value;
    this.props.onChange(newValue);
  };

  render() {
    if (this.props.type === 'expression') {
      return (
        <CodeEditorControl
          {...this.props}
          value={String(this.props.value ?? '')}
          mode='python-expression'
          onChange={this.onChange}
        />
      );
    }

    return (
      <Input
        {...this.props}
        onChange={(e, {value}) => this.onChange(value)}
      />
    );
  }
}
