import {createRoot} from 'react-dom/client';
import {Icon, Message, Popup, Progress, Table} from 'semantic-ui-react';
import {isEmpty, map} from 'lodash';
import {formatNumber} from 'apstra-ui-common';

import uploadStore from './uploadStore';
import {useUploads} from './useUploads';

import './UploadNotifier.less';

let notifierContainer = null;
const getNotifierContainer = () => {
  if (notifierContainer) return notifierContainer;
  notifierContainer = document.createElement('div');
  document.body.appendChild(notifierContainer);
  return notifierContainer;
};
export const initUploadNotifier = () =>
  new Promise((resolve) => {
    createRoot(getNotifierContainer())
      .render(<UploadNotifier ref={resolve} />);
  });

const UploadNotifier = () => {
  const uploads = useUploads();
  const formatBytesNumber = (size) => formatNumber(size, {units: 'B', short: true});
  return (
    <div className='upload-notifier'>
      {!isEmpty(uploads) && (
        <Message
          className='notification'
        >
          <Message.Content>
            <Message.Header>{'Uploads'}</Message.Header>
            <Table basic='very'>
              <Table.Body>
                {map(uploads, ({image, uploadProgress, onCancelRequest, error, id, image_url: imageUrl}) => (
                  <Table.Row key={id}>
                    <Table.Cell width={6} className='name'>{image?.name ?? imageUrl}</Table.Cell>
                    <Table.Cell width={2}>{image?.size ? formatBytesNumber(image?.size) : 'N/A'}</Table.Cell>
                    <Table.Cell>
                      {error ? (
                        <UploadError error={error} />
                      ) : (
                        <Popup
                          content={
                            `${formatBytesNumber(uploadProgress?.loaded)} / ${formatBytesNumber(uploadProgress?.total)}`
                          }
                          disabled={!!error}
                          trigger={
                            <Progress
                              size='small'
                              percent={error ? 100 : uploadProgress?.progress}
                              progress={!error || uploadProgress?.progress < 100}
                              error={!!error}
                              success={uploadProgress?.progress === 100}
                              active
                            />
                          }
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell collapsing>
                      <Icon
                        name='delete'
                        link
                        onClick={() => {
                          if (uploadProgress?.progress) {
                            if (onCancelRequest) onCancelRequest();
                          } else {
                            uploadStore.deleteUploadItem(id);
                          }
                        }}
                        aria-label='Delete'
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Message.Content>
        </Message>
      )}
    </div>
  );
};

const UploadError = ({error}) => {
  const message = error.toMessage();
  return (
    <div className='error'>
      <Icon
        aria-label='Request Status'
        name='warning circle'
        color={error && 'red'}
      />
      <span className='text'>{message?.content}</span>
    </div>
  );
};
