/* eslint-disable sonarjs/no-duplicate-string */
import ace from 'ace-builds';
import {isFunction, isMatch, findIndex, map, mapValues, startsWith, last, split, times} from 'lodash';
import {interpolateRoute, request} from 'apstra-ui-common';

import {
  NODE, OUT, IN, GRAPH_QUERY_FUNCTIONS, GRAPH_QUERY_METHODS, GRAPH_QUERY_METHODS_MAP,
  GRAPH_QUERY_ALL_METHODS, GRAPH_QUERY_KWARGS, GRAPH_QUERY_TAG_MATCHERS, GRAPH_QUERY_MATCHERS
} from './consts';
import getAvailableTypesFromPath from './getAvailableTypesFromPath';
import buildPropertyDocHTMLFromSchema from './buildPropertyDocHTMLFromSchema';
import {makeStringState} from './ace-mode-python-expression';

/* eslint-disable max-len */

ace.define(
  'ace/mode/graph-query',
  ['require', 'exports', 'module'],
  (require, exports) => {
    const {Mode: PythonExpressionMode, HighlightRules: PythonHighlightRules} = require('ace/mode/python-expression');
    const {TokenIterator} = require('ace/token_iterator');
    const {BaseCompleter} = require('ace/base_completer');
    const {FoldMode} = require('ace/mode/folding/fold_mode');
    const {Range} = require('ace/range');

    const KEYWORDS = [
      'lambda', 'if', 'else', 'for', 'or', 'and', 'not', 'is', 'in',
    ].map((name, index) => ({
      caption: name,
      snippet: name,
      meta: 'keyword',
      score: 100 - index,
    }));

    const GQ_FUNCTIONS = GRAPH_QUERY_FUNCTIONS.map(({name, description}, index) => ({
      caption: name,
      snippet: name + '($0)',
      docText: description,
      meta: 'graph query',
      className: 'completion-function ace_',
      score: 1000 - index
    }));

    const GQ_METHODS = GRAPH_QUERY_METHODS.map(({name, description}, index) => ({
      caption: name,
      snippet: name + '($0)',
      docText: description,
      meta: 'graph query',
      className: 'completion-function ace_',
      score: 1000 - index
    }));

    const GQ_KWARGS = mapValues(
      GRAPH_QUERY_KWARGS,
      (values) => values.map(({name, type, description, score}, index) => ({
        caption: name,
        snippet: name,
        docHTML: buildPropertyDocHTMLFromSchema({type, description}),
        meta: 'graph query property',
        className: 'completion-keyword-argument ace_',
        score: score ?? 2000 - index
      })));

    const GQ_MATCHERS = GRAPH_QUERY_MATCHERS.map(({name, description}, index) => ({
      caption: name,
      snippet: name + '($0)',
      docText: description,
      meta: 'property matcher',
      className: 'completion-function ace_',
      score: 1000 - index
    }));

    const GQ_TAG_MATCHERS = GRAPH_QUERY_TAG_MATCHERS.map(({name, description}, index) => ({
      caption: name,
      snippet: name + '($0)',
      docText: description,
      meta: 'tag matcher',
      className: 'completion-function ace_',
      score: 1000 - index
    }));

    class GraphQueryCompleter extends BaseCompleter {
      referenceDesignSchemas = {};
      referenceDesignSchemaFetchingPromises = {};

      async getCustomCompletions(state, session, pos, prefix) {
        const token = session.getTokenAt(pos.row, pos.column);

        // do not autocomplete string escape sequences
        if (token.type === 'constant.language.escape') return [];

        // autocomplete GQ methods
        if (token.type === 'punctuation' && token.value === '.') {
          return this.getMethodCompletions(session, pos) ?? [];
        }
        if (token.type === 'identifier' && GRAPH_QUERY_ALL_METHODS.some((method) => startsWith(method, token.value))) {
          const result = this.getMethodCompletions(session, pos, true);
          if (result) return result;
        }

        if (token.type === 'string' || token.type === 'string.gq-node-name') {
          // autocomplete inside the quotes only
          if (pos.column < token.start + token.value.length || /\w$/.test(token.value)) {
            // check if it's node/relationship type (first positional argument)
            const matchResult = this.matchTokenPattern({session, pos, pattern: [
              ({type}) => type === 'entity.function.name' || type === 'function.support',
              {type: 'paren.lparen', value: '('}
            ]});
            if (matchResult) {
              const [functionToken] = matchResult.tokens;
              if (functionToken.value === NODE) {
                return this.getNodeTypeCompletions(matchResult.iterator);
              } else if (functionToken.value === OUT || functionToken.value === IN) {
                return this.getRelationshipTypeCompletions(matchResult.iterator, functionToken.value);
              }
            }

            // check if it's node/relationship name
            if (token.type === 'string.gq-node-name') {
              return this.getTextCompletions(state, session, pos, prefix);
            }

            // check if it's node/relationship property
            const currentFunction = this.getCurrentFunction({session, pos});
            if (currentFunction && [NODE, OUT, IN].includes(currentFunction.name)) {
              const matchResult = this.matchTokenPattern({
                session, pos, pattern: [{type: 'keyword-argument'}], greedy: true
              });
              if (matchResult) {
                const firstKeywordArgument = matchResult.tokens[0].value;
                if (firstKeywordArgument === 'type') {
                  // it's node/relationship type (keyword argument)
                  if (currentFunction.name === NODE) {
                    return this.getNodeTypeCompletions(matchResult.iterator);
                  } else {
                    return this.getRelationshipTypeCompletions(matchResult.iterator, currentFunction.name);
                  }
                } else if (currentFunction.name === NODE) {
                  // it's node other property
                  return this.getNodePropertyStringValueCompletions(currentFunction.tokens, firstKeywordArgument);
                }
              }
            }
          }
          return [];
        }

        if (
          token.type === 'identifier' ||
          token.type === 'punctuation' ||
          token.type === 'text' && this.matchTokenPattern({session, pos, pattern: [{type: 'punctuation'}]})
        ) {
          const result = [];
          const currentFunction = this.getCurrentFunction({session, pos});
          if (currentFunction && GQ_KWARGS[currentFunction.name]) {
            if ( // check if happens inside chain function
              token.type === 'punctuation' && token.value === '=' ||
              this.matchTokenPattern({session, pos, pattern: [
                {type: 'keyword-argument'},
                {type: 'punctuation', value: '='},
              ]})
            ) {
              // this is value of keyword argument
              if (currentFunction.name === NODE && this.matchTokenPattern({
                session, pos, pattern: [{type: 'keyword-argument', value: 'tag'}]
              })) {
                result.push(...GQ_TAG_MATCHERS);
              } else {
                result.push(...GQ_MATCHERS);
              }
            } else {
              // this is keyword argument
              result.push(...GQ_KWARGS[currentFunction.name]);
              if (currentFunction.name === NODE) {
                result.push(...await this.getNodePropertyNameCompletions(currentFunction.tokens));
              }
            }
          }
          if (!result.length) {
            result.push(...GQ_FUNCTIONS);
          }
          result.push(
            ...KEYWORDS,
            ...await this.getTextCompletions(state, session, pos, prefix)
          );
          return result;
        }
        return this.getTextCompletions(state, session, pos, prefix);
      }

      async getReferenceDesignSchema() {
        const {blueprintDesign: referenceDesignName} = this.params;
        if (!referenceDesignName) return null;
        if (this.referenceDesignSchemas[referenceDesignName]) return this.referenceDesignSchemas[referenceDesignName];
        try {
          let referenceDesignSchemaFetchingPromise = this.referenceDesignSchemaFetchingPromises[referenceDesignName];
          if (referenceDesignSchemaFetchingPromise) return await referenceDesignSchemaFetchingPromise;
          const route = '/api/docs/reference_design_schemas/<reference_design_name>';
          referenceDesignSchemaFetchingPromise = request(interpolateRoute(route, {referenceDesignName}));
          this.referenceDesignSchemaFetchingPromises[referenceDesignName] = referenceDesignSchemaFetchingPromise;
          this.referenceDesignSchemas[referenceDesignName] = await referenceDesignSchemaFetchingPromise;
          return this.referenceDesignSchemas[referenceDesignName];
        } finally {
          delete this.referenceDesignSchemaFetchingPromises[referenceDesignName];
        }
      }

      async getNodeTypeCompletions(iterator) {
        const types = await this.getAvailableTypesFromPath(
          this.getCurrentPath({iterator, currentFunctionName: NODE}),
          NODE
        );
        if (!types) return null;
        return map(types.nodes, (nodeType) => ({
          caption: nodeType,
          snippet: nodeType,
          meta: 'node type',
          className: 'completion-string ace_',
          score: 2000
        }));
      }

      async getRelationshipTypeCompletions(iterator, currentFunctionName) {
        const types = await this.getAvailableTypesFromPath(
          this.getCurrentPath({iterator, currentFunctionName}),
          currentFunctionName
        );
        if (!types) return null;
        return map(types.relationships, (relationship) => ({
          caption: relationship,
          snippet: relationship,
          meta: 'relationship type',
          className: 'completion-string ace_',
          score: 2000
        }));
      }

      async getAvailableTypesFromPath(path, currentFunctionName) {
        const referenceDesignSchema = await this.getReferenceDesignSchema();
        if (!referenceDesignSchema) return null;
        return getAvailableTypesFromPath(path, currentFunctionName, referenceDesignSchema);
      }

      getMethodCompletions(session, pos, methodStarted) {
        if (
          this.matchTokenPattern(
            {session, pos, pattern: [
              ({type, value}) => type === 'paren.rparen' && last(value) === ')'
            ].concat(methodStarted ? [{type: 'punctuation', value: '.'}] : [])}
          )
        ) {
          const currentFunction = this.getCurrentFunction({session, pos, outsideParens: true});
          if (currentFunction && GRAPH_QUERY_METHODS_MAP[currentFunction.name]) {
            return GQ_METHODS.filter(({caption}) => GRAPH_QUERY_METHODS_MAP[currentFunction.name].includes(caption));
          }
        }
        return null;
      }

      getTypeFromFunctionTokens(tokens) {
        let typeAsString;
        if (tokens[2]?.type === 'string') {
          typeAsString = tokens[2].value;
        } else {
          const keywordArgTypePattern = [
            {type: 'keyword-argument', value: 'type'},
            {type: 'punctuation', value: '='},
            {type: 'string'},
          ];
          const keywordArgIndex = findIndex(
            tokens,
            (token, index) => isMatch(tokens.slice(index, index + keywordArgTypePattern.length), keywordArgTypePattern),
            2
          );
          if (keywordArgIndex !== -1) {
            typeAsString = tokens[keywordArgIndex + 2].value;
          }
        }
        if (typeAsString) {
          // FIXME(vkramskikh): reuse logic from parseStringLiteralToken
          return typeAsString.replace(/^\w*['"](.*)['"]$/, '$1');
        }
      }

      async getNodeSchemaFromFunctionTokens(tokens) {
        const nodeType = this.getTypeFromFunctionTokens(tokens);
        if (nodeType) {
          const referenceDesignSchema = await this.getReferenceDesignSchema();
          return referenceDesignSchema?.nodes?.[nodeType];
        }
      }

      async getNodePropertyNameCompletions(tokens) {
        const nodeSchema = await this.getNodeSchemaFromFunctionTokens(tokens);
        if (nodeSchema) {
          return map(nodeSchema.properties, (propertySchema, propertyName) => ({
            caption: propertyName,
            snippet: propertyName,
            meta: 'node property',
            docHTML: buildPropertyDocHTMLFromSchema(propertySchema),
            className: 'completion-keyword-argument ace_',
            score: 2000
          }));
        }
        return [];
      }

      async getNodePropertyStringValueCompletions(tokens, propertyName) {
        const nodeSchema = await this.getNodeSchemaFromFunctionTokens(tokens);
        const propertySchema = nodeSchema?.properties?.[propertyName];
        if (propertySchema?.type === 'string' && propertySchema?.enum) {
          return map(propertySchema.enum, (value) => ({
            caption: value,
            snippet: value,
            meta: 'node property value',
            className: 'completion-string ace_',
            score: 2000
          }));
        }
        return [];
      }

      getCurrentFunction({iterator, session, pos, outsideParens = false}) {
        if (!iterator) iterator = new TokenIterator(session, pos.row, pos.column);
        const tokens = [];
        let currentToken, nextToken;
        let parenNesting = outsideParens ? 0 : 1;
        do {
          do {
            currentToken = iterator.stepBackward();
            if (!currentToken) return null;
          } while (currentToken.type === 'text');
          tokens.unshift(currentToken);
          if (currentToken.type === 'paren.lparen' || currentToken.type === 'paren.rparen') {
            for (const character of split(currentToken.value, '')) {
              if (character === '(') {
                parenNesting--;
              } else if (character === ')') {
                parenNesting++;
              }
            }
          }
          if (
            parenNesting === 0 &&
            nextToken && nextToken.type === 'paren.lparen' && nextToken.value === '(' &&
            currentToken.type === 'entity.function.name' || currentToken.type === 'function.support'
          ) {
            return {iterator, tokens, name: currentToken.value};
          }
          nextToken = currentToken;
        } while (true); // eslint-disable-line no-constant-condition
      }

      getCurrentPath({iterator, session, pos, currentFunctionName}) {
        if (!iterator) iterator = new TokenIterator(session, pos.row, pos.column);
        const path = [];
        let currentToken;
        pathLoop: do {
          do {
            currentToken = iterator.stepBackward();
            if (!currentToken) break pathLoop;
          } while (currentToken.type === 'text');
          // if outside of the function and encountering anything than a single dot - the path is malformed
          if (!(currentToken.type === 'punctuation' && currentToken.value === '.')) break;
          // get previous function in chain
          const previousFunction = this.getCurrentFunction({iterator, outsideParens: true});
          if (!previousFunction) break;
          // check if it's name is valid
          if (
            currentFunctionName === NODE && ![OUT, IN].includes(previousFunction.name) ||
            [OUT, IN].includes(currentFunctionName) && previousFunction.name !== NODE
          ) break;
          // get its type
          path.unshift({
            name: previousFunction.name,
            type: this.getTypeFromFunctionTokens(previousFunction.tokens) || null
          });
          currentFunctionName = previousFunction.name;
        } while (true); // eslint-disable-line no-constant-condition
        if (!path.length) return null;
        // path always start from node(), not relationship
        if (path[0].name !== NODE) return null;
        return path;
      }

      matchTokenPattern({iterator, session, pos, pattern, greedy = false}) {
        if (!iterator) iterator = new TokenIterator(session, pos.row, pos.column);
        const tokens = [];
        pattern.reverse();
        do {
          for (const matcher of pattern) {
            let currentToken;
            do {
              currentToken = iterator.stepBackward();
              if (!currentToken) return null;
            } while (currentToken.type === 'text');
            if (isFunction(matcher) ? matcher(currentToken) : isMatch(currentToken, matcher)) {
              tokens.unshift(currentToken);
            } else if (!greedy) {
              return null;
            } else {
              times(tokens.length, () => iterator.stepForward());
              tokens.length = 0;
              break;
            }
          }
        } while (!tokens.length);
        return {iterator, tokens};
      }

      checkForCustomCharacterCompletions(editor) {
        const pos = editor.getCursorPosition();
        const line = editor.getSession().getLine(pos.row).substr(0, pos.column);
        if (/(['"]|[.,=]\s*)$/.test(line)) {
          this.showCompletionsPopup(editor);
        }
      }
    }

    class GraphQueryHighlightRules extends PythonHighlightRules {
      constructor() {
        super();
        const rules = {
          ...this.getRules(),
          ...makeStringState('node-name-short-double-quote-string', '"', false, false, 'string.gq-node-name'),
          ...makeStringState('node-name-short-single-quote-string', "'", false, false, 'string.gq-node-name'),
        };
        rules.start = [...rules.start];
        const keywordArgumentRuleIndex =
          findIndex(rules.start, ({token}) => Array.isArray(token) && token[0] === 'keyword-argument');
        rules.start.splice(keywordArgumentRuleIndex, 0, {
          token: ['keyword-argument', 'punctuation'],
          regex: '(name)(=)(?=[\'"])',
          next: [
            {
              token: 'string.gq-node-name',
              regex: "'(?=.)",
              next: 'node-name-short-single-quote-string'
            },
            {
              token: 'string.gq-node-name',
              regex: '"(?=.)',
              next: 'node-name-short-double-quote-string'
            },
          ]
        });
        this.$rules = rules;
        this.normalizeRules();
      }
    }

    class GraphQueryFoldMode extends FoldMode {
      foldingStartMarker = /[(]/;

      getFoldWidgetRange(session, foldStyle, row) {
        const line = session.getLine(row);
        const match = line.match(this.foldingStartMarker);

        if (match) {
          const indentationBlock = this.indentationBlock(session, row);
          const openingBracketBlock = this.openingBracketBlock(session, match[0], row, match.index);
          if (indentationBlock && openingBracketBlock) {
            const {
              start: {row: startRowIndentationBlock},
              end: {row: endRowIndentationBlock, column: endColumnIndentationBlock}
            } = indentationBlock;
            const {
              start: {row: startRowBracketBlock, column: startColumnBracketBlock},
              end: {row: endRowBracketBlock}
            } = openingBracketBlock;
            if (endRowIndentationBlock - startRowIndentationBlock >= endRowBracketBlock - startRowBracketBlock) {
              return new Range(
                startRowIndentationBlock,
                startColumnBracketBlock - 1,
                endRowIndentationBlock,
                endColumnIndentationBlock
              );
            }
            return openingBracketBlock;
          }
          return indentationBlock || openingBracketBlock;
        }
      }
    }

    class GraphQueryMode extends PythonExpressionMode {
      $id = 'ace/mode/graph-query';
      HighlightRules = GraphQueryHighlightRules;
      completer = new GraphQueryCompleter();
      foldingRules = new GraphQueryFoldMode();
    }

    exports.Mode = GraphQueryMode;
  }
);

ace.require(['ace/mode/graph-query']);
