import cx from 'classnames';
import * as THREE from 'three';
import {clamp, get, identity, set} from 'lodash';

import {NODE_ROLE_CLASSNAMES} from './types';
import {LOCAL_NODE_ROLES, NODE_ROLES} from '../../../roles';

export function getNodeLocalRole(node) {
  if (node?.role === NODE_ROLES.GENERIC && node?.external) {
    return LOCAL_NODE_ROLES.EXTERNAL_GENERIC;
  }
  return node?.role;
}

export class StyleBridge {
  stylesByClassName = {};
  mountElement = null;

  constructor(mountElement) {
    this.mountElement = mountElement;
  }

  getStyle(className, propName = 'backgroundColor') {
    const {stylesByClassName, mountElement} = this;
    if (!get(stylesByClassName, [className, propName])) {
      const styleBridge = document.createElement('span');
      mountElement.appendChild(styleBridge);
      styleBridge.className = className;
      set(stylesByClassName, [className, propName], window.getComputedStyle(styleBridge)[propName]);
      mountElement.removeChild(styleBridge);
    }
    return get(stylesByClassName, [className, propName]);
  }
}

export function getNodeClass(node) {
  return cx({
    [NODE_ROLE_CLASSNAMES[getNodeLocalRole(node)]]: node.role,
    [`tp-status-${node.layer_status}`]: node.layer_status
  });
}

export function getScreenPosition({position, camera, renderer, object, clampToScreen = true}) {
  const vector = new THREE.Vector3();
  const transform = object.parent ? new THREE.Vector3().setFromMatrixPosition(object.parent.matrixWorld)
    : new THREE.Vector3(1, 1, 1);
  renderer.getSize(vector);
  const widthHalf = 0.5 * vector.x;
  const heightHalf = 0.5 * vector.y;
  vector
    .copy(position)
    .add(transform)
    .project(camera);
  const f = clampToScreen ? (v) => clamp(v, -1, 1) : identity;
  vector.x = (f(vector.x) * widthHalf) + widthHalf;
  vector.y = -(f(vector.y) * heightHalf) + heightHalf;
  return {
    x: vector.x,
    y: vector.y
  };
}

export function getRandomColor() {
  const symbols = '0123456789abcdef';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += symbols[Math.floor(Math.random() * 16)];
  }
  return color;
}
