/* eslint-disable sonarjs/no-duplicate-string */
import ace from 'ace-builds';
import {map, includes, merge} from 'lodash';
import {convertChevrotainErrors} from 'apstra-ui-common';

import buildPropertyDocHTMLFromSchema from '../pythonExpression/buildPropertyDocHTMLFromSchema';
import LuceneApstraStringParser from './LuceneApstraStringParser';

const isStringLike = (schema) => {
  return schema?.type === 'string' || (
    schema?.type === 'array' && schema?.items?.type === 'string'
  );
};

ace.define(
  'ace/mode/lucene-apstra',
  ['require', 'exports', 'module'],
  (require, exports) => {
    const {TextHighlightRules} = require('ace/mode/text_highlight_rules');
    const {Mode: TextMode} = require('ace/mode/text');
    const {TokenIterator} = require('ace/token_iterator');
    const {BaseCompleter} = require('ace/base_completer');

    class LuceneApstraCompleter extends BaseCompleter {
      getCustomCompletions(_, session, pos) {
        const previousToken = this.getPreviousToken({session, pos});

        const result = [];
        if (includes(['string', 'string.quoted', 'paren.rparen'], previousToken?.type)) {
          result.push(...this.getLogicOperators());
        }
        if (!previousToken || includes([
          'string', 'string.quoted', 'paren.rparen', 'paren.lparen', 'keyword.operator'
        ], previousToken?.type)) {
          result.push(...this.getDocuments());
          result.push(...this.getFields());
        }
        return result;
      }

      getLogicOperators() {
        return map(['AND', 'OR'], (keyword, index) => ({
          caption: keyword,
          snippet: keyword,
          meta: 'keyword',
          className: 'completion-function ace_',
          score: 10000 + index,
        }));
      }

      getDocuments() {
        return map(this.params, (_, documentName) => ({
          caption: documentName,
          snippet: documentName,
          className: 'completion-keyword-argument ace_',
          meta: 'type',
          score: 1000
        }));
      }

      getFields() {
        const fields = merge({}, ...map(this.params, 'fields'));
        return map(fields, ({schema}, field) => ({
          caption: field,
          snippet: `${field}:${isStringLike(schema) ? '"$0"' : ''}`,
          docHTML: buildPropertyDocHTMLFromSchema(schema),
          className: 'completion-keyword-argument ace_',
          meta: 'field',
          score: 1000
        }));
      }

      getPreviousToken({iterator, session, pos}) {
        if (!iterator) iterator = new TokenIterator(session, pos.row, pos.column);
        do {
          const currentToken = iterator.stepBackward();
          if (!currentToken) return null;
          if (currentToken.type !== 'text') {
            return currentToken;
          }
        // eslint-disable-next-line no-constant-condition
        } while (true);
      }
    }

    class LuceneApstraStringHighlightRules extends TextHighlightRules {
      constructor() {
        super();

        this.$rules = {
          start: [
            {
              token: 'keyword.operator',
              regex: '(AND|OR)\\b'
            }, {
              token: 'paren.lparen',
              regex: '\\('
            }, {
              token: 'paren.rparen',
              regex: '\\)'
            }, {
              token: 'string.quoted',
              regex: /"(:?[^\\\n\r"]+|\\(:?[nr\\/"]))*"/
            }, {
              token: 'property',
              regex: /(?:\\.|[^\s\-+&|!(){}[\]^"~*?:\\])+:/,
            }, {
              token: 'string',
              regex: /[\w*?./]+/
            },
            {
              token: 'text',
              regex: /\s+/
            }
          ],
        };
        this.normalizeRules();
      }
    }

    class LuceneApstraMode extends TextMode {
      $id = 'ace/mode/lucene-apstra';
      $behaviour = this.$defaultBehaviour;
      HighlightRules = LuceneApstraStringHighlightRules;
      completer = new LuceneApstraCompleter();
      validate(text) {
        try {
          const {lexErrors, parseErrors} = LuceneApstraStringParser.parse(text);
          return convertChevrotainErrors(lexErrors, parseErrors);
        } catch {}
        return [];
      }
    }

    exports.Mode = LuceneApstraMode;
    exports.HighlightRules = LuceneApstraStringHighlightRules;
  }
);

ace.require(['ace/mode/lucene-apstra']);
