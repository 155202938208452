import {Component, Fragment} from 'react';
import {Form, Icon} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {computed, makeObservable} from 'mobx';
import {
  uniq, times, transform, castArray, filter, map, includes, keys, isEmpty, head, values,
  flatten, assign, isString, isPlainObject,
} from 'lodash';
import {DropdownControl, Field, MapInput} from 'apstra-ui-common';

import PythonExpressionParser from '../../pythonExpression/PythonExpressionParser';
import NamedNodeFinder from '../../pythonExpression/NamedNodeFinder';
import TagsControl from '../../components/TagsControl';
import IBAContext from '../IBAContext';
import {NODE} from '../../pythonExpression/consts';

import './QueryTagFilterInput.less';

@observer
export default class QueryTagFilterInput extends Component {
  static contextType = IBAContext;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get nodeOptions() {
    return map(uniq(transform(castArray(this.props.values.graph_query), (result, text) => {
      if (text) {
        const {cst, lexErrors, parseErrors} = PythonExpressionParser.parse(text);
        if (!lexErrors.length && !parseErrors.length) {
          result.push(...NamedNodeFinder.run(cst, {namedNodesFunctions: new Set([NODE])}));
        }
      }
    }, [])), (nodeName) => ({key: nodeName, text: nodeName, value: nodeName}));
  }

  @computed.struct
  get optionsMap() {
    const {filter: filterValue} = this.props.value || {filter: {}};
    const usedNodes = keys(filterValue);
    const optionsMap = {};
    times(usedNodes.length, (index) => {
      optionsMap[index] = filter(this.nodeOptions, ({key: nodeName}) =>
        !includes(usedNodes, nodeName) || usedNodes[index] === nodeName);
    });
    return optionsMap;
  }

  @computed
  get knownTags() {
    const {blueprintTags} = this.context;
    return map(blueprintTags, ({label}) => label);
  }

  @computed
  get errors() {
    const {errors} = this.props;
    return transform(errors, (result, {filter: filterValue}) => {
      assign(result, filterValue);
    }, {});
  }

  render() {
    const {name, value: srcValue = {},
      required, schema, disabled, onChange} = this.props;
    const {optionsMap, knownTags, errors} = this;
    const getErrorsByKey = (key) => {
      if (isString(errors[key])) {
        return castArray(errors[key]);
      } else if (isPlainObject(errors[key])) {
        return map(errors[key], (value, key) => `${key}: ${value}`);
      }
      return [];
    };

    const value = {
      ...srcValue,
      operation: srcValue.operation ?? schema.properties?.operation?.default ?? 'and'
    };

    return (
      <Field required={required} label={schema.title ?? name} description={schema.description}>
        <QueryTagFilterOperation
          schema={schema.properties?.operation}
          operation={value.operation}
          onOperationChange={(operation) => onChange({...value, operation})}
        />
        <QueryTagFilterHeader
          isEmptyValue={isEmpty(value.filter)}
        />
        <MapInput
          value={value.filter}
          schema={{description: schema.properties?.filter?.description}}
          required={required}
          disabled={disabled}
          onChange={(filter) => onChange({...value, filter})}
          buttonText='Add Tag Filter'
          noItemsMessage='There are no tag filters'
          generateNewEntry={() => ['', {}]}
          maxItems={this.nodeOptions.length}
        >
          {({key, value, index, setEntryKey, setEntryValue}) =>
            <Fragment>
              <Field className='query-tag-filter' errors={getErrorsByKey(key)} width={16}>
                <Form.Group>
                  <Field key='key' width={4}>
                    <DropdownControl
                      fluid
                      placeholder='No names specified'
                      value={key}
                      options={optionsMap[index] || []}
                      onChange={(v) => setEntryKey(index, v)}
                    />
                  </Field>
                  <QueryTagFilterMatcherInput
                    value={value}
                    knownTags={knownTags}
                    disabled={disabled}
                    onChange={(v) => setEntryValue(index, v)}
                  />
                </Form.Group>
              </Field>
            </Fragment>
          }
        </MapInput>
      </Field>
    );
  }
}

export const QueryTagFilter = ({value}) => (
  <Fragment>
    {!!value?.operation && <><strong>{'Operation is '}</strong>{value?.operation}</>}
    {map(value?.filter, (value, node) => (
      <div key={node}>
        <strong>{node}</strong>
        {head(keys(value)) === 'is_in' ? ' has assigned ' : ' does not have assigned '}
        {map(flatten(values(value)), (tag) =>
          <span key={tag} className='ui tiny label'><i className='icon tag' />{tag}</span>)}
      </div>
    ))}
  </Fragment>
);

@observer
class QueryTagFilterMatcherInput extends Component {
  @computed
  get matcher() {
    const {value} = this.props;
    return head(keys(value));
  }

  @computed
  get tags() {
    const {value} = this.props;
    return value?.[this.matcher] || [];
  }

  options = [
    {key: 'is_in', text: 'Is In', value: 'is_in'},
    {key: 'not_in', text: 'Not In', value: 'not_in'},
  ];

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {disabled, onChange, knownTags} = this.props;
    return (
      <Fragment>
        <Field width={6}>
          <DropdownControl
            fluid
            placeholder='No matchers specified'
            value={this.matcher}
            options={this.options}
            onChange={(v) => onChange({[v]: []})}
          />
        </Field>
        <Field width={6}>
          <TagsControl
            placeholder='No tags specified'
            value={this.tags}
            disabled={disabled}
            knownTags={knownTags}
            onChange={(v) => onChange({[this.matcher]: v})}
          />
        </Field>
      </Fragment>
    );
  }
}

const operationDropdownOptions = [
  {key: 'and', value: 'and', text: 'and'},
  {key: 'or', value: 'or', text: 'or'},
];

const QueryTagFilterOperation = ({schema, operation, onOperationChange}) => (
  <Form.Group className='query-tag-filter-subheader'>
    <Field width={16} label={schema?.title} description={schema?.description}>
      <DropdownControl
        fluid
        value={operation}
        options={operationDropdownOptions}
        onChange={onOperationChange}
      />
    </Field>
  </Form.Group>
);

const QueryTagFilterHeader = ({isEmptyValue}) => (
  <Field className='query-tag-filter-header'>
    {!isEmptyValue && (
      <Form.Group className='query-tag-filter-subheader'>
        <Field width={4} label='Node Name' />
        <Field width={6} label='Matcher' />
        <Field width={6} label='Tags' />
        <Form.Field className='no-input-field hidden-icon'>
          <Icon name='remove' />
        </Form.Field>
      </Form.Group>
    )}
  </Field>
);
