import {Component} from 'react';
import {HashRouter} from 'react-router-dom';

import Dashboard from './Dashboard';
import IBAContext from '../IBAContext';
import {routes} from './IBA';

export default class DefaultDashboard extends Component {
  static pollingInterval = 10000;
  render() {
    const {
      dashboard, widgets, probes,
      blueprintId, processorDefinitions, allActiveNodes, systemIdMap,
      blueprintPermissions, anomalyRemediationUrl, systemsHrefs
    } = this.props;
    return (
      <HashRouter>
        <IBAContext.Provider
          value={{
            blueprintId, allActiveNodes, systemIdMap, processorDefinitions,
            anomalyRemediationUrl, routes, blueprintPermissions, systemsHrefs
          }}
        >
          <Dashboard
            dashboard={dashboard}
            widgets={widgets}
            probes={probes}
          />
        </IBAContext.Provider>
      </HashRouter>
    );
  }
}
