import {toJS} from 'mobx';
import {isEmpty, intersection, chain, countBy} from 'lodash';

import {eptWidth, eptHeight, canvasWidth} from './settings';
const halfWidth = eptWidth / 2;
const middle = canvasWidth / 2 - halfWidth;
const standardBasePosition = {x: middle - 10, y: 80};

export class Positioner {
  positions;
  epts;
  connectionsCount;

  constructor(epts, links) {
    this.epts = epts;
    this.positions = chain(epts)
      .filter('id')
      .map((eptItem) => toJS(eptItem.position))
      .value();
    this.connectionsCount = countBy(links, 'from');
  }

  // Checks whether ept in this position overlaps with any of already placed
  _isPositionOverlapping({x: px, y: py}) {
    return this.positions.some(({x, y}) => Math.abs(x - px) < eptWidth &&
      Math.abs(y - py) < eptHeight
    );
  }

  // Checks whether the ept in this position is off canvas bounds
  _isPositionOffbounds({x}) {
    return x < 20 || x > canvasWidth - eptWidth + 20;
  }

  // Attempts to place ept to given position
  _tryPlacingTo(ept, position) {
    if (!this._isPositionOverlapping(position) && !this._isPositionOffbounds(position)) {
      ept.moveTo(position);
      this.positions.push(position);
      return true;
    }
    return false;
  }

  // Attempts to position the ept primitive on the canvas.
  // If not linked primitive/AP supplied looks for the suitable candidate.
  position(ept, linkedTo, activePrimitiveId) {
    const inputTypes = toJS(ept.inputTypes);
    const connection = linkedTo || chain(this.epts)
      .filter((eptItem, id) => {
        const {outputTypes, outputIsFlexible} = toJS(eptItem);

        // Filter in EPTs that either have untyped output
        // OR their output types are intersected with the source's input ones
        // AND are not the target EPT itself
        return ept.id !== id &&
          ((!outputTypes && outputIsFlexible) ||
          !isEmpty(intersection(inputTypes, outputTypes)));
      })
      // Sort by stacking priority:
      // * selected candidate comes always first
      // * otherwise comes the candidate with less connections
      .sortBy(({id}) => (id === activePrimitiveId && -1) || this.connectionsCount[id] || 0)
      // And pick the first value
      .first()
      .value();

    // Initial position to start searching placement from
    let basePosition = standardBasePosition;
    if (connection !== undefined && connection.id) {
      basePosition = {x: connection.position.x, y: connection.position.y + eptHeight + 30};
      if (this._isPositionOffbounds(basePosition)) {
        basePosition = standardBasePosition;
      }
    }

    // Iterative placement attempts
    for (let ky = 0; ky < 50; ky++) {
      for (let kx = 0; kx < 300; kx += 27) {
        if (
          this._tryPlacingTo(ept, {x: basePosition.x + kx, y: basePosition.y + (eptHeight + 30) * ky}) ||
          this._tryPlacingTo(ept, {x: basePosition.x - kx, y: basePosition.y + (eptHeight + 30) * ky})
        ) return connection;
      }
    }
    return connection;
  }
}
