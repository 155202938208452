import cx from 'classnames';
import React from 'react';
import {useResizeDetector} from 'react-resize-detector';

import {BottomPanel} from './BottomPanel';
import {MainSidebarMenu} from './MainSidebarMenu';
import {MainSidebarSize} from './types';

import './MainSidebar.less';
import {StateContextProvider} from './StateContext';
import {Widgets} from './Widgets/Widgets';

type MainSidebarProps = {
  defaultSize?: MainSidebarSize;
  info?: string | React.ReactElement;
  infoHref?: string;
  onSizeChanged?: (size: MainSidebarSize) => void;
  withMessage?: boolean;
};

const MainSidebar: React.FC<MainSidebarProps> = ({
  defaultSize = 'expanded',
  info,
  infoHref = '#',
  onSizeChanged,
  withMessage
}) => {
  const [openMenuKey, openMenu] = React.useState<string | undefined>();
  const {height, ref} = useResizeDetector();
  const [size, setSize] = React.useState<MainSidebarSize>(defaultSize);
  React.useEffect(
    () => {
      onSizeChanged?.(size);
    },
    [onSizeChanged, size]
  );
  const closeMenu = React.useCallback(
    () => openMenu(undefined),
    [openMenu]
  );
  const isExpanded = size === 'expanded';
  return (
    <StateContextProvider
      closeMenu={closeMenu}
      height={height}
      openMenu={openMenu}
      openMenuKey={openMenuKey}
      setSize={setSize}
      size={size}
    >
      <div
        ref={ref}
        className={cx('main-sidebar', size, {'with-cluster-message': withMessage})}
        role='navigation'
        aria-label='Sidebar'
      >
        <div
          className='content'
        >
          <div
            className={cx(
              'ui borderless inverted vertical left fixed menu',
              {open: openMenuKey}
            )}
          >
            <div className='item logo'>
              <a className='logo-main' href='#' aria-label='Home'>
                <div
                  className='icon'
                  data-inverted='true'
                  data-position='right center'
                />
                <div className='text'><b>{'Juniper'}</b>{' Apstra™'}</div>
              </a>
              {info && isExpanded &&
                <a className='info' children={info} href={infoHref} />
              }
            </div>
            {isExpanded && <Widgets position='top' />}
            <MainSidebarMenu
              className='scrollable-menu'
            />
            {isExpanded && <Widgets position='bottom' />}
            <BottomPanel />
          </div>
        </div>
      </div>
    </StateContextProvider>
  );
};

export default MainSidebar;
