const syslogConfigSchema = [
  {
    name: 'address',
    required: true,
    schema: {type: 'string', title: 'IP Address'}
  },
  {
    name: 'port',
    required: true,
    schema: {type: 'integer', title: 'Port', default: 514, minimum: 1, maximum: 65535}
  },
  {
    name: 'protocol',
    required: true,
    schema: {type: 'string', title: 'Protocol', default: 'udp', enum: ['udp', 'tcp']}
  },
  {
    name: 'facility',
    required: true,
    schema: {type: 'string', title: 'Facility', enum: [
      'kern',
      'user',
      'mail',
      'daemon',
      'auth',
      'syslog',
      'lpr',
      'news',
      'uucp',
      'authpriv',
      'ftp',
      'cron',
      'local0',
      'local1',
      'local2',
      'local3',
      'local4',
      'local5',
      'local6',
      'local7',
    ]}
  },
  {
    name: 'timezone',
    required: true,
    schema: {type: 'string', title: 'Time Zone', enum: []},
  }
];

export default syslogConfigSchema;
