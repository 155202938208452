import {onEnterKeyHandler} from 'apstra-ui-common';
import cx from 'classnames';
import React from 'react';
import {Icon} from 'semantic-ui-react';

import './Widget.less';

type WidgetProps = React.PropsWithChildren<{
  title?: string | React.ReactElement
}>;

export const Widget: React.FC<WidgetProps> = ({children, title}) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <div className='widget'>
      <div className='header'>
        <div className='title'>{title}</div>
        <div
          className='expand-button'
          onClick={() => setExpanded(!expanded)}
          onKeyDown={onEnterKeyHandler(() => setExpanded(!expanded))}
          role='button'
          tabIndex={0}
        >
          <Icon className={`apstra-sidebar-icon apstra-sidebar-${expanded ? 'collapse-widget' : 'expand-widget'}`} />
        </div>
      </div>
      <div className={cx('content', {expanded})}>
        {children}
      </div>
    </div>
  );
};
