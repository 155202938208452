import cx from 'classnames';
import {filter, isEmpty, sortBy} from 'lodash';
import React from 'react';
import {Icon} from 'semantic-ui-react';

import {Link} from './Link';
import {MenuItem, useMainSidebar} from './MainSidebarProvider';

import {Hoverable} from './Hoverable';
import {PopupMenu} from './PopupMenu';
import {useStateContext} from './StateContext';
import {SubmenuContent} from './SubmenuContent';
import {useTrackOutsideClick} from './useTrackOutsideClick';

import './TopSidebarItem.less';

type TopSidebarMenuProps = {
  menuItem: MenuItem;
}

export const TopSidebarItem: React.FC<TopSidebarMenuProps> = ({
  menuItem
}) => {
  const itemRef = React.useRef<HTMLDivElement>(null);
  const {closeMenu, openMenu, openMenuKey, size} = useStateContext();
  const {menuItems} = useMainSidebar();
  const {iconClass, label} = menuItem;
  const children = React.useMemo(
    () => sortBy(filter(menuItems, {parent: menuItem.menuKey}), 'order'),
    [menuItem.menuKey, menuItems]
  );
  const isExpandable = !isEmpty(children);
  const isExpanded = size === 'expanded';
  const isOpen = openMenuKey === menuItem.menuKey;
  const dropdownProps = React.useMemo(
    () => (isExpandable ? {
      onFocus: () => openMenu(menuItem.menuKey!),
      // onBlur: () => closeMenu(),
      onMouseDown: () => {
        if (isOpen) {
          closeMenu();
        } else {
          openMenu(menuItem.menuKey!);
        }
      }
    } : {}),
    [closeMenu, isExpandable, isOpen, menuItem.menuKey, openMenu]
  );
  useTrackOutsideClick({
    enabled: isOpen && !isExpanded,
    onOutsideClick: closeMenu,
    ref: itemRef
  });
  const MenuWrapper = isExpanded ? React.Fragment : PopupMenu;

  const expandIconClass = `apstra-sidebar-${isOpen ? 'collapse' : 'expand'}`;
  const itemContent = (
    <div
      ref={itemRef}
      className={cx('top-sidebar-item', {expanded: isExpanded})}
    >
      <Hoverable
        highlighted={isOpen || menuItem.active}
      >
        <div
          className={cx('dropdown-container', {open: isOpen})}
          tabIndex={0}
          role='button'
          aria-label={label}
          data-tooltip={label}
          data-position='right center'
          data-inverted=''
          {...dropdownProps}
        >
          {iconClass && <Icon className={cx('apstra-sidebar-icon', 'top-sidebar-item-icon', iconClass)} />}
          {isExpanded && (React.isValidElement(label) ?
            label :
            label && <div className='text'>{label}</div>
          )}
          {isExpanded && isExpandable && <Icon className={cx('apstra-sidebar-icon', expandIconClass)} />}
        </div>
      </Hoverable>
      {isOpen && (
        <MenuWrapper>
          <SubmenuContent menuItems={children} />
        </MenuWrapper>
      )}
    </div>
  );

  return isExpandable ? itemContent : (
    <Link
      children={itemContent}
      menuItem={menuItem}
    />
  );
};
