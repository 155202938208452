import {observer} from 'mobx-react';
import {useState, useEffect, useMemo, useCallback, useRef} from 'react';
import {Modal, Button, Grid, Menu} from 'semantic-ui-react';
import {map, filter, values, forEach, some, flatten, keyBy, size, uniqBy, isArray, sortBy} from 'lodash';
import {Checkbox} from 'apstra-ui-common';

import {useCablingMapStore} from '../store/useCablingMapStore';
import {useTooltip} from '../../components/graphs/GraphTooltips';
import LinkProperties from './LinkProperties';
import Link from '../store/Link';
import Step from '../store/Step';
import {ctrlStages} from '../const.js';

import './NodesLinks.less';

const NodesLinks = ({data, close}) => {
  const scrollRef = useRef();
  const [selectedLinks, setSelection] = useState({});
  const {cablingMap} = useCablingMapStore();
  const {sharedTooltip} = useTooltip();
  const {usedIps, deviceProfiles, nodes} = cablingMap;

  const linkNodes = useMemo(
    () => {
      // Data prop might be either an array of node ids
      // or a link
      return map(isArray(data) ? data : data.nodesIds, (id) => nodes[id]);
    },
    [data, nodes]
  );

  // If data is a link, store its id to focus
  useEffect(
    () => {
      scrollRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
    },
    []
  );

  // We need all links that affect either of the nodes to calculate available interfaces
  const links = uniqBy(flatten(map(linkNodes, 'myLinks')), 'id');

  // Only links between both selected nodes will be managed
  const visibleLinks = sortBy(
    filter(
      links,
      (link) => link.exists && link.contains(linkNodes, true) && !link.isAggregated
    ),
    'timestamp'
  );

  // Precalculate deviceProfile and its port groups
  const nodesDeviceProfiles = useMemo(() => map(
    linkNodes, ({isExternal, deviceProfileId}) => {
      if (isExternal) {
        return {deviceProfile: null, portGroups: null};
      }

      const deviceProfile = deviceProfiles[deviceProfileId];
      return deviceProfile ?
        {deviceProfile, portGroups: deviceProfile.portGroups} :
        {deviceProfile: null, portGroups: null};
    }
  ), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Only links between both selected nodes must be editable
    // Backing em' up
    forEach(visibleLinks, (link) => link.backup());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const hasChanges = some(links, 'hasChanges');
  const hasErrors = some(visibleLinks, (link) => (!link.isValid || link.containsDuplicates(usedIps)));

  // Toggle single link selection
  const toggleLinkSelection = (link) => {
    if (selectedLinks[link.id]) {
      delete selectedLinks[link.id];
    } else {
      selectedLinks[link.id] = link;
    }
    setSelection({...selectedLinks});
  };

  // Creates the new link between the selected two
  const createEmptyLink = useCallback(() => {
    const link = new Link(...linkNodes, cablingMap);

    const baseLabel = `${linkNodes[0].label}<->${linkNodes[1].label}`;
    const linkLabels = keyBy(values(cablingMap.links), 'label');
    let label = baseLabel;

    let index = 0;
    while (linkLabels[label]?.exists) {
      label = `${baseLabel} [${++index}]`;
    }
    link.label = label;
    link.stage = ctrlStages.ADD;
    return link;
  }, [linkNodes, cablingMap]);

  // Handling port selection
  const onPortSelect = useCallback((portId, transformationId, ifc, endpoint) => {
    endpoint.fillWith({
      nodeId: endpoint.nodeId,
      portId,
      transformationId,
      ifc,
      ipv4Addr: endpoint.ipv4Addr,
      ipv6Addr: endpoint.ipv6Addr
    });
  }, []);

  // Create an empty link
  const addBlankLink = useCallback(() => {
    cablingMap.addLink(createEmptyLink());
  }, [cablingMap, createEmptyLink]);

  // Remove a list of links
  const removeLinks = (linksToRemove) => {
    forEach(
      flatten([linksToRemove]),
      (link) => {
        if (link.stage === ctrlStages.ADD) {
          // Newly created links could be deleted immediately
          cablingMap.deleteLink(link);
        } else {
          // Existing links must be marked for deletion first
          // and removed upon commit or restored otherwize
          link.markForDeletion();
        }
        if (selectedLinks[link.id]) {
          toggleLinkSelection(link);
        }
      }
    );
  };

  // Remove selected links
  const removeSelected = () => {
    removeLinks(values(selectedLinks));
  };

  // Commit changes
  const commitChanges = () => {
    const change = [];
    forEach(links, (link) => {
      if (!link.exists) {
        // Removes existing links marked for deletion
        change.push(Step.deletion(link));
        cablingMap.deleteLink(link);
      } else {
        // Commits the changes for all others
        const operation = link.stage;
        const linkHasChanges = link.hasChanges;
        const previousState = link.isUpdate ? link.restore(true) : null;
        link.commit();
        if (linkHasChanges || operation === ctrlStages.ADD) {
          change.push(new Step(operation, link, previousState));
        }
      }
    });
    cablingMap.changes.register(change);
    close();
  };

  // Reverts all changes user made to links
  const discardChanges = () => {
    // When user discards their changes, ...
    forEach(links, (link) => {
      if (link.stage === ctrlStages.ADD) {
        // ... newly added links must be removed
        cablingMap.deleteLink(link);
      } else {
        // and all the others (changed/marked for deletion) - restored
        link.restore();
      }
    });
    close();
  };

  const linkActions = {
    remove: removeLinks,
    portSelect: onPortSelect,
    toggle: toggleLinkSelection,
    aggregateSelection: toggleBatchSelection
  };

  const selectedLinksCount = size(selectedLinks);
  const linksCount = visibleLinks.length;

  // Toggle selection of all links
  const toggleBatchSelection = () => {
    setSelection({...(selectedLinksCount ? {} : keyBy(visibleLinks, 'id'))});
  };

  return (
    <Modal
      open
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      onClose={discardChanges}
      size='fullscreen'
      className='nodes-links'
      mountNode={sharedTooltip.mountRef}
    >
      <Modal.Header>{'Links Management'}</Modal.Header>
      <Modal.Content scrolling>
        <Grid className='port-maps'>
          <Grid.Row columns={2} className='ctrl-dps'>
            {
              map(
                nodesDeviceProfiles,
                ({deviceProfile}, nodeIndex) => {
                  const {label, isExternal} = linkNodes[nodeIndex];
                  return (
                    <Grid.Column key={nodeIndex}>
                      {
                        isExternal ?
                          <div>{'External Node: '}<strong>{label}</strong></div> :
                          <>
                            <div>{'Node: '}<strong>{label}</strong></div>
                            <div>
                              {'Device profile: '}
                              <strong>{deviceProfile ? deviceProfile.label : 'Not selected'}</strong>
                            </div>
                          </>
                      }
                    </Grid.Column>
                  );
                }
              )
            }
          </Grid.Row>
        </Grid>
        {
          map(
            visibleLinks,
            (link) => (
              <LinkProperties
                key={link.id}
                ref={link.id === data?.id ? scrollRef : undefined}
                {...{link, linkNodes, links, nodesDeviceProfiles, selectedLinks, linkActions}}
              />
            )
          )
        }
      </Modal.Content>
      <Modal.Actions>
        {
          !!links.length &&
            <div className='bulk-actions'>
              <Menu secondary>
                {
                  !!linksCount &&
                    <Menu.Item>
                      <Checkbox
                        label='Select All'
                        onClick={toggleBatchSelection}
                        indeterminate={!!selectedLinksCount && selectedLinksCount < linksCount}
                        checked={selectedLinksCount === linksCount}
                      />
                    </Menu.Item>
                }
                {
                  !!selectedLinksCount &&
                    <>
                      <Menu.Item header>{`With ${selectedLinksCount} selected:`}</Menu.Item>
                      <Menu.Item
                        name='Delete'
                        onClick={removeSelected}
                      />
                    </>
                }
              </Menu>
            </div>
        }
        <Button
          secondary
          content='Create New Link'
          onClick={addBlankLink}
        />
        <Button
          primary
          content='Apply Changes'
          disabled={!hasChanges || hasErrors}
          onClick={commitChanges}
        />
        <Button
          content='Discard'
          onClick={discardChanges}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default observer(NodesLinks);
