import {Component} from 'react';
import {has} from 'lodash';

import MultiUploadModal from '../../components/MultiUploadModal';
import {ImportError} from '../../components/ImportModal';
import {
  addServiceNameIfNeeded,
  getServiceName,
  requestServiceRegistry,
  transformRawDataToRequest
} from '../telemetryServiceRegistryUtils';

export default class ServiceSchemasBatchImportModal extends Component {
  uploadFile = async ({file, signal}) => {
    if (file instanceof File) {
      const reader = new FileReader();
      const result = await new Promise((resolve) => {
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsText(file);
      });
      try {
        const parsedSchema = JSON.parse(result);
        const serviceName = getServiceName(file);
        const schema = (has(parsedSchema, 'application_schema')) ?
          addServiceNameIfNeeded({serviceName, schema: parsedSchema}) :
          transformRawDataToRequest(serviceName, parsedSchema);
        return requestServiceRegistry({schema, signal});
      } catch (error) {
        throw new ImportError(`JSON parse error: ${error.toString()}`);
      }
    }
  };

  render() {
    const {...props} = this.props;

    return (
      <MultiUploadModal
        header='Import Service Schemas'
        uploadFile={this.uploadFile}
        actionIcon='download'
        action='Import'
        {...props}
      />
    );
  }
}
