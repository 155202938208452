import React from 'react';
import {map} from 'lodash';
import {Label} from 'semantic-ui-react';
import {natsort} from 'apstra-ui-common';

type TagsProps = {
  tags: string[],
};

export const Tags: React.FC<TagsProps> = ({tags = []}) => {
  const orderedTags = React.useMemo(() => tags.sort(natsort), [tags]);
  return (
    <>
      {map(
        orderedTags,
        (tag) => <Label key={tag} icon='tag' content={tag} />
      )}
    </>
  );
};
