import cx from 'classnames';
import {useMemo, useState} from 'react';
import {map, isUndefined, transform, compact, debounce} from 'lodash';
import {withResizeDetector} from 'react-resize-detector';
import {Pack, hierarchy} from '@visx/hierarchy';
import {Group} from '@visx/group';
import {brandColorNames} from 'apstra-ui-common';

import ChartPopup from './ChartPopup';

import './CirclePackChart.less';

const getNextColor = (index) => {
  return brandColorNames[index % brandColorNames.length];
};

const CirclePackChart = ({
  data, mode, width: chartWidth, className, dimensions, targetRef,
  padding, defaultColor, processPopupContent, circleClassName, debounceWait,
  minParentSize,
}) => {
  const [popupDescription, setPopupDescription] = useState(null);
  const showPopup = debounce((e, data, isLeaf) => {
    setPopupDescription({
      node: e.target,
      header: compact([isLeaf ? 'VN' : 'RZ', data?.label]).join(': '),
      content: processPopupContent(data),
    });
  }, debounceWait);
  const hidePopup = () => {
    showPopup.cancel();
    setPopupDescription(null);
  };
  const {height: chartHeight} = dimensions[mode];
  const root = useMemo(() => {
    const pack = {
      children: transform(data, (result, item, index) => {
        const addedColorItem = {
          color: getNextColor(index),
          ...item,
          children: map(item.children, (child) => ({
            color: getNextColor(index),
            ...child,
          }))
        };
        result.push(addedColorItem);
      }, []),
      name: 'root'
    };
    return hierarchy(pack)
      .sum((node) => !isUndefined(node.children) ? minParentSize : 1)
      .sort((a, b) =>
        (a?.data ? 1 : -1) - (b?.data ? 1 : -1) ||
        (a.children ? 1 : -1) - (b.children ? 1 : -1)
      );
  }, [data, minParentSize]);

  return (
    <div
      ref={targetRef}
      className={cx('circle-pack-chart-graph-container', {expandable: mode !== 'expanded'})}
    >
      <svg className={cx('circle-pack-chart-layout', className)} width={chartWidth} height={chartHeight}>
        <Pack root={root} size={[chartWidth, chartHeight]} padding={padding}>
          {(packData) => {
            const circles = packData.descendants().slice(1);
            return (
              <Group onMouseLeave={hidePopup}>
                {circles.map(({x, y, r, data: {children, color = defaultColor, ...data}}, i) => {
                  const isLeaf = isUndefined(children);
                  if (!x || !y || !r) return null;
                  return (
                    <circle
                      key={`circle-${i}`}
                      r={r}
                      cx={x}
                      cy={y}
                      className={cx('node', color, circleClassName(data, isLeaf), {
                        leaf: isLeaf,
                        parent: !isLeaf
                      })}
                      onMouseEnter={(e) => showPopup(e, data, isLeaf)}
                    />
                  );
                })}
              </Group>
            );
          }}
        </Pack>
      </svg>
      <ChartPopup popupDescription={popupDescription} />
    </div>
  );
};

CirclePackChart.defaultProps = {
  mode: 'compact',
  dimensions: {
    compact: {
      height: 100,
    },
    expanded: {
      height: 400,
    },
  },
  padding: 10,
  defaultColor: 'blue',
  processPopupContent: (data) => data,
  circleClassName: () => null,
  debounceWait: 200,
  minParentSize: 100,
};

export default withResizeDetector(CirclePackChart, {handleWidth: true});
