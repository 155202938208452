import {PureComponent} from 'react';
import {Label, Popup} from 'semantic-ui-react';
import {formatBytes, formatSeconds} from 'apstra-ui-common';

import {ReactComponent as StagePersisted} from '../../../styles/icons/iba/stage-persisted.svg';

export default class PersistedLabel extends PureComponent {
  getLabelDetail = () => {
    const {duration, diskUsage} = this.props;
    if (!diskUsage) {
      return formatSeconds(duration);
    }
    return `${formatSeconds(duration)} / ${formatBytes(diskUsage.size || 0)}`;
  };

  render() {
    const {diskUsage, duration} = this.props;
    const labelDetail = this.getLabelDetail();
    return (
      <Popup
        trigger={
          <Label
            icon={<StagePersisted className='stage-icon' />}
            content='Persisted'
            detail={labelDetail}
          />
        }
      >
        <div>
          <strong>{'Retention duration: '}</strong>
          {formatSeconds(duration)}
        </div>
        {diskUsage && (
          <div>
            <strong>{'Consumed disk size: '}</strong>
            {formatBytes(diskUsage.size || 0)}
          </div>
        )}
      </Popup>
    );
  }
}
