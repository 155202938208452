import {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {map, get, keys, find, head, size, isObject} from 'lodash';
import HTTPStatus from 'http-status';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {computed, makeObservable} from 'mobx';
import {FetchDataError, GenericErrors, ResourceDeletionModal, interpolateRoute, request} from 'apstra-ui-common';

import IBAContext from '../IBAContext';

@observer
export default class WidgetDeletionModal extends Component {
  static contextType = IBAContext;

  static propTypes = {
    open: PropTypes.bool,
    widgets: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })),
    onSuccess: PropTypes.func,
    onClose: PropTypes.func,
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get firstWidgetId() {
    return get(head(this.props.widgets), ['id']);
  }

  @computed
  get batchDeletion() {
    return size(this.props.widgets) > 1;
  }

  getWidgetLabel = (widgetId) => {
    return get(find(this.props.widgets, {id: widgetId}), ['label'], widgetId);
  };

  deleteWidget = () => request(
    interpolateRoute(this.context.routes.widgetDetails,
      {blueprintId: this.context.blueprintId, widgetId: this.firstWidgetId}
    ),
    {method: 'DELETE'}
  );

  deleteWidgets = () => request(
    interpolateRoute(this.context.routes.widgetsBatchDelete, {blueprintId: this.context.blueprintId}),
    {method: 'POST', body: JSON.stringify(map(this.props.widgets, 'id')), throwOnHttpStatus: [HTTPStatus.MULTI_STATUS]}
  );

  submit = () => {
    return this.batchDeletion ? this.deleteWidgets() : this.deleteWidget();
  };

  renderDeleteError = ({widgetId, label, message, dashboards}) => {
    const {blueprintId} = this.context;

    const title = (
      <Fragment>
        {'Widget '}
        <b>
          <Link
            to={`/blueprints/${blueprintId}/analytics/widgets/${widgetId}`}
            style={{color: 'inherit'}}
          >
            {label}
          </Link>
        </b>
        {' was not deleted'}
      </Fragment>
    );

    return (
      <GenericErrors header={title} errors={message}>
        {map(dashboards, ({id, label}, index) =>
          <div key={index}>
            <Link to={`/blueprints/${blueprintId}/analytics/dashboards/${id}`}>{label}</Link>
          </div>
        )}
      </GenericErrors>
    );
  };

  renderBatchDeleteError = (errors) => {
    const {renderBatchDeleteWidgetError, getWidgetLabel} = this;

    const widgetIds = keys(errors);

    if (widgetIds.length === 1) {
      const firstWidgetId = widgetIds[0];
      return this.renderDeleteError({
        widgetId: firstWidgetId,
        label: getWidgetLabel(firstWidgetId),
        dashboards: errors[firstWidgetId].dashboards,
        message: errors[firstWidgetId].message
      });
    }

    return (
      <GenericErrors
        header='Some widgets were not deleted'
        errors={widgetIds.map((widgetId) =>
          renderBatchDeleteWidgetError({
            widgetId,
            label: getWidgetLabel(widgetId),
            message: errors[widgetId].message,
            dashboards: errors[widgetId].dashboards
          }))}
      />
    );
  };

  renderBatchDeleteWidgetError = ({widgetId, label, message, dashboards}) => {
    const {context: {blueprintId}} = this;
    return (
      <div>
        <b><Link to={`/blueprints/${blueprintId}/analytics/widget/${widgetId}`}>{label}</Link></b>
        <div>{message}</div>
        {map(dashboards, ({id, label}, index) =>
          <div key={index}>
            <Link to={`/blueprints/${blueprintId}/analytics/dashboards/${id}`}>{label}</Link>
          </div>
        )}
      </div>
    );
  };

  renderError = (error, props) => {
    const errorCanBeRendered = error.responseBody && (
      this.batchDeletion ? isObject(error.responseBody.errors) : error.responseBody.dashboards
    );

    if (!errorCanBeRendered) {
      return <FetchDataError error={error} {...props} />;
    }

    if (!this.batchDeletion) {
      return this.renderDeleteError({
        widgetId: this.firstWidgetId,
        label: this.getWidgetLabel(this.firstWidgetId),
        message: error.responseBody.errors,
        dashboards: error.responseBody.dashboards
      });
    }

    return this.renderBatchDeleteError(error.responseBody.errors);
  };

  render() {
    const {batchDeletion, props: {widgets}} = this;
    const widgetLabels = map(widgets, 'label');

    return (
      <ResourceDeletionModal
        resourceLabel={widgetLabels[0]}
        resourceLabels={widgetLabels}
        resourceName='Widget'
        resourceNamePlural='Widgets'
        submit={this.submit}
        renderError={this.renderError}
        batchDeletion={batchDeletion}
        disableResubmission
        {...this.props}
      />
    );
  }
}
