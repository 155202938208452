import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Button, Step, Label, Icon, Form, Message, Popup} from 'semantic-ui-react';
import {observable, computed, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {
  map, flatMap, transform, find, some, every, compact, includes,
  keys, values, pull, sortBy, toLower, startsWith, set, get, groupBy,
} from 'lodash';
import cx from 'classnames';
import {LinePath} from '@visx/shape';
import {curveBasis} from '@visx/curve';
import {FuzzySearchBox, formatSeconds, withRouter} from 'apstra-ui-common';

import {ReactComponent as StageAnomaly} from '../../../styles/icons/iba/stage-anomaly.svg';
import {ReactComponent as StagePersisted} from '../../../styles/icons/iba/stage-persisted.svg';
import {ReactComponent as StageStreaming} from '../../../styles/icons/iba/stage-streaming.svg';
import {ReactComponent as StageDynamic} from '../../../styles/icons/iba/stage-dynamic.svg';

import IBAContext from '../IBAContext';
import ProcessorModal from './ProcessorModal';
import ProcessorIcon from './ProcessorIcon';
import generateProbeURI from '../generateProbeURI';
import {getStageByName, processorCanRaiseAnomalies} from '../stageUtils';
import TreeProbeGraph from './graphs/probeGraph/TreeProbeGraph';
import {STAGE_DYNAMIC_POPUP_MESSAGE} from '../consts';
import {EllipsisPopup} from '../../components/EllipsisPopup';

import './ProbeGraph.less';

@observer
export default class ProbeGraph extends Component {
  static contextType = IBAContext;

  static defaultProps = {
    connectorsCanvasWidth: 25,
    processorStepHeight: 46,
    stageStepHeight: 33,
    processorSpacing: 14,
    editable: false,
    errors: [],
  };

  @observable filter = '';

  @action
  updateFilter = (filter) => {
    this.filter = filter;
  };

  matchStage = ({stage, processor, valueToSearch}) => {
    const matches = (string) => includes(toLower(string), valueToSearch);
    if (matches(stage.name)) return true;
    if (some(stage.tags, (tag) => matches(tag))) return true;
    if (matches(processor.name)) return true;
    const processorDefinition = find(this.context.processorDefinitions, {name: processor.type});
    return processorDefinition && matches(processorDefinition.label);
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get matchedStages() {
    const {probe} = this.props;
    const valueParts = compact(toLower(this.filter).split(/\s+/));
    if (!valueParts.length) return {};
    return transform(
      flatMap(
        probe.processors,
        (processor) => map(
          find(this.context.processorDefinitions, {name: processor.type}).outputs,
          (metadata, outputName) => ({processor, stage: find(probe.stages, {name: processor.outputs[outputName]})})
        )
      ),
      (result, {stage, processor}) => {
        if (every(valueParts, (valueToSearch) => this.matchStage({stage, processor, valueToSearch}))) {
          result.push(stage.name);
        }
      },
      []
    );
  }

  get warningCountByStageName() {
    const {editable, probe} = this.props;
    if (editable) return {};
    return transform(probe.stages, (result, {name, warnings}) => {
      result[name] = warnings;
    }, {});
  }

  render() {
    const {editable, actionInProgress, probeNavigationExpanded, ...props} = this.props;
    const {filter, updateFilter, matchedStages, warningCountByStageName} = this;
    const graphProps = {editable, actionInProgress, warningCountByStageName, ...props};
    const filterActivated = !!filter.length;
    return (
      <Fragment>
        {!editable &&
          <Form className='probe-graph-filter'>
            <FuzzySearchBox
              size='small'
              placeholder='Search stages...'
              disabled={actionInProgress}
              initialValue={filter}
              onChange={updateFilter}
            />
          </Form>
        }
        {!filterActivated &&
          <div className='probe-graph-controllers'>
            <Button
              className='expand-button'
              basic
              circular
              size='tiny'
              icon={probeNavigationExpanded ? 'angle left' : 'angle right'}
              onClick={this.props.toggleProbeNavigationExpanded}
              aria-label='Expand probe graph'
            />
          </div>}
        <div className='probe-graph'>
          {filterActivated ?
            <FilteredProbeGraph
              matchedStages={matchedStages}
              {...graphProps}
            />
          : (probeNavigationExpanded ? <TreeProbeGraph {...graphProps} />
              : <FullProbeGraph {...graphProps} />)
          }
        </div>
      </Fragment>
    );
  }
}

@observer
export class FilteredProbeGraph extends Component {
  render() {
    const {
      matchedStages,
      warningCountByStageName, currentStageName,
      highlights, highlightStageAndRelatedProcessors, highlightCurrentEntity,
      setCurrentStageName, editable,
      stageStepHeight, probe,
    } = this.props;
    return (
      matchedStages.length ?
        <div className='navigation-container'>
          <Step.Group vertical fluid>
            {map(matchedStages, (stageName) => {
              const stage = getStageByName({probe, stageName});
              return (
                <StageStep
                  key={stageName}
                  stageStepHeight={stageStepHeight}
                  stageName={stageName}
                  active={stageName === currentStageName}
                  editable={editable}
                  highlights={highlights}
                  highlightStageAndRelatedProcessors={highlightStageAndRelatedProcessors}
                  highlightCurrentEntity={highlightCurrentEntity}
                  setCurrentStageName={setCurrentStageName}
                  anomalyCount={stage.anomaly_count}
                  warningCount={warningCountByStageName[stageName]}
                />
              );
            })}
          </Step.Group>
        </div>
      :
        <Message
          info
          icon='info circle'
          content='No match.'
        />
    );
  }
}

@observer
export class FullProbeGraph extends Component {
  static contextType = IBAContext;

  @observable processorModalOpen = false;

  @action
  openProcessorModal = () => {
    this.processorModalOpen = true;
  };

  @action
  closeProcessorModal = () => {
    this.processorModalOpen = false;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get processorsWithErrors() {
    return this.props.errors.reduce((result, error) => {
      if ('processorName' in error && startsWith(error.type, 'processor')) {
        result[error.processorName] = true;
      }
      return result;
    }, {});
  }

  @computed get processorStagesWithErrors() {
    return this.props.errors.reduce((result, error) => {
      if (error.type === 'stageProperty') set(result, [error.processorName, error.stageName], true);
      return result;
    }, {});
  }

  @computed get hasConnections() {
    return some(this.props.probe.processors, (processor) => some(processor.inputs));
  }

  render() {
    const {
      probe, warningCountByStageName, currentStageName, currentProcessor,
      highlights, highlightProcessorAndRelatedStages, highlightStageAndRelatedProcessors, highlightCurrentEntity,
      setCurrentProcessorName, setCurrentStageName, editable, actionInProgress,
      connectorsCanvasWidth, processorStepHeight, stageStepHeight, processorSpacing,
    } = this.props;
    return (
      <Fragment>
        <ConnectorsCanvas
          width={connectorsCanvasWidth}
          processorStepHeight={processorStepHeight}
          stageStepHeight={stageStepHeight}
          processorSpacing={processorSpacing}
          processors={probe.processors}
          highlights={highlights}
        />
        <div className='navigation-container' style={{paddingLeft: this.hasConnections ? connectorsCanvasWidth : 0}}>
          {probe.processors.map((processor, index) => {
            const processorDefinition = find(this.context.processorDefinitions, {name: processor.type});
            const hasWarnings = some(processor.outputs, (stageName) => warningCountByStageName[stageName]);
            const hasAnomalies = some(processor.outputs, (stageName) => {
              const {anomaly_count: anomalyCount} = getStageByName({probe, stageName});
              return anomalyCount > 0;
            });
            const hasProcessorError = get(probe, ['last_error', 'processor']) === processor.name ||
              this.processorsWithErrors[processor.name];
            return (
              <Step.Group
                key={processor.name}
                className={cx({
                  'has-warnings': hasWarnings,
                  'has-errors': hasAnomalies || hasProcessorError || !!this.processorStagesWithErrors[processor.name],
                })}
                style={{marginTop: index ? processorSpacing : 0}}
                vertical
                fluid
              >
                <ProcessorStep
                  processorStepHeight={processorStepHeight}
                  processor={processor}
                  active={!currentStageName && currentProcessor && processor.name === currentProcessor.name}
                  editable={editable}
                  highlights={highlights}
                  highlightProcessorAndRelatedStages={highlightProcessorAndRelatedStages}
                  highlightCurrentEntity={highlightCurrentEntity}
                  setCurrentProcessorName={setCurrentProcessorName}
                  hasProcessorError={hasProcessorError}
                />
                {map(processorDefinition.outputs, (metadata, outputName) => {
                  const stageName = processor.outputs[outputName];
                  const stage = getStageByName({probe, stageName});
                  return <StageStep
                    key={stageName}
                    stageStepHeight={stageStepHeight}
                    stageName={stageName}
                    active={stageName === currentStageName}
                    editable={editable}
                    highlights={highlights}
                    highlightStageAndRelatedProcessors={highlightStageAndRelatedProcessors}
                    highlightCurrentEntity={highlightCurrentEntity}
                    setCurrentStageName={setCurrentStageName}
                    anomalyCount={stage.anomaly_count}
                    warningCount={warningCountByStageName[stageName]}
                    hasStageError={!!get(this.processorStagesWithErrors, [processor.name, stageName])}
                    canRaiseAnomalies={processorCanRaiseAnomalies(processor)}
                    hasPersistedData={stage.enable_metric_logging}
                    retentionDuration={stage.retention_duration}
                    streamingEnabled={processor.properties.enable_streaming}
                    isDynamic={stage.dynamic}
                  />;
                })}
              </Step.Group>
            );
          })}
          {editable && [
            <button
              key='new-processor-button'
              className={cx('new-processor dashed-add-button', {disabled: actionInProgress})}
              style={{height: processorStepHeight + stageStepHeight}}
              onClick={this.openProcessorModal}
            >
              <div>
                <Icon circular name='plus' color='grey' />
                {' '}
                {'Add Processor'}
              </div>
            </button>,
            <ProcessorModal
              key='processor-modal'
              open={this.processorModalOpen}
              onClose={this.closeProcessorModal}
              probe={probe}
              onSuccess={({result: processorName}) => setCurrentProcessorName(processorName)}
            />
          ]}
        </div>
      </Fragment>
    );
  }
}

@withRouter
@observer
export class ProcessorStep extends Component {
  render() {
    const {
      params: {blueprintId, probeId},
      processor, active, editable,
      highlights, highlightCurrentEntity, highlightProcessorAndRelatedStages, setCurrentProcessorName,
      processorStepHeight, hasProcessorError
    } = this.props;
    return (
      <Step
        key={`processor ${processor.name}`}
        className={cx('processor', {highlighted: processor.name in highlights.processors})}
        style={{height: processorStepHeight}}
        active={active}
        onMouseEnter={() => highlightProcessorAndRelatedStages(processor.name)}
        onMouseLeave={highlightCurrentEntity}
        {...active ?
          {}
        : editable ?
          {link: true, onClick: () => setCurrentProcessorName(processor.name)}
        :
          {link: true, as: Link, to: generateProbeURI({blueprintId, probeId, processorName: processor.name})}
        }
      >
        <Step.Title>
          <ProcessorIcon processorType={processor.type} />
          <EllipsisPopup
            content={processor.name}
            trigger={<span>{processor.name}</span>}
          />
        </Step.Title>
        {!!hasProcessorError &&
          <Label floating>
            <Icon name='warning sign' />
          </Label>
        }
      </Step>
    );
  }
}

@withRouter
@observer
export class StageStep extends Component {
  iconWithTooltip = (Icon, tooltip, className) =>
    <Popup
      trigger={<Icon className={cx('stage-icon icon', className)} aria-label={tooltip} />}
      content={tooltip}
      position='top center'
    />;

  render() {
    const {
      params: {blueprintId, probeId},
      stageName, active, editable,
      highlights, highlightCurrentEntity, highlightStageAndRelatedProcessors, setCurrentStageName,
      anomalyCount, warningCount,
      stageStepHeight, hasStageError, isDynamic,
      canRaiseAnomalies, hasPersistedData, retentionDuration, streamingEnabled
    } = this.props;
    const hasIssues = !!(anomalyCount || warningCount);
    return (
      <Step
        className={cx('stage', {
          highlighted: stageName in highlights.stages,
          very: stageName in highlights.stages && highlights.stages[stageName].veryHighlighted,
          'has-warnings': !anomalyCount && warningCount,
          'has-errors': anomalyCount || hasStageError,
        })}
        style={{height: stageStepHeight}}
        active={active}
        onMouseEnter={() => highlightStageAndRelatedProcessors(stageName)}
        onMouseLeave={highlightCurrentEntity}
        {...active ?
          {}
        : editable ?
          {link: true, onClick: () => setCurrentStageName(stageName)}
        :
          {link: true, as: Link, to: generateProbeURI({blueprintId, probeId, stageName})}
        }
      >
        <div className='step-content'>
          <Step.Title>
            <div className='stage-name'>
              <EllipsisPopup
                content={stageName}
                trigger={<span>{stageName}</span>}
              />
            </div>
            {isDynamic && !editable &&
              <Label size='tiny' className='stage-dynamic-icon'>
                {this.iconWithTooltip(StageDynamic, STAGE_DYNAMIC_POPUP_MESSAGE)}
              </Label>
            }
          </Step.Title>
          <Label size='tiny'>
            {hasPersistedData &&
            this.iconWithTooltip(StagePersisted, `Retention Duration: ${formatSeconds(retentionDuration)}`)}
            {streamingEnabled &&
            this.iconWithTooltip(StageStreaming, 'Streaming enabled')}
            {(canRaiseAnomalies && !hasIssues) &&
            this.iconWithTooltip(StageAnomaly, 'Can raise anomalies')}
            {hasIssues &&
              <Fragment>
                {this.iconWithTooltip(StageAnomaly, anomalyCount ? 'Has anomalies' : 'Has warnings', 'issue')}
                <span className='anomaly-counter'>
                  {anomalyCount || warningCount}
                </span>
              </Fragment>
            }
          </Label>
        </div>
      </Step>
    );
  }
}

@observer
export class ConnectorsCanvas extends Component {
  static contextType = IBAContext;

  static defaultProps = {
    lineRadius: 5,
    arrowMarkerSize: 10,
    maxArrowLevels: 3,
    maxDistinctInputs: 4,
    levelSize: 5,
    outputCounterRadius: 6,
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get elementPositions() {
    const {processors, processorStepHeight, stageStepHeight, processorSpacing} = this.props;
    const positions = {stages: {}, processors: {}};
    let currentPosition = 0;
    for (const processor of processors) {
      positions.processors[processor.name] = currentPosition;
      currentPosition += processorStepHeight;
      const processorDefinition = find(this.context.processorDefinitions, {name: processor.type});
      for (const outputName of keys(processorDefinition.outputs)) {
        const stageName = processor.outputs[outputName];
        positions.stages[stageName] = currentPosition;
        currentPosition += stageStepHeight;
      }
      currentPosition += processorSpacing;
    }
    positions.canvasHeight = currentPosition;
    return positions;
  }

  @computed get arrowPositions() {
    const {elementPositions, props: {
      processors, processorStepHeight, stageStepHeight, arrowMarkerSize, maxArrowLevels, maxDistinctInputs
    }} = this;
    const arrowPositions = flatMap(processors, ({inputs, name: processorName}) => {
      return transform(compact(values(inputs)), (result, {stage: stageName}, stageIndex, stages) => {
        if (processorName in elementPositions.processors && stageName in elementPositions.stages) {
          const inputCount = Math.min(maxDistinctInputs, stages.length);
          const processorPosition =
            elementPositions.processors[processorName] + processorStepHeight / 2 -
            (inputCount - 1) * arrowMarkerSize / 2 + Math.min(stageIndex, maxDistinctInputs - 1) * arrowMarkerSize;
          const stagePosition = elementPositions.stages[stageName] + stageStepHeight / 2;
          const length = Math.abs(processorPosition - stagePosition);
          result.push({processorName, processorPosition, stageName, stagePosition, length});
        }
      }, []);
    });
    const arrowPositionGroups = transform(arrowPositions, (result, position) => {
      const arrowPositionGroup = find(result, (arrowPositionGroup) =>
        arrowPositionGroup.processorPosition === position.processorPosition &&
        arrowPositionGroup.stagePosition === position.stagePosition
      );
      if (arrowPositionGroup) {
        arrowPositionGroup.arrowPositions.push(position);
        arrowPositionGroup.processorPosition = Math.max(
          arrowPositionGroup.processorPosition,
          position.processorPosition
        );
        arrowPositionGroup.stagePosition = Math.min(
          arrowPositionGroup.stagePosition,
          position.stagePosition
        );
        arrowPositionGroup.length = Math.abs(arrowPositionGroup.processorPosition - arrowPositionGroup.stagePosition);
      } else {
        result.push({
          arrowPositions: [position],
          processorPosition: position.processorPosition,
          stagePosition: position.stagePosition,
          length: position.length
        });
      }
    }, []);
    const arrowPositionGroupsByLevel = [];
    function positionsIntersect(position1, position2) {
      const [min1, max1] = sortBy([position1.processorPosition, position1.stagePosition]);
      const [min2, max2] = sortBy([position2.processorPosition, position2.stagePosition]);
      return max1 > min2 && max2 > min1;
    }
    while (arrowPositionGroups.length && arrowPositionGroupsByLevel.length < maxArrowLevels) {
      const arrowPositionGroupsForCurrentLevel = transform(arrowPositionGroups, (result, positionGroup) => {
        if (!result.some((anotherPositionGroup) =>
          positionGroup === anotherPositionGroup || positionsIntersect(positionGroup, anotherPositionGroup)
        )) {
          result.push(positionGroup);
        }
      }, []);
      pull(arrowPositionGroups, ...arrowPositionGroupsForCurrentLevel);
      arrowPositionGroupsByLevel.push(arrowPositionGroupsForCurrentLevel);
    }
    if (arrowPositionGroups.length) {
      arrowPositionGroupsByLevel[arrowPositionGroupsByLevel.length - 1].push(...arrowPositionGroups);
    }
    const arrowPositionsWithLevels = transform(arrowPositionGroupsByLevel, (result, arrowPositionGroups, index) => {
      for (const arrowPositionGroup of arrowPositionGroups) {
        for (const arrowPosition of arrowPositionGroup.arrowPositions) {
          arrowPosition.level = index;
        }
        result.push(...arrowPositionGroup.arrowPositions);
      }
    }, []);
    return arrowPositionsWithLevels;
  }

  @computed get arrowCounters() {
    return groupBy(this.arrowPositions, 'stagePosition');
  }

  render() {
    const {width, lineRadius, arrowMarkerSize, levelSize, highlights, style, outputCounterRadius} = this.props;
    const startX = width;
    const drawnArrows = {};
    return (
      <svg
        className='connectors-canvas'
        style={{...style, width, height: this.elementPositions.canvasHeight}}
      >
        {transform(
          this.arrowPositions,
          (
            [regularArrows, highlightableArrows],
            {processorName, processorPosition, stageName, stagePosition, level}
          ) => {
            const key = `${processorName} ${stageName}`;
            if (key in drawnArrows) return;
            drawnArrows[key] = true;
            const endX = 1 + level * levelSize;
            const startY = Math.min(stagePosition, processorPosition);
            const endY = Math.max(stagePosition, processorPosition);
            const arrowBody = (
              <LinePath
                data={[
                  {x: startX, y: startY},
                  {x: endX + lineRadius, y: startY},
                  {x: endX, y: startY},
                  {x: endX, y: startY + lineRadius},
                  {x: endX, y: endY - lineRadius},
                  {x: endX, y: endY},
                  {x: endX + lineRadius, y: endY},
                  {x: startX, y: endY},
                ]}
                x={({x}) => x}
                y={({y}) => y}
                curve={curveBasis}
              />
            );
            const arrowHead = (
              <path
                d={[
                  `M${startX - arrowMarkerSize},${processorPosition - 1 + arrowMarkerSize / 2}`,
                  `L${startX - 1},${processorPosition}`,
                  `L${startX - arrowMarkerSize},${processorPosition + 1 - arrowMarkerSize / 2}`,
                ].join(' ')}
              />
            );
            regularArrows.push(
              React.cloneElement(arrowBody, {key: `${key} body regular`}),
              React.cloneElement(arrowHead, {key: `${key} head regular`})
            );
            const highlighted =
            processorName in highlights.processors && highlights.processors[processorName].highlightConnections ||
            stageName in highlights.stages && highlights.stages[stageName].highlightConnections;
            const highlightedClassName = highlighted ? 'highlighted' : 'not-highlighted';
            highlightableArrows.push(
              React.cloneElement(arrowBody, {key: `${key} body highlightable`, className: highlightedClassName}),
              React.cloneElement(arrowHead, {key: `${key} head highlightable`, className: highlightedClassName})
            );
          },
          [[], []]
        )}
        {transform(this.arrowCounters, (acc, v, pos) => {
          if (v.length < 2) return;
          const highlighted = some(v, ({processorName, stageName}) =>
            processorName in highlights.processors && highlights.processors[processorName].highlightConnections ||
            stageName in highlights.stages && highlights.stages[stageName].highlightConnections);
          const highlightedClassName = highlighted ? 'highlighted' : 'not-highlighted';
          acc.push(
            <g key={pos} transform={`translate(${startX - outputCounterRadius},${pos})`}>
              <ellipse
                className={cx('output-counter', highlightedClassName)}
                cx={0}
                cy={0}
                rx={outputCounterRadius}
                ry={outputCounterRadius}
              />
              <text className={cx('output-counter', highlightedClassName)} x={0} y={0}>{v.length}</text>
            </g>
          );
        }, [])}
      </svg>
    );
  }
}
