import {Component} from 'react';
import {Form} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {get, isString, isArray} from 'lodash';
import {CodeEditorInput, Field, MapInput, ValueInput} from 'apstra-ui-common';

@observer
export default class QueryExpansionInput extends Component {
  getErrorsByPath = (errors, path) => {
    const value = get(errors, path);
    return isString(value) ? [value] : [];
  };

  getKeyErrors = ({errors}) => {
    if (isArray(errors)) {
      return errors;
    } else if (isString(errors)) {
      return [errors];
    }
    return [];
  };

  render() {
    const {name, schema, value, required, disabled, errors, onChange} = this.props;
    const {getErrorsByPath, getKeyErrors} = this;

    const typeSchema = get(schema, ['additionalProperties', 'properties', 'type']);
    const generatorSchema = get(schema, ['additionalProperties', 'properties', 'generator']);

    return (
      <MapInput
        value={value}
        name={name}
        schema={schema}
        required={required}
        disabled={disabled}
        errors={errors}
        buttonText='Add Key'
        flattenErrors={false}
        onChange={onChange}
      >
        {({key, value: item, index, setEntryKey, setEntryValue, errors}) => {
          return (
            <Field width={16}>
              <Form.Group style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>
                <Form.Field>
                  <label style={{fontSize: '12px'}}>{`Key #${index + 1}`}</label>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <ValueInput
                  value={key}
                  placeholder='Key'
                  schema={{type: 'string'}}
                  fieldProps={{width: 16}}
                  errors={getKeyErrors({errors})}
                  onChange={(key) => {
                    setEntryKey(index, key);
                  }}
                />
              </Form.Group>
              <Form.Group>
                <ValueInput
                  value={item.type}
                  placeholder='Type'
                  schema={typeSchema}
                  fieldProps={{width: 6}}
                  disabled={disabled || !key}
                  errors={getErrorsByPath(errors, 'type')}
                  onChange={(type) => setEntryValue(index, {...item, type})}
                />
                <CodeEditorInput
                  value={item.generator || ''}
                  placeholder='Generator'
                  mode='python-expression'
                  schema={generatorSchema}
                  fieldProps={{width: 10}}
                  disabled={disabled || !key}
                  errors={getErrorsByPath(errors, 'generator')}
                  onChange={(generator) => setEntryValue(index, {...item, generator})}
                />
              </Form.Group>
            </Field>
          );
        }}
      </MapInput>
    );
  }
}
