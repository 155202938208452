import {withResizeDetector} from 'react-resize-detector';
import {useMemo} from 'react';
import {hierarchy, Partition} from '@visx/hierarchy';
import cx from 'classnames';
import {Group} from '@visx/group';
import {Arc} from '@visx/shape';
import {brandColorNames, formatNumber} from 'apstra-ui-common';
import {forEach, transform, uniqueId} from 'lodash';
import {Text} from '@visx/text';
import {scaleOrdinal} from 'd3';

import {Filter3d} from './DonutChart';
import {TooltipPopup, TooltipProvider, useTooltip} from './GraphTooltips';
import ChartLegend, {useHoveredLegendItem} from './ChartLegend';

import './SunburstChart.less';

const findParentNode = (node) => {
  let parent = node.parent;
  while (parent.parent && parent.depth !== 1) {
    parent = node.parent;
  }
  return parent;
};

const SunburstChart = ({
  data, chartWidth, width: containerWidth, className, targetRef, thickness, opacityStep, withLegend,
}) => {
  const root = useMemo(() => {
    return hierarchy(data)
      .sort((a, b) => (b.size || 0) - (a.size || 0))
      .sum((d) => d.size);
  }, [data]);
  const colors = useMemo(() => {
    let i = 0;
    return transform(root.descendants().slice(1), (result, {depth, data, ...node}) => {
      if (depth === 1) {
        result[data.id] = data.color || brandColorNames[i++ % brandColorNames.length];
      } else {
        const parent = findParentNode(node);
        result[data.id] = result[parent?.data.id] || brandColorNames[i++ % brandColorNames.length];
      }
    });
  }, [root]);
  const [ordinalColorScale, legendDescriptionMap] = useMemo(() => {
    const domain = [];
    const range = [];
    const legendDescriptionMap = {};

    const nodes = [];
    const collectNodes = (node, result = []) => {
      result.push(node);
      if (node.children) {
        forEach(node.children, (child) => collectNodes(child, result));
      }
    };
    collectNodes(root, nodes);

    forEach(nodes.slice(1), ({depth, data, value}) => {
      domain.push(data.id);
      legendDescriptionMap[data.id] = {
        name: data.name,
        value: formatNumber(value, {units: data.units, short: true}),
        glyphClassName: `sunburst-legend-${depth}-glyph`,
        itemClassName: `sunburst-legend-${depth}-item`,
      };
      range.push(colors[data.id]);
    });
    return [
      scaleOrdinal()
        .domain(domain)
        .range(range),
      legendDescriptionMap,
    ];
  }, [colors, root]);

  const filterId = useMemo(() => uniqueId('sunburst-chart-shadow-filter'), []);
  const size = chartWidth ? chartWidth : containerWidth;
  const radius = size / 2;
  const backgroundArcThickness = 2.5 * thickness;
  const outerRadius = radius - 0.5 * (backgroundArcThickness - thickness);

  const {onMouseOver, onMouseOut, hoveredItem} = useHoveredLegendItem();

  return (
    <div className='sunburst-chart-wrapper'>
      <div ref={targetRef}>
        <TooltipProvider>
          <svg className={cx('sunburst-chart', className)} width={size} height={size}>
            <defs>
              <Filter3d filterId={filterId} />
            </defs>
            <Group top={radius} left={radius}>
              <Arc
                className='sunburst-chart-background-arc'
                startAngle={0} endAngle={360}
                innerRadius={0}
                outerRadius={radius}
                filter={`url(#${filterId})`}
              />
              <Partition
                top={0}
                left={0}
                root={root}
                size={[2 * Math.PI, outerRadius]}
              >
                {(data) =>
                  <ArcGroup
                    data={data}
                    colors={colors}
                    opacityStep={opacityStep}
                    hoveredItem={hoveredItem}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                  />
                }
              </Partition>
            </Group>
          </svg>
          <TooltipPopup />
        </TooltipProvider>
      </div>
      {withLegend && (
        <ChartLegend
          ordinalColorScale={ordinalColorScale}
          legendDescriptionMap={legendDescriptionMap}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
          hoveredItem={hoveredItem}
        />
      )}
    </div>
  );
};

SunburstChart.defaultProps = {
  thickness: 10,
  opacityStep: 0.3,
};

const ArcGroup = ({data, colors, opacityStep, hoveredItem, onMouseOver, onMouseOut}) => {
  const nodes = useMemo(() => {
    return data.descendants().slice(1);
  }, [data]);
  return (
    <Group>
      {nodes.map((node, i) => (
        <ArcPath
          key={`node-${i}`}
          opacityStep={opacityStep}
          hoveredItem={hoveredItem}
          colors={colors}
          node={node}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        />
      ))}
    </Group>
  );
};

const ArcPath = ({opacityStep, hoveredItem, colors, node, onMouseOut, onMouseOver, shiftSize = 10}) => {
  const {sharedTooltip} = useTooltip();
  const isHovered = useMemo(() => {
    if (hoveredItem === node.data.id) return true;
    const parent = findParentNode(node);
    return parent?.data.id === hoveredItem;
  }, [node, hoveredItem]);
  const arcOffset = isHovered ? shiftSize : 0;
  return (
    <Arc
      innerRadius={node.depth === 1 ? 0 : node.y0}
      outerRadius={node.y1 + arcOffset}
      startAngle={node.x0}
      endAngle={node.x1}
    >
      {({path}) => (
        <g>
          <path
            className={cx('visx-arc', colors[node.data.id], {hovered: isHovered})}
            fillOpacity={1 - opacityStep * (node.depth - 1)}
            d={path()}
            onMouseOut={() => {
              onMouseOut();
              sharedTooltip.hide();
            }}
            onMouseOver={() => {
              onMouseOver(node.data.id);
              sharedTooltip.show(`${node.data.name}: ${node.value}`, true);
            }}
          />
          {node.depth === 1 && (
            <Text
              x={path.centroid()[0]}
              y={path.centroid()[1]}
              textAnchor='middle'
              className='label'
            >
              {node.data.name}
            </Text>
          )}
        </g>
      )}
    </Arc>
  );
};

export default withResizeDetector(SunburstChart, {handleWidth: true});
