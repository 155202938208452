import {Component} from 'react';
import {observable, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {flatMap, castArray, isPlainObject, isString, isArray} from 'lodash';
import {FetchData, FetchDataError, Loader, ResourceModal,
  generatePropertyFromSchema, interpolateRoute, request} from 'apstra-ui-common';

import syslogConfigSchema from '../syslogConfigSchema';
import SyslogConfigForm from './SyslogConfigForm';

@observer
export default class SyslogConfigModal extends Component {
  @observable properties = {};

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static fetchTimeZones = ({routes}) => request(
    routes.syslogConfigTimeZones,
    {method: 'GET'}
  );

  createSyslogConfig = () => request(
    this.props.routes.syslogConfigList,
    {method: 'POST', body: JSON.stringify(this.properties)}
  );

  updateSyslogConfig = () => request(
    interpolateRoute(
      this.props.routes.syslogConfigDetails,
      {syslogConfigId: this.props.syslogConfig.id}
    ),
    {method: 'PUT', body: JSON.stringify(this.properties)}
  );

  getSchemaWithTimeZones = (timeZones) => {
    if (isArray(timeZones)) {
      return syslogConfigSchema.map((property) => {
        if (property.name === 'timezone') {
          return {
            ...property,
            schema: {...property.schema, enum: timeZones}
          };
        }
        return property;
      });
    }
    return syslogConfigSchema;
  };

  @action
  resetState = () => {
    this.properties = {};
    const {syslogConfig} = this.props;
    if (syslogConfig) {
      for (const {name} of syslogConfigSchema) {
        this.properties[name] = syslogConfig[name];
      }
    } else {
      for (const {name, schema} of syslogConfigSchema) {
        this.properties[name] = generatePropertyFromSchema(schema);
      }
    }
  };

  submit = async () => {
    const {mode} = this.props;
    const resourceLabel = `${this.properties.address}:${this.properties.port}`;
    await (mode === 'update' ? this.updateSyslogConfig : this.createSyslogConfig)();
    return {resourceLabel};
  };

  processErrors = ({errors}) => {
    if (isPlainObject(errors)) {
      return flatMap(errors, (propertyErrors, propertyName) => {
        return castArray(propertyErrors).map((message) => ({type: 'property', propertyName, message}));
      });
    } else if (isString(errors)) {
      return [{type: 'generic', message: errors}];
    } else {
      return [];
    }
  };

  render() {
    const {getSchemaWithTimeZones, props: {routes, mode, open, onClose, trigger, onSuccess}} = this;
    return (
      <FetchData
        fetchData={SyslogConfigModal.fetchTimeZones}
        pollingInterval={null}
        fetchParams={{routes}}
        customLoader
        customError
      >
        {({items: timeZones, loaderVisible: fetchTimeZonesLoader, fetchDataError: fetchTimeZonesError}) => (
          <ResourceModal
            mode={mode}
            resourceName='Syslog Config'
            trigger={trigger}
            size='small'
            open={open}
            onClose={onClose}
            resetState={this.resetState}
            submit={this.submit}
            processErrors={this.processErrors}
            onSuccess={onSuccess}
          >
            {({actionInProgress, errors}) =>
                fetchTimeZonesLoader ?
                  <Loader /> :
                  fetchTimeZonesError ?
                    <FetchDataError error={fetchTimeZonesError} /> :
                    <SyslogConfigForm
                      schema={getSchemaWithTimeZones(timeZones)}
                      properties={this.properties}
                      disabled={actionInProgress}
                      errors={errors}
                    />
            }
          </ResourceModal>
        )}
      </FetchData>
    );
  }
}
