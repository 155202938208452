import {Fragment, useContext} from 'react';
import {Button, Divider, Form} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {map, filter, get, head, transform, sortBy} from 'lodash';
import {DropdownControl} from 'apstra-ui-common';

import {GRAPH_QUERY_KWARGS, NODE} from '../pythonExpression/consts';
import QueryNodeAttribute from './QueryNodeAttribute';
import QueryBuilderContext from './QueryBuilderContext';

import './QueryNode.less';

const QueryNode = observer(({query, typeOptions, removePathAfterThisNode, queryKeyPath}) => {
  const {data, addNode, deleteNode, replaceQueryValue} = useContext(QueryBuilderContext);
  const properties = get(data, ['nodes', query.type, 'properties'], {});
  const defaultProperties = transform(GRAPH_QUERY_KWARGS[query.name], (result, {name, ...props}) => {
    result[name] = props;
  }, {});
  const propertiesMap = transform({...properties, ...defaultProperties}, (result, property, name) => {
    result[name] = {...property, name};
  }, {});
  const used = new Set(map(query.kwargs, 'name'));
  const availableProperties = filter(propertiesMap, (value, name) => !used.has(name));
  const options = map(sortBy(availableProperties, 'name'), ({description, name}) => ({
    key: name, text: name, value: name, description
  }));
  const onChange = (key, value, index) => {
    replaceQueryValue([...queryKeyPath, 'kwargs', index], {[key]: value});
  };
  const addAttribute = () => {
    const name = head(options).value;
    const type = propertiesMap[name]?.type ?? 'string';
    const kwarg = {name, value: type === 'array' ? [] : '', type};
    kwarg.matcher = name === 'tag' || name === 'tags' ? 'has_all' : '=';
    addNode(query, 'kwargs', kwarg);
  };

  return (
    <Form>
      <div className='query-node'>
        <div>
          {query.name === NODE ? 'Node Type' : 'Relationship Type'}
        </div>
        <div>
          <DropdownControl
            fluid
            search
            options={typeOptions}
            value={query.type}
            onChange={(value) => {
              replaceQueryValue(queryKeyPath, {type: value});
              removePathAfterThisNode();
            }}
            clearable
          />
        </div>
        <div>
          {query.name === NODE ? 'Node Attributes' : 'Relationship Attributes'}
        </div>
        <div>
          {map(query.kwargs, ({name, value, matcher}, i) => (
            <Fragment key={name}>
              <QueryNodeAttribute
                name={name}
                value={value}
                options={options}
                matcher={matcher}
                attributesMap={propertiesMap}
                onChange={(key, name) => onChange(key, name, i)}
                onDelete={() => deleteNode(query, 'kwargs', i)}
              />
              {i + 1 !== query.kwargs.length && <Divider />}
            </Fragment>
          ))}
          <Button
            size='tiny'
            content='Add Attribute'
            icon='plus'
            disabled={options.length === 0}
            onClick={addAttribute}
            color='teal'
            basic
          />
        </div>
      </div>
    </Form>
  );
});

export default QueryNode;
