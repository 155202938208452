import {isFinite, isNull} from 'lodash';
import PropTypes from 'prop-types';
import {DurationInput} from 'apstra-ui-common';

const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = MINUTE_IN_SECONDS * 60;
const DAY_IN_SECONDS = HOUR_IN_SECONDS * 24;

const AggregationInput = ({label, value, aggregations, onChange, ...props}) => {
  return (
    <DurationInput
      value={isFinite(value) ? value : null}
      selectedValueLabel={label}
      textPrefix=''
      additionLabel='Aggregation: '
      placeholder='Select Aggregation'
      fluid
      durations={aggregations}
      fieldProps={{className: 'aggregation-input'}}
      onChange={(newValue) => onChange(isNull(newValue) ? value : newValue)}
      {...props}
    />
  );
};

AggregationInput.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  aggregations: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.number
  })),
  schema: PropTypes.object,
  value: PropTypes.number,
  errors: PropTypes.arrayOf(PropTypes.string),
  customValueType: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func
};

AggregationInput.defaultProps = {
  aggregations: [
    {text: 'Off', value: 0},
    {text: '5 Seconds', value: 5},
    {text: '10 Seconds', value: 10},
    {text: '30 Seconds', value: 30},
    {text: '1 Minute', value: MINUTE_IN_SECONDS},
    {text: '2 Minutes', value: MINUTE_IN_SECONDS * 2},
    {text: '5 Minutes', value: MINUTE_IN_SECONDS * 5},
    {text: '30 Minutes', value: MINUTE_IN_SECONDS * 30},
    {text: '1 Hour', value: HOUR_IN_SECONDS},
    {text: '12 Hours', value: HOUR_IN_SECONDS * 12},
    {text: '1 Day', value: DAY_IN_SECONDS},
    {text: '7 Days', value: DAY_IN_SECONDS * 7},
    {text: '30 Days', value: DAY_IN_SECONDS * 30},
  ],
  customValueType: 'duration',
  label: ''
};

export default AggregationInput;
