import {observable, action, makeObservable, toJS, computed} from 'mobx';
import {reduce, filter, uniq, transform, forEach, keys, isEmpty, size, sortBy, map, flatten} from 'lodash';

import {generateLocalId, getDirectionToTarget, getTopologicOrder} from '../utils';
import {
  ctrlNodeWidth, ctrlNodeHeight, ctrlIfcPositions, ctrlEntityTypes, ctrlSystemTypes, ctrlGridStep,
  deployModes,
  hostNameValidation,
  ctrlHalfNodeWidth,
  ctrlHalfNodeHeight
} from '../const';

const emptyNode = {
  id: null,
  label: 'Untitled',
  hostname: '',
  systemId: null,
  systemType: ctrlSystemTypes.INTERNAL,
  deployMode: deployModes.UNDEPLOY,
  tags: [],
  color: null,
  position: {x: null, y: null},
  _order: 0,
  _linksCount: 0,

  deviceProfileId: null
};

class Node {
  @observable id;
  @observable label;
  @observable hostname;
  @observable tags;
  @observable color;
  @observable _position;
  @observable arrangePosition;
  @observable systemId;
  @observable systemType;
  @observable deviceProfileId;
  @observable deployMode;
  @observable isFadedOut = false;

  @observable _order;
  _linksCount;
  @observable initialDeviceProfileId = null;

  @observable.ref cablingMapStore = null;

  @computed
  get deviceProfile() {
    return this.cablingMapStore?.deviceProfiles[this.deviceProfileId];
  }

  @computed
  get myLinks() {
    return this.cablingMapStore?.nodesLinks?.[this.id] ?? [];
  }

  @computed
  get myVisibleLinks() {
    return filter(this.myLinks, {isAggregated: false});
  }

  @computed
  get myAggregateLinks() {
    return this.cablingMapStore?.nodesAggregateLinks?.[this.id] ?? [];
  }

  @computed
  get visibleEndpoints() {
    return filter(
      flatten(map([...this.myVisibleLinks, ...this.myAggregateLinks], 'endpoints')),
      {nodeId: this.id}
    );
  }

  @computed
  get isPositioned() {
    return this._position.x !== null && this._position.y !== null;
  }

  get type() {
    return ctrlEntityTypes.NODE;
  }

  @computed
  get isExternal() {
    return this.systemType !== ctrlSystemTypes.INTERNAL;
  }

  @computed
  get allowLinksManagement() {
    return !!this.deviceProfileId || this.isExternal;
  }

  @computed
  get isValid() {
    return !this.validationErrors?.length;
  }

  @action
  resetPosition() {
    this.moveTo(emptyNode.position);
    this._linksCount = 0;
  }

  @computed
  get topologicOrder() {
    return getTopologicOrder(this.position);
  }

  @computed
  get topologicVerticalOrder() {
    return getTopologicOrder(this.position, true);
  }

  @action
  setCustomData(json) {
    let [x, y, color] = [emptyNode.position.x, emptyNode.position.y, emptyNode.color];
    try {
      [x, y, color] = JSON.parse(json);
    } catch {}
    this.moveTo({x, y});
    this.color = color;
  }

  @action
  setIsFadedOut(isFadedOut) {
    this.isFadedOut = isFadedOut;
  }

  get customData() {
    return JSON.stringify([this._position.x, this._position.y, this.color]);
  }

  set customData(json) {
    this.setCustomData(json);
  }

  @action
  moveTo({x, y}) {
    this._position = {x, y};
  }

  @action
  moveBy({x, y}) {
    this.moveTo({x: this._position.x + x, y: this._position.y + y});
  }

  @action
  snapToGrid() {
    this.moveTo({
      x: ctrlGridStep * Math.round(this._position.x / ctrlGridStep),
      y: ctrlGridStep * Math.round(this._position.y / ctrlGridStep)
    });
  }

  @action
  setProperty(property, value) {
    this[property] = value;
  }

  @action
  init(props) {
    Object.assign(this, emptyNode, props);
  }

  @computed
  get position() {
    return this.arrangePosition ?? this._position;
  }

  set position(position) {
    this.moveTo(position);
  }

  // Coordinates of the center of the node
  @computed
  get centerPosition() {
    return {
      x: this._position.x + ctrlHalfNodeWidth,
      y: this._position.y + ctrlHalfNodeHeight
    };
  }

  fallsWithin(start, end) {
    const {x, y} = this._position;
    const [startX, endX] = start.x > end.x ? [end.x, start.x] : [start.x, end.x];
    const [startY, endY] = start.y > end.y ? [end.y, start.y] : [start.y, end.y];

    return (x < endX) && (x + ctrlNodeWidth > startX) &&
      (y < endY) && (y + ctrlNodeHeight > startY);
  }

  clone() {
    return new Node(toJS({
      id: generateLocalId(),
      label: this.label,
      hostname: '',
      tags: [...this.tags],
      systemType: this.systemType,
      deployMode: this.deployMode,
      color: this.color,
      deviceProfileId: this.deviceProfileId,
      position: {...this._position},
      arrangePosition: this.arrangePosition,
    }), this.cablingMapStore);
  }

  constructor(props = {}, cablingMapStore) {
    makeObservable(this);
    if (!cablingMapStore) {
      throw new Error('Node initialization without the reference to store!');
    }
    this.cablingMapStore = cablingMapStore;
    this.init(props);
  }

  @computed
  get interfacesPositions() {
    // Defines which side of the node bar their interfaces must be shown
    // depending on the position of the targets they are connected to

    // For visible (non-aggregated) links
    let positions = transform(
      // For the simple link define position based on the single target node position
      filter(this.myLinks, (link) => !link.isAggregated && link.contains([this])),
      (acc, link) => {
        const [endpoint, {node: target}] = link.orderEndpointsFor(this.id);
        const side = target ?
          getDirectionToTarget(this.position, target.position) :
          ctrlIfcPositions.BOTTOM;

        // For the vertical and horizontal links corresponding topological order must be taken
        const isVertical = side === ctrlIfcPositions.RIGHT || side === ctrlIfcPositions.LEFT;
        const sideOrder = isVertical ? target.topologicVerticalOrder : target.topologicOrder;
        acc.push({
          side,
          order: `${sideOrder}:${target.id}:${link.id}`,
          id: endpoint.id
        });
      },
      []
    );

    // For aggregate links
    positions = transform(
      // For aggregated links all nodes they connect determine all interfaces positions
      this.myAggregateLinks,
      (acc, link) => {
        // Target each of the interfaces to the opposite mean point
        forEach(
          filter(link.endpoints, {nodeId: this.id}),
          ({id, endpointGroup: {index}}) => {
            // All links of an aggregate are targetted to the endpoint group's middle point.
            // If there is the only one node in the group - direction is calculated based on the opposite middle
            const directionPoint = link.groupMiddles[size(link.endpointsByGroup[index]) === 1 ? 1 - index : index];
            const side = getDirectionToTarget(this.centerPosition, directionPoint);
            const isVertical = side === ctrlIfcPositions.RIGHT || side === ctrlIfcPositions.LEFT;

            // Position of the interface on the node's side is always calculated from the opposite middle
            const oppositePoint = link.groupMiddles[1 - index];
            const targetOrder = getTopologicOrder(oppositePoint, isVertical);
            acc.push({
              side,
              order: `${targetOrder}:${link.id}`,
              id
            });
          }
        );
      },
      positions
    );

    return sortBy(positions, 'order');
  }

  getRelatedIds(links) {
    const nodeId = this.id;
    const result = uniq(reduce(links, (result, link) => {
      const id = link.oppositeTo(nodeId);
      if (id) result.push(id);
      return result;
    }, []));
    return result;
  }

  static deserialize(jsonValue, cablingMapStore) {
    const {
      id, label, tags = [], system_id: systemId, system_type: systemType, user_data: customData, device_profile: dp,
      hostname, deploy_mode: deployMode = deployModes.UNDEPLOY, internal
    } = jsonValue;
    const deviceProfileId = dp?.id ?? null;
    const node = new Node({
      id,
      label,
      hostname,
      tags,
      systemId,
      systemType,
      deployMode,
      customData,
      deviceProfileId,
      initialDeviceProfileId: deviceProfileId
    }, cablingMapStore);

    if (internal) {
      Object.assign(node, internal);
    }
    return node;
  }

  serialize() {
    return {
      id: this.id,
      label: this.label,
      hostname: this.hostname,
      tags: toJS(this.tags),
      device_profile: this.deviceProfileId ? {
        id: this.deviceProfileId
      } : null,
      system_id: this.systemId,
      system_type: this.systemType,
      deploy_mode: this.deployMode,
      user_data: this.customData,
      internal: {
      }
    };
  }

  // Validation section moved here
  @computed
  get validationErrors() {
    const {nodesLabelsCounts} = this.cablingMapStore;

    const errors = [];
    if (!this.isExternal && !this.deviceProfileId) {
      errors.push('Device profile is not defined');
    }
    if (!this.label) {
      errors.push('Node label is empty');
    }
    if (this.label && nodesLabelsCounts[this.label] > 1) {
      errors.push('Node label must be unique');
    }
    if (this.hostname && (this.hostname.length > 32 || !hostNameValidation.test(this.hostname))) {
      errors.push('Invalid hostname. It may contain only [a-zA-Z0-9] groups separated by "." or "-"');
    }
    if (!this.isExternal) {
      const brokenLinksToNodes = keys(transform(
        this.myLinks,
        (acc, link) => {
          const [{missingInDeviceProfile}, {node}] = link.orderEndpointsFor(this.id);
          if (missingInDeviceProfile) {
            acc[node?.label] = true;
          }
        },
        {}
      ));

      if (!isEmpty(brokenLinksToNodes)) {
        errors.push(<>{'Check link cabling with '}<b>{brokenLinksToNodes.join(', ')}</b></>);
      }
    }

    return errors;
  }
}

export default Node;
