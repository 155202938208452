import {Button, Grid, Popup} from 'semantic-ui-react';
import {map, transform, isEmpty} from 'lodash';
import {useContext, useMemo} from 'react';
import {LayoutContext} from 'apstra-ui-common';
import {useSetState} from 'react-use';
import {useResizeDetector} from 'react-resize-detector';

import ReportToC from './ReportToC';
import ReportEntity from './ReportEntity';
import ReportContext from './ReportContext';
import {REPORT_TYPES} from './const';
import {useScrollToAnchor} from '../../../useScrollToAnchor';

import './Report.less';

const Report = ({itemsWithPath, onCollapseToggle, collapsedSectionState}) => {
  const context = useContext(LayoutContext);
  const {footerHeight = 0} = context || {};

  const tableOfContents = useMemo(() => {
    const filterSections = (items) => transform(items, (result, item) => {
      if (item?.type === REPORT_TYPES.section) {
        result.push({
          ...item,
          items: collapsedSectionState[item.path] ? [] : filterSections(item.items),
        });
      }
    }, []);
    return filterSections(itemsWithPath);
  }, [itemsWithPath, collapsedSectionState]);

  const scrollToTop = () => window.scrollTo({top: 0, behavior: 'smooth'});
  const {resetScrolledRef} = useScrollToAnchor();

  const {height, ref: reportRef} = useResizeDetector({handleHeight: true});
  const [sectionRefs, setSectionRefs] = useSetState({});
  const onSetSectionRef = (path, ref) => {
    setSectionRefs({[path]: ref});
  };

  return (
    <>
      <Grid.Row className='report-row'>
        {!isEmpty(tableOfContents) && (
          <Grid.Column className='report-toc-column'>
            <ReportToC
              items={tableOfContents}
              sectionRefs={sectionRefs}
              resetScrolledRef={resetScrolledRef}
              collapsedSectionState={collapsedSectionState}
              onCollapseToggle={onCollapseToggle}
              reportHeight={height}
            />
          </Grid.Column>
        )}
        <Grid.Column className='report-column'>
          <ReportContext.Provider value={{collapsedSectionState, onCollapseToggle, onSetSectionRef}}>
            <div ref={reportRef}>
              {map(itemsWithPath, (entity, i) => (
                <ReportEntity
                  key={i}
                  {...entity}
                  lastSectionItem={itemsWithPath.length - 1 === i}
                />
              ))}
            </div>
          </ReportContext.Provider>
        </Grid.Column>
      </Grid.Row>
      <Popup
        trigger={
          <Button
            icon='angle double up'
            className='report-top-scroll'
            onClick={() => scrollToTop()}
            aria-label='Scroll to top'
            style={{bottom: footerHeight}}
          />
        }
        content='Scroll to top'
      />
    </>
  );
};

export default Report;
