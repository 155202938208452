import {Component, Fragment} from 'react';
import {Icon, Label} from 'semantic-ui-react';
import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {flatMap, isEmpty} from 'lodash';
import {Field, RadioGroupInput, createValueRenderer} from 'apstra-ui-common';

import TagsControl from './TagsControl';

import './TagsFilterInput.less';

@observer
export default class TagsFilterInput extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  renderLabel = ({text: tag}) => (
    <Label>
      <Icon name='tag' />
      {tag}
      <Icon link name='delete' onClick={() => this.removeTag(tag)} aria-label='Delete' />
    </Label>
  );

  @computed get value() {
    const value = {tags: [], strict: true};
    return Object.assign(value, this.props.value);
  }

  onTagsChange = (tags) => {
    return this.props.onChange({...this.value, tags});
  };

  onModeChange = (strict) => {
    return this.props.onChange({...this.value, strict});
  };

  render() {
    const {name, schema, required, disabled, errors, placeholder, knownTags} = this.props;
    const {onTagsChange, onModeChange, value: {tags, strict}} = this;
    return (
      <Field
        className='tags-filter-input'
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors}
      >
        {(inputProps) => [
          <TagsControl
            key='tags'
            value={tags}
            placeholder={placeholder}
            allowNewTags={false}
            onChange={onTagsChange}
            knownTags={knownTags}
            {...inputProps}
          />,
          <RadioGroupInput
            key='radio-group'
            value={strict}
            name={name}
            disabled={disabled}
            schema={{
              type: 'boolean',
              oneOf: [
                {const: true, title: 'All'},
                {const: false, title: 'Any'},
              ],
            }}
            onChange={onModeChange}
          />
        ]}
      </Field>
    );
  }
}

export const tagsFilterRenderer = createValueRenderer({
  condition: ({name}) => name === 'tags',
  renderValue({value: {tags, strict}}) {
    return flatMap(tags, (tag, index) => [
      index ? <Fragment key={`${tag} op`}>&nbsp;{strict ? 'and' : 'or'}&nbsp;</Fragment> : null,
      <Label key={`${tag} tag`}><Icon name='tag' />{tag}</Label>,
    ]);
  },
  renderValueInput({name, value, schema, required, disabled, errors, onChange, allowNewTags, knownTags}) {
    return (
      <TagsFilterInput
        name={name}
        value={value}
        schema={schema}
        required={required}
        disabled={disabled}
        errors={errors}
        onChange={onChange}
        knownTags={knownTags}
        allowNewTags={allowNewTags}
      />
    );
  },
  getQueryPartData: ({value: {tags, strict}}) => {
    return isEmpty(tags) ?
      null :
      {
        value: flatMap(tags, (tag) => <Label key={`${tag} tag`}><Icon name='tag' />{tag}</Label>),
        matcher: strict ? '=' : 'any of'
      };
  }
});
