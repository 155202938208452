import {Card, Button, Form, List} from 'semantic-ui-react';
import cx from 'classnames';
import {map} from 'lodash';

import Condition from './Condition';
import './PrimitiveParameters.less';

const emptyCriterion = {
  logic: 'or',
  value: ''
};

const PrimitiveParameters = ({parameters = {}, update, remove, logicButton, nodeGetByType}) => {
  const {schema, criteria = {}} = parameters;

  const updateCriteria = (name, index, value = null) => {
    const conditions = parameters?.criteria?.[name] ?? null;
    let result;

    if (!conditions) {
      // First condition added
      result = [emptyCriterion];
    } else if (value === null) {
      result = [...conditions];
      if (index >= 0) {
        // Condition removal
        result.splice(index, 1);
        if (!result.length) result = null;
      } else {
        // Pushing empty condition in
        result.push({...emptyCriterion, logic: result[0].logic ?? emptyCriterion.logic});
      }
    } else if (value?.logic) {
      // If logic is supplied it must be applied to all criteria
      const logic = value.logic;
      result = map(conditions, (condition) => ({...condition, logic}));
    } else {
      // Condition update
      result = [...conditions];
      result[index] = {...result[index], ...value};
    }

    update({
      ...parameters,
      criteria: {...parameters.criteria, [name]: result}
    });
  };

  const classNames = cx(
    'ct-primitive-parameters'
  );

  return (
    <>
      {logicButton}
      <Card className={classNames}>
        <Button
          icon='trash'
          onClick={() => remove()}
        />
        <Card.Header>
          <h4>{schema.label}</h4>
        </Card.Header>
        <Card.Content>
          <List verticalAlign='middle' className='parameters' divided>
            {
              map(schema.arguments, (argument, index) => {
                const {name, label} = argument;
                const parameterCriteria = criteria?.[name] ?? null;
                return (
                  <List.Item key={index}>
                    <List.Content floated='right'>
                      {
                        parameterCriteria ?
                          null :
                          <Button
                            className='btn-link'
                            content='Any'
                            onClick={() => updateCriteria(name)}
                          />
                      }
                    </List.Content>
                    <List.Content>{label}</List.Content>
                    <List.Content>
                      {
                        parameterCriteria &&
                          <Form size='small'>
                            <List className='conditions'>
                              {
                                map(parameterCriteria, (criterion, index2) => (
                                  <Condition
                                    key={index2}
                                    argument={argument}
                                    criterion={criterion}
                                    update={(value) => updateCriteria(name, index2, value)}
                                    logic={index2 ? (criterion.logic || 'or') : null}
                                    nodeGetByType={nodeGetByType}
                                  />
                                ))
                              }
                              <Button
                                className='ct-add-condition-button btn-link'
                                content='Add condition'
                                onClick={() => updateCriteria(name)}
                              />
                            </List>
                          </Form>
                      }
                    </List.Content>
                  </List.Item>
                );
              })
            }
          </List>
        </Card.Content>
      </Card>
    </>
  );
};

export default PrimitiveParameters;
