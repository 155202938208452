export default {
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    label: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    tags: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    disabled: {
      type: 'boolean',
    },
    processors: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
          },
          type: {
            type: 'string',
            minLength: 1,
          },
          properties: {
            type: 'object',
            additionalProperties: {},
          },
          inputs: {
            type: 'object',
            additionalProperties: {
              type: 'object',
              properties: {
                column: {
                  type: 'string',
                  minLength: 1,
                },
                stage: {
                  type: 'string',
                  minLength: 1,
                },
              },
              additionalProperties: false,
            }
          },
          outputs: {
            type: 'object',
            additionalProperties: {
              type: 'string',
            },
          }
        },
        required: [
          'type',
          'name',
        ],
        additionalProperties: false,
      },
      minItems: 1,
    },
    stages: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
            minLength: 1,
          },
          description: {
            type: 'string',
          },
          enable_metric_logging: {
            type: 'boolean'
          },
          retention_duration: {
            type: 'number'
          },
          tags: {
            type: 'array',
            items: {
              type: 'string'
            },
          },
          units: {
            type: 'object',
            additionalProperties: {
              type: 'string',
            },
          },
        },
        required: [
          'name',
        ],
        additionalProperties: false,
      },
    },
  },
  required: [
    'label',
    'processors',
  ],
  additionalProperties: false,
};
