import {Component, Fragment} from 'react';
import {Form, Message} from 'semantic-ui-react';
import {action, computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {flatMap, mapValues, find, groupBy, filter, pullAllWith, isMatch} from 'lodash';
import {FetchDataError, FormFragment} from 'apstra-ui-common';

@observer
export default class SyslogConfigForm extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  setPropertyValue(name, value) {
    const {properties, errors} = this.props;
    properties[name] = value;
    pullAllWith(errors, [{type: 'property', propertyName: name}], isMatch);
  }

  @computed get errors() {
    const {errors} = this.props;
    return {
      form: mapValues(
        groupBy(filter(errors, {type: 'property'}), 'propertyName'),
        (errorGroup) => flatMap(errorGroup, 'message')
      ),
      generic: find(errors, {type: 'generic'}),
      http: find(errors, {type: 'http'}),
    };
  }

  render() {
    const {errors, props: {properties, ...props}} = this;
    return (
      <Fragment>
        <Form>
          <FormFragment
            {...props}
            values={properties}
            errors={errors.form}
            onChange={(name, value) => this.setPropertyValue(name, value)}
          />
        </Form>
        {errors.http &&
          <FetchDataError error={errors.http.error} />
        }
        {errors.generic &&
          <Message error icon='warning sign' header='Error' content={errors.generic.message} />
        }
      </Fragment>
    );
  }
}
