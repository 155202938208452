import {Component} from 'react';
import {observable, action, computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Message} from 'semantic-ui-react';
import {filter, includes, sortBy} from 'lodash';
import {AsyncActionsModal, DropdownControl} from 'apstra-ui-common';

import {WIDGET_TYPES} from '../consts';
import IBAContext from '../IBAContext';

@observer
export default class WidgetAdditionModal extends Component {
  static contextType = IBAContext;

  @observable widgetId = null;

  @action
  setWidgetId = (widgetId) => {
    this.widgetId = widgetId;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get widgetDropdownOptions() {
    const {widgets, excludeWidgetIds = []} = this.props;
    const availableWidgets = filter(widgets, ({id}) => !includes(excludeWidgetIds, id));
    const sortedAvailableWidgets = sortBy(availableWidgets, 'label');
    return sortedAvailableWidgets.map((widget) => ({
      key: widget.id,
      value: widget.id,
      text: widget.label,
      description: WIDGET_TYPES[widget.type],
    }));
  }

  resetState = () => {
    this.setWidgetId(null);
  };

  submit = () => {
    const {widgetId, props: {onClose, onSuccess}} = this;
    onSuccess(widgetId);
    onClose();
  };

  render() {
    const {...props} = this.props;
    delete props.widgets;
    delete props.excludeWidgetIds;
    delete props.onSuccess;
    return (
      <AsyncActionsModal
        closeIcon
        onMount={this.resetState}
        size='tiny'
        header='Add Widget'
        content={
          () => this.widgetDropdownOptions.length ?
            <DropdownControl
              fluid
              search
              placeholder='Choose widget...'
              options={this.widgetDropdownOptions}
              onChange={this.setWidgetId}
            />
          :
            <Message
              info
              icon='info circle'
              content='No unused widgets available.'
            />
        }
        actions={[
          {
            key: 'submit',
            primary: true,
            content: 'Add Widget',
            disabled: this.widgetId === null,
            onClick: this.submit
          },
        ]}
        {...props}
      />
    );
  }
}
