import cx from 'classnames';
import {find, get, keys, map, some, transform} from 'lodash';
import {Component} from 'react';
import {Icon, Popup, Step} from 'semantic-ui-react';

import {getPossibleStageValues, getStageByName, processorCanRaiseAnomalies} from '../../../stageUtils';
import {ProcessorStep, StageStep} from '../../ProbeGraph';

import {inputConnectorSpace, stageStepHeight, processorStepHeight} from './consts';
import {getProcessorSize} from './utils';
import GraphCanvasContext from './GraphCanvasContext';

import './GraphProcessorView.less';

export default class GraphProcessorView extends Component {
  static contextType = GraphCanvasContext;

  static getConnectors(processor, processorDefinitions, editable) {
    const processorDefinition = find(processorDefinitions, {name: processor.type});
    const inputValues = getPossibleStageValues(processorDefinition, 'inputs');
    const canAddInputs = editable && !!inputValues?.['*'];
    const size = getProcessorSize(processor);
    const outputNames = keys(processorDefinition.outputs);
    const inputNames = keys(processor.inputs);
    const inputCount = inputNames.length + (canAddInputs ? 1 : 0);
    const connectors = {
      inputs: transform(inputNames, (acc, inputName, index) => {
        acc[processor.name + inputName] = {
          x: size.width / 2 + (index - (inputCount - 1) / 2.0) * inputConnectorSpace,
          y: 0,
          label: inputName,
          data: {
            type: 'input',
            processor,
            id: inputName,
          }
        };
      }, {}),
      outputs: transform(outputNames, (acc, outputName, index) => {
        const stage = processor.outputs[outputName];
        acc[stage] = {
          x: 1,
          y: stageStepHeight / 2 + processorStepHeight + index * stageStepHeight,
          label: outputName,
          data: {
            type: 'output',
            processor,
            id: outputName,
            stage,
          }
        };
      }, {}),
    };
    if (canAddInputs) {
      connectors.inputs['*'] = {
        x: size.width / 2 + (inputCount - 1) / 2.0 * inputConnectorSpace,
        y: 0,
        label: 'Add input',
        isButton: true,
        data: {
          type: 'add',
          processor,
          id: '*',
        }
      };
    }
    return connectors;
  }

  render() {
    const {processor} = this.props;
    const {currentProcessor, currentStageName, editable, highlightCurrentEntity, highlightProcessorAndRelatedStages,
      highlightStageAndRelatedProcessors, highlights, probe, processorStagesWithErrors, processorsWithErrors,
      setCurrentProcessorName, setCurrentStageName, warningCountByStageName, deleteProcessor} = this.context;
    const hasWarnings = some(processor.outputs, (stageName) => warningCountByStageName[stageName]);
    const hasAnomalies = some(processor.outputs, (stageName) => {
      const {anomaly_count: anomalyCount} = getStageByName({probe, stageName});
      return anomalyCount > 0;
    });
    const hasProcessorError = get(probe, ['last_error', 'processor']) === processor.name ||
      processorsWithErrors[processor.name];
    return (
      <div className='graph-processor-view'>
        <Step.Group
          key={processor.name}
          className={cx({
            'has-warnings': hasWarnings,
            'has-errors': hasAnomalies || hasProcessorError || !!processorStagesWithErrors[processor.name],
          })}
          vertical
          fluid
        >
          <ProcessorStep
            processorStepHeight={processorStepHeight}
            processor={processor}
            active={!currentStageName && currentProcessor && processor.name === currentProcessor.name}
            editable={editable}
            highlights={highlights}
            highlightProcessorAndRelatedStages={highlightProcessorAndRelatedStages}
            highlightCurrentEntity={highlightCurrentEntity}
            setCurrentProcessorName={setCurrentProcessorName}
            hasProcessorError={hasProcessorError}
          />
          {map(processor.outputs, (stageName) => {
            const stage = getStageByName({probe, stageName});
            return <StageStep
              key={stageName}
              stageStepHeight={stageStepHeight}
              stageName={stageName}
              active={stageName === currentStageName}
              editable={editable}
              highlights={highlights}
              highlightStageAndRelatedProcessors={highlightStageAndRelatedProcessors}
              highlightCurrentEntity={highlightCurrentEntity}
              setCurrentStageName={setCurrentStageName}
              anomalyCount={stage.anomaly_count}
              warningCount={warningCountByStageName[stageName]}
              hasStageError={!!get(processorStagesWithErrors, [processor.name, stageName])}
              canRaiseAnomalies={processorCanRaiseAnomalies(processor)}
              hasPersistedData={stage.enable_metric_logging}
              retentionDuration={stage.retention_duration}
              streamingEnabled={processor.properties.enable_streaming}
              isDynamic={stage.dynamic}
            />;
          })}
        </Step.Group>
        {editable &&
          <Popup
            trigger={
              <Icon
                className='close-button'
                link
                size='tiny'
                name='close'
                onClick={() => deleteProcessor(processor)}
                aria-label='Delete'
              />
            }
            content='Delete'
            offset={[-14, 0]}
          />
        }
      </div>
    );
  }
}
