import {Component} from 'react';
import PropTypes from 'prop-types';
import {sumBy, map, groupBy, keys, constant} from 'lodash';
import {reaction, computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import pluralize from 'pluralize';
import cx from 'classnames';

import './AnomalyGaugeGroups.less';
import {getGauges} from './anomalyGaugeGroupsService';
import GaugeGroup from '../GaugeGroup';
import HistoryBarTimeline from '../HistoryBarTimeline';

@observer
export default class AnomalyGaugeGroups extends Component {
  static propTypes = {
    anomalousStages: PropTypes.arrayOf(PropTypes.shape({
      anomalies: PropTypes.number,
      items: PropTypes.number
    })),
    blueprint: PropTypes.shape({
      links: PropTypes.arrayOf(PropTypes.shape({
        composed_of: PropTypes.arrayOf(PropTypes.string),
        role: PropTypes.string,
        endpoints: PropTypes.arrayOf(PropTypes.shape({
          type: PropTypes.string
        }))
      })),
      nodes: PropTypes.arrayOf(PropTypes.shape({
        role: PropTypes.string
      }))
    }),
    serviceAnomalyDigest: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      role: PropTypes.string,
      count: PropTypes.number
    })),
    barCount: PropTypes.number,
    groupWidth: PropTypes.number,
    onProbeGaugeClick: PropTypes.func,
    onServiceGaugeClick: PropTypes.func,
    serviceTypeResolveFn: PropTypes.func,
  };

  static defaultProps = {
    barCount: 20,
    groupWidth: 530,
    serviceTypeResolveFn: constant('serviceTypeResolveFn is not defined'),
  };

  updateTime = new Date();

  constructor(props) {
    super(props);

    makeObservable(this);

    this.disposeGaugesSourceChanges = reaction(
      () => [this.props.anomalousStages, this.props.blueprint, this.props.serviceAnomalyDigest],
      () => (this.updateTime = new Date()),
      {fireImmediately: true}
    );
  }

  componentWillUnmount() {
    this.disposeGaugesSourceChanges();
  }

  @computed get groupedGauges() {
    const {anomalousStages, blueprint, serviceAnomalyDigest, onServiceGaugeClick, onProbeGaugeClick,
      serviceTypeResolveFn} = this.props;
    const gauges = getGauges(blueprint, serviceAnomalyDigest, serviceTypeResolveFn);

    gauges.forEach((gauge) => {
      const {service, type, role} = gauge;
      gauge.onClick = () => onServiceGaugeClick(service, type, role);
    });

    const groupedGaugesMap = groupBy(gauges, 'service');
    const groupedGauges = map(keys(groupedGaugesMap), (service) => ({
      name: service,
      gauges: groupedGaugesMap[service]
    }));

    const allProbesValue = {
      name: 'All Probes',
      minValue: 0,
      value: sumBy(anomalousStages, 'anomalies'),
      maxValue: sumBy(anomalousStages, 'items'),
      description: 'Summary of anomalies detected by user-defined and instantiated probes within the blueprint.',
      onClick: () => onProbeGaugeClick(allProbesValue),
    };
    groupedGauges.unshift({name: 'All Probes', gauges: [allProbesValue]});

    return groupedGauges;
  }

  getInnerContent = (gauge) => {
    const labels = [];
    labels.push(
      <text key='inner-title' className='gauge-inner-title' dy='-.3em'>
        {gauge.name}
      </text>);

    const value = gauge.value;
    const innerValue = pluralize('anomaly', value, true);
    const valueClassName = cx('gauge-inner-value', {highlight: value > 0});
    labels.push(
      <text key='inner-value' className={valueClassName} dy='1em'>
        {innerValue}
      </text>
    );
    return labels;
  };

  render() {
    const {groupedGauges, updateTime, getInnerContent} = this;
    const {barCount, groupWidth} = this.props;

    return (
      <div className='anomalies-gauges-groups'>
        {map(groupedGauges, ({name, gauges}) => (
          <div key={name} className='anomalies-gauges-group'>
            <div className='title ui header'>{name}</div>
            <GaugeGroup gauges={gauges} getInnerContent={getInnerContent} />
            <HistoryBarTimeline
              history={{values: gauges, time: updateTime}}
              width={groupWidth}
              barCount={barCount}
              axisLeftTitle='Anomaly History'
            />
          </div>
        ))}
      </div>
    );
  }
}
