import {lazy, Suspense} from 'react';
import {Loader} from 'apstra-ui-common';

const ApstraCentralRouter = lazy(() => import(/* webpackChunkName: 'apstra-central' */ './ApstraCentralRouter'));

const ApstraCentral = (props) => {
  if (process.env.APSTRA_UI_CENTRAL) {
    return (
      <Suspense fallback={<Loader />}>
        <ApstraCentralRouter {...props} />
      </Suspense>
    );
  }
};

export default ApstraCentral;
