import {Icon, List} from 'semantic-ui-react';
import {find, isEmpty, map, reverse, sortBy, throttle, transform} from 'lodash';
import {Link, useLocation} from 'react-router-dom';
import React, {memo, useEffect, useMemo, useRef, useState} from 'react';
import cx from 'classnames';

import {EllipsisPopup} from '../../../components/EllipsisPopup';
import {REPORT_TYPES} from './const';

import './ReportToC.less';

const ReportToC = ({
  items, sectionRefs, resetScrolledRef, delay, tocContainerPadding,
  defaultContainerTop, reportHeight, collapsedSectionState, onCollapseToggle,
}) => {
  const containerRef = useRef();

  const parentTop = useMemo(() => {
    if (containerRef.current) {
      const {top} = containerRef.current.getBoundingClientRect();
      return top;
    }
    return defaultContainerTop;
  }, [containerRef, defaultContainerTop]);

  // we need to follow report height to recalculate sections' positions
  // after finishing expand/collapse process
  const sectionsScrollY = useMemo(() => {
    const castFlatPathList = (items) => {
      return transform(items, (result, {path, items}) => {
        result.push(path);
        result.push(...castFlatPathList(items));
      });
    };
    return reverse(sortBy(transform(castFlatPathList(items), (result, path) => {
      const element = sectionRefs[path]?.current;
      if (element) {
        const {y} = element.getBoundingClientRect();
        result.push({path, y: y + window.scrollY});
      }
    }, []), 'y'));
  }, [items, sectionRefs, reportHeight]); // eslint-disable-line react-hooks/exhaustive-deps

  const onScroll = throttle(() => {
    const currentSection = find(
      sectionsScrollY,
      ({y}) => y < window.scrollY + parentTop + tocContainerPadding
    );
    if (currentSection?.path !== selectedSection) {
      setSelectedSection(currentSection?.path);
    }
  }, delay);
  const [selectedSection, setSelectedSection] = useState(null);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  const [hoveredPath, setHovered] = useState('');

  return (
    <div
      ref={containerRef}
      className={cx('report-toc')}
    >
      <List onMouseLeave={() => setHovered('')}>
        <ToC
          items={items}
          selectedSection={selectedSection}
          resetScrolledRef={resetScrolledRef}
          containerRef={containerRef}
          setHovered={setHovered}
          hoveredPath={hoveredPath}
          collapsedSectionState={collapsedSectionState}
          onCollapseToggle={onCollapseToggle}
        />
      </List>
    </div>
  );
};

ReportToC.defaultProps = {
  delay: 30,
  tocContainerPadding: 5,
  defaultContainerTop: 113,
};

const ToC = ({
  items, resetScrolledRef, selectedSection, containerRef, hoveredPath, setHovered,
  collapsedSectionState, onCollapseToggle,
}) => {
  return map(items, ({title, path, items}) => (
    <List.Item
      key={path}
      className={cx({
        selected: selectedSection === path,
        hovered: hoveredPath === path,
      })}
    >
      <ItemToC
        title={title}
        path={path}
        resetScrolledRef={resetScrolledRef}
        onHover={setHovered}
        collapsed={collapsedSectionState[path]}
        onCollapseToggle={onCollapseToggle}
      />
      {!isEmpty(items) && (
        <List.List>
          <ToC
            items={items}
            selectedSection={selectedSection}
            resetScrolledRef={resetScrolledRef}
            containerRef={containerRef}
            hoveredPath={hoveredPath}
            setHovered={setHovered}
            collapsedSectionState={collapsedSectionState}
            onCollapseToggle={onCollapseToggle}
          />
        </List.List>
      )}
    </List.Item>
  ));
};

const ItemToC = memo(({title, path, type, resetScrolledRef, onHover, collapsed, onCollapseToggle}) => {
  const {pathname, search} = useLocation();
  const caretDirection = collapsed ? 'right' : 'down';
  return (
    <Link
      className={cx('toc-item', {'toc-chapter': path.length === 1})}
      to={`${pathname}${search}#header.${path}`}
      onClick={resetScrolledRef}
      onMouseEnter={() => onHover(path)}
    >
      <Icon
        link
        name={type === REPORT_TYPES.section ? 'circle' : `caret ${caretDirection}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onCollapseToggle(path);
        }}
      />
      <EllipsisPopup
        content={title}
        trigger={
          <span>{title}</span>
        }
      />
    </Link>
  );
});

export default memo(ReportToC);
