import {ReactComponent as Average} from '../../../styles/icons/iba/average.svg';
import {ReactComponent as Accumulate} from '../../../styles/icons/iba/accumulate.svg';
import {ReactComponent as Anomaly} from '../../../styles/icons/iba/anomaly.svg';
import {ReactComponent as BGP} from '../../../styles/icons/iba/bgp.svg';
import {ReactComponent as Comparison} from '../../../styles/icons/iba/comparison.svg';
import {ReactComponent as Counter} from '../../../styles/icons/iba/counter.svg';
import {ReactComponent as DetailedInterfaceCounter} from '../../../styles/icons/iba/detailed-interface-counter.svg';
import {ReactComponent as Environment} from '../../../styles/icons/iba/environment.svg';
import {ReactComponent as EVPN1} from '../../../styles/icons/iba/evpn1.svg';
import {ReactComponent as EVPN3} from '../../../styles/icons/iba/evpn3.svg';
import {ReactComponent as EVPN4} from '../../../styles/icons/iba/evpn4.svg';
import {ReactComponent as EVPN5} from '../../../styles/icons/iba/evpn5.svg';
import {ReactComponent as ExtensibleDataCollector} from '../../../styles/icons/iba/extensible-data-collector.svg';
import {ReactComponent as GenericDataCollector} from '../../../styles/icons/iba/generic-data-collector.svg';
import {ReactComponent as GenericGraphCollector} from '../../../styles/icons/iba/generic-graph-collector.svg';
import {ReactComponent as Headroom} from '../../../styles/icons/iba/headroom.svg';
import {ReactComponent as LogicalOperator} from '../../../styles/icons/iba/logical-operator.svg';
import {ReactComponent as MatchCount} from '../../../styles/icons/iba/match-count.svg';
import {ReactComponent as MatchPercent} from '../../../styles/icons/iba/match-percent.svg';
import {ReactComponent as MatchString} from '../../../styles/icons/iba/match-string.svg';
import {ReactComponent as Max} from '../../../styles/icons/iba/max.svg';
import {ReactComponent as Min} from '../../../styles/icons/iba/min.svg';
import {ReactComponent as NodeToNodeCounter} from '../../../styles/icons/iba/node-node-counter.svg';
import {ReactComponent as OpticalThreshold} from '../../../styles/icons/iba/optical-threshold.svg';
import {ReactComponent as OpticalXcvr} from '../../../styles/icons/iba/optical-xcvr.svg';
import {ReactComponent as PeriodicAverage} from '../../../styles/icons/iba/periodic-average.svg';
import {ReactComponent as Range} from '../../../styles/icons/iba/range.svg';
import {ReactComponent as Ratio} from '../../../styles/icons/iba/ratio.svg';
import {ReactComponent as SetComparison} from '../../../styles/icons/iba/set-comparison.svg';
import {ReactComponent as ServiceDataCollector} from '../../../styles/icons/iba/service-data-collector.svg';
import {ReactComponent as SetCount} from '../../../styles/icons/iba/set-count.svg';
import {ReactComponent as StdDev} from '../../../styles/icons/iba/standard-deviation.svg';
import {ReactComponent as State} from '../../../styles/icons/iba/state.svg';
import {ReactComponent as Sub} from '../../../styles/icons/iba/sub.svg';
import {ReactComponent as Sum} from '../../../styles/icons/iba/sum.svg';
import {ReactComponent as SystemUtilization} from '../../../styles/icons/iba/system-utilization.svg';
import {ReactComponent as Telemetry} from '../../../styles/icons/iba/telemetry.svg';
import {ReactComponent as TimeInState} from '../../../styles/icons/iba/time-in-state.svg';
import {ReactComponent as VXLANFloodlist} from '../../../styles/icons/iba/vxlan-floodlist.svg';
import {ReactComponent as Union} from '../../../styles/icons/iba/union.svg';

const iconsByProcessorType = {
  accumulate: Accumulate,
  anomaly: Anomaly,
  average: Average,
  extensible_data_collector: ExtensibleDataCollector,
  generic_graph_collector: GenericGraphCollector,
  generic_data_collector: GenericDataCollector,
  service_data_collector: ServiceDataCollector,
  bgp_session: BGP,
  optical_threshold: OpticalThreshold,
  optical_xcvr: OpticalXcvr,
  comparison: Comparison,
  evpn1: EVPN1,
  evpn3: EVPN3,
  evpn4: EVPN4,
  evpn5: EVPN5,
  environment: Environment,
  if_counter: Counter,
  headroom: Headroom,
  logical: LogicalOperator,
  match_count: MatchCount,
  match_perc: MatchPercent,
  match_string: MatchString,
  max: Max,
  min: Min,
  node_to_node_if_counter: NodeToNodeCounter,
  periodic_average: PeriodicAverage,
  range_check: Range,
  ratio: Ratio,
  service_health: Telemetry,
  set_comparison: SetComparison,
  set_count: SetCount,
  std_dev: StdDev,
  state_check: State,
  subtract: Sub,
  sum: Sum,
  system_utilization: SystemUtilization,
  time_in_state_check: TimeInState,
  traffic_monitor: DetailedInterfaceCounter,
  vxlan_floodlist: VXLANFloodlist,
  union: Union,
};

const DefaultIcon = State;

export default function ProcessorIcon({processorType}) {
  const Icon = iconsByProcessorType[processorType] ?? DefaultIcon;
  return <Icon className='iba-processor-icon' />;
}
