import {Component} from 'react';
import {observer} from 'mobx-react';
import {ActionsMenu, hasBlueprintPermissions} from 'apstra-ui-common';

import generateProbeURI from '../generateProbeURI';
import IBAContext from '../IBAContext';

import './ProbeActions.less';

@observer
export default class ProbeActions extends Component {
  static contextType = IBAContext;

  static defaultProps = {
    showExportButton: false,
  };

  render() {
    const {blueprintId, blueprintPermissions} = this.context;
    const {
      probe, processorName, stageName,
      showExportButton, size,
      setProbeDeletionModalProps, setProbeExportModalProps, setPredefinedProbeModalProps,
    } = this.props;
    const {predefined_probe: predefinedProbe} = probe;
    const hasPermissions = hasBlueprintPermissions({blueprintPermissions, action: 'edit'});
    return (
      <div className='probe-actions'>
        {showExportButton &&
          <ActionsMenu
            size={size}
            items={[{
              icon: 'share square',
              title: 'Export',
              onClick: () => setProbeExportModalProps({open: true, probe}),
            }]}
          />
        }
        <ActionsMenu
          size={size}
          items={[
            {
              icon: 'edit',
              title: 'Edit',
              hasPermissions,
              notPermittedMessage: 'You do not have permission to edit',
              ...(predefinedProbe ? {
                onClick: () =>
                  setPredefinedProbeModalProps({open: true, mode: 'update', probe, processorName, stageName})
              } : {
                href: generateProbeURI({blueprintId, probeId: probe.id, action: 'update', processorName, stageName})
              })
            },
            {
              icon: 'clone',
              title: 'Clone',
              hasPermissions,
              notPermittedMessage: 'You do not have permission to clone',
              ...(predefinedProbe ? {
                onClick: () =>
                  setPredefinedProbeModalProps({open: true, mode: 'clone', probe, processorName, stageName})
              } : {
                href: generateProbeURI({blueprintId, probeId: probe.id, action: 'clone', processorName, stageName})
              })
            },
            {
              icon: 'trash',
              title: 'Delete',
              hasPermissions,
              notPermittedMessage: 'You do not have permission to delete',
              onClick: () => setProbeDeletionModalProps({open: true, probes: [probe]}),
            },
          ]}
        />
      </div>
    );
  }
}
