import {ALL_NODE_ROLES} from '../../../roles';

export enum PLANE_ROLES {
  DEFAULT = 'default',
  SPINE_PLANE = 'spine-plane',
  POD = 'pod',
  RACK = 'rack',
  ACCESS_GROUP = 'access-group',
  EXTERNAL = 'external',
  VLAN = 'vlan',
  SECURITY_ZONE = 'security-zone',
}

export const NODE_ROLE_CLASSNAMES: Partial<Record<ALL_NODE_ROLES, string>> = {
  [ALL_NODE_ROLES.EXTERNAL_GENERIC]: 'tp-node-externalGeneric',
  [ALL_NODE_ROLES.SUPERSPINE]: 'tp-node-superspine',
  [ALL_NODE_ROLES.SPINE]: 'tp-node-spine',
  [ALL_NODE_ROLES.LEAF]: 'tp-node-leaf',
  [ALL_NODE_ROLES.SUMMARY]: 'tp-node-summary',
  [ALL_NODE_ROLES.ACCESS]: 'tp-node-access',
  [ALL_NODE_ROLES.GENERIC]: 'tp-node-generic',
};

export interface ISceneSize {
  depth: number;
  width: number;
  headerHeight: number;
  racksHeight: number;
}

export enum ORIENTATION {
  X = 'X',
  Y = 'Y',
  Z = 'Z',
}
