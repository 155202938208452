import {useState, useEffect, useRef} from 'react';
import cx from 'classnames';
import {select, drag} from 'd3';
import {includes, noop} from 'lodash';

import {canvasWidth, canvasHeight} from '../settings';
import './Draggable.less';

const isInbounds = ({x, y}) => {
  return (
    x >= -canvasWidth &&
    y >= -canvasHeight &&
    x < 2 * canvasWidth &&
    y < 2 * canvasHeight
  );
};

const isDraggableArea = (e) => {
  let item = e.srcElement;
  while (item) {
    if (includes(item.classList, 'not-draggable')) {
      return false;
    }
    item = item.parentElement;
  }
  return true;
};

const Draggable = ({position = {x: 0, y: 0}, children, relative = false,
  onStartDragging = noop, onMove = noop, onDrop = noop, disabled}) => {
  const [isDragging, setDragging] = useState(false);
  const offsetRef = useRef();
  const ref = useRef();
  const positionRef = useRef(position);
  useEffect(() => {positionRef.current = position;}, [position]);

  useEffect(() => {
    const itemDrag = drag()
      .filter((event) => !disabled && isDraggableArea(event))
      .clickDistance(5)
      .on('start', (event) => {
        event.sourceEvent.stopPropagation();
        event.sourceEvent.preventDefault();
        offsetRef.current = {x: event.x - positionRef.current.x, y: event.y - positionRef.current.y};
        setDragging(!disabled);
        onStartDragging(event.sourceEvent);
      })
      .on('drag', (event) => {
        event.sourceEvent.preventDefault();
        const newPoint = relative ? {
          x: event.dx,
          y: event.dy,
        } : {
          x: event.x - offsetRef.current.x,
          y: event.y - offsetRef.current.y,
        };
        if (relative || isInbounds(newPoint)) onMove(newPoint);
      })
      .on('end', () => {
        setDragging(false);
        onDrop();
      });
    select(ref.current).call(itemDrag);
  }, [disabled, onDrop, onMove, onStartDragging, relative]);

  const {x, y} = position;
  const classes = cx(
    'draggable',
    {
      drag: isDragging,
      disabled
    }
  );
  return (
    <g
      ref={ref}
      className={classes}
      transform={`translate(${x},${y})`}

    >
      {children}
    </g>
  );
};

export default Draggable;
