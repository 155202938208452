import {Component, Fragment} from 'react';
import {action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {castArray, isUndefined, has} from 'lodash';
import {Field, MultiItemsControl} from 'apstra-ui-common';

import {ALL_NODE_ROLES} from '../../roles';
import SystemNodeControl from './SystemNodeControl';
import SystemInterfaceControl from './SystemInterfaceControl';

@observer
export default class InterfacesInput extends Component {
  @action
  setInterfaceSystemLabel = (index, systemLabel) => {
    const newValue = [...this.props.value];
    newValue[index].system_label = systemLabel;
    newValue[index].interface_name = '';
    this.props.onChange(newValue);
  };

  @action
  setInterfaceName = (index, interfaceName) => {
    const newValue = [...this.props.value];
    newValue[index].interface_name = interfaceName;
    this.props.onChange(newValue);
  };

  @action
  removeInterface = ({index}) => {
    this.props.onChange(this.props.value.filter((ifc, interfaceIndex) => index !== interfaceIndex));
  };

  @action
  addInterface = () => {
    this.props.onChange([...this.props.value, {system_label: '', interface_name: ''}]);
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  getErrorByIndex = (index, key) => {
    const {errors} = this.props;
    if (isUndefined(errors[index])) return [];
    if (!has(errors[index], key)) return [];
    return castArray(errors[index][key]);
  };

  render() {
    const {name, value, schema, required, disabled} = this.props;
    return (
      <Field
        className='multi-items-input'
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errorsPosition='above'
      >
        {(inputProps) =>
          <MultiItemsControl
            items={value}
            buttonText='Add Interface'
            noItemsMessage='No interfaces specified.'
            addItem={this.addInterface}
            removeItem={this.removeInterface}
            disabled={disabled}
          >
            {({item: {system_label: systemLabel, interface_name: interfaceName}, index}) =>
              <Fragment>
                <Field key='system-label-input' errors={this.getErrorByIndex(index, 'system_label')} width={7}>
                  <SystemNodeControl
                    {...inputProps}
                    placeholder='System'
                    value={systemLabel}
                    onChange={(value) => this.setInterfaceSystemLabel(index, value)}
                    allowedRoles={[
                      ALL_NODE_ROLES.SPINE, ALL_NODE_ROLES.LEAF, ALL_NODE_ROLES.SUPERSPINE, ALL_NODE_ROLES.ACCESS
                    ]}
                  />
                </Field>
                <Field key='interface-name-input' errors={this.getErrorByIndex(index, 'interface_name')} width={8}>
                  <SystemInterfaceControl
                    {...inputProps}
                    placeholder='Interface'
                    systemLabel={systemLabel}
                    interfaces={value}
                    value={interfaceName}
                    onChange={(value) => this.setInterfaceName(index, value)}
                  />
                </Field>
              </Fragment>
            }
          </MultiItemsControl>
        }
      </Field>
    );
  }
}
