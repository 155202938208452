import {FC, useContext, useEffect, useRef, useState} from 'react';
import {forEach, isArray, isBoolean, isEmpty, isString, pickBy} from 'lodash';
import {Checkbox, parseState, stringifyState, withRouter} from 'apstra-ui-common';

import UserStoreContext from '../../../../UserStoreContext';
import {TopologySettingsType, TopologySettingsField} from '../types';

import './TopologySettings.less';

const TOPOLOGY_SETTING_QUERY: Record<keyof TopologySettingsType, string> = {
  showLinks: 'show-links',
  expandNodes: 'expand-nodes',
  toggledNodes: 'toggled-nodes',
  showSpines: 'show-spines',
  showSuperspines: 'show-superspines',
  showLeafs: 'show-leafs',
};

const DEFAULT_SETTINGS: TopologySettingsType = {
  expandNodes: false,
  showLinks: false,
  toggledNodes: [],
  showSpines: false,
  showSuperspines: false,
  showLeafs: false,
};

const BOOLEAN_TOPOLOGY_SETTINGS: Array<TopologySettingsField> = [
  'showLinks', 'expandNodes', 'showLeafs', 'showSpines', 'showSuperspines'
];

const parseQueryParams = ({
  location
}) => {
  const searchParams = new URLSearchParams(location.search);
  const settings = {};

  forEach(TOPOLOGY_SETTING_QUERY, (query, field) => {
    if (searchParams.has(query)) {
      const value = searchParams.get(query);
      if (BOOLEAN_TOPOLOGY_SETTINGS.includes(field as TopologySettingsField)) {
        settings[field] = value === 'true';
        return;
      }
      if (field === 'toggledNodes') {
        try {
          const state = parseState(value);
          settings[field] = isArray(state) ? state : [];
        } catch {
          settings[field] = [];
        }
        return;
      }
      settings[field] = value;
    }
  });

  return settings;
};

const useDefaultSettings = ({
  selectedRackId, showLinks, expandNodes, showNodes, persistSettings, userStoreKey, userStore,
  isCollapsed, location
}) => {
  const storedSettings = userStore.getStoreValue([userStoreKey]);
  let initialSettings = storedSettings ?? {};

  if (isCollapsed) {
    initialSettings.expandNodes = true;
  }
  if (selectedRackId) {
    initialSettings.showLinks = true;
  }
  if (isBoolean(showLinks) && !isBoolean(storedSettings?.showLinks)) {
    initialSettings.showLinks = showLinks;
  }
  if (isBoolean(expandNodes) && !isBoolean(storedSettings?.expandNodes)) {
    initialSettings.expandNodes = expandNodes;
  }
  if (showNodes) {
    initialSettings.showNodes = showNodes;
  }
  if (persistSettings) {
    initialSettings = {...initialSettings, ...parseQueryParams({location})};
  }
  if (selectedRackId) {
    initialSettings.expandNodes = true;
  }
  return {
    ...DEFAULT_SETTINGS,
    ...initialSettings
  };
};

export const TopologySettings: FC<any> = withRouter(({
  isCollapsed,
  isFiveStage,
  showLinks,
  expandNodes,
  userStoreKey,
  // toggledNodes,
  basicSettings,
  hideSettings,
  persistSettings,
  hideExpandNodes,
  showNodes,
  onChange,
  selectedPodId,
  selectedRackId,
  selectedAccessGroupId,
  selectedNodeId,
  hasAccessGroups,
  location,
  navigate
}) => {
  const {userStore} = useContext(UserStoreContext);
  const defaultSettings = useDefaultSettings({
    selectedRackId, showLinks, expandNodes, showNodes, persistSettings, userStoreKey, userStore,
    isCollapsed, location
  });
  const [settings, setSettings] = useState<any>(defaultSettings);
  const prevSettingsRef = useRef(defaultSettings);
  useEffect(
    () => {
      onChange?.(settings);

      const settingsUpdates = pickBy(
        settings,
        (value, key) => prevSettingsRef.current[key] !== value
      );

      if (!isEmpty(settingsUpdates)) {
        if (persistSettings) {
          const searchParams = new URLSearchParams(location.search);
          forEach(settingsUpdates, (value, field) => {
            const query = TOPOLOGY_SETTING_QUERY[field];
            if (query) {
              if (isString(value) || isBoolean(value)) {
                searchParams.set(query, value.toString());
              } else if (isArray(value) && !isEmpty(value)) {
                const state = stringifyState(value);
                searchParams.set(query, state);
              } else {
                searchParams.delete(query);
              }
            }
          });
          navigate(`?${searchParams.toString()}`);
        }
        userStore.setFor(userStoreKey, {...userStore.getStoreValue([userStoreKey]) ?? {}, ...settings});
      }

      prevSettingsRef.current = settings;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, settings]
  );
  const visibleSettings: Record<string, boolean> = {
    expandNodes: !hideExpandNodes && (!selectedRackId || (hasAccessGroups && !selectedAccessGroupId)),
    showLinks: true,
    showNodesSpines: selectedRackId && !selectedNodeId && !basicSettings && !isCollapsed && !selectedAccessGroupId,
    showNodesSuperspines: isFiveStage && selectedPodId && !selectedRackId && !selectedNodeId && !basicSettings &&
      !selectedAccessGroupId,
    showNodesLeafs: hasAccessGroups && !!selectedAccessGroupId
  };

  return hideSettings ? null : (
    <div className='topology-settings'>
      {visibleSettings.expandNodes &&
        <Checkbox
          label='Expand Nodes?'
          indeterminate={!settings.expandNodes && !isEmpty(settings.toggledNodes)}
          checked={settings.expandNodes}
          onChange={() => setSettings((v) => ({...v, expandNodes: !v.expandNodes}))}
          aria-labelledby={undefined}
        />
      }
      {visibleSettings.showLinks &&
        <Checkbox
          label='Show Links?'
          checked={settings.showLinks}
          onChange={() => setSettings((v) => ({...v, showLinks: !v.showLinks}))}
          aria-labelledby={undefined}
        />
      }
      {visibleSettings.showNodesSpines &&
        <Checkbox
          label='Show Spines?'
          checked={settings.showSpines}
          onChange={() => setSettings((v) => ({...v, showSpines: !v.showSpines}))}
          aria-labelledby={undefined}
        />
      }
      {visibleSettings.showNodesSuperspines &&
        <Checkbox
          label='Show Superspines?'
          checked={settings.showSuperspines}
          onChange={() => setSettings((v) => ({...v, showSuperspines: !v.showSuperspines}))}
          aria-labelledby={undefined}
        />
      }
      {visibleSettings.showNodesLeafs &&
        <Checkbox
          label='Show Leafs?'
          checked={settings.showLeafs}
          onChange={() => setSettings((v) => ({...v, showLeafs: !v.showLeafs}))}
          aria-labelledby={undefined}
        />
      }
    </div>
  );
});

export const withTopologySettings = (Component, settingsProps) => (props) => {
  const {rackPreview} = props;
  const [topologySettings, setTopologySettings] = useState<any>(
    rackPreview ? {expandNodes: true, showLinks: true} : null
  );
  return (
    <>
      {!rackPreview && <TopologySettings
        {...props}
        {...settingsProps}
        onChange={setTopologySettings}
      />}
      {(topologySettings || rackPreview) && <Component
        {...props}
        {...topologySettings}
      />}
    </>
  );
};
