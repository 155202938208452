import {lazy, Suspense} from 'react';
import {Card} from 'semantic-ui-react';
import {Loader} from 'apstra-ui-common';

const DashboardCard = lazy(() => import(/* webpackChunkName: 'apstra-central-dashboard-card' */ './DashboardCard'));

const DashboardsList = ({items, canEdit, canDelete, onDeleteItem}) => {
  if (process.env.APSTRA_UI_CENTRAL) {
    return (
      <Suspense fallback={<Loader />}>
        <Card.Group itemsPerRow={4}>
          {items.map(({id, label, last_modified_at: lastModifiedAt, href}) =>
            <DashboardCard
              key={href}
              id={id}
              header={label}
              url={href}
              lastModifiedAt={lastModifiedAt}
              canEdit={canEdit}
              canDelete={canDelete}
              onDelete={onDeleteItem}
            />
          )}
        </Card.Group>
      </Suspense>
    );
  }
};

export default DashboardsList;
