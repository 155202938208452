import {Component, Fragment} from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {Grid, Form, Label} from 'semantic-ui-react';
import {Field} from 'apstra-ui-common';

import IBAContext from '../IBAContext';
import Dashboard, {generateDashboardURI} from './Dashboard';
import UpdatedByLabel from './UpdatedByLabel';
import DashboardCaption from './DashboardCaption';
import DashboardDefaultToggle from './DashboardDefaultToggle';
import PredefinedEntityIcon from './PredefinedEntityIcon';

import './DashboardExpanded.less';

@observer
export default class DashboardExpanded extends Component {
  static contextType = IBAContext;

  renderBreadcrumbSections() {
    const {context: {blueprintId}, props: {dashboard, predefinedDashboards}} = this;

    return [{
      key: 'label',
      active: true,
      content: (
        <Fragment>
          <Link to={generateDashboardURI({blueprintId, dashboardId: dashboard.id})}>{dashboard.label}</Link>
          &nbsp;
          {dashboard.predefined_dashboard &&
            <PredefinedEntityIcon
              predefinedEntities={predefinedDashboards}
              predefinedEntityName={dashboard.predefined_dashboard}
              predefinedEntityType='Predefined dashboard'
              trigger={<Label icon='box' />}
            />
          }
          &nbsp;
          <UpdatedByLabel updatedBy={dashboard.updated_by} timestamp={dashboard.updated_at} />
        </Fragment>
      )
    }];
  }

  render() {
    const {
      dashboard, widgets, setDashboardDeletionModalProps,
      dashboardIdToggleInProgress, toggleDashboardDefaultFlag
    } = this.props;

    return (
      <Grid className='iba-dashboard-expanded'>
        <DashboardCaption
          breadcrumbSections={this.renderBreadcrumbSections()}
          dashboard={dashboard}
          setDashboardDeletionModalProps={setDashboardDeletionModalProps}
          nextToBreadcrumbContent={
            <Form>
              <Field label='Default' inline>
                <DashboardDefaultToggle
                  value={dashboard.default}
                  dashboardIdToggleInProgress={dashboardIdToggleInProgress}
                  onChange={toggleDashboardDefaultFlag}
                />
              </Field>
            </Form>
          }
        />
        <Grid.Row>
          <Grid.Column>
            <Dashboard
              editable={false}
              dashboard={dashboard}
              widgets={widgets}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
