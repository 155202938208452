import {uniqBy} from 'lodash';

export const getFilterByProp = ({filters, updateFilters}) => {
  return ({name, value}) => {
    if (name === 'tags') {
      value = {tags: uniqBy([...(filters?.tags?.tags ?? []), value]), strict: true};
    }
    updateFilters({...filters, [name]: value});
  };
};
