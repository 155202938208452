import {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {action, observable, computed, reaction, makeObservable} from 'mobx';
import {scaleBand, scaleLinear} from 'd3';
import {Group} from '@visx/group';
import {Line} from '@visx/shape';
import {Text} from '@visx/text';
import {range, isFinite, map, max, sumBy, take, defer} from 'lodash';
import pluralize from 'pluralize';
import {Popup} from 'semantic-ui-react';
import cx from 'classnames';
import {formatDateAsTimeFromNow} from 'apstra-ui-common';

import './HistoryBarTimeline.less';

@observer
export default class HistoryBarTimeline extends Component {
  static propTypes = {
    axisLeftTitle: PropTypes.string,
    axisRightTitle: PropTypes.string,
    barCount: PropTypes.number,
    className: PropTypes.string,
    height: PropTypes.number,
    history: PropTypes.shape({
      time: PropTypes.instanceOf(Date),
      values: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        maxValue: PropTypes.number
      }))
    }),
    margin: PropTypes.shape({
      top: PropTypes.number,
      right: PropTypes.number,
      left: PropTypes.number,
      bottom: PropTypes.number
    }),
    popupHeaderWord: PropTypes.string,
    width: PropTypes.number
  };

  static defaultProps = {
    axisLeftTitle: 'History',
    axisRightTitle: 'Now',
    barCount: 20,
    height: 80,
    margin: {
      bottom: 20,
      left: 0,
      right: 0,
      top: 0
    },
    popupHeaderWord: 'alert',
    width: 430
  };

  @observable.ref popupDescription = null;
  @observable.ref bars = [];

  constructor(props) {
    super(props);

    makeObservable(this);

    this.yScale = scaleLinear().rangeRound([0, this.maxBarHeight]).clamp(true);
    this.xScale = scaleBand()
      .domain(range(0, this.props.barCount))
      .range([this.props.width, 0]);
    this.barWidth = this.xScale.bandwidth();

    this.disposeHistoryChangeReaction = reaction(
      () => this.props.history,
      () => this.onHistoryChange(),
      {fireImmediately: true}
    );
  }

  componentWillUnmount() {
    this.disposeHistoryChangeReaction();
  }

  @action
  showPopup = (event, bar) => {
    this.popupDescription = {
      node: event.target,
      header: pluralize(this.props.popupHeaderWord, bar.value, true),
      content: formatDateAsTimeFromNow(bar.time),
      time: +bar.time
    };
  };

  @action
  hidePopup = () => {
    this.popupDescription = null;
  };

  @action
  onHistoryChange = () => {
    const {history: {time, values}} = this.props;

    const value = sumBy(values, 'value');
    const maxValue = sumBy(values, 'maxValue');

    const bar = {time, value, maxValue};
    this.bars = [bar, ...this.bars];

    // +1 to show outgoing bar
    const visibleBarCount = this.props.barCount + 1;
    if (this.bars.length > visibleBarCount) {
      this.bars = this.bars.slice(0, visibleBarCount);
    }

    this.yScale.domain([0, max([this.maxValue, 1])]);
  };

  @computed get maxValue() {
    return max(map(take(this.bars, this.props.barCount), 'maxValue'));
  }

  @computed get maxBarHeight() {
    const {height, margin} = this.props;
    return height - margin.bottom - margin.top;
  }

  getBarPosition = (bar, index = 0) => {
    let x = this.xScale(index);

    // if element is moving out of the chart
    if (!isFinite(x)) {
      x = -this.barWidth;
    }
    const height = this.yScale(bar.value);
    const y = -height;
    return {x, y, height};
  };

  render() {
    const {bars, barWidth, maxBarHeight, popupDescription, getBarPosition, showPopup, hidePopup} = this;
    const {className, height, width, margin, axisLeftTitle, axisRightTitle} = this.props;
    const currentBar = bars[0];
    const barProps = {width: barWidth, showPopup, hidePopup};

    return (
      <svg className={cx('timeline-history-bars', className)} height={height} width={width}>
        <Group>
          <Group top={maxBarHeight}>
            {bars.map((bar, index) =>
              <Bar
                key={String(+bar.time)}
                bar={bar}
                {...barProps}
                {...getBarPosition(bar, index)}
              />
            )}
            <Bar
              key='current'
              bar={currentBar}
              current
              {...barProps}
              {...getBarPosition(currentBar)}
            />
          </Group>

          <Group className='axis' top={height - margin.bottom}>
            <Line className='line' x2={width} y1={0} y2={0} />
            <Text y='1.2em' className='left-title'>{axisLeftTitle}</Text>
            <Text y='1.2em' x={width} className='right-title'>{axisRightTitle}</Text>
          </Group>

          {popupDescription &&
            <Popup key={popupDescription.time} size='tiny' position='top center' context={popupDescription.node} open>
              <Popup.Header>{popupDescription.header}</Popup.Header>
              <Popup.Content>{popupDescription.content}</Popup.Content>
            </Popup>
          }
        </Group>
      </svg>
    );
  }
}

@observer
class Bar extends Component {
  @observable animationTriggered = this.props.current;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    if (!this.animationTriggered) {
      defer(action(() => {
        this.animationTriggered = true;
      }));
    }
  }

  render() {
    const {current, x, y, width, height, bar, showPopup, hidePopup} = this.props;
    const {animationTriggered} = this;
    return (isFinite(x) && isFinite(y)) ?
      <rect
        x={x}
        y={animationTriggered ? y : 0}
        height={animationTriggered ? height : 0}
        width={width}
        className={cx('timeline-history-bar', {current})}
        onMouseEnter={(e) => showPopup(e, bar)}
        onMouseLeave={hidePopup}
      />
    :
      null;
  }
}
