import {Component} from 'react';
import {FetchDataError, GenericErrors, NetworkError,
  interpolateRoute, request, responseBodyErrorsAsArray} from 'apstra-ui-common';

import IBAContext from '../IBAContext';
import MultiUploadModal from '../../components/MultiUploadModal';

export default class ProbeBatchImportModal extends Component {
  static contextType = IBAContext;

  importProbe = ({file, signal}) => request(
    interpolateRoute(this.context.routes.probeList, {blueprintId: this.context.blueprintId}),
    {method: 'POST', body: file, signal}
  );

  renderError = (error) => {
    if (error instanceof NetworkError) {
      const {response, responseBody} = error;
      if (response && response.status === 422 && responseBody && responseBody.errors) {
        return <GenericErrors errors={responseBodyErrorsAsArray(responseBody.errors, 'probe')} />;
      }
    }
    return <FetchDataError error={error} />;
  };

  render() {
    return (
      <MultiUploadModal
        header='Import Probes'
        description='Drag and drop probe files here or click the button below.'
        action='Import'
        accept={{'application/json': [], 'text/plain': []}}
        uploadFile={this.importProbe}
        renderError={this.renderError}
        {...this.props}
        {...this.context}
      />
    );
  }
}
