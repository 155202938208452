import {Component} from 'react';
import {observer} from 'mobx-react';
import {ActionsMenu, hasBlueprintPermissions} from 'apstra-ui-common';

import {generateDashboardURI} from './Dashboard';
import IBAContext from '../IBAContext';

@observer
export default class DashboardActions extends Component {
  static contextType = IBAContext;

  render() {
    const {blueprintId, blueprintPermissions} = this.context;
    const {dashboard, size, setDashboardDeletionModalProps} = this.props;
    const hasPermissions = hasBlueprintPermissions({blueprintPermissions, action: 'edit'});
    return <ActionsMenu
      size={size}
      items={[
        {
          icon: 'edit',
          title: 'Edit',
          hasPermissions,
          notPermittedMessage: 'You do not have permission to edit',
          href: generateDashboardURI({blueprintId, dashboardId: dashboard.id, action: 'update'}),
        },
        {
          icon: 'clone',
          title: 'Clone',
          hasPermissions,
          notPermittedMessage: 'You do not have permission to clone',
          href: generateDashboardURI({blueprintId, dashboardId: dashboard.id, action: 'clone'}),
        },
        {
          icon: 'trash',
          title: 'Delete',
          hasPermissions,
          notPermittedMessage: 'You do not have permission to delete',
          onClick: () => setDashboardDeletionModalProps({open: true, dashboards: [dashboard]}),
        },
      ]}
    />;
  }
}
