import {forEach, max, transform, truncate} from 'lodash';

export const getFittedTickLabels = ({
  textLength, scaleFn, labels, labelFormatFn = (value) => value, labelPadding = 4, widthMax,
}) => {
  if (!textLength || !scaleFn) {
    return transform(labels, (result, group) => {
      result[group] = labelFormatFn(group);
    }, {});
  }

  const showTicks = new Array(labels.length);
  let maxCharacters = -1;
  let widthBetweenTicks = 0;
  forEach(labels, (groupRaw, index) => {
    const label = labelFormatFn(groupRaw);
    const prevX = index - 1 < 0 ? 0 : scaleFn(labels[index - 1]);
    const nextX = index + 1 === labels.length ? widthMax : scaleFn(labels[index + 1]);
    const width = Math.ceil((nextX - prevX) / 2);
    showTicks[index] = (textLength[label] + labelPadding <= width);
    if (showTicks[index] && label.length > maxCharacters) {
      maxCharacters = label.length;
    }
    if (widthBetweenTicks < width) widthBetweenTicks = width;
  });

  return transform(labels, (result, label, index) => {
    if (showTicks[index]) {
      result[label] = labelFormatFn(label);
    } else {
      result[label] = truncate(labelFormatFn(label), {
        length: max([maxCharacters, Math.floor(widthBetweenTicks / 5.5)])
      });
    }
  }, {});
};
